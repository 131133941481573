export default {
  taskFrequencyOptions: [
    "One time",
    "Daily",
    "Weekly",
    "Bi-weekly",
    "Monthly",
    "Quarterly",
    "Yearly",
    "First day of each month",
    "Last day of each month",
  ],

  taskFrequencyTimesOptions: [
    { _id: 1, times: "1 time" },
    { _id: 2, times: "2 times" },
    { _id: 3, times: "3 times" },
    { _id: 4, times: "4 times" },
    { _id: 5, times: "5 times" },
    { _id: 6, times: "6 times" },
    { _id: 7, times: "7 times" },
    { _id: 8, times: "8 times" },
    { _id: 9, times: "9 times" },
    { _id: 10, times: "10 times" },

    { _id: 11, times: "11 times" },
    { _id: 12, times: "12 times" },
    { _id: 13, times: "13 times" },
    { _id: 14, times: "14 times" },
    { _id: 15, times: "15 times" },
    { _id: 16, times: "16 times" },
    { _id: 17, times: "17 times" },
    { _id: 18, times: "18 times" },
    { _id: 19, times: "19 times" },
    { _id: 20, times: "20 times" },

    { _id: 21, times: "21 times" },
    { _id: 22, times: "22 times" },
    { _id: 23, times: "23 times" },
    { _id: 24, times: "24 times" },
    { _id: 25, times: "25 times" },
    { _id: 26, times: "26 times" },
    { _id: 27, times: "27 times" },
    { _id: 28, times: "28 times" },
    { _id: 29, times: "29 times" },
    { _id: 30, times: "30 times" },

    { _id: 31, times: "31 times" },
    { _id: 32, times: "32 times" },
    { _id: 33, times: "33 times" },
    { _id: 34, times: "34 times" },
    { _id: 35, times: "35 times" },
    { _id: 36, times: "36 times" },
    { _id: 37, times: "37 times" },
    { _id: 38, times: "38 times" },
    { _id: 39, times: "39 times" },
    { _id: 40, times: "40 times" },

    { _id: 41, times: "41 times" },
    { _id: 42, times: "42 times" },
    { _id: 43, times: "43 times" },
    { _id: 44, times: "44 times" },
    { _id: 45, times: "45 times" },
    { _id: 46, times: "46 times" },
    { _id: 47, times: "47 times" },
    { _id: 48, times: "48 times" },
    { _id: 49, times: "49 times" },
    { _id: 50, times: "50 times" },

    { _id: 51, times: "51 times" },
    { _id: 52, times: "52 times" },
    { _id: 53, times: "53 times" },
    { _id: 54, times: "54 times" },
    { _id: 55, times: "55 times" },
    { _id: 56, times: "56 times" },
    { _id: 57, times: "57 times" },
    { _id: 58, times: "58 times" },
    { _id: 59, times: "59 times" },
    { _id: 60, times: "60 times" },

    { _id: 61, times: "61 times" },
    { _id: 62, times: "62 times" },
    { _id: 63, times: "63 times" },
    { _id: 64, times: "64 times" },
    { _id: 65, times: "65 times" },
    { _id: 66, times: "66 times" },
    { _id: 67, times: "67 times" },
    { _id: 68, times: "68 times" },
    { _id: 69, times: "69 times" },
    { _id: 70, times: "70 times" },

    { _id: 71, times: "71 times" },
    { _id: 72, times: "72 times" },
    { _id: 73, times: "73 times" },
    { _id: 74, times: "74 times" },
    { _id: 75, times: "75 times" },
    { _id: 76, times: "76 times" },
    { _id: 77, times: "77 times" },
    { _id: 78, times: "78 times" },
    { _id: 79, times: "79 times" },
    { _id: 80, times: "80 times" },

    { _id: 81, times: "81 times" },
    { _id: 82, times: "82 times" },
    { _id: 83, times: "83 times" },
    { _id: 84, times: "84 times" },
    { _id: 85, times: "85 times" },
    { _id: 86, times: "86 times" },
    { _id: 87, times: "87 times" },
    { _id: 88, times: "88 times" },
    { _id: 89, times: "89 times" },
    { _id: 90, times: "90 times" },

    { _id: 91, times: "91 times" },
    { _id: 92, times: "92 times" },
    { _id: 93, times: "93 times" },
    { _id: 94, times: "94 times" },
    { _id: 95, times: "95 times" },
    { _id: 96, times: "96 times" },
    { _id: 97, times: "97 times" },
    { _id: 98, times: "98 times" },
    { _id: 99, times: "99 times" },
    { _id: 100, times: "100 times" },
  ],

  countryOptions: [
    {
      code: "AF",
      name: "Afghanistan",
    },
    {
      code: "AL",
      name: "Albania",
    },
    {
      code: "DZ",
      name: "Algeria",
    },
    {
      code: "AD",
      name: "Andorra",
    },
    {
      code: "AO",
      name: "Angola",
    },
    {
      code: "AG",
      name: "Antigua and Barbuda",
    },
    {
      code: "AR",
      name: "Argentina",
    },
    {
      code: "AM",
      name: "Armenia",
    },
    {
      code: "AU",
      name: "Australia",
    },
    {
      code: "AT",
      name: "Austria",
    },
    {
      code: "AZ",
      name: "Azerbaijan",
    },
    {
      code: "BS",
      name: "Bahamas",
    },
    {
      code: "BH",
      name: "Bahrain",
    },
    {
      code: "BD",
      name: "Bangladesh",
    },
    {
      code: "BB",
      name: "Barbadus",
    },
    {
      code: "BY",
      name: "Belarus",
    },
    {
      code: "BE",
      name: "Belgium",
    },
    {
      code: "BZ",
      name: "Belize",
    },
    {
      code: "BJ",
      name: "Benin",
    },
    {
      code: "BT",
      name: "Bhutan",
    },
    {
      code: "BO",
      name: "Bolivia",
    },
    {
      code: "BA",
      name: "Bosnia And Herzegovina",
    },
    {
      code: "BW",
      name: "Botswana",
    },
    {
      code: "BR",
      name: "Brazil",
    },
    {
      code: "BN",
      name: "Brunei Darussalam",
    },
    {
      code: "BG",
      name: "Bulgaria",
    },
    {
      code: "BF",
      name: "Burkina Faso",
    },
    {
      code: "BI",
      name: "Burundi",
    },
    {
      code: "KH",
      name: "Cambodia",
    },
    {
      code: "CM",
      name: "Cameroon",
    },
    {
      code: "CA",
      name: "Canada",
    },
    {
      code: "CV",
      name: "Capo Verde",
    },
    {
      code: "CF",
      name: "Central African Republic",
    },
    {
      code: "TD",
      name: "Chad",
    },
    {
      code: "CL",
      name: "Chile",
    },
    {
      code: "CN",
      name: "China",
    },
    {
      code: "CO",
      name: "Colombia",
    },
    {
      code: "KM",
      name: "Comoros",
    },
    {
      code: "CG",
      name: "Congo",
    },
    {
      code: "CD",
      name: "Congo, Democratic Republic",
    },
    {
      code: "CR",
      name: "Costa Rica",
    },
    {
      code: "HR",
      name: "Croatia",
    },
    {
      code: "CU",
      name: "Cuba",
    },
    {
      code: "CY",
      name: "Cyprus",
    },
    {
      code: "CZ",
      name: "Czech Republic",
    },
    {
      code: "DK",
      name: "Denmark",
    },
    {
      code: "DJ",
      name: "Djibouti",
    },
    {
      code: "DM",
      name: "Dominica",
    },
    {
      code: "DO",
      name: "Dominican Republic",
    },
    {
      code: "TL",
      name: "East Timor",
    },
    {
      code: "EC",
      name: "Ecuador",
    },
    {
      code: "EG",
      name: "Egypt",
    },
    {
      code: "SV",
      name: "El Salvador",
    },
    {
      code: "GQ",
      name: "Equatorial Guinea",
    },
    {
      code: "ER",
      name: "Eritrea",
    },
    {
      code: "EE",
      name: "Estonia",
    },
    {
      code: "SZ",
      name: "Eswatini",
    },
    {
      code: "ET",
      name: "Ethiopia",
    },
    {
      code: "FJ",
      name: "Fiji",
    },
    {
      code: "FI",
      name: "Finland",
    },
    {
      code: "FR",
      name: "France",
    },
    {
      code: "GA",
      name: "Gabon",
    },
    {
      code: "GM",
      name: "Gambia",
    },
    {
      code: "GE",
      name: "Georgia",
    },
    {
      code: "DE",
      name: "Germany",
    },
    {
      code: "GH",
      name: "Ghana",
    },
    {
      code: "GR",
      name: "Greece",
    },
    {
      code: "GD",
      name: "Grenada",
    },
    {
      code: "GT",
      name: "Guatemala",
    },
    {
      code: "GN",
      name: "Guinea",
    },
    {
      code: "GW",
      name: "Guinea-Bissau",
    },
    {
      code: "GY",
      name: "Guyana",
    },
    {
      code: "HT",
      name: "Haiti",
    },
    {
      code: "HN",
      name: "Honduras",
    },
    {
      code: "HK",
      name: "Hong Kong",
    },
    {
      code: "HU",
      name: "Hungary",
    },
    {
      code: "IS",
      name: "Iceland",
    },
    {
      code: "IN",
      name: "India",
    },
    {
      code: "ID",
      name: "Indonesia",
    },
    {
      code: "IR",
      name: "Iran, Islamic Republic Of",
    },
    {
      code: "IQ",
      name: "Iraq",
    },
    {
      code: "IE",
      name: "Ireland",
    },
    {
      code: "IL",
      name: "Israel",
    },
    {
      code: "IT",
      name: "Italy",
    },
    {
      code: "JM",
      name: "Jamaica",
    },
    {
      code: "JP",
      name: "Japan",
    },
    {
      code: "JO",
      name: "Jordan",
    },
    {
      code: "KZ",
      name: "Kazakhstan",
    },
    {
      code: "KE",
      name: "Kenya",
    },
    {
      code: "KI",
      name: "Kiribati",
    },
    {
      code: "KR",
      name: "Korea",
    },
    {
      code: "XK",
      name: "Kosovo",
    },
    {
      code: "KW",
      name: "Kuwait",
    },
    {
      code: "KG",
      name: "Kyrgyzstan",
    },
    {
      code: "LA",
      name: "Lao People's Democratic Republic",
    },
    {
      code: "LV",
      name: "Latvia",
    },
    {
      code: "LB",
      name: "Lebanon",
    },
    {
      code: "LS",
      name: "Lesotho",
    },
    {
      code: "LR",
      name: "Liberia",
    },
    {
      code: "LY",
      name: "Libya",
    },
    {
      code: "LI",
      name: "Liechtenstein",
    },
    {
      code: "LT",
      name: "Lithuania",
    },
    {
      code: "LU",
      name: "Luxembourg",
    },
    {
      code: "MG",
      name: "Madagascar",
    },
    {
      code: "MW",
      name: "Malawi",
    },
    {
      code: "MY",
      name: "Malaysia",
    },
    {
      code: "MV",
      name: "Maldives",
    },
    {
      code: "ML",
      name: "Mali",
    },
    {
      code: "MT",
      name: "Malta",
    },
    {
      code: "MH",
      name: "Marshall Islands",
    },
    {
      code: "MR",
      name: "Mauritania",
    },
    {
      code: "MU",
      name: "Mauritius",
    },
    {
      code: "MX",
      name: "Mexico",
    },
    {
      code: "FM",
      name: "Micronesia, Federated States Of",
    },
    {
      code: "MD",
      name: "Moldova",
    },
    {
      code: "MC",
      name: "Monaco",
    },
    {
      code: "MN",
      name: "Mongolia",
    },
    {
      code: "ME",
      name: "Montenegro",
    },
    {
      code: "MA",
      name: "Morocco",
    },
    {
      code: "MZ",
      name: "Mozambique",
    },
    {
      code: "MM",
      name: "Myanmar",
    },
    {
      code: "NA",
      name: "Namibia",
    },
    {
      code: "NR",
      name: "Nauru",
    },
    {
      code: "NP",
      name: "Nepal",
    },
    {
      code: "NL",
      name: "Netherlands",
    },
    {
      code: "NZ",
      name: "New Zealand",
    },
    {
      code: "NI",
      name: "Nicaragua",
    },
    {
      code: "NE",
      name: "Niger",
    },
    {
      code: "NG",
      name: "Nigeria",
    },
    {
      code: "NO",
      name: "Norway",
    },
    {
      code: "OM",
      name: "Oman",
    },
    {
      code: "PK",
      name: "Pakistan",
    },
    {
      code: "PW",
      name: "Palau",
    },
    {
      code: "PS",
      name: "Palestine",
    },
    {
      code: "PA",
      name: "Panama",
    },
    {
      code: "PG",
      name: "Papua New Guinea",
    },
    {
      code: "PY",
      name: "Paraguay",
    },
    {
      code: "PE",
      name: "Peru",
    },
    {
      code: "PH",
      name: "Philippines",
    },
    {
      code: "PL",
      name: "Poland",
    },
    {
      code: "PT",
      name: "Portugal",
    },
    {
      code: "QA",
      name: "Qatar",
    },
    {
      code: "RO",
      name: "Romania",
    },
    {
      code: "RU",
      name: "Russian Federation",
    },
    {
      code: "RW",
      name: "Rwanda",
    },
    {
      code: "KN",
      name: "Saint Kitts and Nevis",
    },
    {
      code: "LC",
      name: "Saint Lucia",
    },
    {
      code: "VC",
      name: "Saint Vincent and the Grenadines",
    },
    {
      code: "WS",
      name: "Samoa",
    },
    {
      code: "SM",
      name: "San Marino",
    },
    {
      code: "ST",
      name: "Sao Tome And Principe",
    },
    {
      code: "SA",
      name: "Saudi Arabia",
    },
    {
      code: "SN",
      name: "Senegal",
    },
    {
      code: "RS",
      name: "Serbia",
    },
    {
      code: "SC",
      name: "Seychelles",
    },
    {
      code: "SL",
      name: "Sierra Leone",
    },
    {
      code: "SG",
      name: "Singapore",
    },
    {
      code: "SK",
      name: "Slovakia",
    },
    {
      code: "SI",
      name: "Slovenia",
    },
    {
      code: "SB",
      name: "Solomon Islands",
    },
    {
      code: "SO",
      name: "Somalia",
    },
    {
      code: "ZA",
      name: "South Africa",
    },
    {
      code: "SS",
      name: "South Sudan",
    },
    {
      code: "ES",
      name: "Spain",
    },
    {
      code: "LK",
      name: "Sri Lanka",
    },
    {
      code: "SD",
      name: "Sudan",
    },
    {
      code: "SR",
      name: "Suriname",
    },
    {
      code: "SE",
      name: "Sweden",
    },
    {
      code: "CH",
      name: "Switzerland",
    },
    {
      code: "SY",
      name: "Syrian Arab Republic",
    },
    {
      code: "TW",
      name: "Taiwan",
    },
    {
      code: "TJ",
      name: "Tajikistan",
    },
    {
      code: "TZ",
      name: "Tanzania",
    },
    {
      code: "TH",
      name: "Thailand",
    },
    {
      code: "TG",
      name: "Togo",
    },
    {
      code: "TO",
      name: "Tonga",
    },
    {
      code: "TT",
      name: "Trinidad and Tobago",
    },
    {
      code: "TN",
      name: "Tunisia",
    },
    {
      code: "TR",
      name: "Turkey",
    },
    {
      code: "TM",
      name: "Turkmenistan",
    },
    {
      code: "TV",
      name: "Tuvalu",
    },
    {
      code: "UG",
      name: "Uganda",
    },
    {
      code: "UA",
      name: "Ukraine",
    },
    {
      code: "AE",
      name: "United Arab Emirates",
    },
    {
      code: "GB",
      name: "United Kingdom",
    },
    {
      code: "US",
      name: "United States",
    },
    {
      code: "UY",
      name: "Uruguay",
    },
    {
      code: "UZ",
      name: "Uzbekistan",
    },
    {
      code: "VU",
      name: "Vanuatu",
    },
    {
      code: "VA",
      name: "Vatican City",
    },
    {
      code: "VE",
      name: "Venezuela",
    },
    {
      code: "VN",
      name: "Vietnam",
    },
    {
      code: "YE",
      name: "Yemen",
    },
    {
      code: "ZM",
      name: "Zambia",
    },
    {
      code: "ZW",
      name: "Zimbabwe",
    },
  ],
};
