import styled from "styled-components";
import Colors from "../../Constants/Colors";
import Sizes from "../../Constants/sizes";

const LinkSpan = styled.span`
  width: 100%;
  height: 2.75rem;
  max-width: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  cursor: pointer;
  color: ${Colors.white};
  border-radius: 0.5rem;
  background-color: ${Colors.primary};
  transition: background-color 0.5s ease-in;
  &:hover {
    background-color: ${Colors.hoverButtonColor};
  }
`;

const Link = styled.span`
  font-weight: 500;
  cursor: pointer;
  color: ${Colors.font1};
  transition: all 0.3s;

  &:hover {
    color: ${Colors.primary};
  }

  &:active {
    display: inline-block;
    transform: scale(0.94);
  }
`;

const Box = styled.div`
  background: ${Colors.white};
  width: 100%;
  max-width: 540px;
  box-shadow: 0 24px 64px #26214a1a;
  border-radius: 0.75rem;
  padding: 1.5rem;
  @media (max-width: 768px) {
    padding: 1.5rem 1rem;
  }
`;

const PasswordInputRectangle = styled.input`
  width: 100%;
  height: ${Sizes.formInputHeight};
  margin: 0 0 6px;
  padding: 0 2rem 0 1rem;
  border-radius: ${Sizes.formInputBorderRadius};
  border: 1px solid ${Colors.borderInput};
  background-color: ${Colors.white};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;

  &:hover {
    border: 1px solid ${Colors.primary};
  }

  :focus {
    border: 1px solid ${Colors.primary};
  }
`;

const SocialLoginButton = styled.button`
  width: 100%;
  height: ${Sizes.formInputHeight};
  font-family: Poppins, serif;
  padding: 0 0.5rem;
  font-size: 1rem;
  font-weight: 500;
  opacity: 0.9 !important;
  color: ${(props) => (props.color ? props.color : Colors.font1)};
  border-radius: ${Sizes.formInputBorderRadius};
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : Colors.white};
  border: 1px solid ${Colors.borderInput};
  &:hover {
    opacity: 1 !important;
    border: 1px solid ${Colors.primary};
  }

  @media (max-width: 768px) {
    .icons {
      position: absolute;
      width: 5rem;
      height: 3.15rem;
      left: 0;
      top: 0;
    }
  }
`;

export default {
  LinkSpan,
  Link,
  Box,
  PasswordInputRectangle,
  SocialLoginButton,
};
