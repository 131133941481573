import React from "react";
import { makeStyles } from "@mui/styles";
import Select from "@mui/material/Select";

import MenuItem from "@mui/material/MenuItem";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Colors from "../../../Constants/Colors";

const useStyles = makeStyles({
  root: {
    border: "1px solid lightgrey !important",
    backgroundColor: Colors.white,
    fontFamily: "Poppins !important",
    fontSize: "0.875rem !important",
    height: "2.75rem !important",
    "&:hover": {
      border: "1px solid grey !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },

  select: {
    display: "flex !important",
    justifyContent: "start",
    alignItems: "center !important",
  },

  listMenu: {
    color: "grey",
    marginBottom: "2px !important",
    fontSize: "0.875rem !important",
    fontFamily: "Poppins !important",
    "&:hover": {
      backgroundColor: `${Colors.backgroundPink} !important`,
      border: "none",
    },
    '&[aria-selected="true"]': {
      backgroundColor: `${Colors.backgroundPink} !important`,
    },
  },
});

const invoicedStatusOptions = [
  { label: "All statuses", value: "All statuses" },
  { label: "Draft", value: "Draft" },
  { label: "Sent", value: "Sent" },
  { label: "Due", value: "Overdue" },
  { label: "Paid", value: "Paid" },
];

export default function InvoiceStatus(props) {
  const classes = useStyles();
  return (
    <div>
      <Select
        classes={{
          select: classes.select,
        }}
        className={classes.root}
        value={props.defaultValue ? props.defaultValue : "All statuses"}
        variant="outlined"
        onChange={props.onChange}
        MenuProps={{
          PaperProps: { sx: { maxHeight: 250 } },
        }}
      >
        {invoicedStatusOptions &&
          invoicedStatusOptions.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              classes={{
                root: classes.listMenu,
              }}
            >
              <FiberManualRecordIcon
                sx={{
                  color:
                    option.label === "Draft"
                      ? Colors.font1
                      : option.label === "Sent"
                      ? Colors.primary
                      : option.label === "Due"
                      ? "#f74040"
                      : option.label === "Paid"
                      ? "#188652"
                      : "#c3c3c6",
                  fontSize: 15,
                  margin: "0 6px 0 0",
                }}
              />
              {option.label}
            </MenuItem>
          ))}
      </Select>
    </div>
  );
}
