import styled from "styled-components";
import Colors from "../../../../Constants/Colors";

const ReviewContainer = styled.div`
  width: 100%;
  padding: 1rem 1.5rem;
  boxshadow: 0px 0px 9px 0px lightgrey;
  background: ${Colors.white};
  @media (max-width: 576px) {
    padding: 1rem 0.5rem;
  }
`;

const BrandLogoBox = styled.div`
  width: 100%;
  height: auto;
  min-height: 14rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 0.75rem;
  border: dashed 3px #a9abb3;
  background-color: #e5eaf0;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.1);
  cursor: pointer;
`;

const ReviewBox = styled.div`
  width: 100%;
  height: auto;
  padding: 1.5rem;
  border-radius: 0.75rem;
  background-color: ${Colors.backgroundGrey};
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.1);
  .edit_client_info .edit_client_infoBtn {
    visibility: hidden;
  }
  .edit_client_info:hover .edit_client_infoBtn {
    visibility: visible;
  }

  .edit_your_info .edit_your_infoBtn {
    visibility: hidden;
  }
  .edit_your_info:hover .edit_your_infoBtn {
    visibility: visible;
  }

  @media (max-width: 576px) {
    padding: 1.5rem 1rem;
  }
`;

const TableHead = styled.div`
  border-radius: 0.75rem;
  padding: 1rem;
  background-color: ${Colors.tableHeadColor};
`;

const TableCell = styled.div`
  border-radius: 0.75rem;
  padding: 1rem;
  border-bottom: 2px solid #f4efef;
`;

const SubmitButton = styled.button`
  min-width: 9.375rem;
  position: fixed;
  height: 3.15rem;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 3px;
  bottom: 3px;
  /* padding: 1rem 1.25rem; */
  font-family: Poppins, serif;
  font-size: 0.875rem;
  color: ${(props) => props.color || Colors.white};
  border-radius: 0.75rem;
  background-color: ${(props) => props.backgroundColor || Colors.primary};
  transition: background-color 0.4s ease;
  &:hover {
    background-color: ${(props) =>
      props.backgroundColor || Colors.hoverButtonColor};
  }

  &:active {
    background-color: ${(props) => props.backgroundColor || Colors.primary};
  }
`;

export default {
  ReviewContainer,
  BrandLogoBox,
  ReviewBox,
  TableHead,
  TableCell,
  SubmitButton,
};
