import React, { useState } from "react";
import Styled from "./DeletedUsersStyles";
import TextStyles from "../../../../Constants/TextStyles";
import moment from "moment";
import DeleteIcon from "@mui/icons-material/Delete";
import Colors from "../../../../Constants/Colors";
import DeleteModal from "./DeleteModal";

const deletedUsers = [
  {
    _id: "1",
    firstName: "santraj",
    lastName: "Yadav",
    email: "santrajyadav196@gmail.com",
    country: {
      code: "IN",
      name: "India",
    },
    createdAt: new Date(),
    updatedAt: new Date(),
    userType: "email",
  },
  {
    _id: "2",
    firstName: "santraj",
    lastName: "Yadav",
    email: "santrajyadav196@gmail.com",
    country: {
      code: "IN",
      name: "India",
    },
    createdAt: new Date(),
    updatedAt: new Date(),
    userType: "google",
  },
  {
    _id: "3",
    firstName: "santraj",
    lastName: "Yadav",
    email: "santrajyadav196@gmail.com",
    country: {
      code: "IN",
      name: "India",
    },
    createdAt: new Date(),
    updatedAt: new Date(),
    userType: "linkedIn",
  },
  {
    _id: "3",
    firstName: "santraj",
    lastName: "Yadav",
    email: "santrajyadav196@gmail.com",
    country: {
      code: "IN",
      name: "India",
    },
    createdAt: new Date(),
    updatedAt: new Date(),
    userType: "google",
  },
];

const DeletedUsers = () => {
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const deleteUserHandler = () => {
    console.log("user deleted successfully");
  };
  return (
    <div>
      <TextStyles.FontSize16px className="font-weight-500 mt-3 mt-md-4">
        User Details
      </TextStyles.FontSize16px>

      <Styled.TableHead className="mt-3">
        <div className="d-flex gap-2 align-items-center font-weight-500">
          <TextStyles.FontSize14px
            className="d-flex justify-content-start text-start"
            style={{ width: "120%", minWidth: 30 }}
          >
            <span className="three-dots">Name</span>
          </TextStyles.FontSize14px>

          <TextStyles.FontSize14px
            className="d-flex justify-content-start"
            style={{ width: "160%", minWidth: 30 }}
          >
            <span className="three-dots"> Email</span>
          </TextStyles.FontSize14px>

          <TextStyles.FontSize14px
            className="d-flex justify-content-center"
            style={{ width: "90%", minWidth: 30 }}
          >
            <span className="three-dots">Country</span>
          </TextStyles.FontSize14px>

          <TextStyles.FontSize14px
            className="d-flex justify-content-center"
            style={{ width: "90%", minWidth: 30 }}
          >
            <span className="three-dots">CreatedAt</span>
          </TextStyles.FontSize14px>

          <TextStyles.FontSize14px
            className="d-flex justify-content-center"
            style={{ width: "90%", minWidth: 30 }}
          >
            <span className="three-dots">DeletedAt</span>
          </TextStyles.FontSize14px>

          <TextStyles.FontSize14px
            className="d-flex justify-content-center"
            style={{ width: "90%", minWidth: 30 }}
          >
            <span className="three-dots">User Type</span>
          </TextStyles.FontSize14px>
          <TextStyles.FontSize14px
            className="d-flex justify-content-end"
            style={{
              width: "60%",
              maxWidth: 35,
              minWidth: 35,
            }}
          />
        </div>
      </Styled.TableHead>
      {deletedUsers &&
        deletedUsers.length > 0 &&
        deletedUsers.map((item, index) => {
          return (
            <Styled.TableCell
              key={index}
              style={{
                borderRadius:
                  deletedUsers.length - 1 === index ? "0 0 12px 12px" : "",
              }}
            >
              <div className={`d-flex gap-2 align-items-center w-100 `}>
                <TextStyles.FontSize14px
                  className="d-flex justify-content-start text-start"
                  style={{ width: "120%", minWidth: 30 }}
                >
                  <span className="three-dots">
                    {item && `${item.firstName} ${item.lastName}`}
                  </span>
                </TextStyles.FontSize14px>

                <TextStyles.FontSize14px
                  className="d-flex justify-content-start"
                  style={{ width: "160%", minWidth: 30 }}
                >
                  <span className="three-dots">{item && item.email}</span>
                </TextStyles.FontSize14px>

                <TextStyles.FontSize14px
                  className="d-flex justify-content-center"
                  style={{ width: "90%", minWidth: 30 }}
                >
                  <span className="three-dots">
                    {item &&
                      item.country &&
                      `${item.country.name}(${item.country.code})`}
                  </span>
                </TextStyles.FontSize14px>

                <TextStyles.FontSize14px
                  className="d-flex justify-content-center"
                  style={{ width: "90%", minWidth: 30 }}
                >
                  <span className="three-dots w-75">
                    {item &&
                      item.createdAt &&
                      moment(item && item.createdAt).format("DD MMM YYYY")}
                  </span>
                </TextStyles.FontSize14px>

                <TextStyles.FontSize14px
                  className="d-flex justify-content-center"
                  style={{ width: "90%", minWidth: 30 }}
                >
                  <span className="three-dots">
                    {item &&
                      item.updatedAt &&
                      moment(item && item.updatedAt).format("DD MMM YYYY")}
                  </span>
                </TextStyles.FontSize14px>

                <TextStyles.FontSize14px
                  className="d-flex justify-content-center"
                  style={{ width: "90%", minWidth: 30 }}
                >
                  <span className="three-dots">{item && item.userType}</span>
                </TextStyles.FontSize14px>

                <TextStyles.FontSize14px
                  className="d-flex justify-content-end"
                  style={{
                    width: "60%",
                    maxWidth: 35,
                    minWidth: 35,
                  }}
                >
                  <DeleteIcon
                    onClick={(e) => {
                      setShow(true);
                      setConfirmDialog({
                        onConfirm: () => deleteUserHandler(item),
                      });
                    }}
                    sx={{
                      color: Colors.red,
                      fontSize: 30,
                      background: Colors.white,
                      padding: "2px 6px",
                      borderRadius: "6px",
                      width: 35,
                      height: 30,
                    }}
                  />
                </TextStyles.FontSize14px>
              </div>
            </Styled.TableCell>
          );
        })}
      <DeleteModal
        show={show}
        handleClose={handleClose}
        confirmDialog={confirmDialog}
      />
    </div>
  );
};

export default DeletedUsers;
