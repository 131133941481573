import React from "react";
import { Modal } from "react-bootstrap";
import Colors from "../../../../Constants/Colors";
import TextStyles from "../../../../Constants/TextStyles";

const DeleteModal = ({ confirmDialog, show, handleClose }) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      contentClassName="ToMakeModalBorderRudius12px"
    >
      <div
        className="modal-content"
        style={{ border: "none", borderRadius: 12, padding: "0 15px" }}
      >
        <div className="container-fluid">
          <TextStyles.FontSize20px className="d-flex justify-content-start font-weight-500 my-3">
            Delete User
          </TextStyles.FontSize20px>
          <TextStyles.FontSize14px className="d-flex justify-content-start mb-3">
            Are you sure you want to delete this user?
          </TextStyles.FontSize14px>
          <div className="row my-4">
            <div className="col">
              <TextStyles.CancelButton onClick={handleClose}>
                Cancel
              </TextStyles.CancelButton>
            </div>
            <div className="col">
              <TextStyles.Button
                color={Colors.white}
                borderColor={Colors.red}
                backgroundColor={Colors.red}
                onClick={() => {
                  handleClose();
                  confirmDialog.onConfirm();
                }}
              >
                Delete
              </TextStyles.Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModal;
