export default {
  REGITER: {
    url: "auth/register",
  },
  VERIFY_USER: {
    url: "auth/verify",
  },
  RESEND_EMAIL: {
    url: "auth/resend_email_verification",
  },
  LOGIN: {
    url: "auth/login",
  },
  RESET_PASSWORD: {
    url: "auth/reset-password",
  },
  VERIFY_RESET_PASSWORD: {
    url: "auth/verify-reset-password",
  },
  CHANGE_PASSWORD: {
    url: "auth/change-password",
  },
  SOCIAL_LOGIN: {
    url: "auth/social-login",
  },
  FETCH_USER_SETUP_DATA: {
    url: "auth/fetch-setup-data",
  },
  HIDE_USER_SETUP_DATA: {
    url: "auth/hide-setup-data",
  },
  UPDATE_USER_INFO: {
    url: "auth/profile/account-security/user-info",
  },
  GET_USER_INFO: {
    url: "auth/profile/account-security/user-info",
  },
  UPDATE_USER_PASSWORD: {
    url: "auth/profile/update-password",
  },

  UPDATE_BUSINESS_INFO: {
    url: "auth/profile/business/business-info",
  },
  FETCH_BRAND_AND_LOGO: {
    url: "auth/profile/business/brand_and_logo",
  },

  UPDATE_BRAND_COLOR: {
    url: "auth/profile/business/brand_color",
  },
  UPDATE_BRAND_LOGO: {
    url: "auth/profile/business/brand_logo",
  },
  UPDATE_BRAND_LOGO_ALIGNMENT: {
    url: "auth/profile/business/brand_logo_alignment",
  },
  FETCH_BUSINESS_INFO: {
    url: "auth/profile/business/business-info",
  },
  SET_USER_CURRENCY: {
    url: "auth/profile/business/currency",
  },
  GET_USER_CURRENCY: {
    url: "auth/profile/business/currency",
  },

  FETCH_TEMPLATES: {
    url: "auth/profile/fetch_templates",
  },

  CREATE_TEMPLATE: {
    url: "auth/profile/create_template",
  },

  UPDATE_TEMPLATE: {
    url: "auth/profile/update_template",
  },
  DELETE_TEMPLATE: {
    url: "auth/profile/delete_template",
  },

  DELETE_USER_ACCOUNT: {
    url: "auth/delete_user_account",
  },

  FETCH_USER_TOUR_SETUP_DATA: {
    url: "auth/fetch-tour-setup-data",
  },
  UPDATE_USER_TOUR_SETUP_DATA: {
    url: "auth/update-tour-setup-data",
  },

  // clients routes
  CREATE_CLIENT: {
    url: "clients/create-client",
  },
  FETCH_CLIENT: {
    url: "clients/fetch-clients",
  },
  FETCH_CLIENT_BY_ID: {
    url: "clients/fetch-client",
  },
  FETCH_CLIENT_FOR_A_PROJECT: {
    url: "clients/fetch-client-for-a-project",
  },
  ARCHIVE_CLIENT: {
    url: "clients/archive-client",
  },
  UNARCHIVE_CLIENT: {
    url: "clients/unarchive-client",
  },
  DELETE_CLIENT: {
    url: "clients/delete-client",
  },
  EDIT_CLIENT_ADDRESS: {
    url: "clients/edit_client_address",
  },
  CREATE_CONTACT: {
    url: "clients/create_contact",
  },
  UPDATE_CONTACT: {
    url: "clients/update_contact",
  },
  DELETE_CONTACT: {
    url: "/clients/delete_contact",
  },

  //Project Routes
  CREATE_PROJECT: {
    url: "projects/create-project",
  },
  UPDATE_PROJECT_DETAILS: {
    url: "projects/update_complete_project",
  },
  FETCH_PROJECT: {
    url: "projects/fetch-projects",
  },

  FETCH_PROJECT_BY_ID: {
    url: "projects/fetch-project",
  },
  FETCH_PROJECTS_FOR_A_CLIENT: {
    url: "projects/fetch-projects-for-a-client",
  },
  CHANGE_PROJECT_STATUS: {
    url: "projects/change_project_status",
  },
  UPDATE_PROJECT_START_DATE: {
    url: "projects/update_project_start_date",
  },
  UPDATE_PROJECT_END_DATE: {
    url: "projects/update_project_end_date",
  },
  UPDATE_PROJECT_BI_SC_START_DATE: {
    url: "projects/update_project_billing_schedule_start_date",
  },

  // Transactions
  CREATE_TRANSACTION: {
    url: "tr/transaction",
  },
  DELETE_TRANSACTION: {
    url: "tr/transaction",
  },
  FTECH_TRANSACTIONS: {
    url: "tr/transactions",
  },
  FTECH_TRANSACTIONS_FOR_GRAPH: {
    url: "tr/transactions_for_graph",
  },
  UPDATE_TRANSACTION: {
    url: "tr/transaction",
  },
  // statements

  INCOMES_FILTER: {
    url: "statements/incomes_filter",
  },

  EXPENSES_FILTER: {
    url: "statements/expenses_filter",
  },

  TAXES_FILTER: {
    url: "statements/taxes_filter",
  },
  // dashBoard

  CREATE_QUICK_TASK: {
    url: "home/create_quick_task",
  },

  CREATE_TASK: {
    url: "home/create_task",
  },

  FETCH_TASKS: {
    url: "home/fetch_tasks",
  },
  EDIT_TASK: {
    url: "home/edit_task",
  },

  EDIT_TASK_DUE_DATE: {
    url: "home/edit_task_due_date",
  },

  DELETE_TASK: {
    url: "home/delete_task",
  },

  UPDATE_TASK_STATUS: {
    url: "home/change_task_status",
  },

  FTECH_TRANSACTION_COUNTS: {
    url: "tr/transaction_counts",
  },

  // proposals

  CREATE_PROPOSAL_CONTRACT: {
    url: "pc/proposal_contract",
  },
  FETCH_PROPOSAL_CONTRACT: {
    url: "pc/proposal_contracts",
  },
  UPDATE_PROJECT_DETAILS_PC: {
    url: "pc/proposal_contract/project_details",
  },
  UPDATE_CLIENT_DETAILS_PC: {
    url: "pc/proposal_contract/edit_client",
  },

  EDIT_SERVICE_LIST_PC: {
    url: "pc/proposal_contract/edit_service",
  },
  EDIT_BILLING_SCHEDULE_PC: {
    url: "pc/proposal_contract/edit_billing_schedule",
  },

  CREATE_ADD_SECTION_TEXT_PC: {
    url: "pc/proposal_contract/add_sections_text",
  },
  DELETE_ADD_SECTION_TEXT_PC: {
    url: "pc/proposal_contract/add_sections_text",
  },
  UPDATE_ADD_SECTION_TEXT_PC: {
    url: "pc/proposal_contract/add_sections_text",
  },
  CREATE_ADD_SECTION_IMAGE_PC: {
    url: "pc/proposal_contract/add_sections_image",
  },
  DELETE_ADD_SECTION_IMAGE_PC: {
    url: "pc/proposal_contract/add_sections_image",
  },
  UPDATE_ADD_SECTION_IMAGE_PC: {
    url: "pc/proposal_contract/add_sections_image",
  },
  CREATE_ADD_SECTION_TABLE_PC: {
    url: "pc/proposal_contract/add_sections_table",
  },
  DELETE_ADD_SECTION_TABLE_PC: {
    url: "pc/proposal_contract/add_sections_table",
  },
  UPDATE_ADD_SECTION_TABLE_PC: {
    url: "pc/proposal_contract/add_sections_table",
  },
  CREATE_ADD_SECTION_COLUMN_PC: {
    url: "pc/proposal_contract/add_sections_column",
  },
  DELETE_ADD_SECTION_COLUMN_PC: {
    url: "pc/proposal_contract/add_sections_column",
  },
  UPDATE_ADD_SECTION_COLUMN_PC: {
    url: "pc/proposal_contract/add_sections_column",
  },
  CREATE_ADD_SECTION_FILE_PC: {
    url: "pc/proposal_contract/add_sections_file",
  },
  UPDATE_ADD_SECTION_FILE_PC: {
    url: "pc/proposal_contract/add_sections_file",
  },
  DELETE_ADD_SECTION_FILE_PC: {
    url: "pc/proposal_contract/add_sections_file",
  },
  FETCH_PC_COUNTS: {
    url: "pc/pc_counts",
  },
  // UPDATE_ADD_SECTION_FILE_PC: {
  //   url: "pc/proposal_contract/add_sections_file",
  // },
  UPDATE_SIGNATURE_PC: {
    url: "pc/proposal_contract/signature",
  },

  CREATE_CLIENT_CONTACT: {
    url: "pc/proposal_contract/create_contact",
  },

  EMAIL_SEND_PC: {
    url: "pc/proposal_contract/send_email",
  },

  CONTRACT_SETTINGS_PC: {
    url: "pc/proposal_contract/contract_settings",
  },
  CONTRACT_EXPIRE_DATE_PC: {
    url: "pc/proposal_contract/contract_expire_date",
  },
  UPDATE_ACCEPTANCE_PC: {
    url: "pc/proposal_contract/acceptance",
  },
  UPDATE_WARRANTY_PC: {
    url: "pc/proposal_contract/warranty",
  },
  UPDATE_CONFIDENTIAL_PC: {
    url: "pc/proposal_contract/confidential",
  },
  UPDATE_RESPONSIBILITY_PC: {
    url: "pc/proposal_contract/responsibility",
  },
  UPDATE_COMPENSATION_PC: {
    url: "pc/proposal_contract/compensation",
  },
  UPDATE_OWNERSHIP_RIGHT_PC: {
    url: "pc/proposal_contract/ownership_right",
  },
  UPDATE_NON_SOLICIT_PC: {
    url: "pc/proposal_contract/non_solicit",
  },
  UPDATE_ADD_NON_COMPLETE_PC: {
    url: "pc/proposal_contract/add_non_complete",
  },
  UPDATE_RELATIONSHIP_OF_PARTIES_PC: {
    url: "pc/proposal_contract/relationship_of_parties",
  },
  UPDATE_PROJECT_TERMINATION_NOTICE_PERIOD_PC: {
    url: "pc/proposal_contract/project_termination_notice_period",
  },
  UPDATE_CANCELLATION_FEE_PC: {
    url: "pc/proposal_contract/cancellation_fee",
  },
  UPDATE_PAYMENT_TERMS_PC: {
    url: "pc/proposal_contract/payment_terms",
  },
  UPDATE_CONTRACTOR_TAX_PAYEER_ID_PC: {
    url: "pc/proposal_contract/contractor_tax_id",
  },
  UPDATE_HEALTH_INSURANCE_PC: {
    url: "pc/proposal_contract/health_insurance",
  },
  UPDATE_LATE_PAYMENT_PC: {
    url: "pc/proposal_contract/late_payment",
  },
  UPDATE_DEPOSIT_PC: {
    url: "pc/proposal_contract/deposit",
  },
  UPDATE_REIMBURSEMENT_PC: {
    url: "pc/proposal_contract/reimbursement",
  },
  UPDATE_TERMINATION_REQUEST_PC: {
    url: "pc/proposal_contract/termination_request",
  },
  UPDATE_CHANGES_PC: {
    url: "pc/proposal_contract/changes",
  },
  UPDATE_LIABILITY_LIMITATION_PC: {
    url: "pc/proposal_contract/liability_limitation",
  },
  UPDATE_RETAIN_RIGHTS_PC: {
    url: "pc/proposal_contract/retain_rights",
  },
  UPDATE_GOVERNING_LAW_PC: {
    url: "pc/proposal_contract/governing_law",
  },
  UPDATE_FORCE_MEJEURE_PC: {
    url: "pc/proposal_contract/force_mejeure",
  },
  UPDATE_NOTICES_PC: {
    url: "pc/proposal_contract/notices",
  },
  UPDATE_ADD_SEXUAL_HARASSMENT_CLAUSE_PC: {
    url: "pc/proposal_contract/add_sexual_harassment_clause",
  },
  UPDATE_MISCELLANEOUS_PC: {
    url: "pc/proposal_contract/miscellaneous",
  },
  UPDATE_PRIVACY_DATA_PC: {
    url: "pc/proposal_contract/privacy_data",
  },
  UPDATE_ENTIRE_CONTRACT_PC: {
    url: "pc/proposal_contract/entire_contract",
  },
  CREATE_ADDCLAUSE_PC: {
    url: "pc/proposal_contract/add_add_section_clause",
  },
  UPDATE_ADDCLAUSE_PC: {
    url: "pc/proposal_contract/update_add_section_clause",
  },
  DELETE_PC: {
    url: "pc/delete_pc",
  },
  DISMISSED_PC_BY_USER: {
    url: "pc/dismissed",
  },
  APPROVED_PC_BY_USER: {
    url: "pc/approved",
  },

  // reminders
  FETCH_REMINDERS: {
    url: "reminder/fetch-reminders",
  },
  DISMISS_REMINDER: {
    url: "reminder/dismiss-reminder",
  },
  DONE_REMINDER: {
    url: "reminder/done-reminder",
  },

  // notifications
  FETCH_NOTIFICATIONS: {
    url: "notification/fetch-notifications",
  },
  VIEWED_NOTIFICATIONS: {
    url: "notification/viewed-notifications",
  },

  //admin routes
  FETCH_ACTIVE_USERS: {
    url: "admin/activeUsers",
  },
  FETCH_DELETED_USERS: {
    url: "admin/deletedUsers",
  },
  DELETE_DELETED_USER: {
    url: "admin/deleteDeletedUser",
  },
};
