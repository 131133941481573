import styled from "styled-components";
import Colors from "../../../../Constants/Colors";

const taxBox = styled.div`
  .deleteBtn {
    display: none;
  }
  :hover .deleteBtn {
    display: flex;
    cursor: pointer;
  }
`;

const inputBox = styled.div`
  width: 100%;
  height: 3.15rem;
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 0.75rem;
  padding: 1rem;
  margin-right: 3px;
  background: ${Colors.white};
  border: 1px solid ${Colors.font2};

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }

  percentage::placeholder {
    text-align: right;
  }
`;

export default {
  taxBox,
  inputBox,
};
