import React from "react";
import { Modal } from "react-bootstrap";

import TextStyles from "../../../Constants/TextStyles";
import Colors from "../../../Constants/Colors";
const DismissModal = (props) => {
  const {
    confirmDialog,
    showDismissModal,
    handleCloseDismissModal,
    title,
    content,
  } = props;

  return (
    <Modal
      show={showDismissModal}
      onHide={handleCloseDismissModal}
      centered
      contentClassName="ToMakeModalBorderRudius12px"
    >
      <div
        className="modal-content"
        style={{ border: "none", borderRadius: 12, padding: "0 15px" }}
      >
        <div className="container-fluid">
          <TextStyles.FontSize20px className="d-flex justify-content-start my-3">
            Dismiss {title}
          </TextStyles.FontSize20px>
          <TextStyles.FontSize14px className="d-flex justify-content-start mb-3">
            Are you sure you want to dismiss the {title}?
          </TextStyles.FontSize14px>
          <TextStyles.FontSize14px className="mb-4">
            {content}
          </TextStyles.FontSize14px>

          <div className="row my-4">
            <div className="col">
              <TextStyles.CancelButton onClick={handleCloseDismissModal}>
                <div className="d-flex justify-content-center">Cancel</div>
              </TextStyles.CancelButton>
            </div>
            <div className="col">
              <TextStyles.Button
                color={Colors.white}
                borderColor={Colors.red}
                backgroundColor={Colors.red}
                onClick={() => {
                  handleCloseDismissModal();
                  confirmDialog.onConfirm();
                }}
              >
                <div className="d-flex justify-content-center">Dismiss</div>
              </TextStyles.Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DismissModal;
