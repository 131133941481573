import axios from "axios";

const axiosClient = axios.create();
axiosClient.defaults.baseURL = process.env.REACT_APP_BASE_URL;

axiosClient.defaults.timeout = 20000;

axiosClient.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.code === "ECONNABORTED") {
      err.message = "Server error. Please try again!";
    }

    return Promise.reject(err);
  }
);

export async function getRequest(URL, varToken, payload) {
  const config = {
    headers: {
      authorization: "Bearer " + varToken,
    },
    params: payload,
  };

  return axiosClient.get(URL, config).then((response) => response);
}

export async function postRequest(URL, varToken, payload) {
  const config = {
    headers: {
      authorization: "Bearer " + varToken,
    },
  };

  return axiosClient
    .post(URL, payload, varToken ? config : {})
    .then((response) => response);
}

export async function putRequest(URL, varToken, payload) {
  const config = {
    headers: {
      authorization: "Bearer " + varToken,
    },
  };

  return axiosClient.put(URL, payload, config).then((response) => response);
}

export async function deleteRequest(URL, varToken, payload) {
  const config = {
    headers: {
      authorization: "Bearer " + varToken,
    },
    params: payload,
  };
  return axiosClient.delete(URL, config, payload);
}
