import React, { useState } from "react";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { message } from "antd";
import TextStyles from "../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";
import { useDispatch, useSelector } from "react-redux";
import { updateLiabilityLimitationPC } from "../../../../../../store/service/proposalAndContractService";
import LiabilityLimitationModal from "../../PageModals/LiabilityLimitationModal";
import RemoveModal from "../../../../Commons/RemoveModal";
import Colors from "../../../../../../Constants/Colors";

const LiabilityLimitaion = () => {
  const selectedData = useSelector(
    (state) => state.proposalAndContract.selectedData
  );
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const handleCloseRemoveModal = () => setShowRemoveModal(false);

  const heading =
    selectedData &&
    selectedData.liabilityLimitation &&
    selectedData.liabilityLimitation.heading
      ? selectedData.liabilityLimitation.heading
      : "Indemnification and Limitation of Liability";

  const paragraph =
    selectedData &&
    selectedData.liabilityLimitation &&
    selectedData.liabilityLimitation.paragraph
      ? selectedData &&
        selectedData.liabilityLimitation &&
        selectedData.liabilityLimitation.paragraph
      : `Contractor shall indemnify, defend, and hold the Client harmless from any and all claims, actions, damages, and liabilities (excluding attorneys’ fees, costs, and expenses) arising from: (i) Contractor’s gross negligence, (ii) any claim that the materials or deliverables, or any portion thereof, indeed infringe upon or violate any proprietary rights of any third party, including but not limited to patent, copyright, and trade secret rights, or (iii) a breach or alleged breach of any of Contractor’s representations, warranties, or agreements herein.\n\nClient shall indemnify, defend, and hold the Contractor harmless from any and all claims, actions, damages, liabilities, costs, and expenses (including reasonable attorneys’ fees) arising in any manner caused by:(i) Client’s gross negligence,(ii) any claim that Client provided content, or any portion thereof, indeed infringes upon or violates any proprietary rights of any third party, including but not limited to patent, copyright, and trade secret rights, or(iii) a breach or alleged breach of any of Client’s representations, warranties, or agreements herein.\n\nTo the fullest extent permitted by applicable law, Contractor shall not be liable to the Client for any incidental, consequential, indirect, special, punitive, or exemplary damages (including damages for lost profit, loss of business, or similar) arising from or relating to this Attachment B or this Agreement, Contractor’s performance hereunder, or disruption of any of the foregoing, even if the Client has been advised of the possibility of such damages and regardless of the cause of action, whether sounding in contract, tort, breach of warranty, or otherwise. To the fullest extent permitted by applicable law, Contractor’s aggregate liability under this Attachment B and this Agreement shall in no event exceed the aggregate compensation paid by the Client to the Contractor under this Agreement.`;

  const hideClauseHandler = () => {
    const Obj = {
      _id: selectedData._id,
      liabilityLimitation: {
        required: false,
        heading: null,
        paragraph: null,
      },
    };
    dispatch(updateLiabilityLimitationPC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleCloseRemoveModal();
        message.success("Clause hidden successfully!");
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  return (
    <div
      style={{
        display:
          selectedData &&
          selectedData.liabilityLimitation &&
          selectedData.liabilityLimitation.required === true
            ? "inline"
            : "none",
      }}
    >
      <Styled.ReviewContentBox className="mt-4">
        <Styled.EditButtonToolTip
          onClick={() => setShowModal(true)}
          className="contract_review_edit_tp"
          style={{ marginLeft: -60 }}
        >
          <BorderColorOutlinedIcon sx={{ color: Colors.white, fontSize: 20 }} />
          <span className="ms-1">Edit</span>
        </Styled.EditButtonToolTip>
        <Styled.EditButtonToolTip
          backgroundColor="#383838"
          className="contract_review_edit_tp"
          style={{ marginLeft: 60 }}
          onClick={() => {
            setShowRemoveModal(true);
            setConfirmDialog({
              onConfirm: hideClauseHandler,
            });
          }}
        >
          <VisibilityOffIcon sx={{ color: Colors.white, fontSize: 20 }} />
          <span className="ms-1">Hide</span>
        </Styled.EditButtonToolTip>

        <TextStyles.FontSize20px className="mb-2">
          {heading}
        </TextStyles.FontSize20px>
        <TextStyles.FontSize14px style={{ whiteSpace: "break-spaces" }}>
          {paragraph}
        </TextStyles.FontSize14px>
      </Styled.ReviewContentBox>
      {showModal && (
        <LiabilityLimitationModal
          selectedData={selectedData}
          heading={heading}
          show={showModal}
          handleClose={() => setShowModal(false)}
          paragraph={paragraph}
        />
      )}
      {confirmDialog && (
        <RemoveModal
          title={heading}
          confirmDialog={confirmDialog}
          showRemoveModal={showRemoveModal}
          handleCloseRemoveModal={handleCloseRemoveModal}
        />
      )}
    </div>
  );
};

export default LiabilityLimitaion;
