import { createSlice } from "@reduxjs/toolkit";
import {
  fetchUserSetupData,
  hideSetupInfo,
  updateUserInfo,
  getUserInfo,
  updateUserPassword,
  updateBusinessInfo,
  updateProfileInfoPic,
  getProfileInfoPic,
  deleteProfileInfoPic,
  fetchBusinessInfo,
  setUserCurrency,
  getUserCurrency,
  updateBrandColor,
  updateBrandLogo,
  removeBrandLogo,
  updateLogoAlignment,
  fetchBrandAndLogo,
  fetchTemplates,
  createTemplate,
  updateTemplate,
  deleteTemplate,
  fetchUserTourSetupData,
  updateUserTourSetupData,
  changeUserEmail,
} from "../service/AuthService";

const initialAuthState = {
  isLoading: false,
  error: false,
  userSetupData: null,
  userTourSetupData: null,
  data: null,
  profileData: null,
  businessInfoData: null,
  userCurrency: null,
  brandAndLogoData: {
    brand_color: null,
    brand_logo: null,
    logo_alignment: null,
  },
  templateData: null,
};

const authSlice = createSlice({
  name: "authentication",
  initialState: initialAuthState,
  reducers: {
    login: (state, action) => {
      state.data = action.payload;
    },
    logout: () => {
      localStorage.clear();
      localStorage.setItem("logout", Date.now().toString());
    },
  },
  extraReducers: (builder) => {
    builder
      // user setup info
      .addCase(fetchUserSetupData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUserSetupData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userSetupData = action.payload.data;
      })
      .addCase(fetchUserSetupData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // hide setup info
      .addCase(hideSetupInfo.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(hideSetupInfo.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(hideSetupInfo.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // update user info
      .addCase(updateUserInfo.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateUserInfo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.profileData = action.payload.data;
        state.data.firstName = action.payload.data.parent_user_id.firstName;
        state.data.lastName = action.payload.data.parent_user_id.lastName;
        // state.data.email = action.payload.data.parent_user_id.email;
      })
      .addCase(updateUserInfo.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // get user info
      .addCase(getUserInfo.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserInfo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.profileData = action.payload.data;
      })
      .addCase(getUserInfo.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // update user password
      .addCase(updateUserPassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateUserPassword.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(updateUserPassword.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // business info
      .addCase(updateBusinessInfo.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateBusinessInfo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.businessInfoData = action.payload.data;
      })
      .addCase(updateBusinessInfo.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // update user profile pic
      .addCase(updateProfileInfoPic.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateProfileInfoPic.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data.profilePic = action.payload.data.profilePic;
        state.data.imageUrl = action.payload.data.imageUrl;
      })
      .addCase(updateProfileInfoPic.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // get user profile pic
      .addCase(getProfileInfoPic.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProfileInfoPic.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data.profilePic =
          action.payload.data && action.payload.data.profilePic
            ? action.payload.data.profilePic
            : "";
        state.data.imageUrl =
          action.payload.data && action.payload.data.imageUrl
            ? action.payload.data.imageUrl
            : "";
      })
      .addCase(getProfileInfoPic.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // delete user profile pic
      .addCase(deleteProfileInfoPic.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteProfileInfoPic.fulfilled, (state) => {
        state.isLoading = false;
        state.data.profilePic = "";
        state.data.imageUrl = "";
      })
      .addCase(deleteProfileInfoPic.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // fetch business info
      .addCase(fetchBusinessInfo.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchBusinessInfo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.businessInfoData = action.payload.data;
      })
      .addCase(fetchBusinessInfo.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // set user currency
      .addCase(setUserCurrency.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(setUserCurrency.fulfilled, (state, action) => {
        state.userCurrency = action.payload.data;
        state.isLoading = false;
      })
      .addCase(setUserCurrency.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // get user currency
      .addCase(getUserCurrency.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserCurrency.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userCurrency = action.payload.data;
      })
      .addCase(getUserCurrency.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // fetch brand and logo
      .addCase(fetchBrandAndLogo.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchBrandAndLogo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.brandAndLogoData.brand_color =
          action.payload.data && action.payload.data.brand_color
            ? action.payload.data.brand_color
            : null;
        state.brandAndLogoData.brand_logo =
          action.payload.data && action.payload.data.brand_logo
            ? action.payload.data.brand_logo
            : null;
        state.brandAndLogoData.logo_alignment =
          action.payload.data && action.payload.data.logo_alignment
            ? action.payload.data.logo_alignment
            : null;
      })
      .addCase(fetchBrandAndLogo.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // update brand color
      .addCase(updateBrandColor.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateBrandColor.fulfilled, (state, action) => {
        state.brandAndLogoData.brand_color = action.payload.data.brand_color;
        state.isLoading = false;
      })
      .addCase(updateBrandColor.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // update brand logo
      .addCase(updateBrandLogo.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateBrandLogo.fulfilled, (state, action) => {
        state.brandAndLogoData.brand_logo = action.payload.data.brand_logo;
        state.isLoading = false;
      })
      .addCase(updateBrandLogo.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // remove brand logo
      .addCase(removeBrandLogo.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(removeBrandLogo.fulfilled, (state, action) => {
        state.brandAndLogoData.brand_logo = action.payload.data.brand_logo;
        state.isLoading = false;
      })
      .addCase(removeBrandLogo.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // update logo alignment
      .addCase(updateLogoAlignment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateLogoAlignment.fulfilled, (state, action) => {
        state.brandAndLogoData.logo_alignment =
          action.payload.data.logo_alignment;
        state.isLoading = false;
      })
      .addCase(updateLogoAlignment.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // fetch templates
      .addCase(fetchTemplates.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchTemplates.fulfilled, (state, action) => {
        state.templateData = action.payload.data;
        state.isLoading = false;
      })
      .addCase(fetchTemplates.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // create template
      .addCase(createTemplate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createTemplate.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(createTemplate.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // update template
      .addCase(updateTemplate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateTemplate.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(updateTemplate.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // delete template
      .addCase(deleteTemplate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteTemplate.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteTemplate.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // fetch user tour setup info
      .addCase(fetchUserTourSetupData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUserTourSetupData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userTourSetupData = action.payload.data;
      })
      .addCase(fetchUserTourSetupData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // update user tour setup info
      .addCase(updateUserTourSetupData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateUserTourSetupData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userTourSetupData = action.payload.data;
      })
      .addCase(updateUserTourSetupData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      .addCase(changeUserEmail.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(changeUserEmail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data.email = action.payload.email;
      })
      .addCase(changeUserEmail.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const authActions = authSlice.actions;

export default authSlice.reducer;
