import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import Colors from "../../Constants/Colors";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import PeopleAltRoundedIcon from "@mui/icons-material/PeopleAltRounded";
import FolderSharedRoundedIcon from "@mui/icons-material/FolderSharedRounded";
import AccountBalanceWalletRoundedIcon from "@mui/icons-material/AccountBalanceWalletRounded";
import SaveAsRoundedIcon from "@mui/icons-material/SaveAsRounded";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";
import AccessAlarmsRoundedIcon from "@mui/icons-material/AccessAlarmsRounded";
import RequestPageRoundedIcon from "@mui/icons-material/RequestPageRounded";
import ManageAccountsRoundedIcon from "@mui/icons-material/ManageAccountsRounded";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { useSelector } from "react-redux";

const SideNavContent = (props) => {
  const history = useHistory();
  const homeRoute = useRouteMatch("/db/home");
  const clientRoute = useRouteMatch("/db/clients");
  const projectsRoute = useRouteMatch("/db/projects");
  const AgreementsRoute = useRouteMatch("/db/agreements");
  const transRoute = useRouteMatch("/db/transactions");
  const timerRoute = useRouteMatch("/db/timetracking");
  const statementRoute = useRouteMatch("/db/statements");
  const invoicesRoute = useRouteMatch("/db/invoices");
  const settingsRoute = useRouteMatch("/db/setting");
  const adminRoute = useRouteMatch("/db/admin");

  const data = useSelector((state) => state.auth.data);

  const myDesk = () => {
    history.push("/db/home");
  };

  const clients_dv = () => {
    history.push("/db/clients");
  };

  const Project = () => {
    history.push("/db/projects");
  };

  const Transaction = () => {
    history.push("/db/transactions");
  };

  const Agreements = () => {
    history.push("/db/agreements");
  };

  const invoice = () => {
    history.push("/db/invoices");
  };

  const timeTracking = () => {
    history.push("/db/timetracking/timers");
  };

  const statement = () => {
    history.push("/db/statements/incomes");
  };

  const settings = () => {
    history.push("/db/setting/business");
  };

  const admin = () => {
    history.push("/db/admin");
  };

  return (
    <div
      className={props.show ? "side_drawer show" : "side_drawer"}
      onClick={() => props.closeHandler()}
    >
      <div
        style={{
          background: Colors.white,
          zIndex: 300,
          maxWidth: 250,
          minWidth: 250,
          width: "100%",
          position: "fixed",
          height: "calc(100vh - 69px)",
          overflowY: "auto",
        }}
      >
        <ul
          className="list-group"
          style={{
            margin: "0px 12px",
            padding: "30px 0",
          }}
        >
          <li
            onClick={myDesk}
            style={{
              height: "3.15rem",
              display: "flex",
              alignItems: "center",
              padding: "0 0.70rem",
            }}
            className={
              homeRoute
                ? "list-group-item item-selected  menu-li"
                : "list-group-item item-notselected font-color menu-li homeimg"
            }
          >
            <div className="item-text">
              <span className="me-3 ms-2">
                <HomeRoundedIcon
                  sx={{ fontSize: 28, color: Colors.frontendFont1 }}
                />
              </span>
              <span className="sideBar-content">My desk</span>
            </div>
          </li>

          <li
            onClick={clients_dv}
            style={{
              height: "3.15rem",
              display: "flex",
              alignItems: "center",
              padding: "0 0.70rem",
              marginTop: "0.875rem",
            }}
            className={
              clientRoute
                ? "list-group-item item-selected  menu-li clients_tour"
                : "list-group-item item-notselected font-color menu-li clients_tour"
            }
          >
            <div className="item-text">
              <span className="me-3 ms-2">
                <PeopleAltRoundedIcon
                  sx={{ fontSize: 28, color: Colors.frontendFont1 }}
                />
              </span>
              <span className="sideBar-content">Clients</span>
            </div>
          </li>

          <li
            onClick={Project}
            style={{
              height: "3.15rem",
              display: "flex",
              alignItems: "center",
              padding: "0 0.70rem",
              marginTop: "0.875rem",
            }}
            className={
              projectsRoute
                ? "list-group-item item-selected  menu-li projects_tour"
                : "list-group-item item-notselected font-color menu-li projects_tour"
            }
          >
            <div href="/db/projects">
              <div className="item-text">
                <span className="me-3 ms-2">
                  {" "}
                  <FolderSharedRoundedIcon
                    sx={{ fontSize: 28, color: Colors.frontendFont1 }}
                  />
                </span>
                <span className="sideBar-content">Projects</span>
              </div>
            </div>
          </li>
          <li
            onClick={invoice}
            style={{
              height: "3.15rem",
              display: "flex",
              alignItems: "center",
              padding: "0 0.70rem",
              marginTop: "0.875rem",
            }}
            className={
              invoicesRoute
                ? "list-group-item item-selected  menu-li"
                : "list-group-item item-notselected font-color menu-li "
            }
          >
            <div className="item-text">
              <span className="me-3 ms-2">
                <DescriptionRoundedIcon
                  sx={{ fontSize: 28, color: Colors.frontendFont1 }}
                />
              </span>
              <span className="sideBar-content">Invoices</span>
            </div>
          </li>

          <li
            onClick={Agreements}
            style={{
              height: "3.15rem",
              display: "flex",
              alignItems: "center",
              padding: "0 0.70rem",
              marginTop: "0.875rem",
            }}
            className={
              AgreementsRoute
                ? "list-group-item item-selected  menu-li"
                : "list-group-item item-notselected font-color menu-li "
            }
          >
            <div className="item-text">
              <span className="me-3 ms-2">
                <SaveAsRoundedIcon
                  sx={{ fontSize: 28, color: Colors.frontendFont1 }}
                />
              </span>
              <span className="sideBar-content">Agreements</span>
            </div>
          </li>
          <li
            onClick={Transaction}
            style={{
              height: "3.15rem",
              display: "flex",
              alignItems: "center",
              padding: "0 0.70rem",
              marginTop: "0.875rem",
            }}
            className={
              transRoute
                ? "list-group-item item-selected  menu-li"
                : "list-group-item item-notselected font-color menu-li "
            }
          >
            <div className="item-text">
              <span className="me-3 ms-2">
                {" "}
                <AccountBalanceWalletRoundedIcon
                  sx={{ fontSize: 28, color: Colors.frontendFont1 }}
                />
              </span>
              <span className="sideBar-content">Transactions</span>
            </div>
          </li>
          <li
            onClick={timeTracking}
            style={{
              height: "3.15rem",
              display: "flex",
              alignItems: "center",
              padding: "0 0.70rem",
              marginTop: "0.875rem",
            }}
            className={
              timerRoute
                ? "list-group-item item-selected  menu-li"
                : "list-group-item item-notselected font-color menu-li "
            }
          >
            <div className="item-text">
              <span className="me-3 ms-2">
                <AccessAlarmsRoundedIcon
                  sx={{ fontSize: 28, color: Colors.frontendFont1 }}
                />
              </span>
              <span className="sideBar-content">Time tracking</span>
            </div>
          </li>

          <li
            onClick={statement}
            style={{
              height: "3.15rem",
              display: "flex",
              alignItems: "center",
              padding: "0 0.70rem",
              marginTop: "0.875rem",
            }}
            className={
              statementRoute
                ? "list-group-item item-selected  menu-li"
                : "list-group-item item-notselected font-color menu-li "
            }
          >
            <div className="item-text">
              <span className="me-3 ms-2">
                <RequestPageRoundedIcon
                  sx={{ fontSize: 28, color: Colors.frontendFont1 }}
                />
              </span>
              <span className="sideBar-content">Statements</span>
            </div>
          </li>
          <li
            onClick={settings}
            style={{
              height: "3.15rem",
              display: "flex",
              alignItems: "center",
              padding: "0 0.70rem",
              marginTop: "0.875rem",
            }}
            className={
              settingsRoute
                ? "list-group-item item-selected  menu-li"
                : "list-group-item item-notselected font-color menu-li "
            }
          >
            <div className="item-text">
              <span className="me-3 ms-2">
                <ManageAccountsRoundedIcon
                  sx={{ fontSize: 28, color: Colors.frontendFont1 }}
                />
              </span>
              <span className="sideBar-content">Settings</span>
            </div>
          </li>
          {data && data.role === "admin" && (
            <li
              onClick={admin}
              style={{
                height: "3.15rem",
                display: "flex",
                alignItems: "center",
                padding: "0 0.70rem",
                marginTop: "0.875rem",
              }}
              className={
                adminRoute
                  ? "list-group-item item-selected  menu-li"
                  : "list-group-item item-notselected font-color menu-li "
              }
            >
              <div className="item-text">
                <span className="me-3 ms-2">
                  <AdminPanelSettingsIcon
                    sx={{ fontSize: 28, color: Colors.frontendFont1 }}
                  />
                </span>
                <span className="sideBar-content">Admin</span>
              </div>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default SideNavContent;
