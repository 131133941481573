import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

import { postRequest } from "../../axios/axios";
import URL from "../../axios/constant";
import logo from "../../Assets/assetsnew/backend_logo_with_name.webp";
import loginBg from "../../Assets/frontendImages/loginBg.svg";
import Styled from "./AuthStyles";
import { message } from "antd";
import LoaderSpin from "../NewDashboard/Commons/LoaderSpin";
import TextStyles from "../../Constants/TextStyles";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Colors from "../../Constants/Colors";

const ChangePassword = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const { resetPasswordToken } = useParams();

  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = async (data) => {
    const obj = {
      resetPasswordToken,
      newPassword: data.NewPassword,
      confirmNewPassword: data.ConfirmNewPassword,
    };
    setIsLoading(true);
    postRequest(URL.CHANGE_PASSWORD.url, {}, obj)
      .then((data) => {
        if (data && data.data && data.data.success) {
          setIsLoading(false);
          reset();
          history.push("/db/login");
          message.success(data.data.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        if (err && err.response && err.response.data) {
          return message.error(`${err.response.data.message}`);
        }

        if (err.isAxiosError) {
          return message.error(`${err.message}`);
        }
      });
  };

  return (
    <div style={{ backgroundColor: "#fafbfc" }}>
      <div
        className="fixed-top"
        style={{
          backgroundColor: "#fafbfc",
          width: "100%",
          zIndex: 1000,
          boxShadow: "0 2px 4px 2px rgba(180, 180, 200, 0.2)",
        }}
      >
        <div
          className="d-flex justify-content-between align-items-center px-2 px-sm-3"
          style={{ height: 80, width: "100%" }}
        >
          <TextStyles.FontSize27px
            className="d-flex justify-content-center"
            style={{ cursor: "pointer" }}
            onClick={() => history.push("/")}
          >
            <img src={logo} alt="logo" style={{ maxWidth: 200 }} />
          </TextStyles.FontSize27px>
          <TextStyles.FontSize16px className="d-flex justify-content-end align-items-center w-100">
            <div className="d-none d-sm-flex me-3">
              Already have an account?
            </div>
            <Styled.LinkSpan
              className="text-center"
              onClick={() => history.push("/db/login")}
            >
              Log in
            </Styled.LinkSpan>
          </TextStyles.FontSize16px>
        </div>
      </div>
      <div style={{ paddingTop: 80 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: `url(${loginBg})`,
            backgroundSize: "cover",
            backgroundPosition: "bottom",
            minHeight: "calc(100vh - 80px)",
            width: "100%",
          }}
        >
          <div className="container-fluid pb-4">
            <div className="d-flex justify-content-center align-items-center">
              <Styled.Box>
                <TextStyles.FontSize27px
                  className="text-center mb-4"
                  style={{ fontWeight: 500 }}
                >
                  Reset password
                </TextStyles.FontSize27px>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <TextStyles.FontSize16px>
                    <div className="position-relative">
                      <TextStyles.InputRectangle
                        style={{ paddingLeft: "2.75rem" }}
                        type="password"
                        invalid={errors.NewPassword}
                        name="NewPassword"
                        id="new password"
                        placeholder="New password"
                        {...register("NewPassword", {
                          required: "Password is required.",
                          pattern: {
                            value:
                              /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/i,
                            message:
                              "Please enter minimum six characters, at least one letter, one number and one special character.",
                          },
                        })}
                      />
                      <span
                        style={{
                          position: "absolute",
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          top: "0.9rem",
                          left: "1rem",
                        }}
                      >
                        <LockOutlinedIcon
                          sx={{ fontSize: 20, color: Colors.font2 }}
                        />
                      </span>
                    </div>
                  </TextStyles.FontSize16px>
                  {errors.NewPassword && (
                    <TextStyles.InValidFeedback>
                      {errors.NewPassword.message}
                    </TextStyles.InValidFeedback>
                  )}
                  <TextStyles.FontSize16px className="mt-3">
                    <div className="position-relative">
                      <TextStyles.InputRectangle
                        style={{ paddingLeft: "2.75rem" }}
                        type="password"
                        invalid={errors.ConfirmNewPassword}
                        name="ConfirmNewPassword"
                        id="confirm new password"
                        placeholder="Re-type new password"
                        {...register("ConfirmNewPassword", {
                          required: "Confirm password is required.",
                          pattern: {
                            value:
                              /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/i,
                            message:
                              "Please enter minimum six characters, at least one letter, one number and one special character.",
                          },
                        })}
                      />
                      <span
                        style={{
                          position: "absolute",
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          top: "0.9rem",
                          left: "1rem",
                        }}
                      >
                        <LockOutlinedIcon
                          sx={{ fontSize: 20, color: Colors.font2 }}
                        />
                      </span>
                    </div>
                  </TextStyles.FontSize16px>
                  {errors.ConfirmNewPassword && (
                    <TextStyles.InValidFeedback>
                      {errors.ConfirmNewPassword.message}
                    </TextStyles.InValidFeedback>
                  )}

                  <TextStyles.Button
                    className="mt-4"
                    style={{
                      fontSize: 16,
                    }}
                  >
                    {isLoading ? <LoaderSpin /> : "Reset password"}
                  </TextStyles.Button>
                </form>
              </Styled.Box>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
