import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";

import PauseCircleFilledIcon from "@mui/icons-material/PauseCircleFilled";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";

import Colors from "../../../../Constants/Colors";
import TextStyles from "../../../../Constants/TextStyles";
import { useDispatch, useSelector } from "react-redux";

import { message } from "antd";
import {
  stop_timer_activity,
  start_timer_change_activity_name,
  start_timer_change_project_name,
  createActivityList,
  fetchAllActivityLists,
  cancel_start_activity,
} from "../../../../store/service/timeTrackingService";
import { timeTrackingActions } from "../../../../store/storage/timetrackingSlice";

import { convertSecondsToHHMMSS } from "../../Commons/utils";

import {
  AutoCompleteSelectProject,
  AutoCompleteCreateActivity,
} from "../../Commons/TimeTrackingAutoComplete/GloabalTimerAutocomplete";
import CancelIcon from "@mui/icons-material/Cancel";

const GlobalTimerBox = () => {
  const dispatch = useDispatch();

  const activityListData = useSelector(
    (state) => state.timeTracking.activityListData
  );

  const startActivityData = useSelector(
    (state) => state.timeTracking.startActivityData
  );

  const elapsedTime = useSelector((state) => state.timeTracking.elapsedTime);

  const projectOptionsData = useSelector(
    (state) => state.project.projectOptionsData
  );

  const { reset, handleSubmit, control } = useForm({
    defaultValues: {
      add_activity:
        startActivityData && startActivityData.activity_name && activityListData
          ? activityListData
              .filter(
                (d) => d.activity_name === startActivityData.activity_name
              )
              .map((data) => {
                return { _id: data._id, title: data.activity_name };
              })[0]
          : null,
      select_project:
        startActivityData && startActivityData.project_id && projectOptionsData
          ? projectOptionsData
              .filter((d) => d._id === startActivityData.project_id)
              .map((d) => {
                return {
                  _id: d._id ? d._id : null,
                  title: d && d.project_name ? d.project_name : null,
                  client_id:
                    d && d.client_id && d.client_id._id
                      ? d.client_id._id
                      : null,
                  client_name:
                    d && d.client_id && d.client_id.company_name
                      ? d.client_id.company_name
                      : null,
                };
              })[0]
          : null,
    },
  });

  useEffect(() => {
    reset({
      add_activity:
        startActivityData && startActivityData.activity_name && activityListData
          ? activityListData
              .filter(
                (d) => d.activity_name === startActivityData.activity_name
              )
              .map((data) => {
                return { _id: data._id, title: data.activity_name };
              })[0]
          : null,
      select_project:
        startActivityData && startActivityData.project_id && projectOptionsData
          ? projectOptionsData
              .filter((d) => d._id === startActivityData.project_id)
              .map((d) => {
                return {
                  _id: d._id ? d._id : null,
                  title: d && d.project_name ? d.project_name : null,
                  client_id:
                    d && d.client_id && d.client_id._id
                      ? d.client_id._id
                      : null,
                  client_name:
                    d && d.client_id && d.client_id.company_name
                      ? d.client_id.company_name
                      : null,
                };
              })[0]
          : null,
    });
  }, [startActivityData]);

  const stopTimerHandler = (data) => {
    const Obj = {
      project_id: data.select_project ? data.select_project._id : null,
      client_id: data.select_project ? data.select_project.client_id : null,
      activity_name:
        data.add_activity && data.add_activity.inputValue
          ? data.add_activity.inputValue
          : data.add_activity && data.add_activity._id
          ? data.add_activity.title
          : data.add_activity
          ? data.add_activity
          : null,
    };
    dispatch(timeTrackingActions.stopTimer());
    dispatch(stop_timer_activity(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        dispatch(timeTrackingActions.updateRefresh());
        message.success(`${data.payload.message}`);
      } else {
        message.error(`${data.error.message}`);
      }
    });
  };

  const startTimerChangeActivityNameHandler = (data) => {
    const Obj = {
      activity_name:
        data && data._id
          ? data.title
          : data && data.inputValue
          ? data.inputValue
          : data
          ? data
          : null,
    };
    if (startActivityData && startActivityData.start_time) {
      dispatch(timeTrackingActions.addNewActivityList(Obj));
      if (Obj.activity_name && !data._id) {
        dispatch(createActivityList(Obj)).then((data) => {
          if (data.meta.requestStatus === "fulfilled") {
            dispatch(fetchAllActivityLists());
          }
        });
      }
      dispatch(start_timer_change_activity_name(Obj));
    }
  };

  const startTimerChangeProjectNameHandler = (data) => {
    const Obj = {
      project_id: data && data._id ? data._id : null,
    };
    if (startActivityData && startActivityData.start_time) {
      dispatch(start_timer_change_project_name(Obj));
    }
  };

  const cancelActivityHandler = () => {
    dispatch(timeTrackingActions.stopTimer());
    dispatch(cancel_start_activity()).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        dispatch(fetchAllActivityLists());
      }
    });
  };

  return (
    <div className="d-flex gap-xs-1 gap-sm-2 gap-md-3 align-items-center w-100">
      <div className="d-flex gap-xs-1 gap-sm-2 gap-md-3  align-items-center w-100">
        <div style={{ width: "100%", maxWidth: 40 }}>
          <button
            type="submit"
            // disabled={isLoading ? true : false}
            className="d-flex align-items-center "
          >
            <PlayCircleFilledWhiteIcon
              sx={{
                fontSize: 40,
                color: Colors.white,
                cursor: "pointer",
                display:
                  startActivityData && startActivityData.start_time
                    ? "none"
                    : "block",
              }}
            />
            <PauseCircleFilledIcon
              onClick={handleSubmit(stopTimerHandler)}
              sx={{
                fontSize: 40,
                color: Colors.white,
                cursor: "pointer",
                display:
                  startActivityData && startActivityData.start_time
                    ? "block"
                    : "none",
              }}
            />
          </button>
        </div>
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ width: "100%", maxWidth: 100, minWidth: 100 }}
        >
          <TextStyles.FontSize18px
            style={{ color: Colors.white, lineHeight: 1.25 }}
          >
            {convertSecondsToHHMMSS(elapsedTime).formattedTime}
          </TextStyles.FontSize18px>
        </div>
        <div style={{ width: "100%" }}>
          <Controller
            control={control}
            name="add_activity"
            render={({ field: { onChange, value } }) => (
              <AutoCompleteCreateActivity
                placeholder="Write activity..."
                options={
                  activityListData &&
                  activityListData.map((d) => {
                    return { _id: d._id, title: d.activity_name };
                  })
                }
                onChange={(value) => {
                  onChange(value);
                  startTimerChangeActivityNameHandler(value);
                }}
                defaultValue={value}
              />
            )}
          />
        </div>
      </div>
      <div className="d-none d-sm-flex gap-xs-1 gap-sm-2 gap-md-3 flex-wrap flex-sm-nowrap align-items-center w-100">
        <Controller
          control={control}
          name="select_project"
          render={({ field: { onChange, value } }) => (
            <AutoCompleteSelectProject
              placeholder="Assign to project"
              options={
                projectOptionsData &&
                projectOptionsData.map((d) => {
                  return {
                    _id: d._id ? d._id : null,
                    title: d && d.project_name ? d.project_name : null,
                    client_id:
                      d && d.client_id && d.client_id._id
                        ? d.client_id._id
                        : null,
                    client_name:
                      d && d.client_id && d.client_id.company_name
                        ? d.client_id.company_name
                        : null,
                  };
                })
              }
              onChange={(value) => {
                onChange(value);
                startTimerChangeProjectNameHandler(value);
              }}
              defaultValue={value}
            />
          )}
        />
      </div>
      <div className="w-100" style={{ maxWidth: 25 }}>
        <CancelIcon
          sx={{ fontSize: 25, color: "#fff", cursor: "pointer" }}
          onClick={cancelActivityHandler}
        />
      </div>
    </div>
  );
};

export default React.memo(GlobalTimerBox);
