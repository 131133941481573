import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./LandingPage.module.css";

function LandingPage() {
  return (
    <div className={styles.hero_section}>
      <div
        className="container-fluid"
        style={{ width: "100%", height: "calc(100vh - 82px" }}
        data-aos={"fade-up"}
      >
        <div className="d-flex flex-column justify-content-around align-items-center w-100 h-100">
          <div className={`${styles.features}`}>FEATURES</div>
          <div className="d-flex flex-column justify-content-around align-items-center w-100">
            <h1
              className={`${styles.heading} text-center`}
              // style={{ width: "fit-content" }}
            >
              ROI-boosting agreements
            </h1>
            <div
              className={`${styles.text_heading} text-center mt-3`}
              style={{ maxWidth: 750 }}
            >
              Offer something too tempting to turn down.
            </div>
          </div>{" "}
          <div className="d-flex justify-content-center mb-3">
            <NavLink
              className={styles.btn_begin}
              style={{ textDecoration: "none" }}
              to={"/db/signup"}
            >
              Start now
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
