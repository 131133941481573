import React from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import ClearIcon from "@mui/icons-material/Clear";
import TextStyles from "../../../../Constants/TextStyles";
import SelectCurrency from "../../Commons/SelectCurrency";
import Colors from "../../../../Constants/Colors";
import { invoiceActions } from "../../../../store/storage/invoiceSlice";

const ChangeCurrency = ({ show, handleClose, selectedData }) => {
  const dispatch = useDispatch();
  const { reset, control, watch, handleSubmit } = useForm({
    defaultValues: {
      currency: selectedData && selectedData.currency,
    },
  });
  const invoiceCurrency =
    selectedData && selectedData.currency ? selectedData.currency.code : "";

  const watchCurrency = watch("currency");
  const selectedCurrency = (watchCurrency && watchCurrency.code) || "";
  const isCurrencyChange = invoiceCurrency !== selectedCurrency;

  const formsubmit = (data) => {
    const Obj = {
      currency: data.currency ? data.currency : selectedData.currency,
    };

    dispatch(invoiceActions.selectedInvoiceAction(Obj));
    reset();
    handleClose();
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="ToMakeModalOnRight500px"
        fullscreen
      >
        <form
          onSubmit={handleSubmit(formsubmit)}
          className="modal-content"
          style={{ height: "100%" }}
        >
          <Modal.Header className="d-flex align-items-center justify-content-between">
            <TextStyles.FontSize20px>Invoice settings</TextStyles.FontSize20px>
            <ClearIcon
              sx={{
                color: Colors.font2,
                cursor: "pointer",
                opacity: 0.7,
                "&:hover": {
                  opacity: 1,
                },
              }}
              onClick={handleClose}
            />
          </Modal.Header>
          <Modal.Body>
            <TextStyles.FontSize14px className="mb-3">
              <div className="mb-2">Select currency</div>
              <div className="mb-3">
                <Controller
                  control={control}
                  name="currency"
                  render={({ field: { onChange, value } }) => (
                    <SelectCurrency
                      placeholder="Select currency"
                      onChange={onChange}
                      defaultValue={value}
                    />
                  )}
                />
              </div>
            </TextStyles.FontSize14px>
            {isCurrencyChange && (
              <p
                className="mt-4 p-4 rounded-3"
                style={{ backgroundColor: Colors.backgroundPink }}
              >
                If you change the invoice currency, please make sure that the
                amounts you see reflect the final desired amount in that
                currency. Alternatively, you may choose to change the project
                currency and initiate the process again. Would you like to
                proceed?
              </p>
            )}
          </Modal.Body>
          <Modal.Footer style={{ display: "inline-block" }}>
            <div className="d-flex gap-3 justify-content-between">
              <TextStyles.CancelButton onClick={handleClose}>
                No
              </TextStyles.CancelButton>
              <TextStyles.Button disabled={isCurrencyChange ? false : true}>
                Yes
              </TextStyles.Button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default ChangeCurrency;
