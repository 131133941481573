import { createSlice } from "@reduxjs/toolkit";
import {
  start_timer_activity,
  stop_timer_activity,
  start_timer_change_activity_name,
  start_timer_change_project_name,
  cancel_start_activity,
  createActivityList,
  fetchAllActivityLists,
  add_single_timer_activity,
  fetchActiveActivity,
  fetchAllTimerActivities,
  update_single_timer_activity,
  update_multiple_timer_activity_names,
  update_single_timer_activity_name,
  update_multiple_timer_project_names,
  update_single_timer_project_name,
  update_multiple_timer_invoices,
  update_single_timer_invoice,
  delete_multiple_timer_activities,
  delete_single_timer_activity,
  update_multiple_timer_dates,
  update_single_timer_date,
  merge_timer_activities,
  fetchWeekView,
  updateWeekviewActivityNames,
  createWeekViewSingleActivity,
  updateWeekViewSingleActivity,
  updateWeekViewMultipleActivityTimes,
  fetchActivityReports,
  fetchActivityReportsForGraph,
  fetchActivityCounts,
} from "../service/timeTrackingService";

const initialTTState = {
  activityListData: [],
  ttData: null,
  totalTtCount: null,
  isLoading: false,
  error: null,
  timerData: null,
  weekViewData: null,
  reportsData: null,
  reportsDataForGraph: null,
  totalTtReportCount: null,
  startActivityData: {
    project_id: null,
    activity_name: null,
    start_time: null,
  },
  elapsedTime: 0,
  refresh: false,
  filteredData: {},
  toggleFilter: false,
  sortingData: {},
  filteredWeekviewData: {},
  toggleWeekviewFilter: false,
  filteredReportsData: {},
  toggleReportsFilter: false,
  sortingReportsData: {},
  filteredDataInProject: {},
  toggleFilterInProject: false,
  sortingDataInProject: {},
  filteredWeekviewDataInProject: {},
  toggleWeekviewFilterInProject: false,
  filteredReportsDataInProject: {},
  toggleReportsFilterInProject: false,
  sortingReportsDataInProject: {},
};

export const timeTrackingSlice = createSlice({
  name: "timeTrackingSlice",
  initialState: initialTTState,
  reducers: {
    updateRefresh: (state) => {
      state.refresh = !state.refresh;
    },
    startTimer: (state, action) => {
      state.startActivityData.start_time = Date.now();
      state.startActivityData.project_id = action.payload.project_id
        ? action.payload.project_id
        : null;
      state.startActivityData.activity_name = action.payload.activity_name
        ? action.payload.activity_name
        : null;
    },
    stopTimer: (state) => {
      state.startActivityData.start_time = null;
      state.elapsedTime = 0;
    },

    updateTimer: (state) => {
      if (state.startActivityData && state.startActivityData.start_time) {
        state.elapsedTime = Math.floor(
          (Date.now() - state.startActivityData.start_time) / 1000
        );
      }
    },
    resetActivityAction: (state) => {
      state.startActivityData.start_time = null;
      state.startActivityData.project_id = null;
      state.startActivityData.activity_name = null;
      state.elapsedTime = 0;
    },
    addNewActivityList: (state, action) => {
      const activity_name =
        action.payload.activity_name && action.payload.activity_name.trim();
      const existedActivity = state.activityListData.some(
        (data) => data.activity_name === activity_name
      );
      if (!existedActivity) {
        state.activityListData.unshift({
          _id: `${Math.floor(Math.random() * 100000) + 1}`,
          activity_name: activity_name,
        });
      }
    },
    filterTimerAction: (state, action) => {
      state.filteredData = { ...state.filteredData, ...action.payload };
    },
    toggleTimerAction: (state) => {
      state.toggleFilter = !state.toggleFilter;
    },
    sortingTimerAction: (state, action) => {
      state.sortingData = { ...state.sortingData, ...action.payload };
    },

    filterWeekviewAction: (state, action) => {
      state.filteredWeekviewData = {
        ...state.filteredWeekviewData,
        ...action.payload,
      };
    },
    toggleWeekviewAction: (state) => {
      state.toggleWeekviewFilter = !state.toggleWeekviewFilter;
    },

    filterReportsAction: (state, action) => {
      state.filteredReportsData = {
        ...state.filteredReportsData,
        ...action.payload,
      };
    },
    toggleReportsAction: (state) => {
      state.toggleReportsFilter = !state.toggleReportsFilter;
    },
    sortingReportsAction: (state, action) => {
      state.sortingReportsData = {
        ...state.sortingReportsData,
        ...action.payload,
      };
    },

    filterTimerActionInProject: (state, action) => {
      state.filteredDataInProject = {
        ...state.filteredDataInProject,
        ...action.payload,
      };
    },

    toggleTimerActionInProject: (state) => {
      state.toggleFilterInProject = !state.toggleFilterInProject;
    },
    sortingTimerActionInProject: (state, action) => {
      state.sortingDataInProject = {
        ...state.sortingDataInProject,
        ...action.payload,
      };
    },

    filterReportsActionInProject: (state, action) => {
      state.filteredReportsDataInProject = {
        ...state.filteredReportsDataInProject,
        ...action.payload,
      };
    },
    toggleReportsActionInProject: (state) => {
      state.toggleReportsFilterInProject = !state.toggleReportsFilterInProject;
    },
    sortingReportsActionInProject: (state, action) => {
      state.sortingReportsDataInProject = {
        ...state.sortingReportsDataInProject,
        ...action.payload,
      };
    },

    filterWeekviewActionInProject: (state, action) => {
      state.filteredWeekviewDataInProject = {
        ...state.filteredWeekviewDataInProject,
        ...action.payload,
      };
    },
    toggleWeekviewActionInProject: (state) => {
      state.toggleWeekviewFilterInProject =
        !state.toggleWeekviewFilterInProject;
    },
  },

  extraReducers: (builder) => {
    builder
      // start timer activity
      .addCase(start_timer_activity.pending, (state) => {
        // state.isLoading = true;
      })
      .addCase(start_timer_activity.fulfilled, (state, action) => {
        // state.startActivityData.start_time = new Date(
        //   action.payload.data.start_time
        // ).getTime();
        // state.startActivityData.project_id = action.payload.data.project_id
        //   ? action.payload.data.project_id
        //   : null;
        // state.startActivityData.activity_name = action.payload.data
        //   .activity_name
        //   ? action.payload.data.activity_name
        //   : null;
        // state.isLoading = false;
      })
      .addCase(start_timer_activity.rejected, (state, action) => {
        // state.isLoading = false;
        state.error = action.error.message;
      })

      // stop timer activity
      .addCase(stop_timer_activity.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(stop_timer_activity.fulfilled, (state, action) => {
        state.isLoading = false;
        // state.startActivityData.start_time = null;
        // state.elapsedTime = 0;
      })
      .addCase(stop_timer_activity.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // change start timer activity_name
      .addCase(start_timer_change_activity_name.pending, (state) => {
        // state.isLoading = true;
      })
      .addCase(start_timer_change_activity_name.fulfilled, (state, action) => {
        // state.isLoading = false;
        state.startActivityData.activity_name = action.payload.data
          .activity_name
          ? action.payload.data.activity_name
          : null;
      })
      .addCase(start_timer_change_activity_name.rejected, (state, action) => {
        // state.isLoading = false;
        state.error = action.error.message;
      })

      // change start timer project_name
      .addCase(start_timer_change_project_name.pending, (state) => {
        // state.isLoading = true;
      })
      .addCase(start_timer_change_project_name.fulfilled, (state, action) => {
        // state.isLoading = false;
        state.startActivityData.project_id = action.payload.data.project_id
          ? action.payload.data.project_id
          : null;
      })
      .addCase(start_timer_change_project_name.rejected, (state, action) => {
        // state.isLoading = false;
        state.error = action.error.message;
      })

      // cancel timer activity
      .addCase(cancel_start_activity.pending, (state) => {
        // state.isLoading = true;
      })
      .addCase(cancel_start_activity.fulfilled, (state, action) => {
        // state.startActivityData.start_time = null;
        // state.elapsedTime = 0;
        // state.isLoading = false;
      })
      .addCase(cancel_start_activity.rejected, (state, action) => {
        // state.isLoading = false;
        state.error = action.error.message;
      })

      // fetch active activity
      .addCase(fetchActiveActivity.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchActiveActivity.fulfilled, (state, action) => {
        state.isLoading = false;
        state.startActivityData.start_time = action.payload.data.start_time
          ? new Date(action.payload.data.start_time).getTime()
          : null;
        state.startActivityData.project_id = action.payload.data.project_id
          ? action.payload.data.project_id
          : null;
        state.startActivityData.activity_name = action.payload.data
          .activity_name
          ? action.payload.data.activity_name
          : null;
      })
      .addCase(fetchActiveActivity.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // create activity list
      .addCase(createActivityList.pending, (state) => {
        // state.isLoading = true;
      })
      .addCase(createActivityList.fulfilled, (state, action) => {
        // state.isLoading = false;
        // state.activityListData.push(action.payload.data);
      })
      .addCase(createActivityList.rejected, (state, action) => {
        // state.isLoading = false;
        state.error = action.error.message;
      })

      // fetch all activity list data
      .addCase(fetchAllActivityLists.pending, (state) => {
        // state.isLoading = true;
      })
      .addCase(fetchAllActivityLists.fulfilled, (state, action) => {
        // state.isLoading = false;
        state.activityListData = action.payload.data;
      })
      .addCase(fetchAllActivityLists.rejected, (state, action) => {
        // state.isLoading = false;
        state.error = action.error.message;
      })

      // add single timer activity
      .addCase(add_single_timer_activity.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(add_single_timer_activity.fulfilled, (state, action) => {
        state.isLoading = false;
        state.timerData = action.payload.data;
      })
      .addCase(add_single_timer_activity.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // fetch all activities
      .addCase(fetchAllTimerActivities.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAllTimerActivities.fulfilled, (state, action) => {
        state.isLoading = false;
        state.ttData = action.payload.data.data;
        state.totalTtCount = action.payload.data.totalTtCount;
      })
      .addCase(fetchAllTimerActivities.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // update single activity
      .addCase(update_single_timer_activity.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_single_timer_activity.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_single_timer_activity.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // update multiple activity names
      .addCase(update_multiple_timer_activity_names.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        update_multiple_timer_activity_names.fulfilled,
        (state, action) => {
          state.isLoading = false;
        }
      )
      .addCase(
        update_multiple_timer_activity_names.rejected,
        (state, action) => {
          state.isLoading = false;
          state.error = action.error.message;
        }
      )

      // update single activity name
      .addCase(update_single_timer_activity_name.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_single_timer_activity_name.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_single_timer_activity_name.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // update multiple project names
      .addCase(update_multiple_timer_project_names.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        update_multiple_timer_project_names.fulfilled,
        (state, action) => {
          state.isLoading = false;
        }
      )
      .addCase(
        update_multiple_timer_project_names.rejected,
        (state, action) => {
          state.isLoading = false;
          state.error = action.error.message;
        }
      )

      // update single project name
      .addCase(update_single_timer_project_name.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_single_timer_project_name.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_single_timer_project_name.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // delete multiple activities
      .addCase(delete_multiple_timer_activities.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(delete_multiple_timer_activities.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(delete_multiple_timer_activities.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // delete single activity
      .addCase(delete_single_timer_activity.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(delete_single_timer_activity.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(delete_single_timer_activity.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // update multiple activity's dates
      .addCase(update_multiple_timer_dates.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_multiple_timer_dates.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_multiple_timer_dates.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // update single activity's date
      .addCase(update_single_timer_date.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_single_timer_date.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_single_timer_date.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // update multiple activity's invoices
      .addCase(update_multiple_timer_invoices.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_multiple_timer_invoices.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_multiple_timer_invoices.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // update single activity's invoice
      .addCase(update_single_timer_invoice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_single_timer_invoice.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_single_timer_invoice.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // merge timer activities
      .addCase(merge_timer_activities.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(merge_timer_activities.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(merge_timer_activities.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // fetch weekview data
      .addCase(fetchWeekView.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchWeekView.fulfilled, (state, action) => {
        state.isLoading = false;
        state.weekViewData = action.payload.data;
      })
      .addCase(fetchWeekView.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // update weekview activity names
      .addCase(updateWeekviewActivityNames.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateWeekviewActivityNames.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updateWeekviewActivityNames.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // create single activity in weekview
      .addCase(createWeekViewSingleActivity.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createWeekViewSingleActivity.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(createWeekViewSingleActivity.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // update single activity in weekview
      .addCase(updateWeekViewSingleActivity.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateWeekViewSingleActivity.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updateWeekViewSingleActivity.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // update multiple activity times in weekview
      .addCase(updateWeekViewMultipleActivityTimes.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        updateWeekViewMultipleActivityTimes.fulfilled,
        (state, action) => {
          state.isLoading = false;
        }
      )
      .addCase(
        updateWeekViewMultipleActivityTimes.rejected,
        (state, action) => {
          state.isLoading = false;
          state.error = action.error.message;
        }
      )

      // fetch activity reports data
      .addCase(fetchActivityReports.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchActivityReports.fulfilled, (state, action) => {
        state.isLoading = false;
        state.reportsData = action.payload.data.data;
        state.totalTtReportCount = action.payload.data.totalTtReportCount;
      })
      .addCase(fetchActivityReports.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // fetch activity reports data for graph
      .addCase(fetchActivityReportsForGraph.pending, (state) => {
        // state.isLoading = true;
      })
      .addCase(fetchActivityReportsForGraph.fulfilled, (state, action) => {
        // state.isLoading = false;
        state.reportsDataForGraph = action.payload.data;
      })
      .addCase(fetchActivityReportsForGraph.rejected, (state, action) => {
        // state.isLoading = false;
        state.error = action.error.message;
      })

      // fetch activity counts
      .addCase(fetchActivityCounts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchActivityCounts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.totalTtReportCount = action.payload.data.totalTtReportCount;
      })
      .addCase(fetchActivityCounts.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const timeTrackingActions = timeTrackingSlice.actions;

export default timeTrackingSlice.reducer;
