import React, { useState } from "react";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { message } from "antd";
import TextStyles from "../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";
import { useDispatch, useSelector } from "react-redux";
import { updateHealthInsurancePC } from "../../../../../../store/service/proposalAndContractService";
import RemoveModal from "../../../../Commons/RemoveModal";

import HealthInsuranceModal from "../../PageModals/HealthInsuranceModal.jsx";
import Colors from "../../../../../../Constants/Colors";

const HealthInsurance = () => {
  const selectedData = useSelector(
    (state) => state.proposalAndContract.selectedData
  );
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const handleCloseRemoveModal = () => setShowRemoveModal(false);

  const heading =
    selectedData &&
    selectedData.health_insurance &&
    selectedData.health_insurance.heading
      ? selectedData &&
        selectedData.health_insurance &&
        selectedData.health_insurance.heading
      : "Health insurance and business liability insurance";
  const paragraph =
    selectedData &&
    selectedData.health_insurance &&
    selectedData.health_insurance.paragraph
      ? selectedData &&
        selectedData.health_insurance &&
        selectedData.health_insurance.paragraph
      : "In its capacity of an independent contractor, Contractor agrees that the Client is not obligated to procure business liability insurance for the Contractor and/or medical insurance for Contractor and/or Contractor’s family and that Contractor may procure such medical and/or business liability insurance at Contractor’s expense without any claim for reimbursement from the Client.";

  const hideClauseHandler = () => {
    const Obj = {
      _id: selectedData._id,
      health_insurance: {
        required: false,
        heading: null,
        paragraph: null,
      },
    };
    dispatch(updateHealthInsurancePC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleCloseRemoveModal();
        message.success("Clause hidden successfully!");
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  return (
    <div
      style={{
        display:
          selectedData &&
          selectedData.health_insurance &&
          selectedData.health_insurance.required === true
            ? "inline"
            : "none",
      }}
    >
      <Styled.ReviewContentBox className="mt-4">
        <Styled.EditButtonToolTip
          className="contract_review_edit_tp"
          style={{ marginLeft: -60 }}
          onClick={() => setShowModal(true)}
        >
          <BorderColorOutlinedIcon sx={{ color: Colors.white, fontSize: 20 }} />
          <span className="ms-1">Edit</span>
        </Styled.EditButtonToolTip>
        <Styled.EditButtonToolTip
          backgroundColor="#383838"
          className="contract_review_edit_tp"
          style={{ marginLeft: 60 }}
          onClick={() => {
            setShowRemoveModal(true);
            setConfirmDialog({
              onConfirm: hideClauseHandler,
            });
          }}
        >
          <VisibilityOffIcon sx={{ color: Colors.white, fontSize: 20 }} />
          <span className="ms-1">Hide</span>
        </Styled.EditButtonToolTip>

        <TextStyles.FontSize20px className="mb-2">
          {heading}
        </TextStyles.FontSize20px>
        <TextStyles.FontSize14px>{paragraph}</TextStyles.FontSize14px>
      </Styled.ReviewContentBox>
      {showModal && (
        <HealthInsuranceModal
          show={showModal}
          handleClose={() => setShowModal(false)}
          heading={heading}
          paragraph={paragraph}
          selectedData={selectedData}
        />
      )}
      {confirmDialog && (
        <RemoveModal
          title={heading}
          confirmDialog={confirmDialog}
          showRemoveModal={showRemoveModal}
          handleCloseRemoveModal={handleCloseRemoveModal}
        />
      )}
    </div>
  );
};

export default HealthInsurance;
