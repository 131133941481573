import React from "react";
import styles from "./AboutPage.module.css";
import clientImg from "../../../Assets/frontendImages/whyzodot_client.svg";
import invoiceImg from "../../../Assets/frontendImages/whyzodot_invoice.svg";
import timerImg from "../../../Assets/frontendImages/whyzodot_timer.svg";
import projectImg from "../../../Assets/frontendImages/whyzodot_project.svg";

const AboutPage = () => {
  return (
    <div className={`${styles.section}`}>
      <div className={`container-fluid py-5 py-md-5`}>
        <div
          className="d-flex gap-3 gap-md-5 flex-column align-items-center"
          style={{ overflow: "hidden" }}
        >
          <div className="text-center w-100 mt-3 mt-md-0" data-aos={"fade-up"}>
            <div className={`${styles.heading} mb-3`}>Why Zodot?</div>
            <div className="d-flex justify-content-center">
              <div
                className={`${styles.text_font1} mb-3`}
                style={{ maxWidth: 1000 }}
              >
                At Zodot, we empower freelancers, solopreneurs, SMBs, and
                entrepreneurs with a cloud-based solution for managing projects,
                finances, and time, simplifying your path to success in today's
                fast-paced world.
              </div>
            </div>
          </div>
          <div
            className="row d-flex justify-content-center justify-content-lg-between align-items-center w-100"
            data-aos={"fade-up"}
          >
            <div
              className="col-12 col-sm-6 col-md-4 col-lg-3 d-flex justify-content-center"
              data-aos={"zoom-in"}
            >
              <div className={`${styles.feature_container}`}>
                <div className={`mb-4`}>
                  <img
                    src={timerImg}
                    alt="timerImg"
                    width="100%"
                    height="auto"
                  />
                </div>
                <div
                  className={`${styles.feature_container_number}  text-center mb-2`}
                >
                  4.5K+
                </div>

                <div className={`${styles.container_text} text-center`}>
                  Hours tracked
                </div>
              </div>
            </div>
            <div
              className="col-12 col-sm-6 col-md-4 col-lg-3 d-flex justify-content-center"
              data-aos={"zoom-in"}
            >
              <div className={`${styles.feature_container}`}>
                <div className={`mb-4`}>
                  <img
                    src={clientImg}
                    alt="clientImg"
                    width="100%"
                    height="auto"
                  />
                </div>
                <div
                  className={`${styles.feature_container_number}  text-center mb-2`}
                >
                  1K+
                </div>

                <div className={`${styles.container_text} text-center`}>
                  Clients managed
                </div>
              </div>
            </div>{" "}
            <div
              className="col-12 col-sm-6 col-md-4 col-lg-3 d-flex justify-content-center"
              data-aos={"zoom-in"}
            >
              <div className={`${styles.feature_container}`}>
                <div className={`mb-4`}>
                  <img
                    src={invoiceImg}
                    alt="invoiceImg"
                    width="100%"
                    height="auto"
                  />
                </div>
                <div
                  className={`${styles.feature_container_number}  text-center mb-2`}
                >
                  3.2K+
                </div>
                <div className={`${styles.container_text} text-center`}>
                  Invoices processed
                </div>
              </div>
            </div>
            <div
              className="col-12 col-sm-6 col-md-4 col-lg-3 d-flex justify-content-center"
              data-aos={"zoom-in"}
            >
              <div className={`${styles.feature_container}`}>
                <div className={`mb-4`}>
                  <img
                    src={projectImg}
                    alt="projectImg"
                    width="100%"
                    height="auto"
                  />
                </div>
                <div
                  className={`${styles.feature_container_number}  text-center mb-2`}
                >
                  5K+
                </div>

                <div className={`${styles.container_text} text-center`}>
                  Projects succeeded
                </div>
              </div>
            </div>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
