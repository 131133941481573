import React from "react";
import { makeStyles } from "@mui/styles";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Colors from "../../../Constants/Colors";
import sizes from "../../../Constants/sizes";

const useStyles = makeStyles({
  root: {
    border: "none",
    borderRadius: `${sizes.formInputBorderRadius} !important`,
    fontSize: "1rem !important",
    height: "2.5rem",
    fontFamily: "Poppins !important",

    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },

    "&:hover": {
      border: "none",
    },

    "&.Mui-focused": {
      border: "none",
    },
  },

  select: {
    display: "flex !important",
    justifyContent: "start",
    alignItems: "center !important",
  },

  listMenu: {
    color: Colors.font2,
    fontWeight: "400 !important",
    marginBottom: "2px !important",
    fontSize: "0.875rem !important",
    fontFamily: "Poppins !important",
    "&:hover": {
      backgroundColor: `${Colors.backgroundPink} !important`,
      border: "none",
    },
    '&[aria-selected="true"]': {
      backgroundColor: `${Colors.backgroundPink} !important`,
    },
  },
});

const clientStatusOptions = [
  {
    id: "1",
    value: "all",
    label: "All statuses",
  },
  {
    id: "2",
    value: "active",
    label: "Active",
  },
  {
    id: "3",
    value: "archived",
    label: "Archived",
  },
];

export default function SelectClientStatus(props) {
  const classes = useStyles();

  return (
    <div>
      <Select
        classes={{
          select: classes.select,
        }}
        className={classes.root}
        defaultValue={
          props.defaultValue ? props.defaultValue : clientStatusOptions[1].value
        }
        id="grouped-select"
        sx={{ width: "100%" }}
        onChange={props.onChange}
        MenuProps={{
          PaperProps: { sx: { maxHeight: 250 } },
          // disableScrollLock: true,
        }}
      >
        {clientStatusOptions &&
          clientStatusOptions.map((data, index) => (
            <MenuItem
              key={index}
              value={data.value}
              className={classes.listMenu}
            >
              <FiberManualRecordIcon
                sx={{
                  display: data.label === "All statuses" ? "none" : "",
                  color:
                    data.label === "Active"
                      ? Colors.success
                      : data.label === "Archived"
                      ? "#c3c3c6"
                      : Colors.white,
                  fontSize: 15,
                  margin: "0 6px 0 0",
                }}
              />
              {data.label}
            </MenuItem>
          ))}
      </Select>
    </div>
  );
}
