import React, { useState } from "react";
import Styled from "./ActiveUsersStyles";
import TextStyles from "../../../../Constants/TextStyles";
import moment from "moment";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import Colors from "../../../../Constants/Colors";
import { Popover } from "antd";
// import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

// import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
// import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
// import Person3OutlinedIcon from "@mui/icons-material/Person3Outlined";
import Person3TwoToneIcon from "@mui/icons-material/Person3TwoTone";
import EmailTwoToneIcon from "@mui/icons-material/EmailTwoTone";
import FmdGoodTwoToneIcon from "@mui/icons-material/FmdGoodTwoTone";
import CalendarMonthTwoToneIcon from "@mui/icons-material/CalendarMonthTwoTone";
import AccessTimeTwoToneIcon from "@mui/icons-material/AccessTimeTwoTone";
import ContactMailTwoToneIcon from "@mui/icons-material/ContactMailTwoTone";
// import FolderSharedTwoToneIcon from "@mui/icons-material/FolderSharedTwoTone";
// import EventIcon from "@mui/icons-material/Event";
// import AdjustOutlinedIcon from "@mui/icons-material/AdjustOutlined";
// import FolderTwoToneIcon from "@mui/icons-material/FolderTwoTone";
// import PaidTwoToneIcon from "@mui/icons-material/PaidTwoTone";
import ArrowUpwardTwoToneIcon from "@mui/icons-material/ArrowUpwardTwoTone";
import ArrowDownwardTwoToneIcon from "@mui/icons-material/ArrowDownwardTwoTone";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
// import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";
// import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";
import { useSelector } from "react-redux";

const activeUsers = [
  {
    _id: "1",
    firstName: "santraj",
    lastName: "Yadav",
    email: "santrajyadav196@gmail.com",
    country: {
      code: "IN",
      name: "India",
    },
    createdAt: new Date(),
    lastLogin: new Date(),
    userType: "email",
    totalTasks: "12",
    totalClients: "5",
    totalProjects: "10",
    totalInvoices: "3",
    totalAgreements: "2",
    totalTransactions: "2",
    totalActivities: "20",
  },
  {
    _id: "2",
    firstName: "santraj",
    lastName: "Yadav",
    email: "santrajyadav196@gmail.com",
    country: {
      code: "IN",
      name: "India",
    },
    createdAt: new Date(),
    lastLogin: new Date(),
    userType: "google",
    totalTasks: "12",
    totalClients: "5",
    totalProjects: "10",
    totalInvoices: "3",
    totalAgreements: "2",
    totalTransactions: "2",
    totalActivities: "20",
  },
  {
    _id: "3",
    firstName: "santraj",
    lastName: "Yadav",
    email: "santrajyadav196@gmail.com",
    country: {
      code: "IN",
      name: "India",
    },
    createdAt: new Date(),
    lastLogin: new Date(),
    userType: "linkedIn",
    totalTasks: "12",
    totalClients: "5",
    totalProjects: "10",
    totalInvoices: "3",
    totalAgreements: "2",
    totalTransactions: "2",
    totalActivities: "20",
  },
  {
    _id: "3",
    firstName: "santraj",
    lastName: "Yadav",
    email: "santrajyadav196@gmail.com",
    country: {
      code: "IN",
      name: "India",
    },
    createdAt: new Date(),
    lastLogin: new Date(),
    userType: "google",
    totalTasks: "12",
    totalClients: "5",
    totalProjects: "10",
    totalInvoices: "3",
    totalAgreements: "2",
    totalTransactions: "2",
    totalActivities: "20",
  },
];

const ActiveUsers = () => {
  const [collapse, setCollapse] = useState(null);

  // const toggleFilter = useSelector((state) => state.project.toggleFilter);

  // const filteredData = useSelector((state) => state.project.filteredData);

  // const filterdDateRange =
  //   filteredData && filteredData.filterdDateRange
  //     ? filteredData.filterdDateRange
  //     : null;

  // const filteredClient =
  //   filteredData && filteredData.filteredClient
  //     ? filteredData.filteredClient
  //     : null;

  // const filteredProjectStatus =
  //   filteredData && filteredData.filteredProjectStatus
  //     ? filteredData.filteredProjectStatus
  //     : "Running";

  const sortingData = useSelector((state) => state.project.sortingData);

  const selectedSortingOne =
    sortingData && sortingData.selectedSortingOne
      ? sortingData.selectedSortingOne
      : "project_start_date";
  const selectedSortingTwo =
    sortingData && sortingData.selectedSortingTwo
      ? sortingData.selectedSortingTwo
      : "desc";

  const toggleShowFilter = () => {
    // dispatch(projectsActions.toggleAction());
  };

  const collapsableHandleChange = (index) => {
    if (collapse === index) {
      return setCollapse(null);
    }
    setCollapse(index);
  };

  // sorting clients
  const [openSorting, setOpenSorting] = useState(false);

  const hideSortingHandler = () => {
    setOpenSorting(false);
  };
  const handleOpenSortingChange = (newOpen) => {
    setOpenSorting(newOpen);
  };

  const sortingOptionChangeHandler = (event) => {
    // dispatch(
    //   projectsActions.sortingAction({
    //     selectedSortingOne: event.target.value,
    //   })
    // );
  };

  const sortingOrderingChangeHandler = (event) => {
    // dispatch(
    //   projectsActions.sortingAction({
    //     selectedSortingTwo: event.target.value,
    //   })
    // );
  };

  const sortingContent = () => {
    return (
      <TextStyles.FontSize14px>
        <Styled.Sorting>
          <label className="form-check-label" htmlFor="name">
            <Person3TwoToneIcon sx={{ color: Colors.font2, fontSize: 21 }} />
            <span className="ms-2">Name</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="name"
              type="radio"
              name="sorting"
              value="name"
              checked={selectedSortingOne === "name"}
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>
        <Styled.Sorting>
          <label className="form-check-label" htmlFor="email">
            <EmailTwoToneIcon sx={{ color: Colors.font2, fontSize: 21 }} />
            <span className="ms-2">Email</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="email"
              type="radio"
              name="sorting"
              checked={selectedSortingOne === "email"}
              value="email"
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <Styled.Sorting>
          <label className="form-check-label" htmlFor="country">
            <FmdGoodTwoToneIcon sx={{ color: Colors.font2, fontSize: 21 }} />
            <span className="ms-2">Country</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="country"
              type="radio"
              name="sorting"
              checked={selectedSortingOne === "country"}
              value="country"
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>
        <Styled.Sorting>
          <label className="form-check-label" htmlFor="createdAt">
            <CalendarMonthTwoToneIcon
              sx={{ color: Colors.font2, fontSize: 21 }}
            />
            <span className="ms-2">CraetedAt</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="createdAt"
              type="radio"
              name="sorting"
              checked={selectedSortingOne === "createdAt"}
              value="createdAt"
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <Styled.Sorting>
          <label className="form-check-label" htmlFor="lastLogin">
            <AccessTimeTwoToneIcon sx={{ color: Colors.font2, fontSize: 21 }} />
            <span className="ms-2">Last LoggedIn</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="lastLogin"
              type="radio"
              name="sorting"
              checked={selectedSortingOne === "lastLogin"}
              value="lastLogin"
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <Styled.Sorting>
          <label className="form-check-label" htmlFor="userType">
            <ContactMailTwoToneIcon
              sx={{ color: Colors.font2, fontSize: 21 }}
            />
            <span className="ms-2">UserType</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="userType"
              type="radio"
              name="sorting"
              checked={selectedSortingOne === "userType"}
              value="userType"
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <hr
          style={{
            borderColor: "f9f9f9",
            marginTop: 10,
            marginBottom: 10,
            borderTop: "none",
            opacity: 0.1,
          }}
        />

        <Styled.Sorting>
          <label className="form-check-label" htmlFor="asc">
            <ArrowUpwardTwoToneIcon
              sx={{ color: Colors.font2, fontSize: 21 }}
            />
            <span className="ms-2">Ascending (A-Z)</span>
          </label>

          <div className="form-check">
            <input
              className="form-check-input"
              id="asc"
              type="radio"
              name="ordering"
              checked={selectedSortingTwo === "asc"}
              value="asc"
              onChange={sortingOrderingChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <Styled.Sorting>
          <label className="form-check-label" htmlFor="desc">
            <ArrowDownwardTwoToneIcon
              sx={{ color: Colors.font2, fontSize: 21 }}
            />
            <span className="ms-2">Descending (Z-A)</span>
          </label>

          <div className="form-check">
            <input
              className="form-check-input"
              id="desc"
              type="radio"
              name="ordering"
              checked={selectedSortingTwo === "desc"}
              value="desc"
              onChange={sortingOrderingChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <Styled.Sorting className="mt-3">
          <TextStyles.GreyFont16px
            onClick={hideSortingHandler}
            style={{
              width: "100%",
              fontWeight: 500,
              textAlign: "center",
              cursor: "pointer",
            }}
          >
            Done
          </TextStyles.GreyFont16px>
        </Styled.Sorting>
      </TextStyles.FontSize14px>
    );
  };

  return (
    <div>
      <div className="d-flex flex-wrap gap-3 justify-content-between align-items-center mb-3">
        <TextStyles.FontSize16px className="font-weight-500 mt-3 mt-md-4">
          User Details
        </TextStyles.FontSize16px>
        <div className="d-flex justify-content-end align-items-center ms-auto">
          <TextStyles.HoverEffectSpan onClick={toggleShowFilter}>
            Filter
          </TextStyles.HoverEffectSpan>

          <div
            className="client-partion-rectangle mx-2"
            style={{
              width: "2px",
              height: "23px",
              backgroundColor: "#7d849e",
            }}
          />
          <TextStyles.FontSize16px>
            <Popover
              placement="bottomRight"
              // title={"Hello"}
              content={sortingContent}
              trigger="click"
              open={openSorting}
              onOpenChange={handleOpenSortingChange}
            >
              <TextStyles.HoverEffectSpan sort={true}>
                Sort
                <ArrowDropDownOutlinedIcon
                  sx={{
                    color: Colors.font2,
                    fontSize: 27,
                    transform: openSorting ? "rotate(180deg)" : "",
                  }}
                />
              </TextStyles.HoverEffectSpan>
            </Popover>
          </TextStyles.FontSize16px>
        </div>
      </div>

      <Styled.TableHead className="mt-3">
        <div className="d-flex gap-2 align-items-center font-weight-500">
          <TextStyles.FontSize14px
            className="d-flex justify-content-start text-start"
            style={{ width: "120%", minWidth: 30 }}
          >
            <span className="three-dots">Name</span>
          </TextStyles.FontSize14px>

          <TextStyles.FontSize14px
            className="d-flex justify-content-start"
            style={{ width: "160%", minWidth: 30 }}
          >
            <span className="three-dots"> Email</span>
          </TextStyles.FontSize14px>

          <TextStyles.FontSize14px
            className="d-flex justify-content-center"
            style={{ width: "90%", minWidth: 30 }}
          >
            <span className="three-dots">Country</span>
          </TextStyles.FontSize14px>

          <TextStyles.FontSize14px
            className="d-flex justify-content-center"
            style={{ width: "90%", minWidth: 30 }}
          >
            <span className="three-dots">CreatedAt</span>
          </TextStyles.FontSize14px>

          <TextStyles.FontSize14px
            className="d-flex justify-content-center"
            style={{ width: "90%", minWidth: 30 }}
          >
            <span className="three-dots">Last LoggedIn</span>
          </TextStyles.FontSize14px>

          <TextStyles.FontSize14px
            className="d-flex justify-content-center"
            style={{ width: "90%", minWidth: 30 }}
          >
            <span className="three-dots">User Type</span>
          </TextStyles.FontSize14px>
          <TextStyles.FontSize14px
            className="d-flex justify-content-end"
            style={{
              width: "60%",
              maxWidth: 35,
              minWidth: 35,
            }}
          />
        </div>
      </Styled.TableHead>
      {activeUsers &&
        activeUsers.length > 0 &&
        activeUsers.map((item, index) => {
          return (
            <Styled.TableCell
              key={index}
              style={{
                borderRadius:
                  activeUsers.length - 1 === index ? "0 0 12px 12px" : "",
              }}
            >
              <div
                className={`d-flex gap-2 align-items-center w-100 ${
                  collapse === index ? "mb-3" : ""
                }`}
                onClick={() => collapsableHandleChange(index)}
              >
                <TextStyles.FontSize14px
                  className="d-flex justify-content-start text-start"
                  style={{ width: "120%", minWidth: 30 }}
                >
                  <span className="three-dots">
                    {item && `${item.firstName} ${item.lastName}`}
                  </span>
                </TextStyles.FontSize14px>

                <TextStyles.FontSize14px
                  className="d-flex justify-content-start"
                  style={{ width: "160%", minWidth: 30 }}
                >
                  <span className="three-dots">{item && item.email}</span>
                </TextStyles.FontSize14px>

                <TextStyles.FontSize14px
                  className="d-flex justify-content-center"
                  style={{ width: "90%", minWidth: 30 }}
                >
                  <span className="three-dots">
                    {item &&
                      item.country &&
                      `${item.country.name}(${item.country.code})`}
                  </span>
                </TextStyles.FontSize14px>

                <TextStyles.FontSize14px
                  className="d-flex justify-content-center"
                  style={{ width: "90%", minWidth: 30 }}
                >
                  <span className="three-dots w-75">
                    {item &&
                      item.createdAt &&
                      moment(item && item.createdAt).format("DD MMM YYYY")}
                  </span>
                </TextStyles.FontSize14px>

                <TextStyles.FontSize14px
                  className="d-flex justify-content-center"
                  style={{ width: "90%", minWidth: 30 }}
                >
                  <span className="three-dots">
                    {item &&
                      item.lastLogin &&
                      moment(item && item.lastLogin).format("DD MMM YYYY")}
                  </span>
                </TextStyles.FontSize14px>

                <TextStyles.FontSize14px
                  className="d-flex justify-content-center"
                  style={{ width: "90%", minWidth: 30 }}
                >
                  <span className="three-dots">{item && item.userType}</span>
                </TextStyles.FontSize14px>

                <TextStyles.FontSize14px
                  className="d-flex justify-content-end"
                  style={{
                    width: "60%",
                    maxWidth: 35,
                    minWidth: 35,
                  }}
                >
                  {collapse === index ? (
                    <ExpandLessIcon
                      sx={{
                        color: Colors.primary,
                        fontSize: 30,
                        background: Colors.white,
                        padding: "2px 6px",
                        borderRadius: "6px",
                        width: 35,
                        height: 30,
                      }}
                    />
                  ) : (
                    <ExpandMoreIcon
                      sx={{
                        color: Colors.primary,
                        fontSize: 30,
                        background: Colors.white,
                        padding: "2px 6px",
                        borderRadius: "6px",
                        width: 35,
                        height: 30,
                      }}
                    />
                  )}
                </TextStyles.FontSize14px>
              </div>
              {collapse === index && (
                <hr
                  style={{
                    width: "100%",
                    color: Colors.primary,
                    marginTop: "0",
                  }}
                />
              )}

              {collapse === index && (
                <div>
                  <TextStyles.FontSize14px>
                    Total Tasks: {item.totalTasks}
                  </TextStyles.FontSize14px>
                  <TextStyles.FontSize14px>
                    Total Clients: {item.totalClients}
                  </TextStyles.FontSize14px>{" "}
                  <TextStyles.FontSize14px>
                    Total Projects: {item.totalProjects}
                  </TextStyles.FontSize14px>{" "}
                  <TextStyles.FontSize14px>
                    Total Invoices: {item.totalInvoices}
                  </TextStyles.FontSize14px>{" "}
                  <TextStyles.FontSize14px>
                    Total Agreements: {item.totalTasks}
                  </TextStyles.FontSize14px>{" "}
                  <TextStyles.FontSize14px>
                    Total Activities: {item.totalTasks}
                  </TextStyles.FontSize14px>
                </div>
              )}
            </Styled.TableCell>
          );
        })}
    </div>
  );
};

export default ActiveUsers;
