import React from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { Modal } from "react-bootstrap";
import { message } from "antd";
import LoaderSpin from "../../../Commons/LoaderSpin";

import ClearIcon from "@mui/icons-material/Clear";
import TextStyles from "../../../../../Constants/TextStyles";
import { updateProjectTerminationNoticePeriodPC } from "../../../../../store/service/proposalAndContractService";
import SelectProjectTerminationDays from "../../../Commons/SelectProjectTerminationDays";
import Styled from "./PageModals";
import Colors from "../../../../../Constants/Colors";

const TermAndTerminationModal = ({
  show,
  handleClose,
  heading,
  paragraph,
  selectedData,
}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.proposalAndContract.isLoading);

  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
    control,
    watch,
  } = useForm({
    defaultValues: {
      heading: heading,
      paragraph: paragraph,
      project_termination_notice_period:
        selectedData &&
        selectedData.term_and_termination &&
        selectedData.term_and_termination.project_termination_notice_period &&
        selectedData.term_and_termination.project_termination_notice_period ===
          "7 days"
          ? "1 week"
          : selectedData &&
            selectedData.term_and_termination &&
            selectedData.term_and_termination
              .project_termination_notice_period &&
            selectedData.term_and_termination
              .project_termination_notice_period === "14 days"
          ? "2 weeks"
          : selectedData &&
            selectedData.term_and_termination &&
            selectedData.term_and_termination
              .project_termination_notice_period &&
            selectedData.term_and_termination
              .project_termination_notice_period === "21 days"
          ? "3 weeks"
          : selectedData &&
            selectedData.term_and_termination &&
            selectedData.term_and_termination
              .project_termination_notice_period &&
            selectedData.term_and_termination
              .project_termination_notice_period === "30 days"
          ? "1 month"
          : selectedData &&
            selectedData.term_and_termination &&
            selectedData.term_and_termination
              .project_termination_notice_period &&
            selectedData.term_and_termination
              .project_termination_notice_period !== "7 days" &&
            selectedData.term_and_termination
              .project_termination_notice_period !== "14 days" &&
            selectedData.term_and_termination
              .project_termination_notice_period !== "21 days" &&
            selectedData.term_and_termination
              .project_termination_notice_period !== "30 days"
          ? "Custom"
          : "2 week",
      custom_project_termination_notice_period:
        selectedData &&
        selectedData.term_and_termination &&
        selectedData.term_and_termination.project_termination_notice_period &&
        selectedData.term_and_termination.project_termination_notice_period !==
          "7 days" &&
        selectedData.term_and_termination.project_termination_notice_period !==
          "14 days" &&
        selectedData.term_and_termination.project_termination_notice_period !==
          "21 days" &&
        selectedData.term_and_termination.project_termination_notice_period !==
          "30 days"
          ? selectedData.term_and_termination.project_termination_notice_period.split(
              " "
            )[0]
          : null,
    },
  });

  const project_termination_notice_period = watch(
    "project_termination_notice_period"
  );

  const formsubmit = (item) => {
    const Obj = {
      _id: selectedData && selectedData._id,
      term_and_termination: {
        required: true,
        heading: item.heading,
        paragraph: item.paragraph,
        project_termination_notice_period:
          item.project_termination_notice_period === "Custom"
            ? `${item.custom_project_termination_notice_period} days`
            : item.project_termination_notice_period === "1 week"
            ? "7 days"
            : item.project_termination_notice_period === "2 weeks"
            ? "14 days"
            : item.project_termination_notice_period === "3 weeks"
            ? "21 days"
            : item.project_termination_notice_period === "1 month"
            ? "30 days"
            : "14 days",
      },
    };
    dispatch(updateProjectTerminationNoticePeriodPC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        reset();
        handleClose();
        message.success("Clause updated successfully!");
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="ToMakeModalWidth600px"
        contentClassName="ToMakeModalBorderRudius12px"
        scrollable={true}
        centered={true}
      >
        <form
          onSubmit={handleSubmit(formsubmit)}
          style={{ height: "100%" }}
          className="modal-content"
        >
          <Modal.Header className="d-flex justify-content-between align-items-center">
            <TextStyles.FontSize20px>Edit contract</TextStyles.FontSize20px>
            <ClearIcon
              sx={{
                color: Colors.font2,
                cursor: "pointer",
                opacity: 0.7,
                "&:hover": {
                  opacity: 1,
                },
              }}
              onClick={handleClose}
            />
          </Modal.Header>
          <Modal.Body>
            <TextStyles.FontSize14px className="mb-3">
              <label htmlFor="headline" className="mb-2">
                Headline
              </label>
              <TextStyles.InputRectangle
                placeholder="Ownershi and Licences"
                id="headline"
                name="heading"
                {...register("heading")}
              />
            </TextStyles.FontSize14px>
            <TextStyles.FontSize14px className="mb-3">
              <label htmlFor="headline" className="mb-2">
                Paragraph
              </label>
              <TextStyles.Textarea
                rows={8}
                placeholder="Add text here"
                id="paragraph"
                name="paragraph"
                {...register("paragraph")}
              />
            </TextStyles.FontSize14px>
            <TextStyles.FontSize18px className="mb-3">
              Options
            </TextStyles.FontSize18px>

            <TextStyles.FontSize14px className="mb-3">
              <label
                htmlFor="project_termination_notice_period"
                className="mb-2"
              >
                Project termination notice period
              </label>

              <div className="d-flex flex-wrap gap-3 align-items-center">
                <Controller
                  control={control}
                  name="project_termination_notice_period"
                  render={({ field: { onChange, value } }) => (
                    <SelectProjectTerminationDays
                      width="180px"
                      onChange={onChange}
                      defaultValue={value}
                    />
                  )}
                />

                {project_termination_notice_period === "Custom" && (
                  <>
                    <TextStyles.FontSize14px>After</TextStyles.FontSize14px>
                    <Styled.inputBox
                      invalid={errors.custom_project_termination_notice_period}
                    >
                      <input
                        type="text"
                        name="custom_project_termination_notice_period"
                        style={{
                          width: 24,
                          textAlign: "center",
                          background: "transparent",
                        }}
                        placeholder="00"
                        {...register(
                          "custom_project_termination_notice_period",
                          {
                            required:
                              project_termination_notice_period === "Custom"
                                ? "Please enter custom termination notice period."
                                : false,
                            min: {
                              value: 1,
                              message: "Please enter more than 0.",
                            },
                            pattern: {
                              value: /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/i,
                              message: "Please enter a valid number",
                            },
                          }
                        )}
                      />
                    </Styled.inputBox>
                    <TextStyles.FontSize14px>
                      {project_termination_notice_period === "1"
                        ? "day"
                        : "days"}
                    </TextStyles.FontSize14px>
                  </>
                )}
              </div>
              {errors.custom_project_termination_notice_period && (
                <TextStyles.InValidFeedback className="mt-2">
                  {errors.custom_project_termination_notice_period.message}
                </TextStyles.InValidFeedback>
              )}
            </TextStyles.FontSize14px>
          </Modal.Body>
          <Modal.Footer style={{ display: "inline-block" }}>
            <div className="d-flex gap-3 justify-content-between">
              <TextStyles.CancelButton onClick={handleClose}>
                Cancel
              </TextStyles.CancelButton>
              <TextStyles.Button disabled={isLoading}>
                {isLoading ? <LoaderSpin /> : "Update"}
              </TextStyles.Button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default TermAndTerminationModal;
