import { createSlice } from "@reduxjs/toolkit";
import {
  fetchActiveUsers,
  fetchDeletedUsers,
  deleteDeletedUser,
} from "../service/AdminService";

const initialAdminState = {
  isLoading: false,
  error: false,
  activeUsers: [],
  deletedUsers: [],
  totalUsers: null,
};

const adminSlice = createSlice({
  name: "admin",
  initialState: initialAdminState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // fetch active users
      .addCase(fetchActiveUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchActiveUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.activeUsers = action.payload.data;
      })
      .addCase(fetchActiveUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // fetch deleted users
      .addCase(fetchDeletedUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchDeletedUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.deletedUsers = action.payload.data;
      })
      .addCase(fetchDeletedUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // delete deleted users
      .addCase(deleteDeletedUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteDeletedUser.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteDeletedUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const adminActions = adminSlice.actions;
export default adminSlice.reducer;
