import React from "react";
import LandingPage from "./LandingPage/LandingPage";
import Features from "./Features/Features";
import Footer from "../../Footer/Footer";
import SignupForm from "../../UIs/SignupForm";
import { Helmet } from "react-helmet";

const TaskManagements = () => {
  return (
    <div>
      <Helmet>
        <title>Manage Your Tasks Efficiently - Zodot</title>
        <meta
          content="Manage tasks efficiently with Zodot. It automatically creates and prioritizes tasks, helping you focus on what matters most right now."
          name="description"
        />
        <link
          rel="canonical"
          href="https://zodot.co/features/task-management"
        />
      </Helmet>
      <LandingPage />
      <Features />
      <SignupForm />
      <Footer />
    </div>
  );
};

export default TaskManagements;
