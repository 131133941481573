import React, { useState, useEffect } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { createPC } from "../../../../../store/service/proposalAndContractService";
import { message } from "antd";

import { AutoCompleteSelectClient } from "../../../Commons/AutoComplete";

import Services from "./Services";
import BillingSchedule from "./BillingSchedule";

import Styled from "./SetupStyles";
import TextStyles from "../../../../../Constants/TextStyles";
import SelectCurrency from "../../../Commons/SelectCurrency";
import SwitchButton from "../../../Commons/SwitchButton";
import Colors from "../../../../../Constants/Colors";
import moment from "moment";
import CustomDatePickerOne from "../../../Commons/DatePickers/CustomDatePickerOne";
import { pcActions } from "../../../../../store/storage/proposalAndContractSlice";
import LoaderSpin from "../../../Commons/LoaderSpin";
import { useHistory } from "react-router-dom";

export default function Setup() {
  const dispatch = useDispatch();
  const history = useHistory();
  const clientOptionData = useSelector(
    (state) => state.clients.clientOptionData
  );

  const { selectedData, createDocType, isLoading } = useSelector(
    (state) => state.proposalAndContract
  );

  let billing_scheduled = selectedData && selectedData.billing_scheduled;

  const selectedClient = selectedData && selectedData.client_id;

  const userCurrency = useSelector((state) => state.auth.userCurrency);

  const changeDocTypeHandler = (data) => {
    dispatch(pcActions.createPcDocType(data));
  };

  const [selectEndDate, setSelectEndDate] = useState(
    selectedData && selectedData.project_end_date ? false : true
  );

  const {
    register,
    watch,
    reset,
    formState: { errors },
    handleSubmit,
    control,
    setValue,
  } = useForm({
    defaultValues: {
      servicesList:
        selectedData && selectedData.service_list
          ? selectedData.service_list
          : [
              {
                service_name: "",
                service_rate: "",
                service_rate_type: "Flat fee",
                desc: "",
              },
            ],

      project_name: selectedData && selectedData.project_name,
      client: selectedClient && {
        label: selectedClient.company_name,
        _id: selectedClient._id,
      },
      currency:
        selectedData && selectedData.currency
          ? selectedData.currency
          : userCurrency && userCurrency.currency,

      project_start_date:
        selectedData && selectedData.project_start_date
          ? new Date(selectedData.project_start_date)
          : new Date(),
      project_end_date:
        selectedData &&
        selectedData.project_end_date &&
        new Date(selectedData.project_end_date),

      billingScheduled: {
        deposit: {
          require_deposit:
            billing_scheduled &&
            billing_scheduled.deposit &&
            billing_scheduled.deposit.require_deposit,
          value:
            billing_scheduled &&
            billing_scheduled.deposit &&
            billing_scheduled.deposit.value,
        },
        issue_invoice:
          billing_scheduled &&
          billing_scheduled.issue_invoice &&
          new Date(billing_scheduled.issue_invoice),
        weekly_invoice: {
          start_date:
            billing_scheduled &&
            billing_scheduled.weekly_invoice &&
            billing_scheduled.weekly_invoice.start_date &&
            new Date(billing_scheduled.weekly_invoice.start_date),
          invoices_type:
            billing_scheduled &&
            billing_scheduled.weekly_invoice &&
            billing_scheduled.weekly_invoice.invoices_type,
          num_invoices:
            billing_scheduled &&
            billing_scheduled.weekly_invoice &&
            billing_scheduled.weekly_invoice.num_invoices,
          end_date:
            billing_scheduled &&
            billing_scheduled.weekly_invoice &&
            billing_scheduled.weekly_invoice.end_date &&
            new Date(billing_scheduled.weekly_invoice.end_date),
        },
        biWeekly_invoice: {
          start_date:
            billing_scheduled &&
            billing_scheduled.biWeekly_invoice &&
            billing_scheduled.biWeekly_invoice.start_date &&
            new Date(billing_scheduled.biWeekly_invoice.start_date),
          invoices_type:
            billing_scheduled &&
            billing_scheduled.biWeekly_invoice &&
            billing_scheduled.biWeekly_invoice.invoices_type,
          num_invoices:
            billing_scheduled &&
            billing_scheduled.biWeekly_invoice &&
            billing_scheduled.biWeekly_invoice.num_invoices,
          end_date:
            billing_scheduled &&
            billing_scheduled.biWeekly_invoice &&
            billing_scheduled.biWeekly_invoice.end_date &&
            new Date(billing_scheduled.biWeekly_invoice.end_date),
        },
        monthly_invoice: {
          start_date:
            billing_scheduled &&
            billing_scheduled.monthly_invoice &&
            billing_scheduled.monthly_invoice.start_date &&
            new Date(billing_scheduled.monthly_invoice.start_date),
          invoices_type:
            billing_scheduled &&
            billing_scheduled.monthly_invoice &&
            billing_scheduled.monthly_invoice.invoices_type,
          num_invoices:
            billing_scheduled &&
            billing_scheduled.monthly_invoice &&
            billing_scheduled.monthly_invoice.num_invoices,
          end_date:
            billing_scheduled &&
            billing_scheduled.monthly_invoice &&
            billing_scheduled.monthly_invoice.end_date &&
            new Date(billing_scheduled.monthly_invoice.end_date),
        },
      },
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "servicesList",
  });

  const changedCurrency = watch("currency");
  const currencySymbol = changedCurrency
    ? changedCurrency.symbol
    : userCurrency && userCurrency.currency && userCurrency.currency.symbol;

  const [endDateErrorMessage, setEndDateErrorMessage] = useState(null);
  const startDate = watch("project_start_date");
  const endDate = watch("project_end_date");

  const validateEndDate = () => {
    const eDate = endDate && moment(endDate);
    const sDate = moment(startDate);
    if (eDate && eDate.isBefore(sDate, "day")) {
      setEndDateErrorMessage(
        `End date should be greater than ${moment(new Date(startDate))
          .subtract(1, "d")
          .format("DD MMM YYYY")}.`
      );
      return false;
    } else {
      setEndDateErrorMessage(null);
      return true;
    }
  };

  useEffect(() => {
    validateEndDate();
  }, [startDate, endDate]);

  useEffect(() => {
    setValue("project_end_date", null);
  }, [selectEndDate]);

  const formsubmit = async (data) => {
    const Obj = {
      _id: selectedData && selectedData._id,
      project_name: data.project_name,
      client_id: data.client && data.client._id,
      project_start_date: data.project_start_date
        ? data.project_start_date
        : new Date(),
      project_end_date:
        selectEndDate || data.project_end_date === undefined
          ? null
          : data.project_end_date && new Date(data.project_end_date),
      currency: data.currency
        ? data.currency
        : userCurrency && userCurrency.currency,
      section:
        createDocType === "Create proposal and contract"
          ? "Proposal and contract"
          : createDocType === "Create contract"
          ? "Contract"
          : createDocType === "Create proposal"
          ? "Proposal"
          : "Proposal",
      service_list: data.servicesList,
      billing_scheduled: data.billingScheduled,
    };

    dispatch(createPC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        if (createDocType === "Create contract") {
          history.push("/db/agreements/create/reviewContract");
        } else {
          history.push("/db/agreements/create/reviewProposal");
        }
        message.success(data.payload.message);
      } else {
        message.error(data.error.message);
      }
    });
  };

  return (
    <div className="d-flex justify-content-center mx-2">
      <div style={{ width: "100%", maxWidth: "650px", paddingTop: "2rem" }}>
        <form onSubmit={handleSubmit(formsubmit)}>
          <Styled.SetupBox className="mb-4">
            <TextStyles.FontSize20px
              className="d-flex justify-content-center justify-content-sm-start mb-3"
              style={{ fontWeight: 500 }}
            >
              I want to create a
            </TextStyles.FontSize20px>
            <div className="d-flex flex-wrap gap-3 justify-content-center justify-content-sm-start mb-3">
              <div
                onClick={() => {
                  changeDocTypeHandler("Create proposal and contract");
                }}
              >
                {createDocType === "Create proposal and contract" ? (
                  <Styled.SelectedDocType>
                    Proposal and contract
                  </Styled.SelectedDocType>
                ) : (
                  <Styled.NotSelectedDocType>
                    Proposal and contract
                  </Styled.NotSelectedDocType>
                )}
              </div>
              <div
                onClick={() => {
                  changeDocTypeHandler("Create proposal");
                }}
              >
                {createDocType === "Create proposal" ? (
                  <Styled.SelectedDocType>Proposal only</Styled.SelectedDocType>
                ) : (
                  <Styled.NotSelectedDocType>
                    {" "}
                    Proposal only
                  </Styled.NotSelectedDocType>
                )}
              </div>
              <div
                onClick={() => {
                  changeDocTypeHandler("Create contract");
                }}
              >
                {createDocType === "Create contract" ? (
                  <Styled.SelectedDocType>Contract only</Styled.SelectedDocType>
                ) : (
                  <Styled.NotSelectedDocType>
                    Contract only
                  </Styled.NotSelectedDocType>
                )}
              </div>
            </div>

            <TextStyles.FontSize14px className="mb-3">
              <label htmlFor="project_name" className="mb-2">
                Project name
              </label>
              <TextStyles.InputRectangle
                name="project_name"
                id="project_name"
                invalid={errors.project_name}
                type="text"
                placeholder="Project name"
                {...register("project_name", {
                  required: "Project name is required.",
                  validate: (value) => {
                    let trimedLength = value.trim().length;
                    if (trimedLength === 0) {
                      return "Project name is required.";
                    }
                    if (trimedLength < 3) {
                      return "Project name must be at least 3 characters";
                    }
                    if (trimedLength > 40) {
                      return "Project name must be at most 40 characters";
                    }
                    return true;
                  },
                })}
              />
              {errors.project_name && (
                <TextStyles.InValidFeedback>
                  {errors.project_name.message}
                </TextStyles.InValidFeedback>
              )}
            </TextStyles.FontSize14px>

            <TextStyles.FontSize14px className="mb-3">
              <label htmlFor="client" className="mb-2">
                Select client
              </label>
              <Controller
                control={control}
                name="client"
                rules={{
                  required: {
                    value: true,
                    message: "Client is required.",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <AutoCompleteSelectClient
                    placeholder="Choose a client"
                    options={
                      clientOptionData &&
                      clientOptionData.map((data) => {
                        return { label: data.company_name, _id: data._id };
                      })
                    }
                    onChange={onChange}
                    defaultValue={value}
                    invalid={errors.client}
                  />
                )}
              />
              {errors.client && (
                <TextStyles.InValidFeedback className="mt-2">
                  {errors.client.message}
                </TextStyles.InValidFeedback>
              )}
            </TextStyles.FontSize14px>

            <TextStyles.FontSize14px className="mb-3">
              <label htmlFor="client" className="mb-2">
                Select currency
              </label>
              <Controller
                control={control}
                name="currency"
                render={({ field: { onChange, value } }) => (
                  <SelectCurrency
                    placeholder="Select currency"
                    onChange={onChange}
                    defaultValue={value}
                  />
                )}
              />
            </TextStyles.FontSize14px>

            <TextStyles.FontSize14px className="mb-3">
              <div htmlFor="project_start_date" className="mb-2">
                Start date
              </div>
              <Controller
                name={"project_start_date"}
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <CustomDatePickerOne
                      onChange={onChange}
                      selected={value}
                      placeholder="Select start date"
                      allowClear={false}
                    />
                  );
                }}
              />
            </TextStyles.FontSize14px>
            <TextStyles.FontSize14px className="mb-3">
              <div className="d-flex justify-content-between align-items-center">
                <div htmlFor="project_end_date">End date</div>
                <div className="d-flex align-items-center">
                  <TextStyles.FontSize14px
                    style={{ color: Colors.primary, fontWeight: 500 }}
                  >
                    Ongoing
                  </TextStyles.FontSize14px>
                  <SwitchButton
                    defaultChecked={selectEndDate}
                    onChange={(event) => setSelectEndDate(event.target.checked)}
                  />
                </div>
              </div>
              {selectEndDate ? (
                ""
              ) : (
                <Controller
                  name={"project_end_date"}
                  control={control}
                  rules={{ validate: validateEndDate }}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <CustomDatePickerOne
                        onChange={onChange}
                        selected={value}
                        placeholder="Select end date"
                        allowClear={true}
                      />
                    );
                  }}
                />
              )}
              {endDateErrorMessage && (
                <TextStyles.InValidFeedback className="mt-2">
                  {endDateErrorMessage}
                </TextStyles.InValidFeedback>
              )}
            </TextStyles.FontSize14px>
          </Styled.SetupBox>
          <Services
            currencySymbol={currencySymbol}
            control={control}
            Controller={Controller}
            watch={watch}
            register={register}
            fields={fields}
            remove={remove}
            append={append}
          />
          <BillingSchedule
            currencySymbol={currencySymbol}
            billing_scheduled={billing_scheduled}
            register={register}
            watch={watch}
            setValue={setValue}
            Controller={Controller}
            control={control}
            reset={reset}
          />
          <Styled.SubmitButton disabled={isLoading ? true : false}>
            {isLoading ? (
              <LoaderSpin />
            ) : createDocType === "Create proposal and contract" ? (
              "Create documents"
            ) : (
              createDocType
            )}
          </Styled.SubmitButton>
        </form>
      </div>
    </div>
  );
}
