// import React, { Component, useState } from "react";
import React from "react";
import "./SideDrawer.css";
import Backdrop from "./Backdrop/Backdrop";
import NavBarMobile from "./NavBarMobile/NavBarMobile";

const SideDrawer = (props) => {
  let sideClasses = ["SideDrawer", "Close"];
  if (props.open) {
    sideClasses = ["SideDrawer", "Open"];
  }
  return (
    <>
      <Backdrop clicked={props.close} show={props.open} />
      <div className={sideClasses.join(" ")}>
        <NavBarMobile close={props.close} />
      </div>
    </>
  );
};

// class SideDrawer extends Component {
//   state = { open: this.props.open };

//   handleSidebar = () => {
//     this.setState({ open: !this.props.open });
//   };
//   render() {
//     let sideClasses = ["SideDrawer", "Close"];
//     if (this.props.open) {
//       sideClasses = ["SideDrawer", "Open"];
//     }

//     return (
//       <>
//         <Backdrop clicked={this.props.close} show={this.props.open} />
//         <div className={sideClasses.join(" ")}>
//           <NavBarMobile close={this.props.close} />
//         </div>
//       </>
//     );
//   }
// }

export default SideDrawer;
