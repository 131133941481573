import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { message } from "antd";
import { deleteAddSectionFilePC } from "../../../../../../store/service/proposalAndContractService";
import EditAddSectionFileModal from "./EditAddSectionFileModal";

import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";

import TextStyles from "../../../../../../Constants/TextStyles";
import Styled from "../AddSectionStyles";
import RemoveModal from "../../../../Commons/RemoveModal";
import { getFileFromUrl } from "../../../../Commons/utils";
import Colors from "../../../../../../Constants/Colors";

const File = ({ data, position, pc_id }) => {
  const dispatch = useDispatch();
  const [editModal, setEditModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const handleCloseRemoveModal = () => setShowRemoveModal(false);

  const DownloadForOfflineOutlined = (item) => {
    let filename = item.file_name;
    let indexofUnderscore = filename.indexOf("_");
    let file_name = filename.substring(indexofUnderscore + 1);
    getFileFromUrl(item.file_url, file_name).then((res) => {
      const href = URL.createObjectURL(res);
      const aElement = document.createElement("a");
      aElement.href = href;
      aElement.setAttribute("download", file_name);
      aElement.setAttribute("target", "_blank");
      aElement.click();
      URL.revokeObjectURL(href);
    });
  };

  const deleteHandler = () => {
    const Obj = {
      pc_id: pc_id,
      _id: data._id,
      files: data.files.map((d) => d.file_name),
      position: position,
    };

    dispatch(deleteAddSectionFilePC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        message.success(`${data.payload.message}`);
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  return (
    <div className="mt-4">
      <Styled.ReviewBox>
        <div className="d-flex justify-content-end">
          <HighlightOffOutlinedIcon
            onClick={() => {
              setShowRemoveModal(true);
              setConfirmDialog({
                onConfirm: () => deleteHandler(),
              });
            }}
            sx={{ cursor: "pointer", fontSize: 24, color: Colors.red }}
          />
        </div>

        <TextStyles.FontSize14px className="mb-3">
          <TextStyles.FontSize18px className="mb-2">
            File title
          </TextStyles.FontSize18px>
          <div className="d-flex gap-1 justify-content-between align-items-center">
            <div
              className="d-flex justify-content-start w-100"
              style={{ minWidth: 100 }}
            >
              <Styled.TextBoader
                className="d-flex w-100"
                style={{ minWidth: 100 }}
              >
                <span className="three-dots">{data.file_title}</span>
              </Styled.TextBoader>
            </div>

            <div
              className="d-flex justify-content-end w-100"
              style={{ minWidth: 30, maxWidth: 30 }}
            >
              <BorderColorOutlinedIcon
                onClick={() => setEditModal(true)}
                sx={{
                  fontSize: 24,
                  color: Colors.font2,
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        </TextStyles.FontSize14px>

        <TextStyles.FontSize14px className="mb-3">
          <TextStyles.FontSize18px className="mb-2">
            Files
          </TextStyles.FontSize18px>
          {data &&
            data.files &&
            data.files.map((item, index) => {
              let filename = item.file_name;
              let indexofUnderscore = filename.indexOf("_");
              let file_name = filename.substring(indexofUnderscore + 1);
              return (
                <Styled.FileDownloadContainer
                  className="d-flex align-items-center"
                  key={index}
                  onClick={() => DownloadForOfflineOutlined(item)}
                  style={{ minWidth: 100 }}
                >
                  <span className="three-dots">
                    <InsertDriveFileOutlinedIcon className="pe-2" />
                    {file_name} - {item.size / 1000000} MB
                  </span>
                </Styled.FileDownloadContainer>
              );
            })}
        </TextStyles.FontSize14px>
      </Styled.ReviewBox>
      {editModal && (
        <EditAddSectionFileModal
          show={editModal}
          handleClose={() => setEditModal(false)}
          editData={data}
          pc_id={pc_id}
          position={position}
        />
      )}
      <RemoveModal
        title={"files"}
        confirmDialog={confirmDialog}
        showRemoveModal={showRemoveModal}
        handleCloseRemoveModal={handleCloseRemoveModal}
      />
    </div>
  );
};

export default File;
