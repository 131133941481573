import styled from "styled-components";
import Colors from "../../../../Constants/Colors";

const ButtonBox = styled.div`
  width: 100%;
  max-width: 10rem;
  height: 3.125rem;
  font-weight: 500;
  border-radius: 0.75rem;
  background-color: ${Colors.primary};
  cursor: pointer;
  self-align: center;
  color: ${Colors.white};
  font-family: Poppins, serif;
  font-size: 0.875rem;
  transition: background-color 0.4s ease;
  &:hover {
    background-color: ${Colors.hoverButtonColor};
  }
`;

const TableHead = styled.div`
  padding: 1rem 1rem;
  border-radius: 0.75rem 0.75rem 0 0;
  background-color: ${Colors.tableHeadColor};
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
  contain: inline-size;
`;

const TableCell = styled.div`
  width: 100%;
  height: 4rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 1rem;
  margin: 0 0 2px 0;
  background-color: ${Colors.white};
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
  contain: inline-size;
  &:hover {
    background: ${Colors.backgroundPink};
  }
`;

const ListBox = styled.div`
  width: 100%;
  height: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.white};
  border-radius: 0 0 0.75rem 0.75rem;
  padding: 1rem;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
`;

export default {
  ButtonBox,
  TableHead,
  TableCell,
  ListBox,
};
