import React from "react";
import moment from "moment";
import clientBtn from "../../../../../Assets/assetsnew/image-client.svg";
import Services from "./Services/Services.jsx";
import BillingSchedules from "./BillingSchedules/BillingSchedules.jsx";
import Signature from "./Signatures/Signature.jsx";

import Text from "./AddSection/Text/Text";
import Image from "./AddSection/Image/Image";
import Table from "./AddSection/Table/Table";
import Column from "./AddSection/Column/Column";
import File from "./AddSection/File/File";

import Styled from "./ReviewStyles";
import TextStyles from "../../../../../Constants/TextStyles";
import Colors from "../../../../../Constants/Colors";

import { diffDate } from "../.././../Commons/utils";
const Review = (props) => {
  const selectedData = props && props.selectedData;
  const data = props && props.data;
  const businessInfoData = props && props.businessInfoData;
  const brandAndLogoData = props && props.brandAndLogoData;

  const primaryContactData =
    selectedData &&
    selectedData.client_id &&
    selectedData.client_id.contacts &&
    selectedData.client_id.contacts.filter(
      (d) => d.email === (selectedData && selectedData.client_email)
    );
  let primaryContact;
  if (primaryContactData.length === 1) {
    primaryContact = primaryContactData && primaryContactData[0];
  } else if (primaryContactData.length > 1) {
    primaryContact =
      selectedData &&
      selectedData.client_id &&
      selectedData.client_id.contacts &&
      selectedData.client_id.contacts[0];
  } else {
    primaryContact = null;
  }

  return (
    <div className="px-lg-4 px-md-4 px-sm-3 px-2">
      <div className="d-flex justify-content-center w-100">
        <div style={{ width: "100%", maxWidth: 700 }}>
          <Styled.ReviewContainer
            style={{
              borderTop: `4px solid ${
                brandAndLogoData && brandAndLogoData.brand_color
                  ? brandAndLogoData.brand_color
                  : Colors.primary
              }`,
            }}
          >
            {brandAndLogoData &&
              brandAndLogoData.brand_logo &&
              brandAndLogoData.brand_logo.url && (
                <div
                  className={
                    brandAndLogoData && brandAndLogoData.logo_alignment
                      ? `text-${brandAndLogoData.logo_alignment} mb-3`
                      : "text-center mb-3"
                  }
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={brandAndLogoData.brand_logo.url}
                    alt="logoImg"
                    style={{ maxWidth: 512, maxHeight: 512 }}
                  />
                </div>
              )}

            <Styled.ReviewBox className="mt-4">
              <div className="d-flex gap-3 justify-content-start align-items-center">
                <div className="d-flex w-100" style={{ maxWidth: 50 }}>
                  <img src={clientBtn} alt="clientBtn" />
                </div>
                <TextStyles.FontSize20px
                  className="d-flex w-100"
                  style={{ minWidth: 50 }}
                >
                  <span className="three-dots">
                    {selectedData && selectedData.project_name}
                  </span>
                </TextStyles.FontSize20px>
              </div>

              <div className="d-flex gap-2 justify-content-start align-items-center mt-3">
                <TextStyles.FontSize16px>Starts:</TextStyles.FontSize16px>

                <TextStyles.GreyFont14px
                  className="d-flex"
                  style={{ minWidth: 50 }}
                >
                  <span className="three-dots">
                    {moment(
                      selectedData && selectedData.project_start_date
                    ).format("DD MMM YYYY")}{" "}
                  </span>
                </TextStyles.GreyFont14px>
              </div>
              {selectedData && selectedData.project_end_date ? (
                <div className="d-flex gap-2 justify-content-start align-items-center mt-2">
                  <TextStyles.FontSize16px>Ends:</TextStyles.FontSize16px>
                  <TextStyles.GreyFont14px
                    className="d-flex"
                    style={{ minWidth: 50 }}
                  >
                    <span className="three-dots">
                      {moment(selectedData.project_end_date).format(
                        "DD MMM YYYY"
                      )}
                    </span>
                  </TextStyles.GreyFont14px>
                </div>
              ) : (
                <div className="d-flex gap-2 justify-content-start align-items-center mt-2">
                  <TextStyles.FontSize16px>Ends:</TextStyles.FontSize16px>
                  <TextStyles.GreyFont14px>On going</TextStyles.GreyFont14px>
                </div>
              )}

              {selectedData &&
                selectedData.project_end_date &&
                selectedData.project_start_date && (
                  <div className="d-flex gap-2 justify-content-start align-items-center mt-2">
                    <TextStyles.FontSize16px>Duration:</TextStyles.FontSize16px>
                    <TextStyles.GreyFont14px
                      className="d-flex"
                      style={{ minWidth: 30 }}
                    >
                      <span className="three-dots">
                        {diffDate(
                          selectedData.project_end_date,
                          selectedData.project_start_date
                        )}
                      </span>
                    </TextStyles.GreyFont14px>
                  </div>
                )}
              <div
                className="my-3"
                style={{
                  width: "100%",
                  height: "1px",
                  border: "0.5px solid #edeef2",
                }}
              />
              <div className="row">
                <div className="col-12 col-sm-6">
                  <div className="d-flex justify-content-between align-items-center">
                    <TextStyles.GreyFont18px style={{ color: Colors.primary }}>
                      To
                    </TextStyles.GreyFont18px>
                  </div>
                  <TextStyles.FontSize18px className="d-flex justify-content-start mt-3">
                    {selectedData &&
                      selectedData.client_id &&
                      selectedData.client_id.company_name}
                  </TextStyles.FontSize18px>
                  <TextStyles.FontSize14px className="d-flex justify-content-start mt-2">
                    {primaryContact && primaryContact.contact_name}
                  </TextStyles.FontSize14px>
                  <TextStyles.GreyFont14px className="d-flex justify-content-start mt-2">
                    {primaryContact && primaryContact.email}
                  </TextStyles.GreyFont14px>
                  <TextStyles.GreyFont14px className="d-flex justify-content-start mt-2">
                    {selectedData &&
                      selectedData.client_id &&
                      selectedData.client_id.street}
                    {selectedData &&
                    selectedData.client_id &&
                    selectedData.client_id.suite
                      ? ", "
                      : ""}
                    {selectedData &&
                      selectedData.client_id &&
                      selectedData.client_id.suite}
                    {selectedData &&
                    selectedData.client_id &&
                    selectedData.client_id.city
                      ? ", "
                      : ""}
                    {selectedData &&
                      selectedData.client_id &&
                      selectedData.client_id.city}
                    {selectedData &&
                    selectedData.client_id &&
                    selectedData.client_id.state
                      ? ", "
                      : ""}
                    {selectedData &&
                      selectedData.client_id &&
                      selectedData.client_id.state}
                    {selectedData &&
                    selectedData.client_id &&
                    selectedData.client_id.country
                      ? ", "
                      : ""}
                    {selectedData &&
                      selectedData.client_id &&
                      selectedData.client_id.country}
                    {selectedData &&
                    selectedData.client_id &&
                    selectedData.client_id.zip_code
                      ? ", "
                      : ""}
                    {selectedData &&
                      selectedData.client_id &&
                      selectedData.client_id.zip_code}
                    {selectedData &&
                    selectedData.client_id &&
                    selectedData.client_id.tax_id_label
                      ? ", "
                      : ""}
                    {selectedData &&
                      selectedData.client_id &&
                      selectedData.client_id.tax_id_label}
                    {selectedData &&
                    selectedData.client_id &&
                    selectedData.client_id.tax_id_number
                      ? ", "
                      : ""}
                    {selectedData &&
                      selectedData.client_id &&
                      selectedData.client_id.tax_id_number}
                  </TextStyles.GreyFont14px>
                </div>
                <div
                  className="d-flex d-sm-none my-3"
                  style={{
                    width: "100%",
                    height: "1px",
                    border: "0.5px solid #edeef2",
                  }}
                />
                <div className="col-12 col-sm-6">
                  <div className="d-flex justify-content-between align-items-center">
                    <TextStyles.GreyFont18px style={{ color: Colors.primary }}>
                      From
                    </TextStyles.GreyFont18px>
                  </div>
                  <TextStyles.FontSize18px className="d-flex justify-content-start mt-3">
                    {businessInfoData &&
                    businessInfoData.business_name !== undefined
                      ? businessInfoData.business_name
                      : data && `${data.firstName} ${data.lastName}`}
                  </TextStyles.FontSize18px>
                  <TextStyles.GreyFont14px className="d-flex justify-content-start mt-2">
                    {data && data.email}
                  </TextStyles.GreyFont14px>
                  <TextStyles.GreyFont14px className="d-flex justify-content-start mt-2">
                    {businessInfoData && businessInfoData.street}
                    {businessInfoData && businessInfoData.suite ? ", " : ""}
                    {businessInfoData && businessInfoData.suite}
                    {businessInfoData && businessInfoData.city ? ", " : ""}
                    {businessInfoData && businessInfoData.city}
                    {businessInfoData && businessInfoData.state ? ", " : ""}
                    {businessInfoData && businessInfoData.state}
                    {businessInfoData && businessInfoData.country ? ", " : ""}
                    {businessInfoData && businessInfoData.country}
                    {businessInfoData && businessInfoData.zip_code ? ", " : ""}
                    {businessInfoData && businessInfoData.zip_code}
                    {businessInfoData && businessInfoData.tax_id_label
                      ? ", "
                      : ""}
                    {businessInfoData && businessInfoData.tax_id_label}
                    {businessInfoData && businessInfoData.tax_id_number
                      ? ", "
                      : ""}
                    {businessInfoData && businessInfoData.tax_id_number}
                  </TextStyles.GreyFont14px>
                </div>
              </div>
            </Styled.ReviewBox>

            {selectedData &&
              selectedData.add_section_text_one &&
              selectedData.add_section_text_one.map((data, index) => {
                return (
                  <Text
                    key={index}
                    data={data}
                    pc_id={selectedData._id}
                    position="one"
                  />
                );
              })}

            {selectedData &&
              selectedData.add_section_image_one &&
              selectedData.add_section_image_one.map((data, index) => {
                return (
                  <Image
                    key={index}
                    data={data}
                    position="one"
                    pc_id={selectedData._id}
                  />
                );
              })}

            {selectedData &&
              selectedData.add_section_table_one &&
              selectedData.add_section_table_one.map((data, index) => {
                return (
                  <Table
                    key={index}
                    data={data}
                    position="one"
                    pc_id={selectedData._id}
                  />
                );
              })}

            {selectedData &&
              selectedData.add_section_column_one &&
              selectedData.add_section_column_one.map((data, index) => {
                return (
                  <Column
                    key={index}
                    data={data}
                    position="one"
                    pc_id={selectedData._id}
                  />
                );
              })}

            {selectedData &&
              selectedData.add_section_file_one &&
              selectedData.add_section_file_one.map((data, index) => {
                return (
                  <File
                    key={index}
                    data={data}
                    position="one"
                    pc_id={selectedData._id}
                  />
                );
              })}

            <Services selectedData={selectedData} />
            <BillingSchedules selectedData={selectedData} />

            {selectedData &&
              selectedData.add_section_text_two &&
              selectedData.add_section_text_two.map((data, index) => {
                return (
                  <Text
                    key={index}
                    data={data}
                    position="two"
                    pc_id={selectedData._id}
                  />
                );
              })}

            {selectedData &&
              selectedData.add_section_image_two &&
              selectedData.add_section_image_two.map((data, index) => {
                return (
                  <Image
                    key={index}
                    data={data}
                    position="two"
                    pc_id={selectedData._id}
                  />
                );
              })}

            {selectedData &&
              selectedData.add_section_table_two &&
              selectedData.add_section_table_two.map((data, index) => {
                return (
                  <Table
                    key={index}
                    data={data}
                    position="two"
                    pc_id={selectedData._id}
                  />
                );
              })}

            {selectedData &&
              selectedData.add_section_column_two &&
              selectedData.add_section_column_two.map((data, index) => {
                return (
                  <Column
                    key={index}
                    data={data}
                    position="two"
                    pc_id={selectedData._id}
                  />
                );
              })}

            {selectedData &&
              selectedData.add_section_file_two &&
              selectedData.add_section_file_two.map((data, index) => {
                return (
                  <File
                    key={index}
                    data={data}
                    position="two"
                    pc_id={selectedData._id}
                  />
                );
              })}
            <Signature
              selectedData={selectedData}
              data={data}
              businessInfoData={businessInfoData}
              primaryContact={primaryContact}
            />
          </Styled.ReviewContainer>
        </div>
      </div>
    </div>
  );
};

export default Review;
