import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";
import Styled from "./SetupStyles";

import TextStyles from "../../../../Constants/TextStyles";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import BillingSchedule from "./BillingSchedule";
import Services from "./Services";

import LoaderSpin from "../../Commons/LoaderSpin";

import { createProject as createProjectService } from "../../../../store/service/projectService";
import { Modal } from "react-bootstrap";
import { AutoCompleteSelectClient } from "../../Commons/AutoComplete";
import SwitchButton from "../../Commons/SwitchButton";
import Colors from "../../../../Constants/Colors";
import SelectCurrency from "../../Commons/SelectCurrency";
import moment from "moment";
import CreateClinets from "../../clients/createClients/createClinets";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CustomDatePickerOne from "../../Commons/DatePickers/CustomDatePickerOne";

export default function createProject({
  show,
  handleClose,
  client_id,
  updateRefresh,
  setUpdateRefresh,
  section,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.project.isLoading);
  const clientOptionData = useSelector(
    (state) => state.clients.clientOptionData
  );
  const userCurrency = useSelector((state) => state.auth.userCurrency);
  const [selectEndDate, setSelectEndDate] = useState(false);
  const [showClient, setShowClient] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);

  const {
    register,
    reset,
    watch,
    formState: { errors },
    handleSubmit,
    control,
    setValue,
  } = useForm({
    defaultValues: {
      servicesList: [
        {
          service_name: "",
          service_rate: "",
          service_rate_type: "Flat fee",
          desc: "",
        },
      ],
      client: client_id && {
        _id: client_id._id,
        label: client_id.company_name,
      },

      project_start_date: new Date(),
      currency: userCurrency && userCurrency.currency,
    },
  });

  useEffect(() => {
    if (selectedClient) reset({ client: selectedClient });
  }, [selectedClient]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "servicesList",
  });

  const changedCurrency = watch("currency");
  const currencySymbol = changedCurrency
    ? changedCurrency.symbol
    : userCurrency && userCurrency.currency && userCurrency.currency.symbol;

  const [endDateErrorMessage, setEndDateErrorMessage] = useState(null);
  const startDate = watch("project_start_date");
  const endDate = watch("project_end_date");

  const validateEndDate = () => {
    const eDate = endDate && moment(endDate);
    const sDate = moment(startDate);
    if (eDate && eDate.isBefore(sDate, "day")) {
      setEndDateErrorMessage(
        `End date should be greater than ${moment(new Date(startDate))
          .subtract(1, "d")
          .format("DD MMM YYYY")}.`
      );
      return false;
    } else {
      setEndDateErrorMessage(null);
      return true;
    }
  };

  useEffect(() => {
    validateEndDate();
  }, [startDate, endDate]);

  useEffect(() => {
    setValue("project_end_date", null);
  }, [selectEndDate]);

  const formsubmit = (data) => {
    const Obj = {
      project_name: data.project_name,
      client_id: data.client && data.client._id,
      project_start_date: new Date(data.project_start_date),
      project_end_date:
        selectEndDate || data.project_end_date === undefined
          ? null
          : data.project_end_date && new Date(data.project_end_date),
      currency: data.currency
        ? data.currency
        : userCurrency && userCurrency.currency,
      service_list: data.servicesList,
      billing_scheduled: data.billingScheduled,
    };
    dispatch(createProjectService(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        if (setUpdateRefresh) {
          setUpdateRefresh(!updateRefresh);
        }
        if (section === "navbar") {
          if (history.location.pathname !== "/db/projects") {
            history.push("/db/projects");
          }
        }
        handleClose();
        reset();
        message.success(`${data.payload.message}`);
      } else {
        message.error(`${data.error.message}`);
      }
    });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      fullscreen
      dialogClassName="ToMakeFullscreenModal"
    >
      <div className="modal-header d-flex justify-content-center">
        <TextStyles.FontSize14px
          style={{
            position: "absolute",
            left: "1rem",
            top: "1.25rem",
            cursor: "pointer",
          }}
          onClick={handleClose}
        >
          <ClearIcon
            sx={{
              fontSize: 30,
              color: Colors.font2,
              opacity: 0.7,
              "&:hover": {
                opacity: 1,
              },
            }}
          />
        </TextStyles.FontSize14px>
        <TextStyles.FontSize20px
          className="d-flex justify-content-center"
          style={{ fontWeight: 500, fontSize: "1.5rem" }}
        >
          Create project
        </TextStyles.FontSize20px>
      </div>
      <div className="modal-body d-flex justify-content-center">
        <div style={{ maxWidth: 600, width: "100%" }}>
          <form onSubmit={handleSubmit(formsubmit)}>
            <div className="mt-2 mb-3">
              <Styled.SetupBox className="mb-4">
                <TextStyles.FontSize14px className="mb-3">
                  <label htmlFor="project_name" className="mb-2">
                    Project name
                  </label>
                  <TextStyles.InputRectangle
                    id="project_name"
                    name="project_name"
                    invalid={errors.project_name}
                    type="text"
                    placeholder="Project name"
                    {...register("project_name", {
                      required: "Project name is required.",
                      validate: (value) => {
                        let trimedLength = value.trim().length;
                        if (trimedLength === 0) {
                          return "Project name is required.";
                        }
                        if (trimedLength < 3) {
                          return "Project name must be at least 3 characters";
                        }
                        if (trimedLength > 40) {
                          return "Project name must be at most 40 characters";
                        }
                        return true;
                      },
                    })}
                  />
                  {errors.project_name && (
                    <TextStyles.InValidFeedback>
                      {errors.project_name.message}
                    </TextStyles.InValidFeedback>
                  )}
                </TextStyles.FontSize14px>
                <TextStyles.FontSize14px className="mb-3">
                  <div className="d-flex justify-content-between mb-2">
                    <label htmlFor="client">Select client</label>
                    <div
                      onClick={() => setShowClient(true)}
                      style={{
                        color: Colors.primary,
                        fontWeight: 500,
                        cursor: "pointer",
                      }}
                    >
                      <AddCircleOutlineIcon
                        sx={{ color: Colors.primary, fontSize: 16 }}
                      />
                      New client
                    </div>
                  </div>

                  <Controller
                    control={control}
                    name="client"
                    rules={{
                      required: {
                        value: true,
                        message: "Client is required.",
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <AutoCompleteSelectClient
                        placeholder="Choose a client"
                        options={
                          clientOptionData &&
                          clientOptionData.map((data) => {
                            return { label: data.company_name, _id: data._id };
                          })
                        }
                        defaultValue={value}
                        onChange={onChange}
                        invalid={errors.client}
                      />
                    )}
                  />
                  {errors.client && (
                    <TextStyles.InValidFeedback style={{ marginTop: 6 }}>
                      {errors.client.message}
                    </TextStyles.InValidFeedback>
                  )}
                </TextStyles.FontSize14px>
                <TextStyles.FontSize14px className="mb-3">
                  <label htmlFor="client" className="mb-2">
                    Select currency
                  </label>
                  <Controller
                    control={control}
                    name="currency"
                    render={({ field: { onChange, value } }) => (
                      <SelectCurrency
                        placeholder="Select currency"
                        onChange={onChange}
                        defaultValue={value}
                      />
                    )}
                  />
                </TextStyles.FontSize14px>
                <TextStyles.FontSize14px className="mb-3">
                  <div htmlFor="project_start_date" className="mb-2">
                    Start date
                  </div>
                  <Controller
                    name={"project_start_date"}
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <CustomDatePickerOne
                          onChange={onChange}
                          selected={value}
                          placeholder="Select start date"
                          allowClear={false}
                        />
                      );
                    }}
                  />
                </TextStyles.FontSize14px>

                <TextStyles.FontSize14px className="mb-3">
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <div htmlFor="project_end_date">End date</div>
                    <div className="d-flex align-items-center">
                      <TextStyles.FontSize14px
                        style={{ color: Colors.primary, fontWeight: 500 }}
                      >
                        Ongoing
                      </TextStyles.FontSize14px>
                      <SwitchButton
                        defaultChecked={selectEndDate}
                        onChange={(event) =>
                          setSelectEndDate(event.target.checked)
                        }
                      />
                    </div>
                  </div>
                  {selectEndDate ? (
                    ""
                  ) : (
                    <Controller
                      name={"project_end_date"}
                      control={control}
                      rules={{ validate: validateEndDate }}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <CustomDatePickerOne
                            onChange={onChange}
                            selected={value}
                            placeholder="Select end date"
                            allowClear={true}
                          />
                        );
                      }}
                    />
                  )}
                  {endDateErrorMessage && (
                    <TextStyles.InValidFeedback className="mt-2">
                      {endDateErrorMessage}
                    </TextStyles.InValidFeedback>
                  )}
                </TextStyles.FontSize14px>
              </Styled.SetupBox>
            </div>

            <Services
              control={control}
              Controller={Controller}
              watch={watch}
              register={register}
              fields={fields}
              remove={remove}
              append={append}
              currencySymbol={currencySymbol}
            />
            <BillingSchedule
              currencySymbol={currencySymbol}
              register={register}
              watch={watch}
              setValue={setValue}
              Controller={Controller}
              control={control}
            />

            <div className="pb-3">
              <TextStyles.Button disabled={isLoading ? true : false}>
                {isLoading ? <LoaderSpin /> : "Create project"}
              </TextStyles.Button>
            </div>
          </form>
        </div>
      </div>
      {showClient && (
        <CreateClinets
          show={showClient}
          handleClose={() => setShowClient(false)}
          setUpdateRefresh={setUpdateRefresh}
          setSelectedClient={setSelectedClient}
        />
      )}
    </Modal>
  );
}
