import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import ClearIcon from "@mui/icons-material/Clear";
import TextStyles from "../../../../Constants/TextStyles";
import Styled from "./Taxes";
import Colors from "../../../../Constants/Colors";
import { invoiceActions } from "../../../../store/storage/invoiceSlice";

const DiscountInvoices = ({
  show,
  handleClose,
  selectedData,
  totalServiceAmount,
}) => {
  const dispatch = useDispatch();
  const [percentageValue, setPercentageValue] = useState(
    selectedData && selectedData.discount && selectedData.discount.percentage
      ? true
      : false
  );
  const [discountValue, setDiscountValue] = useState(
    selectedData && selectedData.discount && selectedData.discount.value
      ? true
      : false
  );

  const [input1, setInput1] = useState(
    selectedData && selectedData.discount && selectedData.discount.percentage
      ? parseFloat(selectedData.discount.percentage).toFixed(2)
      : selectedData && selectedData.discount && selectedData.discount.value
      ? parseFloat(
          (parseFloat(selectedData.discount.value) * 100) /
            parseFloat(totalServiceAmount)
        ).toFixed(2)
      : ""
  );
  const [input2, setInput2] = useState(
    selectedData && selectedData.discount && selectedData.discount.value
      ? parseFloat(selectedData.discount.value).toFixed(2)
      : selectedData &&
        selectedData.discount &&
        selectedData.discount.percentage
      ? parseFloat(
          totalServiceAmount *
            parseFloat(selectedData.discount.percentage) *
            0.01
        ).toFixed(2)
      : ""
  );

  const handleInput1Change = (event) => {
    const value = event.target.value;
    setPercentageValue(true);
    setDiscountValue(false);
    setInput1(value);
    setInput2(((totalServiceAmount * value) / 100).toFixed(2));
  };

  const handleInput2Change = (event) => {
    const value = event.target.value;
    setDiscountValue(true);
    setPercentageValue(false);
    setInput2(value);
    setInput1(((100 * value) / totalServiceAmount).toFixed(2));
  };

  const formsubmit = (event) => {
    event.preventDefault();
    const Obj = {
      discount: {
        percentage: percentageValue ? input1 : null,
        value: discountValue ? input2 : null,
      },
    };
    dispatch(invoiceActions.selectedInvoiceAction(Obj));
    handleClose();
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="ToMakeModalOnRight500px"
        fullscreen
      >
        <form
          onSubmit={formsubmit}
          className="modal-content"
          style={{ height: "100%" }}
        >
          <Modal.Header className="d-flex align-items-center justify-content-between">
            <TextStyles.FontSize20px>Discount</TextStyles.FontSize20px>
            <ClearIcon
              sx={{
                color: Colors.font2,
                cursor: "pointer",
                opacity: 0.7,
                "&:hover": {
                  opacity: 1,
                },
              }}
              onClick={handleClose}
            />
          </Modal.Header>
          <Modal.Body>
            <div className="mb-3">
              <div className="d-flex flex-wrap align-items-center">
                <TextStyles.FontSize14px className="me-3">
                  <label htmlFor="value" className="mb-2">
                    Percentage
                  </label>
                  <Styled.inputBox style={{ maxWidth: 110 }} className="me-3">
                    <input
                      style={{
                        width: 55,
                        textAlign: "right",
                        background: "transparent",
                      }}
                      className="percentage"
                      type="number"
                      step="0.01"
                      id="percentage"
                      name="percentage"
                      value={input1}
                      placeholder="0.00"
                      min="1"
                      max="99"
                      onChange={handleInput1Change}
                    />
                    <span>%</span>
                  </Styled.inputBox>
                </TextStyles.FontSize14px>
                <div className="me-3" style={{ fontSize: 27, marginTop: 20 }}>
                  =
                </div>
                <TextStyles.FontSize14px>
                  <label htmlFor="value" className="mb-2">
                    Value
                  </label>
                  <Styled.inputBox style={{ maxWidth: 110, width: 100 }}>
                    <span>
                      {selectedData &&
                        selectedData.currency &&
                        selectedData.currency.symbol}
                    </span>
                    <input
                      style={{
                        width: 55,
                        background: "transparent",
                      }}
                      type="number"
                      step="0.01"
                      id="value"
                      name="value"
                      value={input2}
                      placeholder="0.00"
                      onChange={handleInput2Change}
                    />
                  </Styled.inputBox>
                </TextStyles.FontSize14px>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer style={{ display: "inline-block" }}>
            <div className="d-flex gap-3 justify-content-between">
              <TextStyles.CancelButton onClick={handleClose}>
                Cancel
              </TextStyles.CancelButton>
              <TextStyles.Button disabled={!input1 || !input2}>
                Save
              </TextStyles.Button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default DiscountInvoices;
