import React from "react";
import LandingPage from "./LandingPage/LandingPage";
import Features from "./Features/Features";

import Footer from "../../Footer/Footer";
import SignupForm from "../../UIs/SignupForm";
import { Helmet } from "react-helmet";
const Transactions = () => {
  return (
    <div>
      <Helmet>
        <title>
          Income and Expense Tracking Software For Your Business - Zodot
        </title>
        <meta
          content="Boost business efficiency with Zodot’s user-friendly transaction tracking. Enjoy easy setup, insightful analytics, and reliable results."
          name="description"
        />
        <link rel="canonical" href="https://zodot.co/features/transactions" />
      </Helmet>
      <LandingPage />
      <Features />
      <SignupForm />
      <Footer />
    </div>
  );
};

export default Transactions;
