import React from "react";
import SelectCurrency from "../../../Commons/SelectCurrency";
import TextStyles from "../../../../../Constants/TextStyles";
import { setUserCurrency } from "../../../../../store/service/AuthService";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";

const Currency = () => {
  const dispatch = useDispatch();
  const userCurrency = useSelector((state) => state.auth.userCurrency);

  const currencyHandleChange = (value) => {
    const Obj = {
      currency: value,
    };
    const userCurrencyCode =
      userCurrency && userCurrency.currency && userCurrency.currency.code;
    const changedUserCurrencyCode = value && value.code;

    if (userCurrencyCode !== changedUserCurrencyCode) {
      dispatch(setUserCurrency(Obj)).then((data) => {
        if (data.meta.requestStatus === "fulfilled") {
          message.success(`${data.payload.message}`);
        } else {
          message.error(`${data.error.message}!`);
        }
      });
    }
  };

  return (
    <div>
      <TextStyles.FontSize20px className="my-3">
        Select currency
      </TextStyles.FontSize20px>
      <div style={{ maxWidth: 400 }}>
        <SelectCurrency
          placeholder="Select currency"
          onChange={(value) => {
            if (value) {
              currencyHandleChange(value);
            }
          }}
          defaultValue={userCurrency && userCurrency.currency}
        />
      </div>
    </div>
  );
};

export default Currency;
