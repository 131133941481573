import React from "react";
import ClearIcon from "@mui/icons-material/Clear";

import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";

import LoaderSpin from "../../Commons/LoaderSpin";
import TextStyles from "../../../../Constants/TextStyles";
import {
  createTemplate,
  fetchTemplates,
} from "../../../../store/service/AuthService";
import Colors from "../../../../Constants/Colors";

const AddTemplateModal = ({ show, handleClose }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.auth.isLoading);

  const {
    register,
    formState: { errors, isDirty, isValid },
    handleSubmit,
  } = useForm();

  const formsubmit = (data) => {
    const Obj = {
      template_name: data.template_name,
      template_subject: data.template_subject,
      template_message: data.template_message,
    };
    dispatch(createTemplate(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        dispatch(fetchTemplates());
        handleClose();
        message.success(`${data.payload.message}`);
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      contentClassName="ToMakeModalBorderRudius12px"
      scrollable={true}
      centered
    >
      <form
        onSubmit={handleSubmit(formsubmit)}
        className="modal-content"
        style={{ height: "100%", display: "flex", flexDirection: "column" }}
      >
        <Modal.Header className="d-flex align-items-center justify-content-between">
          <TextStyles.FontSize20px className="three-dots">
            Email message template
          </TextStyles.FontSize20px>
          <ClearIcon
            sx={{
              color: Colors.font2,
              cursor: "pointer",
              opacity: 0.7,
              "&:hover": {
                opacity: 1,
              },
            }}
            onClick={handleClose}
          />
        </Modal.Header>
        <Modal.Body>
          <TextStyles.FontSize14px className="mb-3">
            <label htmlFor="template_name" className="mb-2">
              Template name
            </label>
            <TextStyles.InputRectangle
              invalid={errors.template_name}
              id="template_name"
              type="text"
              placeholder="Write template name"
              name="template_name"
              {...register("template_name", {
                required: "Name is required.",
                validate: (value) => {
                  let trimedLength = value.trim().length;
                  if (trimedLength === 0) {
                    return "Name is required.";
                  }
                  return true;
                },
              })}
            />
            {errors.template_name && (
              <TextStyles.InValidFeedback>
                {errors.template_name.message}
              </TextStyles.InValidFeedback>
            )}
          </TextStyles.FontSize14px>

          <TextStyles.FontSize14px className="mb-3">
            <label htmlFor="template_subject" className="mb-2">
              Subject
            </label>
            <TextStyles.InputRectangle
              invalid={errors.template_subject}
              id="template_subject"
              type="text"
              placeholder="Write subject"
              name="template_subject"
              {...register("template_subject", {
                required: "Subject is required.",
                validate: (value) => {
                  let trimedLength = value.trim().length;
                  if (trimedLength === 0) {
                    return "Subject is required.";
                  }
                  return true;
                },
              })}
            />
            {errors.template_subject && (
              <TextStyles.InValidFeedback>
                {errors.template_subject.message}
              </TextStyles.InValidFeedback>
            )}
          </TextStyles.FontSize14px>

          <TextStyles.FontSize14px className="mb-3">
            <label htmlFor="template_message" className="mb-2">
              Message
            </label>
            <TextStyles.Textarea
              rows={8}
              invalid={errors.template_message}
              id="template_message"
              type="text"
              placeholder="Write message"
              name="template_message"
              {...register("template_message", {
                required: "Message is required.",
                validate: (value) => {
                  let trimedLength = value.trim().length;
                  if (trimedLength === 0) {
                    return "Message is required.";
                  }
                  return true;
                },
              })}
            />

            {errors.template_message && (
              <TextStyles.InValidFeedback>
                {errors.template_message.message}
              </TextStyles.InValidFeedback>
            )}
          </TextStyles.FontSize14px>
        </Modal.Body>
        <Modal.Footer style={{ display: "inline-block" }}>
          <div className="d-flex gap-3 justify-content-between">
            <TextStyles.CancelButton onClick={handleClose}>
              Cancel
            </TextStyles.CancelButton>
            <TextStyles.Button
              disabled={!isDirty || !isValid || isLoading ? true : false}
            >
              {isLoading ? <LoaderSpin /> : "Save"}
            </TextStyles.Button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AddTemplateModal;
