import styled from "styled-components";
import Colors from "../../../../Constants/Colors";

const TableHead = styled.div`
  width: 100%;
  box-shadow: 0 8px 12px 0 rgba(238, 241, 245, 0.89);
  background-color: ${Colors.tableHeadColor};
  padding: 1rem;
  border-radius: 0.75rem 0.75rem 0 0;

  .visible-start-time,
  .visible-activity,
  .visible-project,
  .visible-company {
    visibility: hidden;
  }

  .hover-start-time:hover .visible-start-time,
  .hover-activity:hover .visible-activity,
  .hover-project:hover .visible-project,
  .hover-company:hover .visible-company {
    visibility: visible;
  }
`;

const TableCell = styled.div`
  width: 100%;
  box-shadow: 0 8px 12px 0 rgba(238, 241, 245, 0.89);
  background-color: ${Colors.white};
  padding: 1rem;
  :hover {
    background-color: ${Colors.hoverTableRow};
  }
  .dropdown-menu {
    border: 2px solid #f9f9f9;
    padding: 6px 6px;
    border-radius: 6px;
    box-shadow: 1px 2px 3px 1px rgba(0, 0, 0, 0.2);
  }

  .dropdown-item {
    padding: 7px 12px;
    border-radius: 4px;
  }
  .dropdown-item:hover {
    background: ${Colors.backgroundPink};
  }
`;

const GraphRectangle = styled.div`
  width: 100%;
  height: auto;
  padding: 1.5rem 1rem;
  border-radius: 0.75rem;
  border-bottom: 1px solid #e9eaf0;
  box-shadow: 0 8px 12px 0 rgba(238, 241, 245, 0.89);
  background-color: ${Colors.white};
`;

const Sorting = styled.div`
  display: flex;
  min-width: 13.75rem;
  justify-content: space-between;
  align-items: center;
  padding: 3px 8px;
  border-radius: 6px;

  label {
    display: flex;
    width: 100%;
    cursor: pointer;
  }
  .form-check-input {
    width: 1rem;
    height: 1rem;
    box-shadow: none !important;
    border: 2px solid ${Colors.font2};
    cursor: pointer;
  }
  .form-check-input:checked {
    background-color: ${Colors.primary};
    border-color: ${Colors.primary};
  }

  &:hover {
    background: ${Colors.backgroundPink};
  }
  &:hover svg {
    color: ${Colors.primary};
  }
`;

const ListBox = styled.div`
  width: 100%;
  height: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.white};
  border-radius: 0 0 0.75rem 0.75rem;
  padding: 1rem;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
`;

export default {
  TableHead,
  TableCell,
  GraphRectangle,
  Sorting,
  ListBox,
};
