import styled from "styled-components";
import Colors from "../../../../../Constants/Colors";

const SetupBox = styled.div`
  width: 100%;
  height: auto;
  padding: 1.875rem;
  border-radius: 0.75rem;
  border: solid 1px #b2b6c5;
  background-color: ${Colors.white};

  .deleteBtn {
    display: none;
  }

  & .services_container:hover .deleteBtn {
    display: flex;
    cursor: pointer;
  }

  @media (max-width: 576px) {
    padding: 1.875rem 0.875rem;
  }
`;

const NextButton = styled.button`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 100;
  min-width: fit-content;
  min-width: 6rem;
  padding: 0 1.5rem;
  height: 3.15rem;
  font-family: Poppins, serif;
  font-size: 0.875rem;
  color: ${(props) => props.color || Colors.white};
  border-radius: 0.75rem;
  background-color: ${(props) => props.backgroundColor || Colors.primary};
  transition: background-color 0.4s ease;
  &:hover {
    background-color: ${(props) =>
      props.backgroundColor
        ? `${props.backgroundColor}`
        : `${Colors.hoverButtonColor}`};
  }

  &:active {
    background-color: ${(props) =>
      props.backgroundColor ? props.backgroundColor : `${Colors.primary}`};
  }

  &:disabled {
    opacity: 0.5 !important;
  }
`;

export default {
  SetupBox,
  NextButton,
};
