import React, { useState } from "react";
import { useSelector } from "react-redux";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";

import TextStyles from "../../../../../Constants/TextStyles";
import Styled from "./PageOne";

import PCEditProjectInfo from "../../ProposalReviews/PCEditProjectInfo";
import Colors from "../../../../../Constants/Colors";

const Project = () => {
  const selectedData = useSelector(
    (state) => state.proposalAndContract.selectedData
  );
  const [showProjectModal, setShowProjectModal] = useState(false);

  return (
    <Styled.ReviewContentBox className="mt-4">
      <TextStyles.FontSize16px style={{ fontWeight: 500 }}>
        {selectedData && selectedData.project_name}
      </TextStyles.FontSize16px>
      <Styled.EditButtonToolTip
        className="contract_review_edit_tp"
        onClick={() => setShowProjectModal(true)}
      >
        <BorderColorOutlinedIcon sx={{ color: Colors.white, fontSize: 20 }} />
        <span className="ms-1">Edit</span>
      </Styled.EditButtonToolTip>
      {showProjectModal && (
        <PCEditProjectInfo
          selectedData={selectedData}
          show={showProjectModal}
          handleClose={() => setShowProjectModal(false)}
        />
      )}
    </Styled.ReviewContentBox>
  );
};

export default Project;
