import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";

import ClearIcon from "@mui/icons-material/Clear";

import signatureImg from "../../../../../Assets/assetsnew/signature-small.svg";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";

import LoaderSpin from "../../../Commons/LoaderSpin";

import TextStyles from "../../../../../Constants/TextStyles";
import Styled from "./SignatureStyles";
import { updateSignaturePC } from "../../../../../store/service/proposalAndContractService";
import { getFileFromUrl } from "../../../Commons/utils";
import Colors from "../../../../../Constants/Colors";

const SignProposal = ({ show, handleClose, selectedData }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.proposalAndContract.isLoading);
  const [signature, setSignature] = useState(
    selectedData.signature && selectedData.signature.text
      ? selectedData.signature.text
      : ""
  );
  const [files, setFiles] = useState([]);

  const [fileErrors, setfileErrors] = useState([]);
  const MAX_SIZE = 2000000;

  useEffect(() => {
    if (
      selectedData.signature &&
      selectedData.signature.image &&
      selectedData.signature.image.image_name &&
      selectedData.signature.image.image_url
    ) {
      let filename = selectedData.signature.image.image_name;
      let indexofUnderscore = filename.indexOf("_");
      let file_name = filename.substring(indexofUnderscore + 1);
      getFileFromUrl(selectedData.signature.image.image_url, file_name).then(
        (file) => {
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          });
          setFiles([file]);
        }
      );
    }
  }, []);

  const deleteSignature = () => {
    setSignature("");
    setFiles([]);
  };

  const addSignature = (e) => {
    let sign = e.target.value;
    setFiles([]);
    setSignature(sign);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    maxSize: MAX_SIZE,
    multiple: false,
    onDrop: (acceptedFiles, rejectedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      setSignature("");
      setfileErrors(rejectedFiles.length > 0 ? rejectedFiles[0].errors : []);
      // showText = files.length === 0;
    },
    onDragEnter: () => {
      setFiles([]);
      // setfileErrors([]);
    },
  });

  const imageErrors = {
    FILESIZE: "More than 2MB in size",
    FILETYPE: "Not an image file",
  };

  const getErrorMessage = () => {
    switch (fileErrors[0].code) {
      case "file-invalid-type":
        return (
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 10,
              color: "red",
            }}
          >
            {imageErrors.FILETYPE}
          </p>
        );
      case "file-too-large":
        return (
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 10,
              color: "red",
            }}
          >
            {imageErrors.FILESIZE}
          </p>
        );
      default:
        return (
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 10,
              color: "red",
            }}
          >
            File error
          </p>
        );
    }
  };

  const submitHandler = () => {
    let formData = new FormData();
    formData.set("pc_id", selectedData._id);
    formData.set("text", signature);
    formData.append("image", files[0]);
    formData.append(
      "image_name",
      selectedData.signature &&
        selectedData.signature.image &&
        selectedData.signature.image.image_name
    );
    dispatch(updateSignaturePC(formData)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        // setSignature("");
        handleClose();
        // setFiles([]);
        message.success(`${data.payload.message}`);
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      contentClassName="ToMakeModalBorderRudius12px"
    >
      <Modal.Header
        className="d-flex justify-content-between align-items-center"
        style={{
          border: "none",
        }}
      >
        <TextStyles.FontSize20px>Sign proposal</TextStyles.FontSize20px>
        <ClearIcon
          sx={{
            color: Colors.font2,
            cursor: "pointer",
            opacity: 0.7,
            "&:hover": {
              opacity: 1,
            },
          }}
          onClick={handleClose}
        />
      </Modal.Header>

      <Modal.Body>
        <Styled.SignatureImageBox>
          <TextStyles.FontSize14px>Your signature</TextStyles.FontSize14px>
          {(signature || files.length > 0) && (
            <div className="d-flex justify-content-end me-3">
              <i
                className="bi bi-x-circle-fill"
                style={{ color: "grey", cursor: "pointer" }}
                onClick={deleteSignature}
              />
            </div>
          )}

          {files.length > 0 && (
            <div className="d-flex justify-content-center">
              <img
                alt="Preview"
                key={files[0].preview}
                src={files[0].preview}
                style={{
                  maxWidth: "100%",
                  height: "120px",
                }}
              />
            </div>
          )}
          {files.length === 0 && fileErrors.length > 0 && getErrorMessage()}
          {files.length === 0 && fileErrors.length === 0 && (
            <div className="d-flex align-items-center">
              <img
                src={signatureImg}
                alt="signatureBtn"
                style={{
                  width: 50,
                  height: 54,
                }}
              />
              <div
                style={{
                  fontFamily: "licorice",
                  fontWeight: 400,
                  fontSize: 36,
                  marginLeft: 20,
                }}
              >
                {signature}
              </div>
            </div>
          )}
        </Styled.SignatureImageBox>
        <TextStyles.FontSize14px className="my-3">
          Type your name to sign
        </TextStyles.FontSize14px>

        <div className="d-flex flex-wrap gap-3 justify-content-between align-items-center mb-3">
          <TextStyles.InputRectangle
            style={{ maxWidth: 180 }}
            id="signature"
            placeholder="Type signature"
            name="signature"
            value={signature}
            onChange={(e) => addSignature(e)}
          />
          <div
            style={{ cursor: "pointer", display: "flex" }}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <FileUploadOutlinedIcon
              sx={{ color: Colors.font2, fontSize: 27 }}
            />
            <TextStyles.GreyFont14px>
              or Upload your signature
            </TextStyles.GreyFont14px>
          </div>
        </div>

        <TextStyles.Button
          onClick={submitHandler}
          disabled={
            (signature.trim().length < 1 && files.length < 1) ||
            fileErrors.length > 0 ||
            isLoading
              ? true
              : false
          }
        >
          {(signature.trim().length > 0 || files.length > 0) && isLoading ? (
            <LoaderSpin />
          ) : (
            " Confirm signature"
          )}
        </TextStyles.Button>
      </Modal.Body>
    </Modal>
  );
};

export default SignProposal;
