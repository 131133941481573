import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Colors from "../../../Constants/Colors";

const DaysInputRectangle = styled.input`
  width: 100%;
  text-align: center;
  max-width: 4.5rem;
  padding: 2px 3px;
  border-radius: 6px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border: 1px solid
    ${(props) => (props.invalid ? Colors.red : Colors.borderInput)};
  background-color: ${(props) =>
    props.invalid ? Colors.inputErrorBg : Colors.white};

  :focus {
    border: 1px solid ${Colors.primary};
  }

  &:hover {
    border: 1px solid ${Colors.primary};
  }
`;

const convertToHHMMSS = (totalSeconds) => {
  if (isNaN(totalSeconds) || totalSeconds < 0) {
    return ""; // Return an empty string for invalid or negative input
  }
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  return [
    hours.toString().padStart(2, "0"),
    minutes.toString().padStart(2, "0"),
    seconds.toString().padStart(2, "0"),
  ].join(":");
};

const convertToSeconds = (time) => {
  if (!time) return 0; // Return 0 if time is empty or undefined
  const [hours, minutes, seconds] = time.split(":").map(Number);
  return (hours || 0) * 3600 + (minutes || 0) * 60 + (seconds || 0);
};

const TimeFormatInput = ({
  value = 0, // Default value in seconds
  onChange = () => {},
  onBlur = () => {},
  onClick = () => {},
  disabled = false,
  showModal = false,
  placeholder = "HH:MM:SS",
  selectOnClick = true,
  readOnly = false,
  maxLength = 8,
}) => {
  const [time, setTime] = useState(convertToHHMMSS(value));

  useEffect(() => {
    setTime(convertToHHMMSS(value));
  }, [value]);

  const handleInputChange = (e) => {
    setTime(e.target.value);
  };

  const handleBlur = () => {
    if (!showModal) {
      const inputValue = time.trim();
      let valueInSeconds = 0;

      if (inputValue.includes(":")) {
        // Convert HH:MM:SS to seconds
        valueInSeconds = convertToSeconds(inputValue);
      } else {
        // Treat input as minutes and convert to seconds
        const minutes = parseInt(inputValue, 10);
        valueInSeconds = minutes * 60;
      }
      // Enforce maximum of 24 hours
      if (valueInSeconds > 86400) {
        valueInSeconds = 24 * 3600; // Set to 24:00:00 if exceeded
      }

      const formattedValue = convertToHHMMSS(valueInSeconds);
      setTime(formattedValue);
      onBlur(valueInSeconds);
      onChange(valueInSeconds);
    }
  };

  const handleClick = (e) => {
    onClick(e);
    if (selectOnClick) {
      e.target.select();
    }
  };

  return (
    <DaysInputRectangle
      type="text"
      value={time}
      onChange={handleInputChange}
      onBlur={handleBlur}
      onClick={handleClick}
      placeholder={placeholder}
      maxLength={maxLength}
      disabled={disabled}
      readOnly={readOnly}
    />
  );
};

export default TimeFormatInput;
