import React from "react";
import LandingPage from "./LandingPage/LandingPage";
import Features from "./Features/Features";

import Footer from "../../Footer/Footer";
import SignupForm from "../../UIs/SignupForm";
import { Helmet } from "react-helmet";

const Statements = () => {
  return (
    <div>
      <Helmet>
        <title>Free Income & Expenses Statements with Zodot Reporting</title>
        <meta
          content="Zodot simplifies income and expense reporting with easy, efficient, and accurate tracking. Streamline your financial management today!"
          name="description"
        />
        <link rel="canonical" href="https://zodot.co/features/statements" />
      </Helmet>
      <LandingPage />
      <Features />
      <SignupForm />
      <Footer />
    </div>
  );
};

export default Statements;
