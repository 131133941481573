import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { postRequest } from "../../../../axios/axios";

import TextStyles from "../../../../Constants/TextStyles";
import Styles from "./PublicViewInvoice";
import { Document, Page } from "react-pdf";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { getFileFromUrl } from "../../Commons/utils";
import Colors from "../../../../Constants/Colors";
import { message } from "antd";

const PublicViewInvoice = () => {
  const invoiceData = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [numPages, setNumPages] = useState(0);
  const [bgColor, setBgColor] = useState(Colors.primary);

  useEffect(() => {
    viewInvoiceCall();
  }, [invoiceData]);

  const viewInvoiceCall = async () => {
    setIsLoading(true);
    postRequest(
      "invoices/public_view_invoice",
      {},
      {
        _id: invoiceData.id,
        token: invoiceData.token,
      }
    )
      .then((data) => {
        if (data.data.success === true) {
          setIsLoading(false);
          setFile(data.data.data);
          setBgColor(data.data.data.brand_color);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        if (err && err.response && err.response.data) {
          return message.error(`${err.response.data.message}`);
        }
        if (err.isAxiosError) {
          return message.error(`${err.message}`);
        }
      });
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const pages = [];
  for (let pageNumber = 1; pageNumber <= numPages; pageNumber++) {
    pages.push(<Page key={pageNumber} pageNumber={pageNumber} />);
  }

  const downloadPdfHandler = () => {
    let filename = file.invoice_no;
    getFileFromUrl(file.file, filename).then((res) => {
      const href = URL.createObjectURL(res);
      const aElement = document.createElement("a");
      aElement.href = href;
      aElement.setAttribute("download", `Invoice${filename}`);
      aElement.setAttribute("target", "_blank");
      aElement.click();
      URL.revokeObjectURL(href);
    });
  };

  const LoadingComponent = () => {
    return (
      <div>
        <TextStyles.FontSize14px className="d-flex justify-content-center align-items-center">
          <Styles.LoadingPdf color={bgColor}>
            <div className="dot-flashing" />
          </Styles.LoadingPdf>
        </TextStyles.FontSize14px>
      </div>
    );
  };

  return (
    <div>
      <div
        className="fixed-top"
        style={{
          background: Colors.white,
          borderBottom: "2px solid #dfe1e9",
          zIndex: 200,
        }}
      >
        <TextStyles.FontSize27px
          style={{
            fontWeight: 500,
            textAlign: "center",
            padding: "10px 10px 10px 10px",
          }}
        >
          View invoice
        </TextStyles.FontSize27px>
      </div>
      <div
        className="d-flex justify-content-center w-100 h-100 pb-5 px-lg-4 px-md-4 px-sm-3 px-2"
        style={{ paddingTop: 100, contain: "content" }}
      >
        {isLoading ? (
          <TextStyles.FontSize14px className="d-flex justify-content-center">
            Please wait...
          </TextStyles.FontSize14px>
        ) : (
          <Styles.ViewInvoicePDFBox
            style={{ position: "relative" }}
            bgColor={bgColor}
          >
            <div
              style={{
                position: "absolute",
                top: 12,
                right: 15,
                zIndex: 100,
              }}
            >
              <Styles.Oval onClick={downloadPdfHandler} bgColor={bgColor}>
                <SaveAltIcon sx={{ color: Colors.white, fontSize: 27 }} />
              </Styles.Oval>
            </div>
            {file && (
              <Document
                loading={LoadingComponent}
                file={file.file}
                onLoadSuccess={onDocumentLoadSuccess}
                className="view_invoice_pdf"
              >
                {pages}
              </Document>
            )}
          </Styles.ViewInvoicePDFBox>
        )}
      </div>
    </div>
  );
};

export default PublicViewInvoice;
