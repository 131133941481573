import styled from "styled-components";
import Colors from "../../../../Constants/Colors";

const TermBox = styled.div`
  width: 100%;
  height: auto;
  padding: 1.875rem 1.5rem;
  border-radius: 0.75rem;
  box-shadow: 0 8px 12px 0 rgba(238, 241, 245, 0.89);
  background-color: ${Colors.white};
  @media (max-width: 576px) {
    padding: 1.25rem 0.75rem;
  }
`;

const TermEditBox = styled.div`
  display: flex;
  width: 4.875rem;
  height: 2rem;
  padding: 6px 12px;
  border-radius: 8px;
  background-color: ${Colors.backgroundPink};
  cursor: pointer;
`;

const TermDateBox = styled.div`
  width: 13.75rem;
  dispay: flex;
  justify-content: center;
  align-items: center;
  // padding: 10px 20px;
  border-radius: 8px;
  background-color: ${Colors.backgroundGrey};
`;

export default { TermBox, TermEditBox, TermDateBox };
