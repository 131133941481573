import React, { useState } from "react";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { message } from "antd";
import TextStyles from "../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";
import { useDispatch, useSelector } from "react-redux";
import { updateConfidentialPC } from "../../../../../../store/service/proposalAndContractService";
import ConfidentialModal from "../../PageModals/ConfidentialModal";
import RemoveModal from "../../../../Commons/RemoveModal";
import Colors from "../../../../../../Constants/Colors";

const Confidential = () => {
  const selectedData = useSelector(
    (state) => state.proposalAndContract.selectedData
  );
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const handleCloseRemoveModal = () => setShowRemoveModal(false);
  const heading =
    selectedData &&
    selectedData.confidential &&
    selectedData.confidential.heading
      ? selectedData &&
        selectedData.confidential &&
        selectedData.confidential.heading
      : "Confidentiality & Non-Disclosure";
  const paragraph =
    selectedData &&
    selectedData.confidential &&
    selectedData.confidential.paragraph
      ? selectedData &&
        selectedData.confidential &&
        selectedData.confidential.paragraph
      : `Both parties shall maintain strict confidentiality of all Confidential Information (as defined in the subsequent sentence) shared by either party under this Agreement. "Confidential Information" includes non-public information marked as confidential by the disclosing party and any information that should reasonably be considered confidential in the context of disclosure, whether or not marked as "Confidential". If a party is legally required to disclose Confidential Information due to a judicial or governmental order, it shall promptly notify the other party to explore alternative legal remedies for maintaining confidentiality. Each party receiving Confidential Information is responsible for any breach caused by its employees, affiliates, representatives, or agents. The receiving party agrees to indemnify and hold the disclosing party harmless from any liabilities, claims, damages, losses, costs, and expenses resulting from such a breach. The obligations under this section shall remain in effect for three years following the termination of this Agreement.
`;

  const hideClauseHandler = () => {
    const Obj = {
      _id: selectedData._id,
      confidential: {
        required: false,
        heading: null,
        paragraph: null,
      },
    };
    dispatch(updateConfidentialPC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleCloseRemoveModal();
        message.success("Clause hidden successfully!");
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  return (
    <div
      style={{
        display:
          selectedData &&
          selectedData.confidential &&
          selectedData.confidential.required === true
            ? "inline"
            : "none",
      }}
    >
      <Styled.ReviewContentBox className="mt-4">
        <Styled.EditButtonToolTip
          onClick={() => setShowModal(true)}
          className="contract_review_edit_tp"
          style={{ marginLeft: -60 }}
        >
          <BorderColorOutlinedIcon sx={{ color: Colors.white, fontSize: 20 }} />
          <span className="ms-1">Edit</span>
        </Styled.EditButtonToolTip>
        <Styled.EditButtonToolTip
          backgroundColor="#383838"
          className="contract_review_edit_tp"
          style={{ marginLeft: 60 }}
          onClick={() => {
            setShowRemoveModal(true);
            setConfirmDialog({
              onConfirm: hideClauseHandler,
            });
          }}
        >
          <VisibilityOffIcon sx={{ color: Colors.white, fontSize: 20 }} />
          <span className="ms-1">Hide</span>
        </Styled.EditButtonToolTip>

        <TextStyles.FontSize20px className="mb-2">
          {heading}
        </TextStyles.FontSize20px>
        <TextStyles.FontSize14px>{paragraph}</TextStyles.FontSize14px>
      </Styled.ReviewContentBox>
      {showModal && (
        <ConfidentialModal
          selectedData={selectedData}
          paragraph={paragraph}
          heading={heading}
          show={showModal}
          handleClose={() => setShowModal(false)}
        />
      )}
      {confirmDialog && (
        <RemoveModal
          title={heading}
          confirmDialog={confirmDialog}
          showRemoveModal={showRemoveModal}
          handleCloseRemoveModal={handleCloseRemoveModal}
        />
      )}
    </div>
  );
};

export default Confidential;
