import styled from "styled-components";
import Colors from "../../../Constants/Colors";

const Boxes = styled.div`
  height: 3.15rem;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  border-radius: 0.75rem;
  cursor: pointer;
  font-family: Poppins;
  font-size: 0.875rem;
  background-color: ${Colors.primary};
  color: ${Colors.white};

  &:hover {
    background-color: ${Colors.hoverButtonColor};
  }
`;

const InputBox = styled.div`
  border-radius: 0.75rem;
  height: 3.15rem;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  border: 1px solid
    ${(props) => (props.invalid ? Colors.red : Colors.borderInput)};
  background-color: ${(props) =>
    props.invalid ? Colors.inputErrorBg : Colors.white};

  transition: border 0.4s ease;

  &:hover {
    border: 1px solid ${Colors.primary};
  }
`;

const ImageBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  position: relative;
  height: 100%;
  min-height: 400px;
  padding: 0.75rem;
  border: dashed 2px ${(props) => (props.fileErrors ? "red" : "#a9abb3")};
  background-color: #e5eaf0;
  box-sizing: border-box;
  #pdf-controls {
    display: none;
  }

  #pdf-renderer {
    overflow-y: initial;
  }

  #loading-renderer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #image-renderer {
    background: #e5eaf0;
  }
  @media (max-width: 576px) {
    min-height: 300px;
  }
`;

const TransactionGraphRectangle = styled.div`
  width: 100%;
  padding: 1rem;
  border-radius: 0.75rem;
  box-shadow: 0 8px 12px 0 rgba(238, 241, 245, 0.89);
  background-color: ${Colors.white};
  content: contain;
`;

const TableHead = styled.div`
  border-radius: 0.75rem 0.75rem 0 0;
  padding: 1rem;
  background-color: ${Colors.tableHeadColor};
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
  .transaction-actions {
    position: relative;
    max-width: 2.25rem;
    min-width: 2.25rem;
    display: flex;
    justify-content: end;
    align-items: center;
  }

  .visible-purpose,
  .visible-project,
  .visible-company,
  .visible-date,
  .visible-amount {
    visibility: hidden;
  }

  .hover-purpose:hover .visible-purpose,
  .hover-project:hover .visible-project,
  .hover-company:hover .visible-company,
  .hover-date:hover .visible-date,
  .hover-amount:hover .visible-amount {
    visibility: visible;
  }

  @media (max-width: 576px) {
    .transaction-actions {
      max-width: 2.25rem;
      min-width: 2.25rem;
    }
  }
`;

const TableCell = styled.div`
  width: 100%;
  height: 4rem;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  cursor: pointer;
  margin: 0 0 2px 0;
  background-color: ${Colors.white};
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
  .showImage {
    visibility: hidden;
  }
  &:hover {
    background: ${Colors.hoverTableRow};
  }

  &:hover .showImage {
    visibility: visible;
  }

  .dropdown-menu {
    border: 2px solid #f9f9f9;
    padding: 6px 6px;
    border-radius: 6px;
    box-shadow: 1px 2px 3px 1px rgba(0, 0, 0, 0.2);
  }

  .dropdown-item {
    padding: 7px 12px;
    border-radius: 4px;
  }
  .dropdown-item:hover {
    background: ${Colors.backgroundPink};
  }
  .transaction-actions {
    position: relative;
    max-width: 2.25rem;
    min-width: 2.25rem;
    display: flex;
    justify-content: end;
    align-items: center;
  }

  @media (max-width: 576px) {
    .transaction-actions {
      max-width: 2.25rem;
      min-width: 2.25rem;
    }
  }
`;

const ListBox = styled.div`
  width: 100%;
  height: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.white};
  border-radius: 0 0 0.75rem 0.75rem;
  padding: 1rem;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
`;

const Sorting = styled.div`
  display: flex;
  min-width: 13.75rem;
  justify-content: space-between;
  align-items: center;
  padding: 3px 8px;
  border-radius: 6px;

  label {
    display: flex;
    width: 100%;
    cursor: pointer;
  }
  .form-check-input {
    width: 1rem;
    height: 1rem;
    box-shadow: none !important;
    border: 2px solid ${Colors.font2};
    cursor: pointer;
  }
  .form-check-input:checked {
    background-color: ${Colors.primary};
    border-color: ${Colors.primary};
  }

  &:hover {
    background: ${Colors.backgroundPink};
  }
  &:hover svg {
    color: ${Colors.primary};
  }
`;

export default {
  Boxes,
  InputBox,
  ImageBox,
  TransactionGraphRectangle,
  TableHead,
  TableCell,
  ListBox,
  Sorting,
};
