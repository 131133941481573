import React, { useState } from "react";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { message } from "antd";
import TextStyles from "../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";
import { useDispatch, useSelector } from "react-redux";
import { updateNonSolicitPC } from "../../../../../../store/service/proposalAndContractService";
import RemoveModal from "../../../../Commons/RemoveModal";
import NonSolicitModal from "../../PageModals/NonSolicitModal";
import Colors from "../../../../../../Constants/Colors";

const NonSolicit = () => {
  const selectedData = useSelector(
    (state) => state.proposalAndContract.selectedData
  );
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const handleCloseRemoveModal = () => setShowRemoveModal(false);

  const heading =
    selectedData && selectedData.non_solicit && selectedData.non_solicit.heading
      ? selectedData &&
        selectedData.non_solicit &&
        selectedData.non_solicit.heading
      : "Non-Solicit";
  const paragraph =
    selectedData &&
    selectedData.non_solicit &&
    selectedData.non_solicit.paragraph
      ? selectedData &&
        selectedData.non_solicit &&
        selectedData.non_solicit.paragraph
      : "Contractor agrees that throughout the period of providing Services and for one year thereafter, they will refrain from encouraging or soliciting any employee, vendor, client, or contractor of the Client to depart from the Client for any reason.";

  const hideClauseHandler = () => {
    const Obj = {
      _id: selectedData._id,
      non_solicit: {
        required: false,
        heading: null,
        paragraph: null,
      },
    };
    dispatch(updateNonSolicitPC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleCloseRemoveModal();
        message.success("Clause hidden successfully!");
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  return (
    <div
      style={{
        display:
          selectedData &&
          selectedData.non_solicit &&
          selectedData.non_solicit.required === true
            ? "inline"
            : "none",
      }}
    >
      <Styled.ReviewContentBox className="mt-4">
        <Styled.EditButtonToolTip
          onClick={() => setShowModal(true)}
          className="contract_review_edit_tp"
          style={{ marginLeft: -60 }}
        >
          <BorderColorOutlinedIcon sx={{ color: Colors.white, fontSize: 20 }} />
          <span className="ms-1">Edit</span>
        </Styled.EditButtonToolTip>
        <Styled.EditButtonToolTip
          backgroundColor="#383838"
          className="contract_review_edit_tp"
          style={{ marginLeft: 60 }}
          onClick={() => {
            setShowRemoveModal(true);
            setConfirmDialog({
              onConfirm: hideClauseHandler,
            });
          }}
        >
          <VisibilityOffIcon sx={{ color: Colors.white, fontSize: 20 }} />
          <span className="ms-1">Hide</span>
        </Styled.EditButtonToolTip>

        <TextStyles.FontSize20px className="mb-2">
          {heading}
        </TextStyles.FontSize20px>
        <TextStyles.FontSize14px>{paragraph}</TextStyles.FontSize14px>
      </Styled.ReviewContentBox>
      {showModal && (
        <NonSolicitModal
          selectedData={selectedData}
          paragraph={paragraph}
          heading={heading}
          show={showModal}
          handleClose={() => setShowModal(false)}
        />
      )}
      {confirmDialog && (
        <RemoveModal
          title={heading}
          confirmDialog={confirmDialog}
          showRemoveModal={showRemoveModal}
          handleCloseRemoveModal={handleCloseRemoveModal}
        />
      )}
    </div>
  );
};

export default NonSolicit;
