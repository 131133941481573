import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import "./NavBarMobile.css";
import logo from "../../../../Assets/assetsnew/frontend_logo_with_name.webp";
import ArrowDropDownSharpIcon from "@mui/icons-material/ArrowDropDownSharp";

import CloseIcon from "@mui/icons-material/Close";
import Colors from "../../../../Constants/Colors";
import { useDispatch } from "react-redux";
import { authActions } from "../../../../store/storage/authSlice";

const NavBarMobile = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  let urlElements = window.location.href.split("/");
  const token = localStorage.getItem('token');

  const logoutHandler = () => {
    dispatch(authActions.logout());
    history.push("/");
  };

  return (
    <>
      <div>
        <div className="Nav-top" onClick={props.close}>
          <NavLink to="/">
            <img
              src={logo}
              alt="Logo"
              width={175}
              height={50}
              style={{
                maxWidth: "100%",
                height: "auto",
                objectFit: "contain",
              }}
            />
          </NavLink>
          <CloseIcon
            sx={{ fontSize: 30, color: Colors.white, cursor: "pointer" }}
          />
        </div>
        <ul className="Nav-mainMenu-mobile ul-reset-mobile pt-3">
          <li className="Nav-Submenu-mobile link-mobile-t">
            <span
              className="features"
              style={{
                color:
                  urlElements[3] === "features"
                    ? `${Colors.brandColorFrontend}`
                    : "",
              }}
            >
              Fetaures
              <ArrowDropDownSharpIcon />
            </span>
            <ul className={"mobile-view"}>
              <li onClick={props.close}>
                <NavLink
                  to="/features/task-management"
                  activeClassName="is-active"
                  style={{ textDecoration: "none" }}
                  className="Nav-link-ul"
                >
                  Task management
                </NavLink>
              </li>
              <li onClick={props.close}>
                <NavLink
                  to="/features/invoicing"
                  activeClassName="is-active"
                  style={{ textDecoration: "none" }}
                  className="Nav-link-ul"
                >
                  Invoicing
                </NavLink>
              </li>
              <li onClick={props.close}>
                <NavLink
                  to="/features/agreements"
                  activeClassName="is-active"
                  style={{ textDecoration: "none" }}
                  className="Nav-link-ul"
                >
                  Agreements
                </NavLink>
              </li>
              <li onClick={props.close}>
                <NavLink
                  to="/features/transactions"
                  activeClassName="is-active"
                  style={{ textDecoration: "none" }}
                  className="Nav-link-ul"
                >
                  Transactions
                </NavLink>
              </li>
              <li onClick={props.close}>
                <NavLink
                  to="/features/time-tracking"
                  activeClassName="is-active"
                  style={{ textDecoration: "none" }}
                  className="Nav-link-ul"
                >
                  Time tracking
                </NavLink>
              </li>

              <li onClick={props.close}>
                <NavLink
                  to="/features/statements"
                  activeClassName="is-active"
                  style={{ textDecoration: "none" }}
                  className="Nav-link-ul"
                >
                  Statements
                </NavLink>
              </li>
            </ul>
          </li>
          <li className="link-mobile" onClick={props.close}>
            <NavLink
              className="Nav-link-mobile"
              activeClassName="is-active"
              style={{ textDecoration: "none" }}
              to="/reviews"
            >
              Reviews
            </NavLink>
          </li>
          <li className="link-mobile" onClick={props.close}>
            <NavLink
              className="Nav-link-mobile"
              activeClassName="is-active"
              style={{ textDecoration: "none" }}
              to="/why-zodot"
            >
              Why Zodot?
            </NavLink>
          </li>
        </ul>
      </div>
      <div>
        <ul className="Nav-webApp u-reset-ul ps-0">
          <li className="Nav-signUp-mobile">
            <NavLink
              className="Nav-webApp-link-mobile Nav-webApp-link Nav-webApp-link--signUp"
              style={{ textDecoration: "none" }}
              to={token ? "/db/home" : "/db/signup"}
            >
              <span>{token ? "Go to dashboard" : "Sign up for free"}</span>
            </NavLink>
          </li>
          <li className="Nav-logIn-mobile" onClick={props.close}>
            <NavLink
              className="Nav-webApp-link Nav-webApp-link--signIn"
              style={{ textDecoration: "none" }}
              to={!token ? "/db/login" : ""}
            >
              {token ? <span onClick={logoutHandler}>Log out</span> : "Log in"}
            </NavLink>
          </li>
        </ul>
      </div>
    </>
  );
};

export default NavBarMobile;
