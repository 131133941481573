import React from "react";
import { useForm } from "react-hook-form";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";

import ClearIcon from "@mui/icons-material/Clear";

import TextStyles from "../../../../Constants/TextStyles";
import Colors from "../../../../Constants/Colors";
import { invoiceActions } from "../../../../store/storage/invoiceSlice";

const InvoiceNotes = ({ show, handleClose, selectedData }) => {
  const dispatch = useDispatch();
  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      invoice_notes: selectedData && selectedData.invoice_notes,
    },
  });

  const formsubmit = (data) => {
    const Obj = {
      invoice_notes: data.invoice_notes,
    };
    dispatch(invoiceActions.selectedInvoiceAction(Obj));
    reset();
    handleClose();
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="ToMakeModalOnRight500px"
        fullscreen
      >
        <form
          onSubmit={handleSubmit(formsubmit)}
          className="modal-content"
          style={{ height: "100%" }}
        >
          <Modal.Header className="d-flex align-items-center justify-content-between">
            <TextStyles.FontSize20px>Notes</TextStyles.FontSize20px>
            <ClearIcon
              sx={{
                color: Colors.font2,
                cursor: "pointer",
                opacity: 0.7,
                "&:hover": {
                  opacity: 1,
                },
              }}
              onClick={handleClose}
            />
          </Modal.Header>
          <Modal.Body>
            <TextStyles.FontSize14px className="mb-3">
              <label htmlFor="invoice_notes" className="mb-2">
                {" "}
                Notes
              </label>
              <TextStyles.Textarea
                invalid={errors.invoice_notes}
                rows={20}
                id="invoice_notes"
                name="invoice_notes"
                placeholder="Add a note ..."
                {...register("invoice_notes", {
                  required: "Note is required.",
                  validate: (value) => {
                    let trimedLength = value.trim().length;
                    if (trimedLength === 0) {
                      return "Note is required.";
                    }
                    return true;
                  },
                })}
              />
              {errors.invoice_notes && (
                <TextStyles.InValidFeedback>
                  {errors.invoice_notes.message}
                </TextStyles.InValidFeedback>
              )}
            </TextStyles.FontSize14px>
          </Modal.Body>
          <Modal.Footer style={{ display: "inline-block" }}>
            <div className="d-flex gap-3 justify-content-between">
              <TextStyles.CancelButton onClick={handleClose}>
                Cancel
              </TextStyles.CancelButton>
              <TextStyles.Button>Save</TextStyles.Button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default InvoiceNotes;
