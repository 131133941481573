import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./LandingPage.module.css";
import { TypeAnimation } from "react-type-animation";
import HomePageHeroSectionImage from "../../../Assets/frontendImages/landingPage_image.avif";
import HomePageHeroSectionImageMobileView from "../../../Assets/frontendImages/landingPage_image_mobile.avif";

function LandingPage() {
  return (
    <div className={styles.hero_section}>
      <div className="container-fluid">
        <div className="d-flex flex-column flex-md-row align-items-center">
          <div
            className="text-center text-md-start w-100 mt-3 mt-md-0"
            data-aos={"fade-right"}
          >
            <h1 className="d-flex flex-column">
              <span className={`text-white ${styles.heading}`}>
                <span className={styles.italic}>Accelerate</span>
                <span> your business management with Zodot’s </span>
              </span>
              <span
                className="mb-4"
                style={{ height: "auto", width: "100%", lineBreak: "auto" }}
              >
                <TypeAnimation
                  sequence={[
                    // Same substring at the start will only be typed out once, initially
                    "invoices",
                    1000, // wait 1s before replacing "Mice" with "Hamsters"
                    "agreements",
                    1000,
                    "time tracking",
                    1000,
                    "statements and reports",
                    1000,
                  ]}
                  wrapper="span"
                  speed={50}
                  className={`${styles.typingAnimation}`}
                  repeat={Infinity}
                />
              </span>
            </h1>

            <div className="d-flex justify-content-center justify-content-md-start mb-4">
              <NavLink
                className={styles.btn_begin}
                style={{ textDecoration: "none" }}
                to={"/db/signup"}
              >
                Begin today
              </NavLink>
            </div>

            <p className={`${styles.text}`}>
              Free for all users – no credit card required!
            </p>
          </div>
          <div className="d-flex justify-content-center align-items-center w-100 mt-3 mt-md-0 pb-3">
            <div
              data-aos={"fade-left"}
              style={{
                width: "100%",
                height: "100%",
                maxWidth: 700,
                maxHeight: 700,
              }}
            >
              <picture>
                {/* For larger screens */}
                <source
                  media="(min-width: 576px)"
                  srcSet={HomePageHeroSectionImage}
                  width={650}
                  height={450}
                />
                {/* For smaller screens */}
                <source
                  media="(max-width: 575px)"
                  srcSet={HomePageHeroSectionImageMobileView}
                  width={550}
                  height={450}
                />
                {/* Fallback image */}
                <img
                  src={HomePageHeroSectionImage} // Default source
                  alt="homeHeroSectionImageBtn"
                  width={650} // Default width
                  height={450} // Default height
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                    objectFit: "contain",
                  }}
                />
              </picture>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
