import React from "react";
import ClearIcon from "@mui/icons-material/Clear";
import TextStyles from "../../../../Constants/TextStyles";
import { Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import {
  AutoCompleteCreateActivity,
  AutoCompleteSelectProject,
} from "../../Commons/AutoComplete";
import LoaderSpin from "../../Commons/LoaderSpin";
import moment from "moment";
import {
  add_single_timer_activity,
  createActivityList,
  fetchAllActivityLists,
} from "../../../../store/service/timeTrackingService";
import Colors from "../../../../Constants/Colors";
import { timeTrackingActions } from "../../../../store/storage/timetrackingSlice";
import CustomDatePickerOne from "../../Commons/DatePickers/CustomDatePickerOne";
import CustomTimePickerOne from "../../Commons/DatePickers/CustomTimePickerOne";

const SingleActivityAddModal = ({
  show,
  handleClose,
  setUpdateRefresh,
  updateRefresh,
  selectedData,
}) => {
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state.timeTracking.isLoading);

  const activityListData = useSelector(
    (state) => state.timeTracking.activityListData
  );

  const projectOptionsData = useSelector(
    (state) => state.project.projectOptionsData
  );

  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
    control,
    clearErrors,
    getValues,
  } = useForm({
    defaultValues: {
      select_project: selectedData && {
        _id: selectedData._id,
        title: selectedData.project_name,
        client_id: selectedData && selectedData.client_id,
        client_name: selectedData && selectedData.client_id,
      },
      start_date: new Date(),
      start_time: new Date(),
    },
  });

  const validateAtLeastOneField = () => {
    const { hours, minutes, seconds } = getValues();
    const hrs = parseFloat(hours) || 0;
    const mins = parseFloat(minutes) || 0;
    const secs = parseFloat(seconds) || 0;
    if (hrs > 0 || mins > 0 || secs > 0) {
      clearErrors("hours");
      clearErrors("minutes");
      clearErrors("seconds");
      return true;
    } else {
      return "Time is required.";
    }
  };

  const createActivityHandler = (data) => {
    if (data && !data._id) {
      let Obj = {
        activity_name: data.inputValue || data,
      };
      dispatch(timeTrackingActions.addNewActivityList(Obj));
      dispatch(createActivityList(Obj)).then((data) => {
        if (data.meta.requestStatus === "fulfilled") {
          dispatch(fetchAllActivityLists());
        }
      });
    }
  };

  const formsubmit = (data) => {
    const hours = parseFloat(data.hours) || 0;
    const minutes = parseFloat(data.minutes) || 0;
    const seconds = parseFloat(data.seconds) || 0;
    const client_id = data.select_project
      ? data.select_project.client_id
      : null;
    const project_id = data.select_project ? data.select_project._id : null;
    const activity_name =
      data.select_activity && data.select_activity.inputValue
        ? data.select_activity.inputValue
        : data.select_activity && data.select_activity._id
        ? data.select_activity.title
        : data.select_activity
        ? data.select_activity
        : null;

    let start_time = new Date(
      moment(data.start_date).set({
        hour: moment(data.start_time).get("hour"),
        minute: moment(data.start_time).get("minute"),
        second: moment(data.start_time).get("second"),
      })
    );

    let timesInSecs = hours * 60 * 60 + minutes * 60 + seconds;
    let is_invoiced = data.invoiced;

    const Obj = {
      client_id,
      project_id,
      activity_name,
      start_time,
      timesInSecs,
      is_invoiced,
    };

    dispatch(add_single_timer_activity(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        setUpdateRefresh(!updateRefresh);
        handleClose();
        reset();
        message.success(`${data.payload.message}`);
      } else {
        message.error(`${data.error.message}`);
      }
    });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      contentClassName="ToMakeModalBorderRudius12px"
      scrollable={true}
      centered
    >
      <form
        onSubmit={handleSubmit(formsubmit)}
        className="modal-content"
        style={{ height: "100%" }}
      >
        <Modal.Header className="d-flex align-items-center justify-content-between">
          <TextStyles.FontSize20px>Add activity</TextStyles.FontSize20px>
          <ClearIcon
            sx={{
              color: Colors.font2,
              cursor: "pointer",
              opacity: 0.7,
              "&:hover": {
                opacity: 1,
              },
            }}
            onClick={handleClose}
          />
        </Modal.Header>

        <Modal.Body className="modal-body">
          <TextStyles.FontSize14px className="mb-3">
            <div className="mb-2">Time</div>
            <div className="d-flex gap-3 flex-wrap justify-content-start align-items-center">
              <div className="d-flex justify-content-around align-items-center">
                <div
                  className="d-flex align-items-center"
                  style={{
                    border: `1px solid ${Colors.borderInput}`,
                    padding: "0 1rem",
                    borderRadius: "0.75rem",
                    height: "3.15rem",
                  }}
                >
                  <input
                    type="number"
                    min={0}
                    max={23}
                    style={{ width: 25 }}
                    placeholder="00"
                    {...register("hours", {
                      pattern: {
                        value: /^[0-9]+$/i,
                        message: "Please enter a valid number for hours.",
                      },
                      validate: validateAtLeastOneField,
                    })}
                  />
                  <div className="ms-2">hrs</div>
                </div>
              </div>
              <div className="d-flex justify-content-around align-items-center">
                <div
                  className="d-flex align-items-center"
                  style={{
                    border: `1px solid ${Colors.borderInput}`,
                    padding: "0 1rem",
                    borderRadius: "0.75rem",
                    height: "3.15rem",
                  }}
                >
                  <input
                    min={0}
                    max={59}
                    type="number"
                    style={{ width: 25 }}
                    placeholder="00"
                    {...register("minutes", {
                      pattern: {
                        value: /^[0-9]+$/i,
                        message: "Please enter a valid number for minutes.",
                      },
                      validate: validateAtLeastOneField,
                    })}
                  />
                  <div className="ms-2">mins</div>
                </div>
              </div>
              <div className="d-flex justify-content-around align-items-center">
                <div
                  className="d-flex align-items-center"
                  style={{
                    border: `1px solid ${Colors.borderInput}`,
                    padding: "0 1rem",
                    borderRadius: "0.75rem",
                    height: "3.15rem",
                  }}
                >
                  <input
                    min={0}
                    max={59}
                    type="number"
                    style={{ width: 25 }}
                    placeholder="00"
                    {...register("seconds", {
                      pattern: {
                        value: /^[0-9]+$/i,
                        message: "Please enter a valid number for seconds.",
                      },
                      validate: validateAtLeastOneField,
                    })}
                  />
                  <div className="ms-2">sec</div>
                </div>
              </div>
            </div>

            {(errors.hours && (
              <TextStyles.InValidFeedback style={{ marginTop: 6 }}>
                {errors.hours.message}
              </TextStyles.InValidFeedback>
            )) ||
              (errors.minutes && (
                <TextStyles.InValidFeedback style={{ marginTop: 6 }}>
                  {errors.minutes.message}
                </TextStyles.InValidFeedback>
              )) ||
              (errors.seconds && (
                <TextStyles.InValidFeedback style={{ marginTop: 6 }}>
                  {errors.seconds.message}
                </TextStyles.InValidFeedback>
              ))}
          </TextStyles.FontSize14px>

          <TextStyles.FontSize14px className="mb-3">
            <div className="mb-2">Activity</div>
            <Controller
              control={control}
              name="select_activity"
              render={({ field: { onChange, value } }) => (
                <AutoCompleteCreateActivity
                  placeholder="Write activity..."
                  options={
                    activityListData &&
                    activityListData.map((d) => {
                      return { _id: d._id, title: d.activity_name };
                    })
                  }
                  onChange={(value) => {
                    onChange(value);
                    createActivityHandler(value);
                  }}
                />
              )}
            />
          </TextStyles.FontSize14px>
          <TextStyles.FontSize14px className="mb-3">
            <div className="mb-2">Project</div>
            <Controller
              control={control}
              name="select_project"
              render={({ field: { onChange, value } }) => (
                <AutoCompleteSelectProject
                  placeholder="Assign to project"
                  options={
                    projectOptionsData &&
                    projectOptionsData.map((d) => {
                      return {
                        _id: d._id ? d._id : null,
                        title: d && d.project_name ? d.project_name : null,
                        client_id:
                          d && d.client_id && d.client_id._id
                            ? d.client_id._id
                            : null,
                        client_name:
                          d && d.client_id && d.client_id.company_name
                            ? d.client_id.company_name
                            : null,
                      };
                    })
                  }
                  onChange={onChange}
                  defaultValue={value}
                />
              )}
            />
          </TextStyles.FontSize14px>
          <TextStyles.FontSize14px className="mb-3">
            <div className="mb-2">Date</div>
            <div>
              <Controller
                name={"start_date"}
                control={control}
                rules={{ required: "Please select date" }}
                render={({ field: { onChange, value } }) => {
                  return (
                    <CustomDatePickerOne
                      onChange={onChange}
                      selected={value}
                      placeholder="Setect date"
                      allowClear={false}
                    />
                  );
                }}
              />
            </div>
            {errors.start_date && (
              <TextStyles.InValidFeedback>
                {errors.start_date.message}
              </TextStyles.InValidFeedback>
            )}
          </TextStyles.FontSize14px>
          <TextStyles.FontSize14px className="mb-3">
            <div className="mb-2">Start time</div>
            <div>
              <Controller
                name={"start_time"}
                control={control}
                // rules={{ required: "Please select start time" }}
                render={({ field: { onChange, value } }) => {
                  return (
                    <CustomTimePickerOne
                      onChange={onChange}
                      selected={value}
                      placeholder="Start Time"
                      allowClear={false}
                    />
                  );
                }}
              />
            </div>
          </TextStyles.FontSize14px>
          <TextStyles.FontSize14px>
            <TextStyles.CheckBox className="form-check d-flex align-items-center">
              <input
                className="form-check-input"
                type="checkbox"
                id="flexCheckChecked"
                name="invoiced"
                {...register("invoiced")}
              />
              <label className="form-check-label" htmlFor="flexCheckChecked">
                <div>Marked as invoiced</div>
              </label>
            </TextStyles.CheckBox>
          </TextStyles.FontSize14px>
        </Modal.Body>
        <Modal.Footer style={{ display: "inline-block", padding: "6px 12px" }}>
          <div className="d-flex gap-3 justify-content-between">
            <TextStyles.Button disabled={isLoading}>
              {isLoading ? <LoaderSpin /> : "Save"}
            </TextStyles.Button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default SingleActivityAddModal;
