import React from "react";
import LandingPage from "./LandingPage/LandingPage";
import Features from "./Features/Features";

import Footer from "../../Footer/Footer";
import AdditionalFeatures from "./AdditionalFeatures/AdditionalFeatures";
import SignupForm from "../../UIs/SignupForm";
import { Helmet } from "react-helmet";

const Invoice = () => {
  return (
    <div>
      <Helmet>
        <title>Best Invoicing and Billing Software Online - Zodot</title>
        <meta
          content="Streamline invoicing with Zodot! This smart tool automatically generates invoices and notifies you when they're viewed or paid, simplifying billing."
          name="description"
        />
        <link rel="canonical" href="https://zodot.co/features/invoicing" />
      </Helmet>
      <LandingPage />
      <Features />
      <AdditionalFeatures />
      <SignupForm />
      <Footer />
    </div>
  );
};

export default Invoice;
