import { createSlice } from "@reduxjs/toolkit";
import {
  createAndSendInvoice,
  saveAsDraftInvoice,
  fetchInvoices,
  deleteInvoice,
  paidInvoice,
  fetchInvoiceCounts,
} from "../service/invoiceService";

const invoiceInitialState = {
  invoiceData: null,
  invoiceTotalCount: null,
  isLoading: false,
  error: null,
  selectedInvoice: null,
  contactData: null,
  selectedInvoiceData: null,
  createInvoiceMode: null,
  createInvoiceClientMode: null,
  filteredData: {},
  toggleFilter: false,
  sortingData: {},
  filteredDataInClient: {},
  toggleFilterInClient: false,
  sortingDataInClient: {},
  filteredDataInProject: {},
  toggleFilterInProject: false,
  sortingDataInProject: {},
};

export const invoiceSlice = createSlice({
  name: "invoices",
  initialState: invoiceInitialState,
  reducers: {
    editInvoice: (state, action) => {
      state.selectedInvoiceData = action.payload;
      state.selectedInvoice = action.payload;
      state.createInvoiceMode = null;
      state.createInvoiceClientMode = null;
    },
    createInvoiceModeAction: (state, action) => {
      state.createInvoiceMode = action.payload;
      state.selectedInvoiceData = null;
      state.createInvoiceClientMode = null;
    },
    createInvoiceClientModeAction: (state, action) => {
      state.createInvoiceClientMode = action.payload;
    },
    selectedInvoiceAction: (state, action) => {
      state.selectedInvoiceData = {
        ...state.selectedInvoiceData,
        ...action.payload,
      };
    },
    selectedInvoiceResetAction: (state) => {
      state.selectedInvoiceData = null;
      state.createInvoiceMode = null;
      state.selectedInvoice = null;
      state.createInvoiceClientMode = null;
    },

    filterAction: (state, action) => {
      state.filteredData = { ...state.filteredData, ...action.payload };
    },
    toggleAction: (state) => {
      state.toggleFilter = !state.toggleFilter;
    },
    sortingAction: (state, action) => {
      state.sortingData = { ...state.sortingData, ...action.payload };
    },
    filterActionInClient: (state, action) => {
      state.filteredDataInClient = {
        ...state.filteredDataInClient,
        ...action.payload,
      };
    },
    toggleActionInClient: (state) => {
      state.toggleFilterInClient = !state.toggleFilterInClient;
    },
    sortingActionInClient: (state, action) => {
      state.sortingDataInClient = {
        ...state.sortingDataInClient,
        ...action.payload,
      };
    },

    filterActionInProject: (state, action) => {
      state.filteredDataInProject = {
        ...state.filteredDataInProject,
        ...action.payload,
      };
    },
    toggleActionInProject: (state) => {
      state.toggleFilterInProject = !state.toggleFilterInProject;
    },
    sortingActionInProject: (state, action) => {
      state.sortingDataInProject = {
        ...state.sortingDataInProject,
        ...action.payload,
      };
    },
  },

  extraReducers: (builder) => {
    builder
      // create and send invoice
      .addCase(createAndSendInvoice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createAndSendInvoice.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(createAndSendInvoice.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // save as draft invoice
      .addCase(saveAsDraftInvoice.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(saveAsDraftInvoice.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(saveAsDraftInvoice.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // fetch invoices
      .addCase(fetchInvoices.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchInvoices.fulfilled, (state, action) => {
        state.isLoading = false;
        state.invoiceData = action.payload.data.data;
        state.invoiceTotalCount = action.payload.data.invoiceTotalCount;
      })
      .addCase(fetchInvoices.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // delete invoice
      .addCase(deleteInvoice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteInvoice.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteInvoice.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // paid invoice
      .addCase(paidInvoice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(paidInvoice.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(paidInvoice.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // fetch invoice count
      .addCase(fetchInvoiceCounts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchInvoiceCounts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.invoiceTotalCount = action.payload.data.invoiceTotalCount;
      })
      .addCase(fetchInvoiceCounts.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const invoiceActions = invoiceSlice.actions;

export default invoiceSlice.reducer;
