import React from "react";
import "./NavButton.css";
import { NavLink } from "react-router-dom";
import logo from "../../../../Assets/assetsnew/frontend_logo_with_name.webp";

const NavButton = (props) => {
  return (
    <>
      <div className="container-fluid">
        <div className="Nav-top">
          <NavLink to="/">
            <img
              src={logo}
              alt="Logo"
              width={175}
              height={50}
              style={{
                maxWidth: "100%",
                height: "auto",
                objectFit: "contain",
              }}
            />
          </NavLink>

          <div className="Toggle" onClick={props.open}>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavButton;
