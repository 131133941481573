import React, { useEffect } from "react";
import TimerBox from "./TimerBox";
import TimerList from "./TimerList";
import { useDispatch } from "react-redux";
import { fetchAllActivityLists } from "../../../../../store/service/timeTrackingService";

const Timer = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAllActivityLists());
  }, []);
  return (
    <div>
      <TimerBox />
      <TimerList />
    </div>
  );
};

export default Timer;
