import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Colors from "../../../Constants/Colors";
import sizes from "../../../Constants/sizes";

const useStyles = makeStyles({
  root: {
    border: `1px solid ${Colors.borderInput}`,
    borderRadius: sizes.formInputBorderRadius,
    height: sizes.formInputHeight,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: `${Colors.white} !important`,

    "& .MuiOutlinedInput-root": {
      padding: "0 !important",
    },

    "& .MuiAutocomplete-input": {
      fontFamily: "Poppins",
      padding: "0 2rem 0 1rem !important",
      fontSize: "0.875rem",
      color: Colors.font1,
    },

    "&:hover": {
      border: `1px solid ${Colors.primary}`,
    },

    "&.Mui-focused": {
      border: `1px solid ${Colors.primary}`,
    },
  },
  rootError: {
    border: `1px solid ${Colors.red}`,
    background: Colors.inputErrorBg,
    borderRadius: sizes.formInputBorderRadius,
    "& .MuiAutocomplete-input": {
      fontFamily: "Poppins",
      fontSize: "0.875rem",
      color: Colors.font1,
    },
  },
  option: {
    fontFamily: "Poppins",
    fontSize: "0.875rem",
    color: Colors.font1,
    marginBottom: "2px !important",
    "&:hover": {
      backgroundColor: `${Colors.backgroundPink} !important`,
    },
    '&[aria-selected="true"]': {
      backgroundColor: `${Colors.backgroundPink} !important`,
    },
  },

  input: {
    "& input::placeholder": {
      fontFamily: "Poppins",
      padding: "none",
      fontSize: "0.875rem",
      color: Colors.font1,
    },
  },
});

export default function SelectCurrency(props) {
  var { placeholder, onChange, label } = props;
  const classes = useStyles();

  return (
    <Autocomplete
      classes={{
        option: classes.option,
      }}
      className={classes.root}
      id="country-select-demo"
      sx={{
        width: props.width,
        "& .MuiAutocomplete-clearIndicator": {
          display: "none",
        },
      }}
      options={countryOptions}
      autoHighlight
      value={props.defaultValue ? props.defaultValue : null}
      onChange={(event, value) => {
        onChange(value);
      }}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      getOptionLabel={(option) => `${option.name} (${option.code})`}
      renderInput={(params) => (
        <TextField
          sx={{
            "& .MuiOutlinedInput-notchedOutline": { border: "none" },
          }}
          {...params}
          label={label}
          classes={{ root: classes.input }}
          placeholder={placeholder}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password",
          }}
        />
      )}
    />
  );
}

const countryOptions = [
  { name: "Afghan Afghani", code: "AFN", symbol: "؋" },
  { name: "Albanian Lek", code: "ALL", symbol: "Lek" },
  { name: "Algerian Dinar", code: "DZD", symbol: "دج" },
  { name: "Angolan Kwanza", code: "AOA", symbol: "Kz" },
  { name: "Argentine Peso", code: "ARS", symbol: "$" },
  { name: "Armenian Dram", code: "AMD", symbol: "֏" },
  { name: "Aruban Florin", code: "AWG", symbol: "ƒ" },
  { name: "Australian Dollar", code: "AUD", symbol: "$" },
  { name: "Azerbaijani Manat", code: "AZN", symbol: "₼" },
  { name: "Bahamian Dollar", code: "BSD", symbol: "B$" },
  { name: "Bahraini Dinar", code: "BHD", symbol: ".د.ب" },
  { name: "Bangladeshi Taka", code: "BDT", symbol: "৳" },
  { name: "Barbadian Dollar", code: "BBD", symbol: "Bds$" },
  { name: "Belarusian Ruble", code: "BYN", symbol: "Br" },
  { name: "Belize Dollar", code: "BZD", symbol: "$" },
  { name: "Bermudan Dollar", code: "BMD", symbol: "$" },
  { name: "Bhutanese Ngultrum", code: "BTN", symbol: "Nu." },
  { name: "Bolivian Boliviano", code: "BOB", symbol: "Bs." },
  {
    name: "Bosnia and Herzegovina Convertible Mark",
    code: "BAM",
    symbol: "KM",
  },
  { name: "British Pound Sterling", code: "GBP", symbol: "£" },
  { name: "Botswana Pula", code: "BWP", symbol: "P" },
  { name: "Brazilian Real", code: "BRL", symbol: "R$" },
  { name: "Brunei Dollar", code: "BND", symbol: "B$" },
  { name: "Bulgarian Lev", code: "BGN", symbol: "Лв." },
  { name: "Burundian Franc", code: "BIF", symbol: "FBu" },
  { name: "Canadian Dollar", code: "CAD", symbol: "$" },
  { name: "Cape Verdean Escudo", code: "CVE", symbol: "$" },
  { name: "Chilean Peso", code: "CLP", symbol: "$" },
  { name: "Chilean Unit of Account", code: "CLF", symbol: "CLF" },
  { name: "Chinese Yuan", code: "CNY", symbol: "¥" },
  { name: "Colombian Peso", code: "COP", symbol: "$" },
  { name: "Comorian Franc", code: "KMF", symbol: "CF" },
  { name: "Congolese Franc", code: "CDF", symbol: "FC" },
  { name: "Costa Rican Colón", code: "CRC", symbol: "₡" },
  { name: "Cambodian Riel", code: "KHR", symbol: "៛" },
  // { name: "Croatian Kuna", code: "HRK", symbol: "kn" },
  // { name: "Cuban Convertible Peso", code: "CUC", symbol: "$, CUC" },
  { name: "Czech Republic Koruna", code: "CZK", symbol: "Kč" },
  { name: "Danish Krone", code: "DKK", symbol: "Kr." },
  { name: "Djiboutian Franc", code: "DJF", symbol: "Fdj" },
  { name: "Dominican Peso", code: "DOP", symbol: "RD$" },
  { name: "East Caribbean Dollar", code: "XCD", symbol: "$" },
  { name: "Egyptian Pound", code: "EGP", symbol: "£" },
  { name: "Ethiopian Birr", code: "ETB", symbol: "Nkf" },
  { name: "Euro", code: "EUR", symbol: "€" },
  { name: "Fijian Dollar", code: "FJD", symbol: "FJ$" },
  { name: "Gambian Dalasi", code: "GMD", symbol: "D" },
  { name: "Georgian Lari", code: "GEL", symbol: "ლ" },
  { name: "Ghanaian Cedi", code: "GHS", symbol: "GH¢" },
  { name: "Guatemalan Quetzal", code: "GTQ", symbol: "Q" },
  { name: "Guinean Franc", code: "GNF", symbol: "FG" },
  { name: "Guyanese Dollar", code: "GYD", symbol: "$" },
  { name: "Haitian Gourde", code: "HTG", symbol: "G" },
  { name: "Honduran Lempira", code: "HNL", symbol: "L" },
  { name: "Hong Kong Dollar", code: "HKD", symbol: "$" },
  { name: "Hungarian Forint", code: "HUF", symbol: "Ft" },
  { name: "Icelandic Króna", code: "ISK", symbol: "kr" },
  { name: "Indian Rupee", code: "INR", symbol: "₹" },
  { name: "Indonesian Rupiah", code: "IDR", symbol: "Rp" },
  { name: "Iranian Rial", code: "IRR", symbol: "﷼" },
  { name: "Iraqi Dinar", code: "IQD", symbol: "ع.د" },
  { name: "Israeli New Shekel", code: "ILS", symbol: "₪" },
  { name: "Jamaican Dollar", code: "JMD", symbol: "J$" },
  { name: "Japanese Yen", code: "JPY", symbol: "¥" },
  { name: "Jordanian Dinar", code: "JOD", symbol: "ا.د" },
  { name: "Kazakhstani Tenge", code: "KZT", symbol: "лв" },
  { name: "Kenyan Shilling", code: "KES", symbol: "KSh" },
  { name: "Kuwaiti Dinar", code: "KWD", symbol: "ك.د" },
  { name: "Kyrgystani Som", code: "KGS", symbol: "лв" },
  { name: "Laotian Kip", code: "LAK", symbol: "₭" },
  { name: "Lebanese Pound", code: "LBP", symbol: "£" },
  { name: "Lesotho Loti", code: "LSL", symbol: "L" },
  { name: "Liberian Dollar", code: "LRD", symbol: "$" },
  { name: "Libyan Dinar", code: "LYD", symbol: "ل.د" },
  { name: "Macedonian Denar", code: "MKD", symbol: "ден" },
  { name: "Malagasy Ariary", code: "MGA", symbol: "Ar" },
  { name: "Malawian Kwacha", code: "MWK", symbol: "MK" },
  // { name: "Malaysian Ringgit", code: "MYR", symbol: "RM" },
  { name: "Maldivian Rufiyaa", code: "MVR", symbol: "Rf" },
  { name: "Mauritanian Ouguiya", code: "MRO", symbol: "MRU" },
  { name: "Mauritian Rupee", code: "MUR", symbol: "₨" },
  { name: "Mexican Peso", code: "MXN", symbol: "$" },
  { name: "Moldovan Leu", code: "MDL", symbol: "L" },
  { name: "Moroccan Dirham", code: "MAD", symbol: "د.م." },
  { name: "Mozambican Metical", code: "MZN", symbol: "MT" },
  // { name: "Burmese Kyat", code: "MMK", symbol: "Ks" },
  { name: "Namibian Dollar", code: "NAD", symbol: "$" },
  { name: "Nepalese Rupee", code: "NPR", symbol: "₨" },
  { name: "Netherlands Antillean Guilder", code: "ANG", symbol: "ƒ" },
  { name: "New Zealand Dollar", code: "NZD", symbol: "$" },
  { name: "Nicaraguan Córdoba", code: "NIO", symbol: "C$" },
  { name: "Nigerian Naira", code: "NGN", symbol: "₦" },
  { name: "Norwegian Krone", code: "NOK", symbol: "kr" },
  { name: "New Taiwan Dollar", code: "TWD", symbol: "NT$" },
  { name: "Omani Rial", code: "OMR", symbol: "﷼" },
  { name: "Pakistani Rupee", code: "PKR", symbol: "₨" },
  { name: "Panamanian Balboa", code: "PAB", symbol: "B/." },
  { name: "Papua New Guinean Kina", code: "PGK", symbol: "K" },
  { name: "Paraguayan Guarani", code: "PYG", symbol: "Gs" },
  { name: "Philippine Peso", code: "PHP", symbol: "₱" },
  { name: "Polish Zloty", code: "PLN", symbol: "zł" },
  { name: "Qatari Rial", code: "QAR", symbol: "﷼" },
  { name: "Romanian Leu", code: "RON", symbol: "lei" },
  { name: "Russian Ruble", code: "RUB", symbol: "₽" },
  { name: "Rwandan Franc", code: "RWF", symbol: "FRw" },
  { name: "Saudi Riyal", code: "SAR", symbol: "﷼" },
  { name: "Serbian Dinar", code: "RSD", symbol: "дин" },
  { name: "Seychellois Rupee", code: "SCR", symbol: "SRe" },
  // { name: "Sierra Leonean Leone", code: "SLL", symbol: "Le" },
  { name: "Singapore Dollar", code: "SGD", symbol: "$" },
  { name: "Solomon Islands Dollar", code: "SBD", symbol: "Si$" },
  { name: "Somali Shilling", code: "SOS", symbol: "S" },
  { name: "South African Rand", code: "ZAR", symbol: "R" },
  { name: "South Korean Won", code: "KRW", symbol: "₩" },
  // { name: "Venezuelan Bolívar", code: "VES", symbol: "Bs.S" },
  { name: "Sri Lankan Rupee", code: "LKR", symbol: "Rs" },
  { name: "Sudanese Pound", code: "SDG", symbol: ".س.ج" },
  { name: "Surinamese Dollar", code: "SRD", symbol: "$" },
  { name: "Swazi Lilangeni", code: "SZL", symbol: "E" },
  { name: "Swedish Krona", code: "SEK", symbol: "kr" },
  { name: "Swiss Franc", code: "CHF", symbol: "CHf" },
  { name: "Tajikistani Somoni", code: "TJS", symbol: "SM" },
  { name: "Tanzanian Shilling", code: "TZS", symbol: "TSh" },
  { name: "Thai Baht", code: "THB", symbol: "฿" },
  { name: "Tongan Pa'anga", code: "TOP", symbol: "T$" },
  { name: "Trinidad & Tobago Dollar", code: "TTD", symbol: "$" },
  { name: "Tunisian Dinar", code: "TND", symbol: "ت.د" },
  { name: "Turkish Lira", code: "TRY", symbol: "TL" },
  { name: "Turkmenistani Manat", code: "TMT", symbol: "T" },
  { name: "Ugandan Shilling", code: "UGX", symbol: "USh" },
  { name: "Ukrainian Hryvnia", code: "UAH", symbol: "₴" },
  { name: "United Arab Emirates Dirham", code: "AED", symbol: "إ.د" },
  { name: "US Dollar", code: "USD", symbol: "$" },
  { name: "Uruguayan Peso", code: "UYU", symbol: "$" },
  { name: "Uzbekistani Som", code: "UZS", symbol: "лв" },
  { name: "Vietnamese Dong", code: "VND", symbol: "₫" },
  { name: "West African CFA Franc", code: "XOF", symbol: "CFA" },
  { name: "Yemeni Rial", code: "YER", symbol: "﷼" },
  { name: "Zambian Kwacha", code: "ZMW", symbol: "ZK" },
];
