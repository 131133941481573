import React from "react";

import TextStyles from "../../../../../../../Constants/TextStyles";
import Styled from "../AddSectionStyles";

const Column = ({ data }) => {
  return (
    <Styled.ReviewBox className="mt-4">
      <TextStyles.FontSize14px className="mb-3">
        <div className="mb-2">Column title</div>
        <div className="d-flex gap-1 justify-content-between align-items-center">
          <div
            className="d-flex justify-content-start w-100"
            style={{ minWidth: 100 }}
          >
            <Styled.TextBoader
              className="d-flex w-100"
              style={{ minWidth: 100 }}
            >
              <span className="three-dots">{data.column_title}</span>
            </Styled.TextBoader>
          </div>
        </div>
      </TextStyles.FontSize14px>
      <div>
        <TextStyles.FontSize14px className="d-flex gap-1 gap-sm-2 justify-content-start align-items-center mb-2">
          <div className="d-flex justify-content-start w-100">
            <Styled.TextBoader>{data.input.input_one}</Styled.TextBoader>
          </div>
          <div className="d-flex justify-content-start w-100">
            <Styled.TextBoader>{data.input.input_two}</Styled.TextBoader>
          </div>
          <div className="d-flex justify-content-start w-100">
            <Styled.TextBoader>{data.input.input_three}</Styled.TextBoader>
          </div>
        </TextStyles.FontSize14px>
      </div>
    </Styled.ReviewBox>
  );
};

export default Column;
