import React from "react";
import { TimePicker, Space } from "antd";
import dayjs from "dayjs";
import "./CustomTimePickerTwo.css";

const CustomTimePickerTwo = ({
  status = null,
  placeholder = "Select Time",
  timeFormat = "hh:mm A",
  selected = null,
  onChange,
  allowClear = false,
  disabled = false,
  ...rest
}) => {
  const handleTimeChange = (time) => {
    if (onChange) {
      onChange(time ? new Date(time) : null);
    }
  };

  return (
    <Space
      direction="vertical"
      style={{
        width: "100%",
      }}
    >
      <TimePicker
        status={status}
        value={selected ? dayjs(selected) : null}
        onChange={handleTimeChange}
        format={timeFormat}
        placeholder={placeholder}
        allowClear={allowClear}
        disabled={disabled}
        suffixIcon={null}
        {...rest}
        className="custum-time-picker-two"
        popupClassName="custum-time-picker-two-popup"
      />
    </Space>
  );
};

export default CustomTimePickerTwo;
