import styled from "styled-components";
import Colors from "../../../../Constants/Colors";

const TableHead = styled.div`
  border-radius: 0.75rem 0.75rem 0 0;
  padding: 1rem;
  background-color: ${Colors.tableHeadColor};
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
`;

const TableCell = styled.div`
  width: 100%;
  padding: 1rem;
  cursor: pointer;
  margin: 0 0 2px 0;
  background-color: ${Colors.white};
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
  &:hover {
    background: ${Colors.hoverTableRow};
  }
`;

export default {
  TableHead,
  TableCell,
};
