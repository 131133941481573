import React, { useState, useRef, useEffect } from "react";
import { DatePicker } from "antd";
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import dayjs from "dayjs";
import "./CustomDatePickerTwo.css";
import Colors from "../../../../Constants/Colors";
import TextStyles from "../../../../Constants/TextStyles";
import CancelIcon from "@mui/icons-material/Cancel";

const CustomDatePickerTwo = ({
  title = "select Date",
  placeholder = "Select Date",
  dateFormat = "DD MMM YYYY",
  selected = null,
  onChange,
  invalid = false,
  minDate = null,
  maxDate = null,
  allowClear = false,
  ...rest
}) => {
  const [open, setOpen] = useState(false); // State to control date picker visibility
  const datePickerRef = useRef(null); // Ref to track date picker div

  // Close the date picker on outside click
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target)
      ) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Function to handle date change
  const handleDateChange = (date) => {
    setOpen(false); // Close date picker after selection
    if (onChange) {
      onChange(new Date(date)); // Trigger the callback if provided
    }
  };

  // Toggle date picker visibility
  const toggleDatePicker = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const clearDate = (event) => {
    event.stopPropagation();
    if (onChange) {
      onChange(null); // Trigger the callback if provided
    }
  };

  return (
    <div style={{ position: "relative" }} ref={datePickerRef}>
      {/* Custom Button */}
      <div className="custom-date-picker-two-btn" onClick={toggleDatePicker}>
        <div className="d-flex flex-column three-dots w-100">
          <TextStyles.FontSize14px
            className="text-start three-dots w-100"
            style={{ fontWeight: 500 }}
          >
            {title}
          </TextStyles.FontSize14px>
          <TextStyles.GreyFont14px className="text-start mt-1 three-dots w-100">
            {selected ? dayjs(selected).format(dateFormat) : placeholder}
          </TextStyles.GreyFont14px>
        </div>
        <div className="text-end three-dots w-100" style={{ maxWidth: 60 }}>
          {allowClear && selected && (
            <CancelIcon
              className="clear-icon"
              onClick={clearDate}
              style={{ color: Colors.primary, fontSize: 20 }}
            />
          )}
          <DateRangeOutlinedIcon
            style={{ color: Colors.primary, fontSize: 27 }}
          />
        </div>
      </div>

      {open && (
        <div
          style={{ position: "absolute", top: "100%", left: 0, zIndex: 1000 }}
        >
          <DatePicker
            // defaultValue={selected ? dayjs(selected) : null}
            value={selected ? dayjs(selected) : null}
            onChange={handleDateChange}
            open={open}
            style={{ visibility: "hidden", position: "absolute" }}
            getPopupContainer={() => datePickerRef.current}
            minDate={minDate && dayjs(minDate)}
            maxDate={maxDate && dayjs(maxDate)}
            {...rest}
            popupClassName="custum-date-picker-two-popup"
          />
        </div>
      )}
    </div>
  );
};

export default CustomDatePickerTwo;
