import React from "react";
import { Modal } from "react-bootstrap";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import { useForm, useFieldArray } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";

import LoaderSpin from "../../../../Commons/LoaderSpin";

import ClearIcon from "@mui/icons-material/Clear";

import AddCircleOutlineTwoToneIcon from "@mui/icons-material/AddCircleOutlineTwoTone";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";

import TextStyles from "../../../../../../Constants/TextStyles";
import Styled from "../AddSectionStyles";

import { createAddSectionTablePC } from "../../../../../../store/service/proposalAndContractService";
import Colors from "../../../../../../Constants/Colors";

const AddSectionTableModal = ({
  show,
  handleClose,
  selectedData,
  position,
}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.proposalAndContract.isLoading);

  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    defaultValues: {
      tableLists: [{ input_one: "", input_two: "" }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "tableLists",
  });

  const formsubmit = (data) => {
    const Obj = {
      pc_id: selectedData._id,
      table_title: data.table_title,
      input_array: data.tableLists,
      position: position,
    };
    dispatch(createAddSectionTablePC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleClose();
        reset();
        message.success(`${data.payload.message}`);
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="ToMakeModalWidth600px"
      contentClassName="ToMakeModalBorderRudius12px"
      scrollable={true}
      centered={true}
    >
      <form
        onSubmit={handleSubmit(formsubmit)}
        style={{ height: "100%" }}
        className="modal-content"
      >
        <Modal.Header
          className="d-flex justify-content-between align-items-center"
          style={{ borderBottom: "0px" }}
        >
          <TextStyles.FontSize20px>Add table</TextStyles.FontSize20px>
          <ClearIcon
            sx={{
              color: Colors.font2,
              cursor: "pointer",
              opacity: 0.7,
              "&:hover": {
                opacity: 1,
              },
            }}
            onClick={handleClose}
          />
        </Modal.Header>

        <Modal.Body>
          <TextStyles.FontSize14px className="mb-2">
            <label htmlFor="image_title" className="mb-2">
              Table title
            </label>

            <div
              className="d-flex justify-content-start align-items-center"
              style={{ maxWidth: "calc(100% - 30px)" }}
            >
              <TextStyles.InputRectangle
                id="table_title"
                invalid={errors.table_title}
                type="text"
                placeholder="Section title (e.g, Overview and goals )"
                name="table_title"
                {...register("table_title", {
                  required: "Title is required.",
                  validate: (value) => {
                    let trimedLength = value.trim().length;
                    if (trimedLength === 0) {
                      return "Title is required.";
                    }
                    return true;
                  },
                })}
              />
            </div>
            {errors.table_title && (
              <TextStyles.InValidFeedback>
                {errors.table_title.message}
              </TextStyles.InValidFeedback>
            )}
          </TextStyles.FontSize14px>

          {fields &&
            fields.map((item, index) => {
              return (
                <Styled.EditContainer key={item.id}>
                  <TextStyles.FontSize14px className="d-flex justify-content-start align-items-center mb-3">
                    <div
                      className="d-flex gap-1 gap-sm-2 gap-md-3 justify-content-between w-100"
                      style={{ maxWidth: "calc(100% - 30px)" }}
                    >
                      <div className="d-flex flex-column justify-content-start w-100">
                        <TextareaAutosize
                          style={{
                            overflow: "hidden",
                            width: "100%",
                            padding: "1rem 0.6rem",
                            border: `1px solid ${Colors.font2}`,
                            background: Colors.white,
                            borderRadius: 12,
                          }}
                          minRows={7}
                          id="input_one"
                          placeholder="Type here..."
                          name="input_one"
                          {...register(`tableLists.${index}.input_one`, {
                            required: "This field is required.",
                            validate: (value) => {
                              let trimedLength = value.trim().length;
                              if (trimedLength === 0) {
                                return "This field is required.";
                              }
                              return true;
                            },
                          })}
                        />
                        {errors.tableLists &&
                          errors.tableLists[index] &&
                          errors.tableLists[index].input_one && (
                            <TextStyles.InValidFeedback className="mt-2">
                              {errors.tableLists &&
                                errors.tableLists[index] &&
                                errors.tableLists[index].input_one &&
                                errors.tableLists[index].input_one.message}
                            </TextStyles.InValidFeedback>
                          )}
                      </div>
                      <div className="d-flex flex-column justify-content-start w-100">
                        <TextareaAutosize
                          style={{
                            overflow: "hidden",
                            width: "100%",
                            padding: "1rem 0.6rem",
                            border: `1px solid ${Colors.font2}`,
                            background: Colors.white,
                            borderRadius: 12,
                          }}
                          minRows={7}
                          id="input_two"
                          placeholder="Type here..."
                          name="input_two"
                          {...register(`tableLists.${index}.input_two`, {
                            required: "This field is required.",
                            validate: (value) => {
                              let trimedLength = value.trim().length;
                              if (trimedLength === 0) {
                                return "This field is required.";
                              }
                              return true;
                            },
                          })}
                        />
                        {errors.tableLists &&
                          errors.tableLists[index] &&
                          errors.tableLists[index].input_two && (
                            <TextStyles.InValidFeedback className="mt-2">
                              {errors.tableLists &&
                                errors.tableLists[index] &&
                                errors.tableLists[index].input_two &&
                                errors.tableLists[index].input_two.message}
                            </TextStyles.InValidFeedback>
                          )}
                      </div>
                    </div>
                    <div
                      className="d-flex align-items-center justify-content-center w-100"
                      style={{ maxWidth: 30 }}
                    >
                      {fields.length !== 1 && (
                        <DeleteForeverRoundedIcon
                          className="edit_imgBtn"
                          onClick={() => remove(index)}
                          sx={{
                            fontSize: 24,
                            color: Colors.font2,
                            cursor: "pointer",
                          }}
                        />
                      )}
                    </div>
                  </TextStyles.FontSize14px>
                </Styled.EditContainer>
              );
            })}
          <TextStyles.FontSize14px className="mt-2">
            <div
              className="d-flex justify-content-end w-100"
              style={{ maxWidth: "calc(100% - 30px)" }}
            >
              <div
                onClick={() => append({ input_one: "", input_two: "" })}
                style={{ cursor: "pointer" }}
              >
                <AddCircleOutlineTwoToneIcon
                  sx={{ color: Colors.font1, fontSize: 22 }}
                />
                <span className="me-1 ms-2">Add another row</span>
              </div>
            </div>
          </TextStyles.FontSize14px>
        </Modal.Body>

        <Modal.Footer style={{ display: "inline-block" }}>
          <div className="d-flex gap-3 justify-content-around align-items-center">
            <TextStyles.CancelButton onClick={handleClose}>
              Cancel
            </TextStyles.CancelButton>
            <TextStyles.Button disabled={isLoading}>
              {isLoading ? <LoaderSpin /> : "Save"}
            </TextStyles.Button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AddSectionTableModal;
