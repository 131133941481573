import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Colors from "../../../Constants/Colors";
import sizes from "../../../Constants/sizes";

const useStyles = makeStyles({
  root: {
    border: `1px solid  ${Colors.borderInput}`,
    backgroundColor: `${Colors.white} !important`,
    borderRadius: sizes.formInputBorderRadius,
    height: sizes.formInputHeight,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "& .MuiOutlinedInput-root": {
      padding: "0 !important",
    },

    "& .MuiAutocomplete-input": {
      fontFamily: "Poppins",
      padding: "0 2rem 0 1rem !important",
      fontSize: "0.875rem",
      color: Colors.font1,
    },

    "&:hover": {
      border: `1px solid ${Colors.primary} !important`,
    },

    "&.Mui-focused": {
      border: `1px solid ${Colors.primary} !important`,
    },
  },
  rootError: {
    border: `1px solid ${Colors.red}`,
    background: Colors.inputErrorBg,
    borderRadius: sizes.formInputBorderRadius,
    "& .MuiAutocomplete-input": {
      fontFamily: "Poppins",
      fontSize: "0.875rem",
      color: Colors.font1,
    },
  },
  option: {
    fontFamily: "Poppins",
    fontSize: "0.875rem",
    color: Colors.font1,
    marginBottom: "2px !important",
    "&:hover": {
      backgroundColor: `${Colors.backgroundPink} !important`,
    },
    '&[aria-selected="true"]': {
      backgroundColor: `${Colors.backgroundPink} !important`,
    },
  },

  input: {
    "& input::placeholder": {
      fontFamily: "Poppins",
      padding: "none",
      fontSize: "0.875rem",
      color: Colors.font1,
    },
  },
});

export default function SelectSTDCode(props) {
  var { placeholder, onChange, label, invalid, disableClearable } = props;
  const classes = useStyles();

  return (
    <Autocomplete
      classes={{
        option: classes.option,
      }}
      className={invalid ? classes.rootError : classes.root}
      id="country-select-demo"
      sx={{
        width: props.width,
        "& .MuiAutocomplete-clearIndicator": {
          display: "none",
        },
      }}
      options={countries}
      value={props.defaultValue ? props.defaultValue : null}
      onChange={(event, value) => {
        onChange(value);
      }}
      disableClearable={disableClearable ? disableClearable : false}
      getOptionLabel={(option) =>
        `${option.country}(${option.code}) +${option.country_code}`
      }
      isOptionEqualToValue={(option, value) => option.code === value.code}
      autoHighlight
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            alt=""
          />
          <span className="three-dots">
            {option.country} ({option.code}) +{option.country_code}
          </span>
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          sx={{
            "& .MuiOutlinedInput-notchedOutline": { border: "none" },
          }}
          {...params}
          label={label}
          classes={{ root: classes.input }}
          placeholder={placeholder}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password",
          }}
        />
      )}
    />
  );
}

const countries = [
  { code: "AF", country: "Afghanistan", country_code: "93" },
  { code: "AL", country: "Albania", country_code: "355" },
  { code: "DZ", country: "Algeria", country_code: "213" },
  { code: "AD", country: "Andorra", country_code: "376" },
  { code: "AO", country: "Angola", country_code: "244" },
  { code: "AG", country: "Antigua and Barbuda", country_code: "1-268" },
  { code: "AR", country: "Argentina", country_code: "54" },
  { code: "AM", country: "Armenia", country_code: "374" },
  { code: "AU", country: "Australia", country_code: "61" },
  { code: "AT", country: "Austria", country_code: "43" },
  { code: "AZ", country: "Azerbaijan", country_code: "994" },
  { code: "BS", country: "Bahamas", country_code: "1-242" },
  { code: "BH", country: "Bahrain", country_code: "973" },
  { code: "BD", country: "Bangladesh", country_code: "880" },
  { code: "BB", country: "Barbados", country_code: "1-246" },
  { code: "BY", country: "Belarus", country_code: "375" },
  { code: "BE", country: "Belgium", country_code: "32" },
  { code: "BZ", country: "Belize", country_code: "501" },
  { code: "BJ", country: "Benin", country_code: "229" },
  { code: "BT", country: "Bhutan", country_code: "975" },
  { code: "BO", country: "Bolivia", country_code: "591" },
  { code: "BA", country: "Bosnia and Herzegovina", country_code: "387" },
  { code: "BW", country: "Botswana", country_code: "267" },
  { code: "BR", country: "Brazil", country_code: "55" },
  { code: "BN", country: "Brunei Darussalam", country_code: "673" },
  { code: "BG", country: "Bulgaria", country_code: "359" },
  { code: "BF", country: "Burkina Faso", country_code: "226" },
  { code: "BI", country: "Burundi", country_code: "257" },
  { code: "KH", country: "Cambodia", country_code: "855" },
  { code: "CM", country: "Cameroon", country_code: "237" },
  { code: "CA", country: "Canada", country_code: "1" },
  { code: "CV", country: "Cape Verde", country_code: "238" },
  { code: "CF", country: "Central African Republic", country_code: "236" },
  { code: "TD", country: "Chad", country_code: "235" },
  { code: "CL", country: "Chile", country_code: "56" },
  { code: "CN", country: "China", country_code: "86" },
  { code: "CO", country: "Colombia", country_code: "57" },
  { code: "KM", country: "Comoros", country_code: "269" },
  { code: "CG", country: "Congo", country_code: "242" },
  { code: "CD", country: "Congo, Democratic Republic", country_code: "243" },
  { code: "CR", country: "Costa Rica", country_code: "506" },
  { code: "HR", country: "Croatia", country_code: "385" },
  { code: "CU", country: "Cuba", country_code: "53" },
  { code: "CY", country: "Cyprus", country_code: "357" },
  { code: "CZ", country: "Czech Republic", country_code: "420" },
  { code: "DK", country: "Denmark", country_code: "45" },
  { code: "DJ", country: "Djibouti", country_code: "253" },
  { code: "DM", country: "Dominica", country_code: "1-767" },
  { code: "DO", country: "Dominican Republic", country_code: "1-809" },
  { code: "TL", country: "East Timor", country_code: "670" },
  { code: "EC", country: "Ecuador", country_code: "593" },
  { code: "EG", country: "Egypt", country_code: "20" },
  { code: "SV", country: "El Salvador", country_code: "503" },
  { code: "GQ", country: "Equatorial Guinea", country_code: "240" },
  { code: "ER", country: "Eritrea", country_code: "291" },
  { code: "EE", country: "Estonia", country_code: "372" },
  { code: "SZ", country: "Eswatini", country_code: "268" },
  { code: "ET", country: "Ethiopia", country_code: "251" },
  { code: "FJ", country: "Fiji", country_code: "679" },
  { code: "FI", country: "Finland", country_code: "358" },
  { code: "FR", country: "France", country_code: "33" },
  { code: "GA", country: "Gabon", country_code: "241" },
  { code: "GM", country: "Gambia", country_code: "220" },
  { code: "GE", country: "Georgia", country_code: "995" },
  { code: "DE", country: "Germany", country_code: "49" },
  { code: "GH", country: "Ghana", country_code: "233" },
  { code: "GR", country: "Greece", country_code: "30" },
  { code: "GD", country: "Grenada", country_code: "1-473" },
  { code: "GT", country: "Guatemala", country_code: "502" },
  { code: "GN", country: "Guinea", country_code: "224" },
  { code: "GW", country: "Guinea-Bissau", country_code: "245" },
  { code: "GY", country: "Guyana", country_code: "592" },
  { code: "HT", country: "Haiti", country_code: "509" },
  { code: "HN", country: "Honduras", country_code: "504" },
  { code: "HK", country: "Hong Kong", country_code: "852" },
  { code: "HU", country: "Hungary", country_code: "36" },
  { code: "IS", country: "Iceland", country_code: "354" },
  { code: "IN", country: "India", country_code: "91" },
  { code: "ID", country: "Indonesia", country_code: "62" },
  { code: "IR", country: "Iran, Islamic Republic of", country_code: "98" },
  { code: "IQ", country: "Iraq", country_code: "964" },
  { code: "IE", country: "Ireland", country_code: "353" },
  { code: "IL", country: "Israel", country_code: "972" },
  { code: "IT", country: "Italy", country_code: "39" },
  { code: "JM", country: "Jamaica", country_code: "1-876" },
  { code: "JP", country: "Japan", country_code: "81" },
  { code: "JO", country: "Jordan", country_code: "962" },
  { code: "KZ", country: "Kazakhstan", country_code: "7" },
  { code: "KE", country: "Kenya", country_code: "254" },
  { code: "KI", country: "Kiribati", country_code: "686" },
  { code: "KR", country: "Korea", country_code: "82" },
  { code: "XK", country: "Kosovo", country_code: "383" },
  { code: "KW", country: "Kuwait", country_code: "965" },
  { code: "KG", country: "Kyrgyzstan", country_code: "996" },
  {
    code: "LA",
    country: "Lao People's Democratic Republic",
    country_code: "856",
  },
  { code: "LV", country: "Latvia", country_code: "371" },
  { code: "LB", country: "Lebanon", country_code: "961" },
  { code: "LS", country: "Lesotho", country_code: "266" },
  { code: "LR", country: "Liberia", country_code: "231" },
  { code: "LY", country: "Libya", country_code: "218" },
  { code: "LI", country: "Liechtenstein", country_code: "423" },
  { code: "LT", country: "Lithuania", country_code: "370" },
  { code: "LU", country: "Luxembourg", country_code: "352" },
  { code: "MG", country: "Madagascar", country_code: "261" },
  { code: "MW", country: "Malawi", country_code: "265" },
  { code: "MY", country: "Malaysia", country_code: "60" },
  { code: "MV", country: "Maldives", country_code: "960" },
  { code: "ML", country: "Mali", country_code: "223" },
  { code: "MT", country: "Malta", country_code: "356" },
  { code: "MH", country: "Marshall Islands", country_code: "692" },
  { code: "MR", country: "Mauritania", country_code: "222" },
  { code: "MU", country: "Mauritius", country_code: "230" },
  { code: "MX", country: "Mexico", country_code: "52" },
  {
    code: "FM",
    country: "Micronesia, Federated States of",
    country_code: "691",
  },
  { code: "MD", country: "Moldova", country_code: "373" },
  { code: "MC", country: "Monaco", country_code: "377" },
  { code: "MN", country: "Mongolia", country_code: "976" },
  { code: "ME", country: "Montenegro", country_code: "382" },
  { code: "MA", country: "Morocco", country_code: "212" },
  { code: "MZ", country: "Mozambique", country_code: "258" },
  { code: "MM", country: "Myanmar", country_code: "95" },
  { code: "NA", country: "Namibia", country_code: "264" },
  { code: "NR", country: "Nauru", country_code: "674" },
  { code: "NP", country: "Nepal", country_code: "977" },
  { code: "NL", country: "Netherlands", country_code: "31" },
  { code: "NZ", country: "New Zealand", country_code: "64" },
  { code: "NI", country: "Nicaragua", country_code: "505" },
  { code: "NE", country: "Niger", country_code: "227" },
  { code: "NG", country: "Nigeria", country_code: "234" },
  { code: "NO", country: "Norway", country_code: "47" },
  { code: "OM", country: "Oman", country_code: "968" },
  { code: "PK", country: "Pakistan", country_code: "92" },
  { code: "PW", country: "Palau", country_code: "680" },
  { code: "PS", country: "Palestine", country_code: "970" },
  { code: "PA", country: "Panama", country_code: "507" },
  { code: "PG", country: "Papua New Guinea", country_code: "675" },
  { code: "PY", country: "Paraguay", country_code: "595" },
  { code: "PE", country: "Peru", country_code: "51" },
  { code: "PH", country: "Philippines", country_code: "63" },
  { code: "PL", country: "Poland", country_code: "48" },
  { code: "PT", country: "Portugal", country_code: "351" },
  { code: "QA", country: "Qatar", country_code: "974" },
  { code: "RO", country: "Romania", country_code: "40" },
  { code: "RU", country: "Russian Federation", country_code: "7" },
  { code: "RW", country: "Rwanda", country_code: "250" },
  { code: "KN", country: "Saint Kitts and Nevis", country_code: "1-869" },
  { code: "LC", country: "Saint Lucia", country_code: "1-758" },
  {
    code: "VC",
    country: "Saint Vincent and the Grenadines",
    country_code: "1-784",
  },
  { code: "WS", country: "Samoa", country_code: "685" },
  { code: "SM", country: "San Marino", country_code: "378" },
  { code: "ST", country: "Sao Tome and Principe", country_code: "239" },
  { code: "SA", country: "Saudi Arabia", country_code: "966" },
  { code: "SN", country: "Senegal", country_code: "221" },
  { code: "RS", country: "Serbia", country_code: "381" },
  { code: "SC", country: "Seychelles", country_code: "248" },
  { code: "SL", country: "Sierra Leone", country_code: "232" },
  { code: "SG", country: "Singapore", country_code: "65" },
  { code: "SK", country: "Slovakia", country_code: "421" },
  { code: "SI", country: "Slovenia", country_code: "386" },
  { code: "SB", country: "Solomon Islands", country_code: "677" },
  { code: "SO", country: "Somalia", country_code: "252" },
  { code: "ZA", country: "South Africa", country_code: "27" },
  { code: "SS", country: "South Sudan", country_code: "211" },
  { code: "ES", country: "Spain", country_code: "34" },
  { code: "LK", country: "Sri Lanka", country_code: "94" },
  { code: "SD", country: "Sudan", country_code: "249" },
  { code: "SR", country: "Suriname", country_code: "597" },
  { code: "SE", country: "Sweden", country_code: "46" },
  { code: "CH", country: "Switzerland", country_code: "41" },
  { code: "SY", country: "Syrian Arab Republic", country_code: "963" },
  { code: "TW", country: "Taiwan", country_code: "886" },
  { code: "TJ", country: "Tajikistan", country_code: "992" },
  { code: "TZ", country: "Tanzania", country_code: "255" },
  { code: "TH", country: "Thailand", country_code: "66" },
  { code: "TG", country: "Togo", country_code: "228" },
  { code: "TO", country: "Tonga", country_code: "676" },
  { code: "TT", country: "Trinidad and Tobago", country_code: "1-868" },
  { code: "TN", country: "Tunisia", country_code: "216" },
  { code: "TR", country: "Turkey", country_code: "90" },
  { code: "TM", country: "Turkmenistan", country_code: "993" },
  { code: "TV", country: "Tuvalu", country_code: "688" },
  { code: "UG", country: "Uganda", country_code: "256" },
  { code: "UA", country: "Ukraine", country_code: "380" },
  { code: "AE", country: "United Arab Emirates", country_code: "971" },
  { code: "GB", country: "United Kingdom", country_code: "44" },
  { code: "US", country: "United States", country_code: "1" },
  { code: "UY", country: "Uruguay", country_code: "598" },
  { code: "UZ", country: "Uzbekistan", country_code: "998" },
  { code: "VU", country: "Vanuatu", country_code: "678" },
  { code: "VA", country: "Vatican City", country_code: "379" },
  { code: "VE", country: "Venezuela", country_code: "58" },
  { code: "VN", country: "Vietnam", country_code: "84" },
  { code: "YE", country: "Yemen", country_code: "967" },
  { code: "ZM", country: "Zambia", country_code: "260" },
  { code: "ZW", country: "Zimbabwe", country_code: "263" },
];
