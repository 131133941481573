import { createSlice } from "@reduxjs/toolkit";
import {
  fetchNotifications,
  ViewedNotifications,
} from "../service/notificationService";

const initialNotificationState = {
  notifications: null,
  totalCount: null,
  page: 1,
  isNotViewed: null,
  isLoading: false,
  error: null,
};

export const notificationSlice = createSlice({
  name: "reminder",
  initialState: initialNotificationState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // fetch notifications
      .addCase(fetchNotifications.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.data.page > 1) {
          state.notifications.push(...action.payload.data.data);
        } else {
          state.notifications = action.payload.data.data;
        }
        state.totalCount = action.payload.data.totalCount;
        state.isNotViewed = action.payload.data.isNotViewed;
        state.page = action.payload.data.page;
      })
      .addCase(fetchNotifications.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // viewed notifications
      .addCase(ViewedNotifications.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(ViewedNotifications.fulfilled, (state, action) => {
        state.isLoading = false;
        state.notifications = action.payload.data;
        state.isNotViewed = null;
      })
      .addCase(ViewedNotifications.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});
export const notificationAction = notificationSlice.actions;
export default notificationSlice.reducer;
