import React from "react";
import { Modal } from "react-bootstrap";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import ClearIcon from "@mui/icons-material/Clear";

import Colors from "../../../../../Constants/Colors";
import Styled from "../../createProject/SetupStyles";
import TextStyles from "../../../../../Constants/TextStyles";
import SelectServiceType from "../../../Commons/SelectServiceType";
import {
  fetchProjectForOptions,
  updateProjectServices,
} from "../../../../../store/service/projectService";
import LoaderSpin from "../../../Commons/LoaderSpin";

export default function EditServiceModal({ show, selectedProject, onClose }) {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.project);

  const {
    register,
    formState: { errors },
    control,
    handleSubmit,
  } = useForm({
    defaultValues: {
      servicesList: [{}],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "servicesList",
  });

  const currencySymbol = selectedProject.currency.symbol;

  const submitHandler = (data) => {
    const projectData = {
      project_id: selectedProject._id,
      service_list: (data.servicesList && data.servicesList) || [],
    };

    dispatch(updateProjectServices(projectData)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        dispatch(fetchProjectForOptions());
        onClose();

        message.success(res.payload.message);
      } else {
        message.error(`${res.error.message}!`);
      }
    });
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      fullscreen
      dialogClassName="ToMakeModalOnRight500px"
    >
      <form onSubmit={handleSubmit(submitHandler)} className="modal-content">
        <Modal.Header className="d-flex justify-content-between">
          <TextStyles.FontSize20px>Add Services</TextStyles.FontSize20px>
          <ClearIcon
            sx={{
              color: Colors.font2,
              cursor: "pointer",
              opacity: 0.7,
              "&:hover": {
                opacity: 1,
              },
            }}
            onClick={onClose}
          />
        </Modal.Header>

        <Modal.Body>
          <Styled.SetupBox
            style={{ border: "none", borderRadius: 0, padding: 0 }}
            modal-dialog
          >
            {fields &&
              fields.map((item, index) => {
                return (
                  <div key={index} className="services_container">
                    <TextStyles.FontSize14px className="mb-3 ">
                      <label htmlFor="service_name" className="mb-2">
                        Service name
                      </label>
                      <div className="d-flex flex-row align-items-center">
                        <div>
                          <TextStyles.InputRectangle
                            style={{ width: "100%" }}
                            id="service_name"
                            type="text"
                            placeholder="Service name"
                            name="service_name"
                            {...register(`servicesList.${index}.service_name`, {
                              required: "Service name is required.",
                            })}
                          />

                          {errors &&
                            errors.servicesList &&
                            errors.servicesList[index] &&
                            errors.servicesList[index].service_name && (
                              <TextStyles.InValidFeedback>
                                {
                                  errors.servicesList[index].service_name
                                    .message
                                }
                              </TextStyles.InValidFeedback>
                            )}
                        </div>
                        <span className="ms-4">
                          {fields.length !== 1 && (
                            <DeleteForeverRoundedIcon
                              sx={{ color: Colors.font2 }}
                              onClick={() => remove(index)}
                              className="deleteBtn"
                            />
                          )}
                        </span>
                      </div>
                    </TextStyles.FontSize14px>

                    <TextStyles.FontSize14px className="mb-3">
                      <label htmlFor="service_rate" className="mb-2">
                        Rate
                      </label>
                      <div className="d-flex flex-wrap align-items-center gap-3 mb-1">
                        <div>
                          <Styled.inputBox className="d-flex align-items-center justify-content-center">
                            <span>{currencySymbol}</span>
                            <input
                              style={{ width: 80 }}
                              type="number"
                              id="service_rate"
                              min={"0"}
                              name="service_rate"
                              placeholder="00.00"
                              {...register(
                                `servicesList.${index}.service_rate`,
                                {
                                  required: "Rate is required.",
                                }
                              )}
                            />
                          </Styled.inputBox>
                        </div>
                        <div>
                          <Controller
                            defaultValue={"Flat fee"}
                            control={control}
                            name={`servicesList.${index}.service_rate_type`}
                            render={({ field: { onChange, value } }) => (
                              <SelectServiceType
                                placeholder="Select"
                                onChange={onChange}
                                defaultValue={value}
                              />
                            )}
                          />
                        </div>
                      </div>

                      {errors &&
                        errors.servicesList &&
                        errors.servicesList[index] &&
                        errors.servicesList[index].service_rate && (
                          <TextStyles.InValidFeedback>
                            {errors.servicesList[index].service_rate.message}
                          </TextStyles.InValidFeedback>
                        )}
                    </TextStyles.FontSize14px>

                    <TextStyles.FontSize14px className="mb-3">
                      <label htmlFor="description" className="mb-2">
                        Description
                      </label>
                      <TextStyles.Textarea
                        rows={4}
                        id="description"
                        name="desc"
                        placeholder="Add a note..."
                        {...register(`servicesList.${index}.desc`)}
                      />
                    </TextStyles.FontSize14px>

                    <div
                      className="mb-3"
                      style={{
                        width: "100%",
                        height: "1px",
                        border: "0.5px solid #edeef2",
                      }}
                    />
                  </div>
                );
              })}

            <TextStyles.FontSize14px
              className="d-flex justify-content-end"
              style={{ cursor: "pointer" }}
              onClick={() =>
                append({
                  service_name: "",
                  service_rate: "",
                  service_rate_type: "Flat fee",
                  desc: "",
                })
              }
            >
              <AddCircleOutlineOutlinedIcon sx={{ color: Colors.font1 }} />
              <span className="ms-2">Add another service</span>
            </TextStyles.FontSize14px>
          </Styled.SetupBox>
        </Modal.Body>

        <Modal.Footer style={{ display: "inline-block" }}>
          <div className="d-flex gap-3 justify-content-between">
            <TextStyles.CancelButton onClick={onClose} disabled={isLoading}>
              Cancel
            </TextStyles.CancelButton>
            <TextStyles.Button disabled={isLoading}>
              {isLoading ? <LoaderSpin /> : "Save"}
            </TextStyles.Button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
