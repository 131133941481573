import styled from "styled-components";
import Colors from "../../../../../Constants/Colors";

const DashBoardContainer = styled.div`
  width: 23.75rem;
  height: 12.5rem;
  content: contain;
  margin: 1.25rem 0;
  padding: 1.25rem;
  border-radius: 0.75rem;
  border: 2px solid ${Colors.font2};
  background-color: ${Colors.white};
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  &:hover {
    border: 3px solid ${Colors.primary};
  }
`;

const SubmitButton = styled.button`
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  padding: 1rem 1.25rem;
  font-family: Poppins, serif;
  font-size: 0.875rem;
  color: ${(props) => props.color || Colors.white};
  border-radius: 0.75rem;
  background-color: ${(props) => props.backgroundColor || Colors.primary};
  transition: background-color 0.4s ease;

  &:hover {
    background-color: ${Colors.hoverButtonColor};
  }
  &:disabled {
    opacity: 0.5 !important;
  }
`;

export default {
  DashBoardContainer,
  SubmitButton,
};
