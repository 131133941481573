import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import ClearIcon from "@mui/icons-material/Clear";
import { BackButton } from "../../../../Commons/BackAndForwardButton";

import Styled from "./CreateInvoiceStyles";
import TextStyles from "../../../../../../Constants/TextStyles";
import Colors from "../../../../../../Constants/Colors";
import { useLocation } from "react-router-dom";
import ProtectedRoute from "../../../../../auth/ProtectedRoute";
import InvoiceSetup from "../../../../invoices/CreateInvoice/InvoiceSetup/InvoiceSetup";
import { invoiceActions } from "../../../../../../store/storage/invoiceSlice";

import ReviewInvoice from "../../../../invoices/ReviewInvoice/ReviewInvoice";
import SendInvoice from "../../../../invoices/SendInvoice/SendInvoice";
import DiscardChangesModal from "../../../../invoices/InvoiceModals/DiscardChangesModal";
import SaveChangesModal from "../../../../invoices/InvoiceModals/SaveChangesModal";
import { saveAsDraftInvoice } from "../../../../../../store/service/invoiceService";
import { message } from "antd";

const CreateInvoice = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const pathname = location && location.pathname.split("/")[6];

  const selectedInvoiceData = useSelector(
    (state) => state.invoices.selectedInvoiceData
  );

  const selectedProject = useSelector((state) => state.project.selectedProject);

  // modals
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [showDiscardModal, setShowDiscardModal] = useState(false);
  const [showSaveChangesModal, setShowSaveChangesModal] = useState(false);

  const discardChangesHandler = () => {
    dispatch(invoiceActions.selectedInvoiceResetAction());
    history.push(`/db/projects/${selectedProject._id}/invoices`);
  };

  const saveChangesHandler = () => {
    const Obj = {
      ...selectedInvoiceData,
      status: "Draft",
    };
    dispatch(saveAsDraftInvoice(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        history.push(`/db/projects/${selectedProject._id}/invoices`);
        dispatch(invoiceActions.selectedInvoiceResetAction());
        message.success(`${data.payload.message}`);
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  return (
    <div
      style={{
        background: Colors.backgroundGrey,
        minHeight: "100vh",
      }}
    >
      <div
        style={{
          background: Colors.white,
          borderBottom: "2px solid #dfe1e9",
          zIndex: 200,
        }}
        className="fixed-top"
      >
        <div className="p-2">
          <TextStyles.FontSize14px
            style={{
              position: "absolute",
              left: "0.75rem",
              top: "0.75rem",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <ClearIcon
                onClick={() => {
                  if (
                    selectedInvoiceData &&
                    ((selectedInvoiceData.invoice_services &&
                      selectedInvoiceData.invoice_services.length > 0) ||
                      (selectedInvoiceData.line_item_services &&
                        selectedInvoiceData.line_item_services.length > 0))
                  ) {
                    setShowDiscardModal(true);
                    setConfirmDialog({
                      onConfirm: () => discardChangesHandler(),
                    });
                  } else {
                    history.push(
                      `/db/projects/${selectedProject._id}/invoices`
                    );
                  }
                }}
                sx={{
                  fontSize: 24,
                  color: Colors.font2,
                  cursor: "pointer",
                  opacity: 0.7,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              />
              {selectedInvoiceData &&
                ((selectedInvoiceData.invoice_services &&
                  selectedInvoiceData.invoice_services.length > 0) ||
                  (selectedInvoiceData.line_item_services &&
                    selectedInvoiceData.line_item_services.length > 0)) && (
                  <Styled.SaveAndCloseButtun
                    onClick={() => {
                      setShowSaveChangesModal(true);
                      setConfirmDialog({
                        onConfirm: () => saveChangesHandler(),
                      });
                    }}
                  >
                    Save & Close
                  </Styled.SaveAndCloseButtun>
                )}
            </div>
          </TextStyles.FontSize14px>
          <TextStyles.FontSize20px
            style={{
              textAlign: "center",
              fontWeight: 500,
              fontSize: 24,
            }}
          >
            Create invoice
          </TextStyles.FontSize20px>
        </div>

        <div className="d-flex">
          <Styled.ProgressBar>
            <div
              className="col"
              style={{
                height: "100%",
                paddingTop: "3px",
                backgroundColor: Colors.primary,
                color: Colors.white,
                justifyContent: "center",
                display: "flex",
              }}
            >
              Setup
            </div>
            <div
              className="col"
              style={
                pathname === "review" || pathname === "send"
                  ? {
                      height: "100%",
                      paddingTop: "3px",
                      backgroundColor: Colors.primary,
                      color: Colors.white,
                      justifyContent: "center",
                      display: "flex",
                    }
                  : { justifyContent: "center", display: "flex" }
              }
            >
              Review
            </div>
            <div
              className="col"
              style={
                pathname === "send"
                  ? {
                      height: "100%",
                      paddingTop: "3px",
                      backgroundColor: Colors.primary,
                      color: Colors.white,
                      justifyContent: "center",
                      display: "flex",
                    }
                  : { justifyContent: "center", display: "flex" }
              }
            >
              Send
            </div>
          </Styled.ProgressBar>
        </div>
      </div>

      <div style={{ paddingTop: 100 }}>
        {pathname !== "setup" && (
          <div
            style={{ position: "fixed", left: 20, top: "50vh", zIndex: 200 }}
            onClick={() => history.goBack()}
          >
            <BackButton />
          </div>
        )}
        {pathname === "setup" && (
          <ProtectedRoute
            path="/db/projects/:id/invoices/create/setup"
            exact
            component={InvoiceSetup}
          />
        )}
        {pathname === "review" && (
          <ProtectedRoute
            path="/db/projects/:id/invoices/create/review"
            exact
            component={ReviewInvoice}
          />
        )}{" "}
        {pathname === "send" && (
          <ProtectedRoute
            path="/db/projects/:id/invoices/create/send"
            exact
            component={SendInvoice}
          />
        )}
        {showDiscardModal && (
          <DiscardChangesModal
            show={showDiscardModal}
            confirmDialog={confirmDialog}
            handleClose={() => setShowDiscardModal(false)}
          />
        )}
        {showSaveChangesModal && (
          <SaveChangesModal
            show={showSaveChangesModal}
            confirmDialog={confirmDialog}
            handleClose={() => setShowSaveChangesModal(false)}
          />
        )}
      </div>
    </div>
  );
};

export default CreateInvoice;
