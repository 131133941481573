import React, { useState } from "react";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { message } from "antd";
import TextStyles from "../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";
import { useDispatch, useSelector } from "react-redux";
import { updatePrivacyDataPC } from "../../../../../../store/service/proposalAndContractService";
import RemoveModal from "../../../../Commons/RemoveModal";
import Colors from "../../../../../../Constants/Colors";

const PrivacyData = () => {
  const selectedData = useSelector(
    (state) => state.proposalAndContract.selectedData
  );
  const dispatch = useDispatch();
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const handleCloseRemoveModal = () => setShowRemoveModal(false);

  const hideClauseHandler = () => {
    const Obj = {
      _id: selectedData._id,
      privacy_data: {
        required: false,
      },
    };
    dispatch(updatePrivacyDataPC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleCloseRemoveModal();
        message.success("Clause hidden successfully!");
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  return (
    <div
      style={{
        display:
          selectedData &&
          selectedData.privacy_data &&
          selectedData.privacy_data.required === true
            ? "inline"
            : "none",
      }}
    >
      <Styled.ReviewContentBox className="mt-4">
        <Styled.EditButtonToolTip
          backgroundColor="#383838"
          className="contract_review_edit_tp"
          onClick={() => {
            setShowRemoveModal(true);
            setConfirmDialog({
              onConfirm: hideClauseHandler,
            });
          }}
        >
          <VisibilityOffIcon sx={{ color: Colors.white, fontSize: 20 }} />
          <span className="ms-1">Hide</span>
        </Styled.EditButtonToolTip>

        <TextStyles.FontSize20px className="mb-2">
          Data privacy and security
        </TextStyles.FontSize20px>
        <TextStyles.FontSize14px>
          <div>
            <TextStyles.FontSize18px> A. Definitions:</TextStyles.FontSize18px>
            <div className="mt-2">
              <div className="mt-2 ms-3">
                1. "Borrower Data" means all information, including Personal
                Data, related to the Client ("Borrower").
              </div>
              <div className="mt-2 ms-3">
                2. "Client Data" refers to Personal Data, Employment Data, and
                other information provided by or processed for the Client by the
                Contractor or third parties on behalf of the Client.
              </div>
              <div className="mt-2 ms-3">
                3. "Data Protection Laws" encompasses data privacy and
                protection laws, including the European Community Data
                Protection Directive and other applicable laws.
              </div>
              <div className="mt-2 ms-3">
                4. "Notices" includes filings, communications, press releases,
                or reports related to any Security Breach.
              </div>
              <div className="mt-2 ms-3">
                5. "Personal Data" covers any information related to an
                identified or identifiable natural person, including names,
                addresses, Social Security numbers, financial information, and
                health-related data.
              </div>
              <div className="mt-2 ms-3">
                6. "Processing" refers to any operation performed on Client
                Data, such as collection, storage, or disclosure.
              </div>
              <div className="mt-2 ms-3">
                7. "Security Breach" indicates any unauthorized access, use, or
                loss of Client Data compromising its security, confidentiality,
                or integrity.
              </div>
            </div>
          </div>
          <div className="mt-3">
            <TextStyles.FontSize18px>
              B. Data Processing:
            </TextStyles.FontSize18px>
            <TextStyles.FontSize14px className="mt-2">
              Contractor will process Client Data only as required for the
              Project's services or as instructed by the Client in writing. For
              Employment Data, Contractor will adhere to Client policy or an
              agreed-upon policy.
            </TextStyles.FontSize14px>
          </div>
          <div className="mt-3">
            <TextStyles.FontSize18px>C. Data Security:</TextStyles.FontSize18px>
            <TextStyles.FontSize14px className="mt-2">
              Contractor shall provide Client with data security system
              information upon request. Contractor will comply with the Client's
              applicable data security policies and implement measures to ensure
              data security, confidentiality, and prevention of unauthorized
              access.
            </TextStyles.FontSize14px>
          </div>
          <div className="mt-3">
            <TextStyles.FontSize18px>
              D. European Community:
            </TextStyles.FontSize18px>
            <TextStyles.FontSize14px className="mt-2">
              If Personal Data is transferred outside the European Community,
              both parties will comply with applicable Data Protection Laws,
              including executing data transfer agreements or notifying local
              Data Protection Authorities.
            </TextStyles.FontSize14px>
          </div>
          <div className="mt-3">
            <TextStyles.FontSize18px>
              E. Agreement with Third Parties:
            </TextStyles.FontSize18px>
            <TextStyles.FontSize14px className="mt-2">
              Contractor will ensure that any third parties involved in
              providing services maintain adequate data security measures
              consistent with Contractor's obligations.
            </TextStyles.FontSize14px>
          </div>
          <div className="mt-3">
            <TextStyles.FontSize18px>
              F. Security Breach Notification and Costs:
            </TextStyles.FontSize18px>
            <TextStyles.FontSize14px className="mt-2">
              Contractor will promptly notify Client of any Security Breach
              involving Client Data and take necessary actions to investigate,
              prevent, and mitigate the impact. Contractor will provide relevant
              information for Client's compliance with applicable laws and
              reimburse Client for costs incurred due to a Security Breach. Any
              voluntary actions will be equitably allocated between the parties.
            </TextStyles.FontSize14px>
          </div>
        </TextStyles.FontSize14px>
      </Styled.ReviewContentBox>

      {confirmDialog && (
        <RemoveModal
          title={"Data privacy and security"}
          confirmDialog={confirmDialog}
          showRemoveModal={showRemoveModal}
          handleCloseRemoveModal={handleCloseRemoveModal}
        />
      )}
    </div>
  );
};

export default PrivacyData;
