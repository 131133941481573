import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { message } from "antd";

import { emailSendPC } from "../../../../store/service/proposalAndContractService";

import LoaderSpin from "../../Commons/LoaderSpin";

import CancelSharpIcon from "@mui/icons-material/CancelSharp";
import ControlPointSharpIcon from "@mui/icons-material/ControlPointSharp";

import EmailPreview from "./EmailPreview.jsx";
import PCDone from "./PCDone.jsx";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";

import TextStyles from "../../../../Constants/TextStyles";
import Colors from "../../../../Constants/Colors";
import Styled from "./SendStyles";
import { ReactTooltip } from "../../Commons/ReactTooltip";

import { Popover } from "antd";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddTemplateModal from "./AddTemplateModal";
import AddContactModal from "./AddContactModal";

const Send = (props) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.proposalAndContract.isLoading);
  const selectedData = useSelector(
    (state) => state.proposalAndContract.selectedData
  );
  const data = useSelector((state) => state.auth.data);
  const templateData = useSelector((state) => state.auth.templateData);
  const businessInfoData = useSelector((state) => state.auth.businessInfoData);
  const brandAndLogoData = useSelector((state) => state.auth.brandAndLogoData);

  // template
  const [openTemplate, setOpenTemplate] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState("Default");
  const [addTemplateModal, setAddTemplateModal] = useState(false);

  const hideTemplateHandler = () => {
    setOpenTemplate(false);
  };
  const handleOpenTemplateChange = (newOpen) => {
    setOpenTemplate(newOpen);
  };

  const primaryContactName =
    selectedData &&
    selectedData.client_id &&
    selectedData.client_id.contacts &&
    selectedData.client_id.contacts[0];

  const [addContactModal, setAddContactModal] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [showPCDoneModal, setShowPCDoneModal] = useState(false);
  const [contact, setContact] = useState(
    selectedData && selectedData.client_id && selectedData.client_id.contacts
  );

  const removeContact = (data) => {
    const filteredContact = contact.filter((d) => d.email !== data.email);
    setContact(filteredContact);
  };

  const subjectData = `Review ${selectedData.section} "${
    selectedData.project_name
  }" from ${
    businessInfoData && businessInfoData.business_name
      ? businessInfoData.business_name
      : `${data.firstName} ${data.lastName}`
  }`;

  const [subject, setSubject] = useState(subjectData);

  let textData;
  if (selectedData.section === "Proposal") {
    textData = `Hello ${
      primaryContactName ? primaryContactName.contact_name : ""
    },\n\nCheck out the proposal! Feel free to suggest any adjustments.\n\nBest,\n${
      businessInfoData && businessInfoData.business_name
        ? businessInfoData.business_name
        : `${data.firstName} ${data.lastName}`
    }`;
  } else if (selectedData.section === "Contract") {
    textData = `Hey ${
      primaryContactName ? primaryContactName.contact_name : ""
    },\n\nI've attached the contract for your review and approval. Take a quick look, and if you have any changes in mind, just let me know.\n\nLooking forward to getting started!\n\nBest,\n${
      businessInfoData && businessInfoData.business_name
        ? businessInfoData.business_name
        : `${data.firstName} ${data.lastName}`
    }`;
  } else {
    textData = `Hi ${
      primaryContactName ? primaryContactName.contact_name : ""
    },\n\nI am pleased to present my proposal and contract for your review. I look forward to discussing any adjustments or questions you may have.\n\nBest regards,\n${
      businessInfoData && businessInfoData.business_name
        ? businessInfoData.business_name
        : `${data.firstName} ${data.lastName}`
    }`;
  }

  const [text, setText] = useState(textData);

  const { register, reset, watch, handleSubmit } = useForm({
    defaultValues: {
      email_subject: subjectData,
      email_text: textData,
    },
  });

  const revisedSubject = watch("email_subject");
  const revisedText = watch("email_text");

  useEffect(() => {
    setSubject(revisedSubject);
  }, [revisedSubject]);

  useEffect(() => {
    setText(revisedText);
  }, [revisedText]);

  useEffect(() => {
    if (selectedTemplate !== "Default") {
      const filteredTemplate =
        templateData &&
        templateData.filter(
          (item) => item.template_name === selectedTemplate
        )[0];

      setSubject(filteredTemplate.template_subject);
      setText(filteredTemplate.template_message);
      reset({
        email_subject: filteredTemplate.template_subject,
        email_text: filteredTemplate.template_message,
      });
    } else {
      setSubject(subjectData);
      setText(textData);
      reset({
        email_subject: subjectData,
        email_text: textData,
      });
    }
  }, [selectedTemplate]);

  const formsubmit = (item) => {
    const Obj = {
      _id: selectedData._id,
      email_subject: item.email_subject,
      email_text: item.email_text,
      email_template: item.email_template,
      emails_sent_to: contact.map((d) => {
        return { email: d.email };
      }),
      status: "Sent",
    };
    dispatch(emailSendPC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        setShowPCDoneModal(true);
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  const templateContent = () => {
    return (
      <TextStyles.FontSize14px>
        <Styled.TemplateLi
          onClick={() => {
            setSelectedTemplate("Default");
            hideTemplateHandler();
          }}
        >
          Default
        </Styled.TemplateLi>
        {templateData &&
          templateData.map((item, index) => {
            return (
              <Styled.TemplateLi
                key={index}
                onClick={() => {
                  setSelectedTemplate(item.template_name);
                  hideTemplateHandler();
                }}
                style={{ minWidth: "4rem" }}
              >
                <span className="three-dots"> {item.template_name}</span>
              </Styled.TemplateLi>
            );
          })}

        <Styled.TemplateLi
          className="d-flex mt-2"
          onClick={() => {
            setAddTemplateModal(true);
            hideTemplateHandler();
          }}
        >
          <AddCircleIcon sx={{ fontSize: 16, color: Colors.primary }} />
          <span
            className="ms-1"
            style={{
              color: Colors.primary,
              fontWeight: 500,
              textDecoration: "1px solid underline",
            }}
          >
            New template
          </span>
        </Styled.TemplateLi>
      </TextStyles.FontSize14px>
    );
  };

  return (
    <div className="px-lg-4 px-md-4 px-sm-3 px-2">
      <div
        className="d-flex justify-content-center w-100"
        style={{ contain: "content" }}
      >
        <div style={{ width: "100%", maxWidth: 650 }}>
          <TextStyles.FontSize20px className="d-flex justify-content-center mb-3">
            Compose email
          </TextStyles.FontSize20px>
          <Styled.SendBox>
            <TextStyles.FontSize14px className="mb-2">
              To
            </TextStyles.FontSize14px>
            <div className="d-flex gap-2 justify-content-between align-items-center">
              <Styled.ChipsRectangle>
                {contact &&
                  contact.map((item, index) => {
                    return (
                      <div key={index}>
                        <ReactTooltip
                          anchorId={`contact${index}`}
                          content={item.email}
                          variant={"brandColor"}
                          color={Colors.white}
                        />
                        <Styled.ChipRectangle id={`contact${index}`}>
                          <TextStyles.FontSize14px
                            className="d-inline-block me-2"
                            style={{ color: Colors.primary }}
                          >
                            {item.contact_name}
                          </TextStyles.FontSize14px>
                          <CancelSharpIcon
                            onClick={() => removeContact(item)}
                            sx={{
                              fontSize: 16,
                              color: Colors.primary,
                              cursor: "pointer",
                            }}
                          />
                        </Styled.ChipRectangle>
                      </div>
                    );
                  })}
              </Styled.ChipsRectangle>
              <ControlPointSharpIcon
                onClick={() => setAddContactModal(true)}
                sx={{ color: Colors.font1, fontSize: 24, cursor: "pointer" }}
              />
            </div>
          </Styled.SendBox>
          <form onSubmit={handleSubmit(formsubmit)}>
            <Styled.SendBox className="my-4">
              <div className="d-flex flex-wrap flex-sm-nowrap gap-3 justify-content-between">
                <TextStyles.FontSize14px className="w-100">
                  <TextStyles.InputRectangle
                    placeholder="Subject"
                    // defaultValue={subject}
                    name="email_subject"
                    {...register(`email_subject`, {
                      required: "Subject can't be empty.",
                      validate: (value) => {
                        let trimedLength = value.trim().length;
                        if (trimedLength === 0) {
                          return "Subject can't be empty.";
                        }
                        return true;
                      },
                    })}
                  />
                </TextStyles.FontSize14px>
                <div
                  className="w-100"
                  style={{ maxWidth: "7.5rem", marginLeft: "auto" }}
                >
                  <Styled.Template>
                    <Popover
                      placement="bottomRight"
                      zIndexPopup={150}
                      // title={"Hello"}
                      content={templateContent}
                      trigger="click"
                      open={openTemplate}
                      onOpenChange={handleOpenTemplateChange}
                      overlayClassName="email-template-popover"
                    >
                      <TextStyles.FontSize14px
                        style={{
                          cursor: "pointer",
                          color: Colors.primary,
                          fontWeight: 500,
                          width: "100%",
                          height: "100%",
                          padding: "0 15px 0 20px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div
                          className="three-dots"
                          style={{
                            width: "6rem",
                          }}
                        >
                          {selectedTemplate}
                        </div>
                        <ArrowDropDownOutlinedIcon
                          sx={{
                            color: Colors.primary,
                            fontSize: 27,
                            transform: openTemplate ? "rotate(180deg)" : "",
                          }}
                        />
                      </TextStyles.FontSize14px>
                    </Popover>
                  </Styled.Template>
                </div>
              </div>
              <TextStyles.FontSize14px className="mt-4">
                <TextStyles.Textarea
                  className="textarea_padding_right"
                  rows={8}
                  placeholder="Add Text..."
                  name="email_text"
                  {...register(`email_text`, {
                    required: "Email text can't be empty.",
                    validate: (value) => {
                      let trimedLength = value.trim().length;
                      if (trimedLength === 0) {
                        return "Email text can't be empty.";
                      }
                      return true;
                    },
                  })}
                />
              </TextStyles.FontSize14px>
            </Styled.SendBox>
            <div className="pb-3">
              <div className="d-flex gap-3 justify-content-between">
                <TextStyles.CancelButton
                  onClick={() => setShowPreviewModal(true)}
                >
                  Preview email
                </TextStyles.CancelButton>

                <TextStyles.Button
                  disabled={contact.length === 0 || isLoading ? true : false}
                >
                  {isLoading && addContactModal === false ? (
                    <LoaderSpin />
                  ) : (
                    "Send"
                  )}
                </TextStyles.Button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {props.backBtn}

      {showPreviewModal && (
        <EmailPreview
          show={showPreviewModal}
          handleClose={() => setShowPreviewModal(false)}
          data={data}
          businessInfoData={businessInfoData}
          selectedData={selectedData}
          subject={subject}
          text={text}
          brandAndLogoData={brandAndLogoData}
        />
      )}

      {showPCDoneModal && (
        <PCDone
          show={showPCDoneModal}
          handleClose={() => setShowPCDoneModal(false)}
          data={data}
          selectedData={selectedData}
        />
      )}

      {addTemplateModal && (
        <AddTemplateModal
          show={addTemplateModal}
          handleClose={() => setAddTemplateModal(false)}
        />
      )}
      {addContactModal && (
        <AddContactModal
          contact={contact}
          setContact={setContact}
          show={addContactModal}
          handleClose={() => setAddContactModal(false)}
        />
      )}
    </div>
  );
};

export default Send;
