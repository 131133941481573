import React, { useState, useEffect } from "react";
import { Dropdown, DatePicker, Button } from "antd";
import TodayIcon from "@mui/icons-material/Today";
import Colors from "../../../Constants/Colors";
import dayjs from "dayjs";
import "./selectDate.css";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";

const { RangePicker } = DatePicker;

const SelectRange = ({ onChange, defaultValue }) => {
  const [selected, setSelected] = useState(defaultValue);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    if (defaultValue && typeof defaultValue === "object") {
      setSelected(defaultDateRange(defaultValue));
    } else {
      setSelected(defaultValue);
    }
  }, []);

  const notifyDateChange = (newDate) => {
    if (onChange) {
      onChange(newDate);
    }
  };

  const defaultDateRange = (date) => {
    const startDate = dayjs(date[0]).format("DD MMM YYYY");
    const endDate = dayjs(date[1]).format("DD MMM YYYY");
    return `${startDate} ~ ${endDate}`;
  };

  const handleCustomRangeSelector = (date, value) => {
    if (value) {
      setSelected(defaultDateRange(value));
      setOpenDatePicker(false);
      setDropdownOpen(false);
      notifyDateChange(value);
    }
  };

  const handleDropdownVisibleChange = (open) => {
    if (!openDatePicker) {
      setDropdownOpen(open); // Only change if DatePicker is not open
    }
  };

  const selectDates = (value) => {
    setSelected(value.key);
    setDropdownOpen(false); // Close dropdown
    notifyDateChange(value.key); // Notify the parent component
  };

  // Open the DatePicker when Custom Week is selected
  const openCustomDateRangerSelector = () => {
    setDropdownOpen(false); // Close the dropdown immediately
    setTimeout(() => {
      setOpenDatePicker(true); // Open DatePicker after a slight delay to avoid conflicts
    }, 100); // Delay to avoid immediate dropdown closure issues
  };

  const predefinedRanges = [
    "All time",
    "Last 7 days",
    "Last 15 days",
    "Last 30 days",
    "Last 45 days",
    "Last 60 days",
    "Last 90 days",
    "This month",
    "Last month",
    "This year",
    "Last year",
  ];

  const menuItems = [
    ...predefinedRanges.map((range) => ({
      key: range,
      label: range,
      onClick: selectDates,
    })),
    {
      key: "custom",
      label: "Choose date range ...",
      onClick: openCustomDateRangerSelector,
    },
  ];

  return (
    <Dropdown
      menu={{
        items: menuItems,
        selectable: true,
        defaultSelectedKeys: selected,
      }}
      trigger={["click"]}
      open={dropdownOpen}
      onOpenChange={handleDropdownVisibleChange}
      overlayClassName="custum-range-dropdown"
    >
      <div className="custum-range-picker-box">
        <div
          style={{
            display: "inline-flex",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => setDropdownOpen(!dropdownOpen)}
        >
          <TodayIcon sx={{ color: Colors.primary, fontSize: 20 }} />
          <span style={{ margin: "0 8px" }}>
            {selected ? selected : "All time"}
          </span>
          <Button
            className="button-hover"
            type="text"
            icon={
              dropdownOpen ? (
                <CaretUpOutlined className="dropdown-icon" />
              ) : (
                <CaretDownOutlined className="dropdown-icon" />
              )
            }
          />
        </div>

        {openDatePicker && (
          <RangePicker
            value={dayjs()}
            onChange={handleCustomRangeSelector}
            open={openDatePicker}
            onOpenChange={(open) => setOpenDatePicker(open)}
            className="custom-range-picker"
            popupClassName="custom-range-picker-popup"
          />
        )}
      </div>
    </Dropdown>
  );
};

export default SelectRange;
