import { createSlice } from "@reduxjs/toolkit";
import {
  fetchReminders,
  dismissedReminder,
  doneReminder,
} from "../service/reminderService";

const initialReminderState = {
  reminders: null,
  isLoading: false,
  error: null,
};

export const reminderSlice = createSlice({
  name: "reminder",
  initialState: initialReminderState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // fetch reminders
      .addCase(fetchReminders.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchReminders.fulfilled, (state, action) => {
        state.isLoading = false;
        state.reminders = action.payload.data;
      })
      .addCase(fetchReminders.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // dismiss reminder
      .addCase(dismissedReminder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(dismissedReminder.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(dismissedReminder.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // done reminder
      .addCase(doneReminder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(doneReminder.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(doneReminder.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});
export const reminderAction = reminderSlice.actions;
export default reminderSlice.reducer;
