import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import LoaderSpin from "../Commons/LoaderSpin";
import ClearIcon from "@mui/icons-material/Clear";
import Styles from "./headerStyles";
import TextStyles from "../../../Constants/TextStyles";
import Colors from "../../../Constants/Colors";
import { useDispatch, useSelector } from "react-redux";
import { fetchNotifications } from "../../../store/service/notificationService";

const Notifications = ({
  hideNotificationPopupHandler,
  openPopupNotification,
}) => {
  const dispatch = useDispatch();
  const notifications = useSelector(
    (state) => state.notifications.notifications
  );
  const pages = useSelector((state) => state.notifications.page);

  const totalNotifications = useSelector(
    (state) => state.notifications.totalCount
  );
  const isLoading = useSelector((state) => state.notifications.isLoading);

  const notificationContentRef = useRef(null);

  const [page, setPage] = useState(2);
  const perPage = 10;
  const maxPage = Math.ceil(totalNotifications / perPage);

  useEffect(() => {
    const handleScroll = () => {
      const { scrollHeight, scrollTop, clientHeight } =
        notificationContentRef.current;
      const scrollBottom = scrollTop + clientHeight >= scrollHeight - 10;

      if (scrollBottom && page <= maxPage && pages !== page) {
        const getData = setTimeout(() => {
          dispatch(
            fetchNotifications({
              page: page,
              perPage: perPage,
            })
          );
          setPage((prevPage) => prevPage + 1);
        }, 500);
        return () => clearTimeout(getData);
      }
    };

    if (notificationContentRef.current && openPopupNotification) {
      notificationContentRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      notificationContentRef.current.removeEventListener(
        "scroll",
        handleScroll
      );
    };
  }, [page]);

  return (
    <div className="d-block position-relative w-100 h-100">
      <div
        className="d-flex justify-content-between align-items-center px-3 pt-3 pb-2"
        style={{ borderBottom: "2px solid #e0e3f0" }}
      >
        <TextStyles.GreyFont20px style={{ fontWeight: 600 }}>
          Notifications
        </TextStyles.GreyFont20px>
        <div onClick={hideNotificationPopupHandler}>
          <ClearIcon
            sx={{
              fontSize: 25,
              cursor: "pointer",
              color: Colors.font2,
              borderRadius: 9,
              "&:hover": {
                background: Colors.backgroundGrey,
              },
            }}
          />
        </div>
      </div>

      <div
        style={{
          width: "100%",
          maxWidth: 450,
          maxHeight: 424,
          height: "100%",
          overflowY: "auto",
          overflowX: "hidden",
        }}
        ref={notificationContentRef}
      >
        <div className="position-relative">
          {notifications &&
            notifications.map((data, index) => {
              return (
                <Styles.NotificationElement
                  key={index}
                  style={{
                    borderBottom:
                      notifications.length - 1 === index
                        ? "none"
                        : "2px solid #e0e3f0",
                    background:
                      data && data.isViewed ? "" : Colors.backgroundPink,
                  }}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.htmlContent,
                    }}
                  />
                  <TextStyles.GreyFont14px
                    className="mt-2"
                    style={{ fontSize: 13 }}
                  >
                    <span className="me-2">
                      {" "}
                      {data.createdAt
                        ? moment(data.createdAt).format("DD MMM YYYY")
                        : ""}
                    </span>
                    <span>
                      {" "}
                      {data.createdAt
                        ? moment(data.createdAt).format("LT")
                        : ""}
                    </span>
                  </TextStyles.GreyFont14px>
                </Styles.NotificationElement>
              );
            })}
          {isLoading && (
            <div style={{ position: "sticky", zIndex: 100, bottom: 20 }}>
              <LoaderSpin color={Colors.primary} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Notifications;
