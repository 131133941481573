import React from "react";
import { makeStyles } from "@mui/styles";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ListSubheader from "@mui/material/ListSubheader";
import CreateNewFolderOutlinedIcon from "@mui/icons-material/CreateNewFolderOutlined";
import Colors from "../../../Constants/Colors";

const useStyles = makeStyles({
  root: {
    border: `1px solid ${Colors.borderInput} !important`,
    backgroundColor: Colors.white,
    fontWeight: "400 !important",
    borderRadius: "6px !important",
    fontFamily: "Poppins !important",
    fontSize: "0.875rem !important",
    height: "2.75rem !important",
    "&:hover": {
      border: `1px solid ${Colors.primary} !important`,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },

  select: {
    display: "flex !important",
    justifyContent: "start",
    alignItems: "center !important",
  },

  listMenu: {
    color: Colors.font1,
    fontWeight: "400 !important",
    marginBottom: "2px !important",
    fontSize: "0.875rem !important",
    fontFamily: "Poppins !important",
    "&:hover": {
      backgroundColor: `${Colors.backgroundPink} !important`,
      border: "none",
    },
    '&[aria-selected="true"]': {
      backgroundColor: `${Colors.backgroundPink} !important`,
    },
  },
});

export default function SelectProjects(props) {
  const classes = useStyles();
  let projects = props.options;

  let projectMappedData =
    projects &&
    projects.map((d) => {
      return {
        _id: d._id,
        project_name: d.project_name,
        client_id: d.client_id && d.client_id._id,
        client_name: d.client_id && d.client_id.company_name,
      };
    });

  const projectData =
    projectMappedData &&
    projectMappedData.sort((a, b) => {
      return a.client_name.localeCompare(b.client_name, undefined, {
        sensitivity: "base",
      });
    });

  let group =
    projectData &&
    projectData.reduce((acc, value) => {
      acc[value.client_id] = [...(acc[value.client_id] || []), value];
      return acc;
    }, {});

  const mappingData = group && Object.values(group);

  const renderSelectGroup = (data) => {
    const items = data.map((item) => {
      return (
        <MenuItem key={item._id} value={item._id} className={classes.listMenu}>
          <CreateNewFolderOutlinedIcon
            sx={{
              fontSize: 23,
              color: Colors.primary,
              marginRight: 1,
            }}
          />

          {item.project_name}
        </MenuItem>
      );
    });
    return [
      <ListSubheader
        style={{
          color: Colors.font1,
          fontWeight: 400,
          fontSize: 16,
          fontFamily: "Poppins",
        }}
      >
        {data[0].client_name}
      </ListSubheader>,
      items,
    ];
  };

  return (
    <div>
      <Select
        classes={{
          select: classes.select,
        }}
        className={classes.root}
        value={props.defaultValue ? props.defaultValue : "0"}
        id="grouped-select"
        label="Grouping"
        onChange={props.onChange}
        MenuProps={{
          PaperProps: { sx: { maxHeight: 250 } },
          disableScrollLock: false,
        }}
      >
        <MenuItem value={0} className={classes.listMenu}>
          <CreateNewFolderOutlinedIcon
            sx={{
              fontSize: 23,
              color: Colors.primary,
              marginRight: 1,
            }}
          />
          All projects
        </MenuItem>
        {mappingData && mappingData.map((data) => renderSelectGroup(data))}
      </Select>
    </div>
  );
}
