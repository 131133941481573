import React, { useState } from "react";
import { DatePicker, Space, Button, Dropdown } from "antd";
import {
  LeftOutlined,
  RightOutlined,
  CalendarOutlined,
  CaretDownOutlined,
  CaretUpOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import "./SelectWeekview.css";

// Default function to get formatted week range
const defaultGetWeekRange = (date) => {
  const startOfWeek = dayjs(date).startOf("week").format("DD MMM YYYY");
  const endOfWeek = dayjs(date).endOf("week").format("DD MMM YYYY");
  return `${startOfWeek} ~ ${endOfWeek}`;
};

const SelectWeekview = ({
  initialDate = dayjs(), // Allow passing an initial date, default to current date
  onWeekChange, // Callback to return the selected week range
  getWeekRange = defaultGetWeekRange, // Custom week formatting function
}) => {
  const [customDate, setCustomDate] = useState(initialDate); // Start with the initial date
  const [date, setDate] = useState(getWeekRange(initialDate)); // Start with the formatted week range
  const [openPicker, setOpenPicker] = useState(false); // Control the visibility of DatePicker
  const [dropdownOpen, setDropdownOpen] = useState(false); // Control dropdown visibility

  const notifyWeekChange = (newDate) => {
    if (onWeekChange) {
      //   const startOfWeek = dayjs(newDate).startOf("week");
      //   const endOfWeek = startOfWeek.add(6, "day"); // Calculate last date of the week
      //   const formattedStartDate = startOfWeek.format("YYYY-MM-DD");
      //   const formattedEndDate = endOfWeek.format("YYYY-MM-DD");

      // You can choose to return both dates or as a range
      //   onWeekChange({ start: formattedStartDate, end: formattedEndDate });
      onWeekChange(newDate);
      // or if you prefer a range format:
      // onWeekChange(`${formattedStartDate} - ${formattedEndDate}`);
    }
  };

  // Function to update the week based on the provided number of weeks to add or subtract
  const updateWeek = (e, weeksToAdd) => {
    e.stopPropagation();
    const baseDate = customDate || dayjs(); // Use the selected customDate or the current date if no custom date is selected
    const newWeekStart = dayjs(baseDate)
      .startOf("week")
      .add(weeksToAdd, "week");
    const newWeekRange = getWeekRange(newWeekStart);
    setDate(newWeekRange);
    setCustomDate(newWeekStart); // Update the custom date as well
    notifyWeekChange(newWeekStart); // Notify the parent component
  };

  // Previous Week based on current date
  const setPreviousWeek = () => {
    const newWeekStart = dayjs().startOf("week").subtract(1, "week");
    setDate(getWeekRange(newWeekStart));
    setCustomDate(newWeekStart); // Update custom date when selecting Last Week
    setDropdownOpen(false); // Close dropdown
    notifyWeekChange(newWeekStart); // Notify the parent component
  };

  // Next Week based on current date
  const setNextWeek = () => {
    const newWeekStart = dayjs().startOf("week").add(1, "week");
    setDate(getWeekRange(newWeekStart));
    setCustomDate(newWeekStart); // Update custom date when selecting Next Week
    setDropdownOpen(false); // Close dropdown
    notifyWeekChange(newWeekStart); // Notify the parent component
  };

  // Reset to the current week
  const resetToCurrentWeek = () => {
    const currentWeekRange = getWeekRange(dayjs());
    setDate(currentWeekRange);
    setCustomDate(dayjs()); // Reset to current date
    setDropdownOpen(false); // Close dropdown
    notifyWeekChange(dayjs()); // Notify the parent component
  };

  // Handle custom week change from DatePicker
  const handleCustomWeekChange = (value) => {
    if (value) {
      setCustomDate(value); // Update custom date
      const selectedDate = getWeekRange(value);
      setDate(selectedDate);
      setOpenPicker(false); // Close DatePicker after week selection
      setDropdownOpen(false); // Explicitly close dropdown after selection
      notifyWeekChange(value); // Notify the parent component
    }
  };

  // Open the DatePicker when Custom Week is selected
  const openCustomWeekSelector = () => {
    setDropdownOpen(false); // Close the dropdown immediately
    setTimeout(() => {
      setOpenPicker(true); // Open DatePicker after a slight delay to avoid conflicts
    }, 100); // Delay to avoid immediate dropdown closure issues
  };

  // Handle dropdown visibility change
  const handleDropdownVisibleChange = (open) => {
    if (!openPicker) {
      setDropdownOpen(open); // Only change if DatePicker is not open
    }
  };

  // Items for dropdown menu
  const menuItems = [
    {
      key: "previous",
      label: "Last Week",
      onClick: setPreviousWeek, // Previous week relative to the current date
    },
    {
      key: "current",
      label: "This Week",
      onClick: resetToCurrentWeek, // Reset to current week
    },
    {
      key: "next",
      label: "Next Week",
      onClick: setNextWeek, // Next week relative to the current date
    },
    {
      key: "custom",
      label: "Choose Week ...",
      onClick: openCustomWeekSelector, // Set DatePicker to open
    },
  ];

  return (
    <Space direction="vertical" size={12}>
      <Dropdown
        menu={{ items: menuItems }}
        trigger={["click"]}
        open={dropdownOpen} // Control dropdown visibility using 'open'
        onOpenChange={handleDropdownVisibleChange} // Handle dropdown visibility change
        overlayClassName="custum-week-dropdown"
      >
        <div className="custum-week-picker-box">
          <CalendarOutlined style={{ fontSize: 16, margin: 6 }} />
          {/* Previous Week Button */}
          <Button
            className="button-hover"
            type="text"
            icon={<LeftOutlined />}
            onClick={(e) => updateWeek(e, -1)} // Subtract 1 week from selected/custom date
          />

          {/* Display the current week range */}
          <span>{date}</span>

          {/* Next Week Button */}
          <Button
            className="button-hover"
            type="text"
            icon={<RightOutlined />}
            onClick={(e) => updateWeek(e, 1)} // Add 1 week to selected/custom date
          />

          {/* Dropdown toggle button */}
          <Button
            className="button-hover"
            type="text"
            onClick={() => setDropdownOpen(!dropdownOpen)}
            icon={
              dropdownOpen ? (
                <CaretUpOutlined className="dropdown-icon" />
              ) : (
                <CaretDownOutlined className="dropdown-icon" />
              )
            }
          />

          {/* DatePicker for Custom Week Selection */}
          {openPicker && (
            <DatePicker
              onChange={handleCustomWeekChange}
              picker="week"
              value={customDate} // Set value to the selected custom date
              open={openPicker} // Control opening via state
              onOpenChange={(open) => setOpenPicker(open)} // Close picker when a date is selected or user cancels
              style={{ visibility: "hidden", position: "absolute" }} // Hide DatePicker input field
              className="custum-week-picker"
              popupClassName="custum-week-picker-popup"
            />
          )}
        </div>
      </Dropdown>
    </Space>
  );
};

export default SelectWeekview;
