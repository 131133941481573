import React from "react";
import ClearIcon from "@mui/icons-material/Clear";

import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";

import LoaderSpin from "../../Commons/LoaderSpin";
import TextStyles from "../../../../Constants/TextStyles";
import { createTemplate } from "../../../../store/service/AuthService";
import Colors from "../../../../Constants/Colors";

const AddTemplateModal = ({
  show,
  handleClose,
  updateRefresh,
  setUpdateRefresh,
}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.auth.isLoading);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const formsubmit = (data) => {
    const Obj = {
      template_name: data.template_name,
      template_subject: data.template_subject,
      template_message: data.template_message,
    };
    dispatch(createTemplate(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleClose();
        setUpdateRefresh(!updateRefresh);
        message.success(`${data.payload.message}`);
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      contentClassName="ToMakeModalBorderRudius12px"
      scrollable={true}
      centered
    >
      <form
        onSubmit={handleSubmit(formsubmit)}
        className="modal-content"
        style={{ height: "100%" }}
      >
        <div
          className="modal-header"
          style={{ padding: "21px 24px 20px", border: "none" }}
        >
          <TextStyles.FontSize20px className="d-flex justify-content-start w-100">
            <span className="three-dots">Email message template</span>
          </TextStyles.FontSize20px>
          <ClearIcon
            sx={{
              color: Colors.font2,
              cursor: "pointer",
              opacity: 0.7,
              "&:hover": {
                opacity: 1,
              },
            }}
            onClick={handleClose}
          />
        </div>

        <div className="modal-body" style={{ padding: "0 24px" }}>
          <TextStyles.FontSize14px className="mb-3">
            <label className="mb-2" htmlFor="template_name">
              Template name
            </label>
            <TextStyles.InputRectangle
              invalid={errors.template_name}
              id="template_name"
              type="text"
              placeholder="Write template name"
              name="template_name"
              {...register("template_name", {
                required: "Name is required.",
                validate: (value) => {
                  let trimedLength = value.trim().length;
                  if (trimedLength === 0) {
                    return "Name is required.";
                  }
                  return true;
                },
              })}
            />
            {errors.template_name && (
              <TextStyles.InValidFeedback>
                {errors.template_name.message}
              </TextStyles.InValidFeedback>
            )}
          </TextStyles.FontSize14px>

          <TextStyles.FontSize14px className="mb-3">
            <label className="mb-2" htmlFor="template_subject">
              Subject
            </label>
            <TextStyles.InputRectangle
              invalid={errors.template_subject}
              id="template_subject"
              type="text"
              placeholder="Write subject"
              name="template_subject"
              {...register("template_subject", {
                required: "Subject is required.",
                validate: (value) => {
                  let trimedLength = value.trim().length;
                  if (trimedLength === 0) {
                    return "Subject is required.";
                  }
                  return true;
                },
              })}
            />
            {errors.template_subject && (
              <TextStyles.InValidFeedback>
                {errors.template_subject.message}
              </TextStyles.InValidFeedback>
            )}
          </TextStyles.FontSize14px>

          <TextStyles.FontSize14px className="mb-3">
            <label className="mb-2" htmlFor="template_message">
              Message
            </label>
            <TextStyles.Textarea
              rows={8}
              invalid={errors.template_message}
              id="template_message"
              type="text"
              placeholder="Write message"
              name="template_message"
              {...register("template_message", {
                required: "Message is required.",
                validate: (value) => {
                  let trimedLength = value.trim().length;
                  if (trimedLength === 0) {
                    return "Message is required.";
                  }
                  return true;
                },
              })}
              style={{ marginBottom: 6 }}
            />

            {errors.template_message && (
              <TextStyles.InValidFeedback>
                {errors.template_message.message}
              </TextStyles.InValidFeedback>
            )}
          </TextStyles.FontSize14px>
        </div>

        <div
          className="modal-footer"
          style={{
            width: "100%",
            display: "inline-block",
            padding: "10px 18px 15px",
            border: "none",
          }}
        >
          <TextStyles.Button disabled={isLoading}>
            {isLoading ? <LoaderSpin /> : "Save"}
          </TextStyles.Button>
        </div>
      </form>
    </Modal>
  );
};

export default AddTemplateModal;
