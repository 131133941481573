import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { fetchIncomes } from "../../../../../store/service/statementService";

import Colors from "../../../../../Constants/Colors";
import TextStyles from "../../../../../Constants/TextStyles";
import Styled from "../../StatementStyles";
import LoaderSpin from "../../../Commons/LoaderSpin";
import pdfSupportedCurrency from "../../../Commons/pdfSupportedCurrency";

import pdfBrandingLogo from "../../../../../Assets/assetsnew/pdfBrandingLogo.png";

import {
  Page,
  Text,
  View,
  Font,
  Document,
  StyleSheet,
  PDFDownloadLink,
  Image,
} from "@react-pdf/renderer";
import SelectClients from "../../../Commons/SelectClients";
import SelectProjects from "../../../Commons/SelectProjects";

import PoppinsItalic from "../../../../../Assets/fonts/Poppins-Italic.ttf";
import PoppinsRegular from "../../../../../Assets/fonts/Poppins-Regular.ttf";
import PoppinsMedium from "../../../../../Assets/fonts/Poppins-Medium.ttf";
import PoppinsSemiBold from "../../../../../Assets/fonts/Poppins-SemiBold.ttf";
import PoppinsBold from "../../../../../Assets/fonts/Poppins-Bold.ttf";
import CODE2000 from "../../../../../Assets/fonts/CODE2000.TTF";
import SelectDate from "../../../Commons/SelectDate";
import { statementsActions } from "../../../../../store/storage/statementSlice";

Font.register({
  family: "Poppins",
  fonts: [
    { src: PoppinsRegular, fontWeight: 400 },
    { src: PoppinsMedium, fontWeight: 500 },
    { src: PoppinsSemiBold, fontWeight: 600 },
    { src: PoppinsBold, fontWeight: 700 },
    { src: PoppinsItalic, fontStyle: "italic" },
  ],
});

Font.register({
  family: "CODE2000",
  fonts: [{ src: CODE2000 }],
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: "white",
    color: Colors.font1,
    width: "100%",
    fontWeight: 400,
    fontSize: 10,
    padding: 30,
    lineHeight: 1,
  },
  heading: {
    fontFamily: "Poppins",
    textAlign: "center",
    fontSize: 20,
    fontWeight: 500,
  },
  name: {
    fontFamily: "Poppins",
    textAlign: "center",
    fontSize: 14,
    marginTop: 6,
  },
  range: {
    fontFamily: "Poppins",
    textAlign: "center",
    fontSize: 11,
    marginTop: 9,
    marginBottom: 9,
    color: Colors.font2,
  },

  notes: {
    fontFamily: "Poppins",
    fontSize: 10,
    marginTop: 6,
    color: Colors.font2,
    fontStyle: "italic",
    textAlign: "center",
  },

  desc_heading: {
    fontFamily: "Poppins",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: 16,
    marginTop: 15,
    marginBottom: 15,
  },

  desc: {
    fontFamily: "Poppins",
    fontSize: 10,
    display: "flex",
    flexDirection: "row",
    borderTop: `1px solid ${Colors.font2}`,
  },

  desc_subHeading: {
    fontFamily: "Poppins",
    fontSize: 12,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: 8,
    paddingBottom: 12,
    paddingLeft: 10,
    borderTop: `1px solid ${Colors.font2}`,
  },
});

const Incomes = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.auth.data);
  const businessInfoData = useSelector((state) => state.auth.businessInfoData);
  const incomesData = useSelector((state) => state.statements.incomesData);
  const isLoading = useSelector((state) => state.statements.isLoading);
  const clientOptionData = useSelector(
    (state) => state.clients.clientOptionData
  );
  const projectOptionsData = useSelector(
    (state) => state.project.projectOptionsData
  );

  const userCurrency = useSelector((state) => state.auth.userCurrency);
  const currencySymbol =
    userCurrency && userCurrency.currency && userCurrency.currency.symbol;

  const toggleFilter = useSelector(
    (state) => state.statements.toggleIncomeFilter
  );
  const filteredData = useSelector(
    (state) => state.statements.filteredIncomeData
  );

  const filterdDateRange =
    filteredData && filteredData.filterdDateRange
      ? filteredData.filterdDateRange
      : null;
  const filteredProject =
    filteredData && filteredData.filteredProject
      ? filteredData.filteredProject
      : null;

  const filteredClient =
    filteredData && filteredData.filteredClient
      ? filteredData.filteredClient
      : null;

  const incomesMappingData = useMemo(() => {
    return (
      incomesData && incomesData.filter((d) => d.transaction_type === "income")
    );
  }, [incomesData]);

  const totalIncomesArray = useMemo(() => {
    return (
      incomesMappingData &&
      incomesMappingData.map((d) => d.total_amount_in_user_currency)
    );
  }, [incomesMappingData]);

  const totalIncomes = useMemo(() => {
    return (
      totalIncomesArray &&
      totalIncomesArray.reduce(
        (preValue, curValue) => parseFloat(preValue) + parseFloat(curValue),
        0
      )
    );
  }, [totalIncomesArray]);

  const expensesMappingData = useMemo(() => {
    return (
      incomesData && incomesData.filter((d) => d.transaction_type === "expense")
    );
  }, [incomesData]);

  const totalExpensesArray = useMemo(() => {
    return (
      expensesMappingData &&
      expensesMappingData.map((d) => d.total_amount_in_user_currency)
    );
  }, [expensesMappingData]);

  const totalExpenses = useMemo(() => {
    return (
      totalExpensesArray &&
      totalExpensesArray.reduce(
        (preValue, curValue) => parseFloat(preValue) + parseFloat(curValue),
        0
      )
    );
  }, [totalExpensesArray]);

  const netProfit = useMemo(() => {
    return totalIncomes - totalExpenses;
  }, [totalIncomes, totalExpenses]);

  const toggleShowFilter = () => {
    dispatch(statementsActions.toggleIncomeAction());
  };

  const currencyCode =
    userCurrency && userCurrency.currency ? userCurrency.currency.code : "";

  const pdfSupportedCurrSymbol = pdfSupportedCurrency(currencyCode);

  useEffect(() => {
    let start_time = null;
    let end_time = null;

    if (filterdDateRange && typeof filterdDateRange === "object") {
      start_time = moment(filterdDateRange[1]).format("YYYY-MM-DD");
      end_time = moment(filterdDateRange[0]).format("YYYY-MM-DD");
    } else {
      if (filterdDateRange === "Last 7 days") {
        start_time = moment(new Date()).format("YYYY-MM-DD");
        end_time = moment(start_time).subtract(6, "d").format("YYYY-MM-DD");
      } else if (filterdDateRange === "Last 15 days") {
        start_time = moment(new Date()).format("YYYY-MM-DD");
        end_time = moment(start_time).subtract(14, "d").format("YYYY-MM-DD");
      } else if (filterdDateRange === "Last 30 days") {
        start_time = moment(new Date()).format("YYYY-MM-DD");
        end_time = moment(start_time).subtract(29, "d").format("YYYY-MM-DD");
      } else if (filterdDateRange === "Last 45 days") {
        start_time = moment(new Date()).format("YYYY-MM-DD");
        end_time = moment(start_time).subtract(44, "d").format("YYYY-MM-DD");
      } else if (filterdDateRange === "Last 60 days") {
        start_time = moment(new Date()).format("YYYY-MM-DD");
        end_time = moment(start_time).subtract(59, "d").format("YYYY-MM-DD");
      } else if (filterdDateRange === "Last 90 days") {
        start_time = moment(new Date()).format("YYYY-MM-DD");
        end_time = moment(start_time).subtract(89, "d").format("YYYY-MM-DD");
      } else if (filterdDateRange === "This month") {
        const startDateOfThisMonth = moment(new Date())
          .startOf("month")
          .format("YYYY-MM-DD");
        const endDateOfThisMonth = moment(new Date())
          .endOf("month")
          .format("YYYY-MM-DD");
        start_time = moment(endDateOfThisMonth).format("YYYY-MM-DD");
        end_time = moment(startDateOfThisMonth).format("YYYY-MM-DD");
      } else if (filterdDateRange === "Last month") {
        const endDateOfLastMonth = moment(new Date())
          .subtract(1, "months")
          .endOf("month")
          .format("YYYY-MM-DD");
        const startDateOfLastMonth = moment(new Date())
          .subtract(1, "months")
          .startOf("month")
          .format("YYYY-MM-DD");
        start_time = moment(endDateOfLastMonth).format("YYYY-MM-DD");
        end_time = moment(startDateOfLastMonth).format("YYYY-MM-DD");
      } else if (filterdDateRange === "This year") {
        const endDateOfThisYear = moment(new Date())
          .endOf("year")
          .format("YYYY-MM-DD");
        const startDateOfThisYear = moment(new Date())
          .startOf("year")
          .format("YYYY-MM-DD");
        start_time = moment(endDateOfThisYear).format("YYYY-MM-DD");
        end_time = moment(startDateOfThisYear).format("YYYY-MM-DD");
      } else if (filterdDateRange === "Last year") {
        const endDateOfLastYear = moment(new Date())
          .subtract(1, "years")
          .endOf("year")
          .format("YYYY-MM-DD");
        const startDateOfLastYear = moment(new Date())
          .subtract(1, "years")
          .startOf("year")
          .format("YYYY-MM-DD");
        start_time = moment(endDateOfLastYear).format("YYYY-MM-DD");
        end_time = moment(startDateOfLastYear).format("YYYY-MM-DD");
      }
    }

    const Obj = {
      start_time,
      end_time,
      client_id: filteredClient !== 0 ? filteredClient : null,
      project_id: filteredProject !== 0 ? filteredProject : null,
    };
    dispatch(fetchIncomes(Obj));
  }, [filteredData]);

  const IncomesPdf = () => {
    return (
      <Document>
        <Page size="A4" style={styles.page}>
          <View
            style={{
              position: "absolute",
              top: 16,
              left: 16,
              width: 90,
              height: "auto",
            }}
          >
            <Image src={pdfBrandingLogo} />
          </View>

          <View style={styles.heading}>
            <Text>Income Statement</Text>
          </View>
          <View style={styles.name}>
            <Text>
              {businessInfoData && businessInfoData.business_name
                ? businessInfoData.business_name
                : `${data.firstName} ${data.lastName}`}
            </Text>
          </View>
          <View style={styles.range}>
            <Text>
              {filterdDateRange && typeof filterdDateRange === "object"
                ? `From ${moment(filterdDateRange[0]).format("Do MMM YYYY")}`
                : filterdDateRange === "Last 7 days"
                ? `From ${moment(new Date())
                    .subtract(6, "d")
                    .format("Do MMM YYYY")}`
                : filterdDateRange === "Last 15 days"
                ? `From ${moment(new Date())
                    .subtract(14, "d")
                    .format("Do MMM YYYY")}`
                : filterdDateRange === "Last 30 days"
                ? `From ${moment(new Date())
                    .subtract(29, "d")
                    .format("Do MMM YYYY")}`
                : filterdDateRange === "Last 45 days"
                ? `From ${moment(new Date())
                    .subtract(44, "d")
                    .format("Do MMM YYYY")}`
                : filterdDateRange === "Last 60 days"
                ? `From ${moment(new Date())
                    .subtract(59, "d")
                    .format("Do MMM YYYY")}`
                : filterdDateRange === "Last 90 days"
                ? `From ${moment(new Date())
                    .subtract(89, "d")
                    .format("Do MMM YYYY")}`
                : filterdDateRange === "This month"
                ? `From ${moment(new Date())
                    .startOf("month")
                    .format("Do MMM YYYY")}`
                : filterdDateRange === "Last month"
                ? `From ${moment(new Date())
                    .startOf("month")
                    .subtract(1, "months")
                    .format("Do MMM YYYY")}`
                : filterdDateRange === "This year"
                ? `From ${moment(new Date())
                    .startOf("year")
                    .format("Do MMM YYYY")}`
                : filterdDateRange === "Last year"
                ? `From ${moment(new Date())
                    .startOf("year")
                    .subtract(1, "years")
                    .format("Do MMM YYYY")}`
                : `From ${
                    incomesData &&
                    incomesData.length &&
                    moment(
                      incomesData[incomesData.length - 1].paid_date
                    ).format("Do MMM YYYY")
                  }`}

              {filterdDateRange && typeof filterdDateRange === "object"
                ? ` to ${moment(filterdDateRange[1]).format("Do MMM YYYY")}`
                : filterdDateRange ===
                  ("Last 7 days" ||
                    "Last 15 days" ||
                    "Last 30 days" ||
                    "Last 45 days" ||
                    "Last 60 days" ||
                    "Last 90 days")
                ? ` to ${moment(new Date()).format("Do MMM YYYY")}`
                : filterdDateRange === "This month"
                ? ` to ${moment(new Date())
                    .endOf("month")
                    .format("Do MMM YYYY")}`
                : filterdDateRange === "Last month"
                ? ` to ${moment(new Date())
                    .subtract(1, "months")
                    .endOf("month")
                    .format("Do MMM YYYY")}`
                : filterdDateRange === "This year"
                ? ` to ${moment(new Date())
                    .endOf("year")
                    .format("Do MMM YYYY")}`
                : filterdDateRange === "Last year"
                ? ` to ${moment(new Date())
                    .subtract(1, "years")
                    .endOf("year")
                    .format("Do MMM YYYY")}`
                : ` to ${moment(new Date()).format("Do MMM YYYY")}`}
            </Text>
          </View>

          <View style={styles.notes}>
            <Text>
              Note: The currency of the statement is in
              {` ${
                userCurrency &&
                userCurrency.currency &&
                userCurrency.currency.name
              }. `}
            </Text>
          </View>

          {incomesData && incomesData.length > 0 && (
            <View style={styles.desc_heading}>
              <Text>Income</Text>
            </View>
          )}

          {incomesMappingData && incomesMappingData.length > 0 && (
            <View style={{ border: `1px solid ${Colors.font2}` }}>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  fontWeight: "bold",
                  fontSize: 12,
                  backgroundColor: `${Colors.backgroundGrey}`,
                }}
              >
                <Text
                  style={{
                    width: "15%",
                    borderRight: `1px solid ${Colors.font2}`,
                    paddingTop: 6,
                    paddingBottom: 10,
                    paddingLeft: 10,
                  }}
                >
                  Date
                </Text>

                <Text
                  style={{
                    width: "30%",
                    borderRight: `1px solid ${Colors.font2}`,
                    paddingTop: 6,
                    paddingBottom: 10,
                    paddingLeft: 10,
                  }}
                >
                  Client
                </Text>

                <Text
                  style={{
                    width: "30%",
                    borderRight: `1px solid ${Colors.font2}`,
                    paddingTop: 6,
                    paddingBottom: 10,
                    paddingLeft: 10,
                  }}
                >
                  Project
                </Text>

                <Text
                  style={{
                    width: "25%",
                    paddingTop: 6,
                    paddingBottom: 10,
                    paddingLeft: 10,
                  }}
                >
                  Amount
                </Text>
              </View>

              {incomesMappingData.map((item, index) => {
                return (
                  <View style={styles.desc} key={index}>
                    <Text
                      style={{
                        width: "15%",
                        maxLines: 1,
                        borderRight: `1px solid ${Colors.font2}`,
                        paddingTop: 6,
                        paddingBottom: 10,
                        paddingLeft: 10,
                      }}
                    >
                      {moment(item.paid_date).format("DD/MM/YYYY")}
                    </Text>

                    <Text
                      style={{
                        width: "30%",
                        maxLines: 1,
                        borderRight: `1px solid ${Colors.font2}`,
                        paddingTop: 6,
                        paddingBottom: 10,
                        paddingLeft: 10,
                      }}
                    >
                      {item.client_id && item.client_id.company_name}
                    </Text>

                    <Text
                      style={{
                        width: "30%",
                        maxLines: 1,
                        borderRight: `1px solid ${Colors.font2}`,
                        paddingTop: 6,
                        paddingBottom: 10,
                        paddingLeft: 10,
                      }}
                    >
                      {item.project_id && item.project_id.project_name
                        ? item.project_id.project_name
                        : ""}
                    </Text>

                    <Text
                      style={{
                        width: "25%",
                        maxLines: 1,
                        paddingTop: 6,
                        paddingBottom: 10,
                        paddingLeft: 10,
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: `${
                            currencyCode === "INR" || !pdfSupportedCurrSymbol
                              ? "Poppins"
                              : "CODE2000"
                          }`,
                        }}
                      >
                        {pdfSupportedCurrSymbol ? currencySymbol : currencyCode}
                      </Text>
                      <Text>
                        {parseFloat(item.total_amount_in_user_currency).toFixed(
                          2
                        )}
                      </Text>
                    </Text>
                  </View>
                );
              })}

              {totalIncomes > 0 && (
                <View style={styles.desc_subHeading}>
                  <Text style={{ fontWeight: 500 }}>Total Income</Text>
                  <Text style={{ minWidth: "25%" }}>
                    <Text
                      style={{
                        fontFamily: `${
                          currencyCode === "INR" || !pdfSupportedCurrSymbol
                            ? "Poppins"
                            : "CODE2000"
                        }`,
                      }}
                    >
                      {pdfSupportedCurrSymbol ? currencySymbol : currencyCode}
                    </Text>
                    <Text>{parseFloat(totalIncomes).toFixed(2)}</Text>
                  </Text>
                </View>
              )}
            </View>
          )}

          {totalExpenses > 0 && (
            <View style={styles.desc_heading}>
              <Text>Less Expenses</Text>
            </View>
          )}

          {expensesMappingData && expensesMappingData.length > 0 && (
            <View
              style={{ border: `1px solid ${Colors.font2}`, marginBottom: 12 }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  fontWeight: "bold",
                  fontSize: 12,
                  backgroundColor: `${Colors.backgroundGrey}`,
                }}
              >
                <Text
                  style={{
                    width: "15%",
                    borderRight: `1px solid ${Colors.font2}`,
                    paddingTop: 6,
                    paddingBottom: 10,
                    paddingLeft: 10,
                  }}
                >
                  Date
                </Text>

                <Text
                  style={{
                    width: "30%",
                    borderRight: `1px solid ${Colors.font2}`,
                    paddingTop: 6,
                    paddingBottom: 10,
                    paddingLeft: 10,
                  }}
                >
                  Client
                </Text>

                <Text
                  style={{
                    width: "30%",
                    borderRight: `1px solid ${Colors.font2}`,
                    paddingTop: 6,
                    paddingBottom: 10,
                    paddingLeft: 10,
                  }}
                >
                  Project
                </Text>

                <Text
                  style={{
                    width: "25%",
                    paddingTop: 6,
                    paddingBottom: 10,
                    paddingLeft: 10,
                  }}
                >
                  Amount
                </Text>
              </View>

              {expensesMappingData.map((item, index) => {
                return (
                  <View style={styles.desc} key={index}>
                    <Text
                      style={{
                        width: "15%",
                        maxLines: 1,
                        borderRight: `1px solid ${Colors.font2}`,
                        paddingTop: 6,
                        paddingBottom: 10,
                        paddingLeft: 10,
                      }}
                    >
                      {moment(item.paid_date).format("DD/MM/YYYY")}
                    </Text>

                    <Text
                      style={{
                        width: "30%",
                        maxLines: 1,
                        borderRight: `1px solid ${Colors.font2}`,
                        paddingTop: 6,
                        paddingBottom: 10,
                        paddingLeft: 10,
                      }}
                    >
                      {item.client_id && item.client_id.company_name}
                    </Text>

                    <Text
                      style={{
                        width: "30%",
                        maxLines: 1,
                        borderRight: `1px solid ${Colors.font2}`,
                        paddingTop: 6,
                        paddingBottom: 10,
                        paddingLeft: 10,
                      }}
                    >
                      {item.project_id && item.project_id.project_name
                        ? item.project_id.project_name
                        : ""}
                    </Text>

                    <Text
                      style={{
                        width: "25%",
                        maxLines: 1,
                        paddingTop: 6,
                        paddingBottom: 10,
                        paddingLeft: 10,
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: `${
                            currencyCode === "INR" || !pdfSupportedCurrSymbol
                              ? "Poppins"
                              : "CODE2000"
                          }`,
                        }}
                      >
                        {pdfSupportedCurrSymbol ? currencySymbol : currencyCode}
                      </Text>
                      <Text>
                        {parseFloat(item.total_amount_in_user_currency).toFixed(
                          2
                        )}
                      </Text>
                    </Text>
                  </View>
                );
              })}

              {totalExpenses > 0 && (
                <View style={styles.desc_subHeading}>
                  <Text style={{ fontWeight: 500 }}>Total Expense</Text>
                  <Text style={{ minWidth: "25%" }}>
                    <Text
                      style={{
                        fontFamily: `${
                          currencyCode === "INR" || !pdfSupportedCurrSymbol
                            ? "Poppins"
                            : "CODE2000"
                        }`,
                      }}
                    >
                      {pdfSupportedCurrSymbol ? currencySymbol : currencyCode}
                    </Text>
                    <Text>{parseFloat(totalExpenses).toFixed(2)}</Text>
                  </Text>
                </View>
              )}
            </View>
          )}

          {(totalExpenses > 0 || totalIncomes > 0) && (
            <View
              style={{
                ...styles.desc_heading,
                backgroundColor: Colors.backgroundGrey,
                padding: `10px 0 14px 0`,
                borderTop: `1px solid ${Colors.font2}`,
                borderBottom: `1px solid ${Colors.font2}`,
              }}
            >
              <Text style={{ fontWeight: 500 }}>Profit Before Tax</Text>
              <Text style={{ minWidth: "25%" }}>
                {/* {netProfit < 0
                  ? `-${currencySymbol}${parseFloat(
                      Math.abs(netProfit)
                    ).toFixed(2)}`
                  : netProfit > 0
                  ? `${currencySymbol}${parseFloat(Math.abs(netProfit)).toFixed(
                      2
                    )}`
                  : netProfit === 0
                  ? `${currencySymbol}00.00`
                  : ""} */}
                <Text
                  style={{
                    fontFamily: `${
                      currencyCode === "INR" || !pdfSupportedCurrSymbol
                        ? "Poppins"
                        : "CODE2000"
                    }`,
                  }}
                >
                  {netProfit < 0 ? "-" : ""}
                  {pdfSupportedCurrSymbol ? currencySymbol : currencyCode}
                </Text>

                <Text>
                  {netProfit !== 0
                    ? parseFloat(Math.abs(netProfit)).toFixed(2)
                    : "00.00"}
                </Text>
              </Text>
            </View>
          )}
        </Page>
      </Document>
    );
  };

  return (
    <div>
      <div
        className="d-flex gap-2 justify-content-end my-3"
        style={{ minHeight: 40 }}
      >
        <TextStyles.HoverEffectSpan onClick={toggleShowFilter}>
          Filter
        </TextStyles.HoverEffectSpan>

        {(incomesMappingData && incomesMappingData.length) ||
        (expensesMappingData && expensesMappingData.length) ? (
          <span
            className="d-flex justify-content-center align-items-center p-0"
            style={{
              width: 40,
              height: 40,
              background: Colors.primary,
              borderRadius: "50%",
              cursor: "pointer",
            }}
          >
            <PDFDownloadLink
              document={<IncomesPdf />}
              fileName="Incomestatement.pdf"
            >
              <SaveAltIcon sx={{ color: Colors.white, fontSize: 27 }} />
            </PDFDownloadLink>
          </span>
        ) : (
          ""
        )}
      </div>

      {toggleFilter && (
        <div className="d-flex flex-wrap gap-3 mb-3">
          <div>
            <TextStyles.FontSize14px className="d-flex mb-2">
              Date range
            </TextStyles.FontSize14px>

            <SelectDate
              defaultValue={filterdDateRange && filterdDateRange}
              onChange={(value) => {
                dispatch(
                  statementsActions.filterIncomeAction({
                    filterdDateRange: value,
                  })
                );
              }}
            />
          </div>

          <div>
            <TextStyles.FontSize14px className="d-flex mb-2">
              Client
            </TextStyles.FontSize14px>
            <SelectClients
              options={clientOptionData}
              defaultValue={filteredClient && filteredClient}
              onChange={(e) =>
                dispatch(
                  statementsActions.filterIncomeAction({
                    filteredClient: e.target.value,
                  })
                )
              }
            />
          </div>

          <div>
            <TextStyles.FontSize14px className="d-flex mb-2">
              Project
            </TextStyles.FontSize14px>
            <SelectProjects
              defaultValue={filteredProject && filteredProject}
              options={projectOptionsData}
              onChange={(e) =>
                dispatch(
                  statementsActions.filterIncomeAction({
                    filteredProject: e.target.value,
                  })
                )
              }
            />
          </div>
        </div>
      )}

      {!isLoading &&
        incomesMappingData &&
        !incomesMappingData.length &&
        incomesMappingData &&
        !expensesMappingData.length && (
          <div
            className="text-center font-weight-500"
            style={{ marginTop: "7rem", fontSize: "1.3rem" }}
          >
            No income or expense found!
          </div>
        )}

      {isLoading ? (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: 200 }}
        >
          <LoaderSpin color={Colors.primary} />
        </div>
      ) : (incomesMappingData && incomesMappingData.length) ||
        (expensesMappingData && expensesMappingData.length) ? (
        <Styled.EditIncomesBox className="mx-auto">
          <TextStyles.FontSize27px className="mb-2 text-center font-weight-500">
            Income Statement
          </TextStyles.FontSize27px>
          <TextStyles.FontSize20px className="mb-1 text-center font-weight-500">
            {businessInfoData && businessInfoData.business_name
              ? businessInfoData.business_name
              : `${data.firstName} ${data.lastName}`}
          </TextStyles.FontSize20px>
          <TextStyles.GreyFont14px className="mb-4 text-center font-weight-500">
            {filterdDateRange && typeof filterdDateRange === "object"
              ? `From ${moment(filterdDateRange[0]).format("Do MMM YYYY")}`
              : filterdDateRange === "Last 7 days"
              ? `From ${moment(new Date())
                  .subtract(6, "d")
                  .format("Do MMM YYYY")}`
              : filterdDateRange === "Last 15 days"
              ? `From ${moment(new Date())
                  .subtract(14, "d")
                  .format("Do MMM YYYY")}`
              : filterdDateRange === "Last 30 days"
              ? `From ${moment(new Date())
                  .subtract(29, "d")
                  .format("Do MMM YYYY")}`
              : filterdDateRange === "Last 45 days"
              ? `From ${moment(new Date())
                  .subtract(44, "d")
                  .format("Do MMM YYYY")}`
              : filterdDateRange === "Last 60 days"
              ? `From ${moment(new Date())
                  .subtract(59, "d")
                  .format("Do MMM YYYY")}`
              : filterdDateRange === "Last 90 days"
              ? `From ${moment(new Date())
                  .subtract(89, "d")
                  .format("Do MMM YYYY")}`
              : filterdDateRange === "This month"
              ? `From ${moment(new Date())
                  .startOf("month")
                  .format("Do MMM YYYY")}`
              : filterdDateRange === "Last month"
              ? `From ${moment(new Date())
                  .startOf("month")
                  .subtract(1, "months")
                  .format("Do MMM YYYY")}`
              : filterdDateRange === "This year"
              ? `From ${moment(new Date())
                  .startOf("year")
                  .format("Do MMM YYYY")}`
              : filterdDateRange === "Last year"
              ? `From ${moment(new Date())
                  .startOf("year")
                  .subtract(1, "years")
                  .format("Do MMM YYYY")}`
              : `From ${
                  incomesData &&
                  incomesData.length &&
                  moment(incomesData[incomesData.length - 1].paid_date).format(
                    "Do MMM YYYY"
                  )
                }`}

            {filterdDateRange && typeof filterdDateRange === "object"
              ? ` to ${moment(filterdDateRange[1]).format("Do MMM YYYY")}`
              : filterdDateRange ===
                ("Last 7 days" ||
                  "Last 15 days" ||
                  "Last 30 days" ||
                  "Last 45 days" ||
                  "Last 60 days" ||
                  "Last 90 days")
              ? ` to ${moment(new Date()).format("Do MMM YYYY")}`
              : filterdDateRange === "This month"
              ? ` to ${moment(new Date()).endOf("month").format("Do MMM YYYY")}`
              : filterdDateRange === "Last month"
              ? ` to ${moment(new Date())
                  .subtract(1, "months")
                  .endOf("month")
                  .format("Do MMM YYYY")}`
              : filterdDateRange === "This year"
              ? ` to ${moment(new Date()).endOf("year").format("Do MMM YYYY")}`
              : filterdDateRange === "Last year"
              ? ` to ${moment(new Date())
                  .subtract(1, "years")
                  .endOf("year")
                  .format("Do MMM YYYY")}`
              : ` to ${moment(new Date()).format("Do MMM YYYY")}`}
          </TextStyles.GreyFont14px>

          {incomesMappingData && incomesMappingData.length > 0 && (
            <>
              <TextStyles.FontSize22px className="font-weight-500">
                Income
              </TextStyles.FontSize22px>
              <hr className="w-100 text-dark m-0 mb-3" />
            </>
          )}

          {incomesMappingData && incomesMappingData.length > 0 && (
            <Styled.StatementContainer style={{ fontWeight: 500 }}>
              <TextStyles.FontSize16px className="three-dots">
                Date
              </TextStyles.FontSize16px>

              <TextStyles.FontSize16px className="three-dots">
                Client
              </TextStyles.FontSize16px>

              <TextStyles.FontSize16px className="three-dots">
                Project
              </TextStyles.FontSize16px>

              <TextStyles.FontSize16px className="three-dots">
                Amount
              </TextStyles.FontSize16px>
            </Styled.StatementContainer>
          )}

          {incomesMappingData &&
            incomesMappingData.length > 0 &&
            incomesMappingData.map((item, index) => {
              return (
                <div key={index}>
                  <Styled.StatementContainer>
                    <TextStyles.FontSize14px className="three-dots">
                      {moment(item.paid_date).format("DD/MM/YYYY")}
                    </TextStyles.FontSize14px>

                    <TextStyles.FontSize14px className="three-dots">
                      {item.client_id && item.client_id.company_name}
                    </TextStyles.FontSize14px>

                    <TextStyles.FontSize14px className="three-dots">
                      {item.project_id && item.project_id.project_name
                        ? item.project_id.project_name
                        : ""}
                    </TextStyles.FontSize14px>

                    <TextStyles.FontSize14px className="three-dots">
                      {`${
                        pdfSupportedCurrSymbol ? currencySymbol : currencyCode
                      }${parseFloat(item.total_amount_in_user_currency).toFixed(
                        2
                      )}`}
                    </TextStyles.FontSize14px>
                  </Styled.StatementContainer>
                </div>
              );
            })}

          {incomesMappingData && incomesMappingData.length > 0 && (
            <>
              <hr className="w-100 text-dark m-0" />

              <div className="d-flex justify-content-between align-items-center gap-2 mt-2 mb-5">
                <TextStyles.FontSize16px className="three-dots font-weight-500">
                  Total Income
                </TextStyles.FontSize16px>

                <TextStyles.FontSize16px
                  className="three-dots text-end text-md-start font-weight-500"
                  style={{ minWidth: 132 }}
                >
                  {`${
                    pdfSupportedCurrSymbol ? currencySymbol : currencyCode
                  }${parseFloat(totalIncomes).toFixed(2)}`}
                </TextStyles.FontSize16px>
              </div>
            </>
          )}

          {expensesMappingData && expensesMappingData.length > 0 && (
            <>
              <TextStyles.FontSize22px className="font-weight-500 mb-1">
                Less Expenses
              </TextStyles.FontSize22px>

              <hr className="w-100 text-dark m-0 mb-3" />
            </>
          )}

          {expensesMappingData && expensesMappingData.length > 0 && (
            <Styled.StatementContainer className="font-weight-500">
              <TextStyles.FontSize16px className="three-dots">
                Date
              </TextStyles.FontSize16px>

              <TextStyles.FontSize16px className="three-dots">
                Client
              </TextStyles.FontSize16px>

              <TextStyles.FontSize16px className="three-dots">
                Project
              </TextStyles.FontSize16px>

              <TextStyles.FontSize16px className="three-dots">
                Amount
              </TextStyles.FontSize16px>
            </Styled.StatementContainer>
          )}

          {expensesMappingData &&
            expensesMappingData.map((item, index) => {
              return (
                <div key={index}>
                  <Styled.StatementContainer>
                    <TextStyles.FontSize14px className="three-dots">
                      {moment(item.paid_date).format("DD/MM/YYYY")}
                    </TextStyles.FontSize14px>

                    <TextStyles.FontSize14px className="three-dots">
                      {item.client_id && item.client_id.company_name}
                    </TextStyles.FontSize14px>

                    <TextStyles.FontSize14px className="three-dots">
                      {item.project_id && item.project_id.project_name
                        ? item.project_id.project_name
                        : ""}
                    </TextStyles.FontSize14px>

                    <TextStyles.FontSize14px className="three-dots">
                      {`${
                        pdfSupportedCurrSymbol ? currencySymbol : currencyCode
                      }${parseFloat(item.total_amount_in_user_currency).toFixed(
                        2
                      )}`}
                    </TextStyles.FontSize14px>
                  </Styled.StatementContainer>
                </div>
              );
            })}

          {expensesMappingData && expensesMappingData.length > 0 && (
            <div className="d-flex justify-content-between align-items-center gap-2 mb-3">
              <TextStyles.FontSize16px className="font-weight-500 three-dots">
                Total Expense
              </TextStyles.FontSize16px>

              <TextStyles.FontSize16px
                className="three-dots text-end text-md-start font-weight-500"
                style={{ minWidth: 132 }}
              >
                {`${
                  pdfSupportedCurrSymbol ? currencySymbol : currencyCode
                }${parseFloat(totalExpenses).toFixed(2)}`}
              </TextStyles.FontSize16px>
            </div>
          )}

          {((incomesMappingData && incomesMappingData.length > 0) ||
            (expensesMappingData && expensesMappingData.length > 0)) && (
            <hr className="w-100 text-dark m-0" />
          )}

          {((incomesMappingData && incomesMappingData.length > 0) ||
            (expensesMappingData && expensesMappingData.length > 0)) && (
            <div className="d-flex justify-content-between align-items-center w-100 mt-2">
              <TextStyles.FontSize20px
                className="d-flex justify-content-start w-100"
                style={{ minWidth: 100, fontWeight: 500 }}
              >
                <span className="three-dots">Profit Before Tax</span>
              </TextStyles.FontSize20px>
              <TextStyles.FontSize20px
                className="d-flex justify-content-end w-100"
                style={{ minWidth: 100 }}
              >
                <span
                  className="three-dots text-end text-md-start font-weight-500"
                  style={{ minWidth: 132 }}
                >
                  {netProfit < 0
                    ? `-${
                        pdfSupportedCurrSymbol ? currencySymbol : currencyCode
                      }${parseFloat(Math.abs(netProfit)).toFixed(2)}`
                    : netProfit > 0
                    ? `${
                        pdfSupportedCurrSymbol ? currencySymbol : currencyCode
                      }${parseFloat(Math.abs(netProfit)).toFixed(2)}`
                    : netProfit === 0
                    ? `${
                        pdfSupportedCurrSymbol ? currencySymbol : currencyCode
                      }00.00`
                    : ""}
                </span>
              </TextStyles.FontSize20px>
            </div>
          )}
        </Styled.EditIncomesBox>
      ) : (
        ""
      )}
    </div>
  );
};

export default Incomes;
