import React from "react";
import Colors from "../../../Constants/Colors";
import ProtectedRoute from "../../auth/ProtectedRoute";
import { BackButton } from "../Commons/BackAndForwardButton";
import Styled from "./AdminStyles";
import { useHistory } from "react-router-dom";
import ActiveUsers from "./ActiveUsers/ActiveUsers";
import DeletedUsers from "./DeletedUsers/DeletedUsers";

const Admin = () => {
  const history = useHistory();
  const urlElements = window.location.href.split("/");
  return (
    <div
      style={{
        backgroundColor: Colors.backgroundGrey,
        minHeight: "100vh",
        padding: "105px 0 60px 0",
        contain: "content",
      }}
    >
      <div
        className="d-flex justify-content-between align-items-center px-2 px-sm-3 px-md-4 px-lg-4"
        style={{ height: "4rem" }}
      >
        <div
          style={{
            width: "100%",
            position: "relative",
            overflowY: "hidden",
            height: "100%",
          }}
        >
          <Styled.SliderBox
            style={{
              position: "absolute",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              onClick={() => {
                history.push("/db/admin/activeUsers");
              }}
            >
              {urlElements[5] === "activeUsers" ? (
                <Styled.SelectedItem>Active Users</Styled.SelectedItem>
              ) : (
                <Styled.NotSelectedItem>Active Users</Styled.NotSelectedItem>
              )}
            </div>

            <div
              onClick={() => {
                history.push("/db/admin/deletedUsers");
              }}
            >
              {urlElements[5] === "deletedUsers" ? (
                <Styled.SelectedItem>Deleted Users</Styled.SelectedItem>
              ) : (
                <Styled.NotSelectedItem>Deleted Users</Styled.NotSelectedItem>
              )}
            </div>
          </Styled.SliderBox>
        </div>
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 25,
          }}
          onClick={() => history.goBack()}
        >
          <BackButton />
        </div>
      </div>
      <div className="px-2 px-sm-3 px-md-4 px-lg-4">
        <ProtectedRoute
          path="/db/admin/activeUsers"
          exact
          component={ActiveUsers}
        />
        <ProtectedRoute
          path="/db/admin/deletedUsers"
          exact
          component={DeletedUsers}
        />
      </div>
    </div>
  );
};

export default Admin;
