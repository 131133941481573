import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { message } from "antd";

import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";

import TextStyles from "../../../../../../Constants/TextStyles";
import Styled from "../AddSectionStyles";
import RemoveModal from "../../../../Commons/RemoveModal";
import { deleteAddSectionColumnPC } from "../../../../../../store/service/proposalAndContractService";
import EditAddSectionColumnModal from "./EditAddsectionColumnModal";
import Colors from "../../../../../../Constants/Colors";

const Column = ({ data, position, pc_id }) => {
  const dispatch = useDispatch();
  const [editModal, setEditModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const handleCloseRemoveModal = () => setShowRemoveModal(false);

  const deleteHandler = () => {
    const Obj = {
      pc_id: pc_id,
      _id: data._id,
      position: position,
    };

    dispatch(deleteAddSectionColumnPC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        message.success(`${data.payload.message}`);
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  return (
    <div className="mt-4">
      <Styled.ReviewBox>
        <div className="d-flex justify-content-end">
          <HighlightOffOutlinedIcon
            onClick={() => {
              setShowRemoveModal(true);
              setConfirmDialog({
                onConfirm: () => deleteHandler(),
              });
            }}
            sx={{ cursor: "pointer", fontSize: 24, color: Colors.red }}
          />
        </div>
        <TextStyles.FontSize14px className="mb-3">
          <div className="mb-2">Column title</div>
          <div className="d-flex gap-1 justify-content-between align-items-center">
            <div
              className="d-flex justify-content-start w-100"
              style={{ minWidth: 100 }}
            >
              <Styled.TextBoader
                className="d-flex w-100"
                style={{ minWidth: 100 }}
              >
                <span className="three-dots">{data.column_title}</span>
              </Styled.TextBoader>
            </div>

            <div
              className="d-flex justify-content-end w-100"
              style={{ minWidth: 30, maxWidth: 30 }}
            >
              <BorderColorOutlinedIcon
                onClick={() => setEditModal(true)}
                sx={{
                  fontSize: 24,
                  color: Colors.font2,
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        </TextStyles.FontSize14px>

        <div>
          <TextStyles.FontSize14px className="d-flex gap-1 gap-sm-2 justify-content-start mb-2">
            <div className="d-flex justify-content-start w-100">
              <Styled.TextBoader>{data.input.input_one}</Styled.TextBoader>
            </div>
            <div className="d-flex justify-content-start w-100">
              <Styled.TextBoader>{data.input.input_two}</Styled.TextBoader>
            </div>
            <div className="d-flex justify-content-start w-100">
              <Styled.TextBoader>{data.input.input_three}</Styled.TextBoader>
            </div>
          </TextStyles.FontSize14px>
        </div>
      </Styled.ReviewBox>
      {editModal && (
        <EditAddSectionColumnModal
          show={editModal}
          handleClose={() => setEditModal(false)}
          editData={data}
          pc_id={pc_id}
          position={position}
        />
      )}
      <RemoveModal
        title={"column"}
        confirmDialog={confirmDialog}
        showRemoveModal={showRemoveModal}
        handleCloseRemoveModal={handleCloseRemoveModal}
      />
    </div>
  );
};

export default Column;
