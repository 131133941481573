import styled from "styled-components";
import Colors from "../../../../../Constants/Colors";

const AddSectionContainer = styled.div`
  width: 100%;
  position: relative;
  .add_section_show_feature {
    display: none;
    z-index: 1;
    position: absolute;
    margin-top: -9.5rem;
    justify-content: space-between;
  }
  :hover .add_section_show_feature {
    display: flex;
  }

  @media (max-width: 576px) {
    .add_section_show_feature {
      margin-top: -7.5rem;
    }
  }
`;

const AddSectionBox = styled.div`
  width: 100%;
  padding: 0.75rem;
  border-radius: 0.75rem;
  display: flex;
  justify-content: center;
  cursor: pointer;
  position: relative;
  color: ${Colors.primary};
  background-color: ${Colors.backgroundPink};
  box-shadow: 0 0 4px 2px #e5e0e0;
`;

const TextBoader = styled.div`
  width: 100%;
  overflow-wrap: anywhere;
  border-radius: 0.75rem;
  padding: 0.6rem 1rem;
  border: 1px solid ${(props) => (props.invalid ? "red" : "#e4e4e4")};
  background-color: ${(props) => (props.invalid ? "#e7d5d5" : Colors.white)};
  @media (max-width: 576px) {
    padding: 0.6rem;
  }
`;

const IconsBox = styled.div`
  width: 8rem;
  height: 8rem;
  cursor: pointer;
  border-radius: 0.75rem;
  box-shadow: 0 8px 12px 0 rgba(61, 61, 61, 0.09);
  background-color: ${Colors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &:hover {
    border: 2px solid ${Colors.primary};
  }
  img {
    width: 100%;
    max-width: 3.5rem;
    height: 100%;
    max-height: 3.5rem;
  }
  @media (max-width: 576px) {
    width: 6rem;
    height: 6rem;
    img {
      max-width: 2.5rem;
      max-height: 2.5rem;
    }
  }
`;

const ReviewBox = styled.div`
  width: 100%;
  height: auto;
  padding: 1.5rem;
  border-radius: 0.75rem;
  background-color: ${Colors.backgroundGrey};
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.1);
  @media (max-width: 576px) {
    padding: 1rem;
  }
`;

const ImageBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: auto;
  min-height: 12.5rem;
  padding: 1.125rem;
  border-radius: 0.75rem;
  border: dashed 3px #a9abb3;
  background-color: #e5eaf0;
  box-sizing: border-box;
  @media (max-width: 576px) {
    padding: 0.5rem;
  }
`;

const EditContainer = styled.div`
  .edit_imgBtn {
    visibility: hidden;
  }
  :hover .edit_imgBtn {
    visibility: visible;
  }
`;

const FileDownloadContainer = styled.div`
  cursor: pointer;
  background: #e5eaf0;
  border: 1px solid #e9e9e9;
  border-radius: 0.75rem;
  padding: 1rem;
  margin: 0px 0px 6px 0px;
  transition: all 0.3s ease;
  :hover {
    font-weight: 500;
    text-decoration: underline ${Colors.font1} 1.5px;
  }
  :hover i {
    color: ${Colors.font1};
    font-weight: 500;
  }
  @media (max-width: 576px) {
    padding: 0.5rem;
  }
`;

export default {
  AddSectionContainer,
  AddSectionBox,
  IconsBox,
  ReviewBox,
  ImageBox,
  TextBoader,
  EditContainer,
  FileDownloadContainer,
};
