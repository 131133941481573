import styled from "styled-components";
import Colors from "../../Constants/Colors";

const DashboardDrawer = styled.div`
  .item-selected {
    font-family: Poppins, sans-serif;
    border-radius: 0.75rem !important;
    background-color: ${Colors.primary} !important;
    color: ${Colors.white};
  }
  .item-selected a {
    text-decoration: none;
  }

  .item-selected .item-text {
    color: ${Colors.white} !important;
  }

  .item-selected svg {
    filter: invert(1) brightness(5) !important ;
  }

  .item-notselected a {
    text-decoration: none;
  }

  .item-notselected {
    font-family: Poppins, sans-serif;
    border: none !important;
    background-color: ${Colors.white} !important;
    color: var(--black);
  }

  .item-text {
    color: ${Colors.frontendFont1} !important;
    display: flex;
    align-items: center;
  }

  .font-color {
    color: ${Colors.frontendFont1};
    font-weight: 500;
  }

  .menu-li:hover {
    font-family: Poppins, sans-serif;
    border-radius: 0.75rem !important;
    background-color: ${Colors.primary} !important;
    color: ${Colors.white} !important;
    cursor: pointer;
  }

  .menu-li:hover a {
    text-decoration: none !important;
    color: ${Colors.white} !important;
  }

  .menu-li:hover .item-text {
    color: ${Colors.white} !important;
  }

  .menu-li a:visited {
    color: var(--font2);
  }

  .menu-li:hover svg {
    filter: invert(1) brightness(5) !important;
  }

  .dashboard-content {
    width: calc(100% - 250px);
  }

  .side_drawer {
    padding-top: 4.312rem;
    height: 100%;
    background: ${Colors.white};
  }

  .side_drawer.show {
    transform: translateX(0);
  }

  @media screen and (max-width: 992px) {
    .side_drawer {
      left: 0;
      position: fixed;
      width: 15.625rem;
      z-index: 300;
      transform: translate(-100%);
      transition: transform 0.4s ease-out;
    }
    .dashboard-content {
      width: 100vw;
    }
  }

  @media screen and (min-width: 993px) {
    .side_drawer {
      width: 15.625rem;
    }
  }
`;

export default {
  DashboardDrawer,
};
