import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { editClient } from "../../../../../store/service/clientService";

import Contact from "../contacts/Contact.jsx";
import LoaderSpin from "../../../Commons/LoaderSpin";

import { message } from "antd";
import TextStyles from "../../../../../Constants/TextStyles";
import SelectCountry from "../../../Commons/SelectCountry";
import Constant from "../../../Commons/Constant";

const AddressAndContacts = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.clients.isLoading);
  const c_data_position = useSelector((state) => state.clients.c_data_position);
  const [submited, setSubmitted] = useState(false);

  const {
    register,
    reset,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      company_name: c_data_position && c_data_position.company_name,
      street: c_data_position && c_data_position.street,
      suite: c_data_position && c_data_position.suite,
      city: c_data_position && c_data_position.city,
      zip_code: c_data_position && c_data_position.zip_code,
      country:
        c_data_position &&
        c_data_position.country &&
        Constant.countryOptions.filter(
          (data) => data.name === c_data_position.country
        )[0],
      state: c_data_position && c_data_position.state,
      tax_id_label: c_data_position && c_data_position.tax_id_label,
      tax_id_number: c_data_position && c_data_position.tax_id_number,
    },
  });

  useEffect(() => {
    reset({
      company_name: c_data_position && c_data_position.company_name,
      street: c_data_position && c_data_position.street,
      suite: c_data_position && c_data_position.suite,
      city: c_data_position && c_data_position.city,
      zip_code: c_data_position && c_data_position.zip_code,
      country:
        c_data_position &&
        c_data_position.country &&
        Constant.countryOptions.filter(
          (data) => data.name === c_data_position.country
        )[0],
      state: c_data_position && c_data_position.state,
      tax_id_label: c_data_position && c_data_position.tax_id_label,
      tax_id_number: c_data_position && c_data_position.tax_id_number,
    });
  }, [c_data_position]);

  const addressFormSubmit = async (data) => {
    const Obj = {
      client_id: c_data_position._id,
      company_name: data.company_name,
      zip_code: data.zip_code,
      street: data.street,
      suite: data.suite,
      city: data.city,
      country: data.country ? data.country.name : null,
      state: data.state,
      tax_id_label: data.tax_id_label,
      tax_id_number: data.tax_id_number,
    };
    setSubmitted(true);
    dispatch(editClient(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        setSubmitted(false);
        message.success(`${data.payload.message}`);
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  return (
    <>
      <div className="d-flex mt-4 w-100">
        <div className="w-100" style={{ maxWidth: 700 }}>
          <form onSubmit={handleSubmit(addressFormSubmit)}>
            <TextStyles.FontSize14px className="mb-3">
              <label htmlFor="company_name" className="mb-2">
                Company name
              </label>
              <TextStyles.InputRectangle
                invalid={errors.company_name}
                id="company_name"
                type="text"
                name="company_name"
                placeholder="Company name"
                {...register("company_name", {
                  required: "Company name is required.",
                  validate: (value) => {
                    let trimedLength = value.trim().length;
                    if (trimedLength === 0) {
                      return "Company name is required.";
                    }
                    return true;
                  },
                })}
              />
              {errors.company_name && (
                <TextStyles.InValidFeedback>
                  {errors.company_name.message}
                </TextStyles.InValidFeedback>
              )}
            </TextStyles.FontSize14px>
            <TextStyles.FontSize14px className="mb-3">
              <div className="row">
                <div className="col-12 col-sm-6 mb-3 mb-sm-0">
                  <label htmlFor="street_address" className="mb-2">
                    Street address
                  </label>
                  <TextStyles.InputRectangle
                    id="street_address"
                    type="text"
                    placeholder="Street address"
                    name="street"
                    {...register("street")}
                  />
                </div>
                <div className="col-12 col-sm-6">
                  <label htmlFor="suite_address" className="mb-2">
                    Suite address
                  </label>
                  <TextStyles.InputRectangle
                    id="suite_address"
                    type="text"
                    placeholder="suite address"
                    name="suite"
                    {...register("suite")}
                  />
                </div>
              </div>
            </TextStyles.FontSize14px>
            <TextStyles.FontSize14px className="mb-3">
              <div className="row">
                <div className="col-12 col-sm-6 mb-3 mb-sm-0">
                  <label htmlFor="city" className="mb-2">
                    City
                  </label>
                  <TextStyles.InputRectangle
                    id="city"
                    type="text"
                    placeholder="City"
                    name="city"
                    {...register("city")}
                  />
                </div>
                <div className="col-12 col-sm-6">
                  <label htmlFor="zip_code" className="mb-2">
                    Zip code
                  </label>
                  <TextStyles.InputRectangle
                    id="zip_code"
                    type="text"
                    placeholder="Zip code"
                    name="zip_code"
                    {...register("zip_code")}
                  />
                </div>
              </div>
            </TextStyles.FontSize14px>
            <TextStyles.FontSize14px className="mb-3">
              <div className="row">
                <div className="col-12 col-sm-6 mb-3 mb-sm-0">
                  <label htmlFor="state" className="mb-2">
                    State
                  </label>
                  <TextStyles.InputRectangle
                    id="state"
                    type="text"
                    placeholder="State"
                    name="state"
                    {...register("state")}
                  />
                </div>
                <div className="col-12 col-sm-6">
                  {" "}
                  <label htmlFor="country" className="mb-2">
                    Country
                  </label>
                  <div>
                    <Controller
                      control={control}
                      id="country"
                      name="country"
                      render={({ field: { onChange, value } }) => (
                        <SelectCountry
                          placeholder="Select country"
                          onChange={onChange}
                          defaultValue={value}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </TextStyles.FontSize14px>
            <TextStyles.FontSize14px className="mb-3">
              <div className="row">
                <div className="col-12 col-sm-6 mb-3 mb-sm-0">
                  <label htmlFor="tax_id_label" className="mb-2">
                    Tax ID label
                  </label>
                  <TextStyles.InputRectangle
                    id="tax_id_label"
                    type="text"
                    placeholder="Tax ID label"
                    name="tax_id_label"
                    {...register("tax_id_label")}
                  />
                </div>
                <div className="col-12 col-sm-6">
                  <label htmlFor="tax_id_number" className="mb-2">
                    Tax ID number
                  </label>
                  <TextStyles.InputRectangle
                    id="tax_id_number"
                    type="text"
                    placeholder="Tax ID number"
                    name="tax_id_number"
                    {...register("tax_id_number")}
                  />
                </div>
              </div>
            </TextStyles.FontSize14px>
            <div className="mb-3">
              <TextStyles.Button type="submit" disabled={submited}>
                {submited && isLoading ? <LoaderSpin /> : "Save changes"}
              </TextStyles.Button>
            </div>
          </form>
        </div>
      </div>
      <Contact />
    </>
  );
};

export default AddressAndContacts;
