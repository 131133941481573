import React from "react";

import ClearIcon from "@mui/icons-material/Clear";

import TextStyles from "../../Constants/TextStyles";
import { Modal } from "react-bootstrap";
import Colors from "../../Constants/Colors";
import { useHistory } from "react-router-dom";

const DeletedAccountSuccessModal = ({ show, handleClose }) => {
  const history = useHistory();
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      contentClassName="ToMakeModalBorderRudius12px"
    >
      <Modal.Header
        className="d-flex justify-content-between align-items-center"
        style={{ borderBottom: "0px" }}
      >
        <TextStyles.FontSize27px style={{ fontWeight: 500 }}>
          Welcome back!
        </TextStyles.FontSize27px>
        <ClearIcon
          sx={{
            color: Colors.font2,
            cursor: "pointer",
            opacity: 0.7,
            "&:hover": {
              opacity: 1,
            },
          }}
          onClick={handleClose}
        />
      </Modal.Header>
      <Modal.Body className="py-0">
        <TextStyles.FontSize16px className="mb-3">
          Your account has been deleted.
        </TextStyles.FontSize16px>
        <TextStyles.FontSize16px className="mb-3">
          Please contact us to create your account with the same email id.
        </TextStyles.FontSize16px>
        <div className="d-flex justify-content-center mb-3">
          <TextStyles.Button
            onClick={() => history.push("/contact")}
            style={{
              fontSize: 16,
              width: 160,
            }}
          >
            Contact us
          </TextStyles.Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeletedAccountSuccessModal;
