import { createSlice } from "@reduxjs/toolkit";
import { deleteUserAccount } from "../service/deleteUserAccountService";

const initialDeleteUserAccountState = {
  isLoading: false,
  error: null,
};

export const deleteUserAccountSlice = createSlice({
  name: "deleteUserAccount",
  initialState: initialDeleteUserAccountState,
  reducers: {},

  extraReducers: (builder) => {
    // TODO: delete user account
    builder
      .addCase(deleteUserAccount.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteUserAccount.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteUserAccount.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});
export const deleteUserAccountAction = deleteUserAccountSlice.actions;
export default deleteUserAccountSlice.reducer;
