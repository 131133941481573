import React from "react";
import { Modal } from "react-bootstrap";
import ClearIcon from "@mui/icons-material/Clear";

import moment from "moment";

import TextStyles from "../../../../Constants/TextStyles";
import Colors from "../../../../Constants/Colors";

const EmailPreview = ({
  show,
  handleClose,
  text,
  subject,
  businessInfoData,
  selectedInvoiceData,
  data,
  brandAndLogoData,
}) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="ToMakeModalOnRight600px"
      fullscreen
    >
      <Modal.Header className="d-flex align-items-center justify-content-between">
        <TextStyles.FontSize20px style={{ fontWeight: 500, fontSize: 24 }}>
          Email preview
        </TextStyles.FontSize20px>
        <ClearIcon
          sx={{
            color: Colors.font2,
            fontSize: 30,
            cursor: "pointer",
            opacity: 0.7,
            "&:hover": {
              opacity: 1,
            },
          }}
          onClick={handleClose}
        />
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex mb-2">
          <TextStyles.GreyFont14px className="pe-2">
            From:
          </TextStyles.GreyFont14px>
          <TextStyles.FontSize14px
            className="d-flex w-100"
            style={{ minWidth: 100 }}
          >
            <span className="three-dots">
              {businessInfoData && businessInfoData.business_name
                ? `${businessInfoData.business_name} via Zodot`
                : `${data.firstName} ${data.lastName} via Zodot`}
            </span>
          </TextStyles.FontSize14px>
        </div>

        <div className="d-flex mb-2">
          <TextStyles.GreyFont14px className="pe-2">
            Subject:
          </TextStyles.GreyFont14px>
          <TextStyles.FontSize14px
            className="d-flex w-100"
            style={{ minWidth: 100 }}
          >
            <span className="three-dots">{subject}</span>
          </TextStyles.FontSize14px>
        </div>

        <div
          className="p-3 p-sm-4 mt-4"
          style={{
            background: Colors.white,
            borderTop: `4px solid ${
              brandAndLogoData && brandAndLogoData.brand_color
                ? brandAndLogoData.brand_color
                : Colors.primary
            }`,
            boxShadow: "0px 0px 9px 0px lightgrey",
          }}
        >
          {brandAndLogoData &&
            brandAndLogoData.brand_logo &&
            brandAndLogoData.brand_logo.file_url && (
              <div
                className={
                  brandAndLogoData && brandAndLogoData.logo_alignment
                    ? `text-${brandAndLogoData.logo_alignment} mb-3`
                    : "text-center mb-3"
                }
              >
                <img
                  src={brandAndLogoData.brand_logo.file_url}
                  alt="logoImg"
                  style={{ maxWidth: 512, maxHeight: 512 }}
                />
              </div>
            )}
          <div className="d-flex justify-content-center">
            <TextStyles.FontSize27px
              style={{ fontWeight: 500 }}
            >{`${selectedInvoiceData.invoice_title}`}</TextStyles.FontSize27px>
          </div>

          <TextStyles.FontSize14px
            className="mt-3 pe-0 pe-sm-5"
            style={{ whiteSpace: "pre-line" }}
          >
            {text}
          </TextStyles.FontSize14px>
          <TextStyles.FontSize18px className="mt-3" style={{ fontWeight: 500 }}>
            Details
          </TextStyles.FontSize18px>
          {selectedInvoiceData &&
            selectedInvoiceData.client_id &&
            selectedInvoiceData.client_id.company_name && (
              <div className="d-flex mt-2">
                <TextStyles.FontSize14px className="me-1">
                  Client:
                </TextStyles.FontSize14px>
                <TextStyles.GreyFont14px>
                  {selectedInvoiceData.client_id.company_name}
                </TextStyles.GreyFont14px>
              </div>
            )}

          {selectedInvoiceData &&
            selectedInvoiceData.project_id &&
            selectedInvoiceData.project_id.project_name && (
              <div className="d-flex mt-2">
                <TextStyles.FontSize14px className="me-1">
                  Project:
                </TextStyles.FontSize14px>
                <TextStyles.GreyFont14px>
                  {selectedInvoiceData.project_id.project_name}
                </TextStyles.GreyFont14px>
              </div>
            )}

          <div className="d-flex mt-2">
            <TextStyles.FontSize14px className="me-1">
              Amount due:
            </TextStyles.FontSize14px>
            <TextStyles.GreyFont14px>
              {selectedInvoiceData &&
                selectedInvoiceData.currency &&
                selectedInvoiceData.currency.symbol}
              {selectedInvoiceData && selectedInvoiceData.invoiced_amount
                ? parseFloat(selectedInvoiceData.invoiced_amount).toFixed(2)
                : "00:00"}
            </TextStyles.GreyFont14px>
          </div>

          <div className="d-flex mt-2">
            <TextStyles.FontSize14px className="me-1">
              Due:
            </TextStyles.FontSize14px>
            <TextStyles.GreyFont14px>
              {selectedInvoiceData &&
              selectedInvoiceData.issued &&
              selectedInvoiceData.due_date &&
              moment(selectedInvoiceData.issued).format("DD MMM YYYY") !==
                moment(selectedInvoiceData.due_date).format("DD MMM YYYY")
                ? moment(selectedInvoiceData.due_date).format("DD MMM YYYY")
                : "Upon receipt"}
            </TextStyles.GreyFont14px>
          </div>

          <div className="d-flex mt-2">
            <TextStyles.FontSize14px className="me-1">
              Issued:
            </TextStyles.FontSize14px>
            <TextStyles.GreyFont14px>
              {selectedInvoiceData && selectedInvoiceData.issued
                ? moment(
                    selectedInvoiceData && selectedInvoiceData.issued
                  ).format("DD MMM YYYY")
                : moment(new Date()).format("DD MMM YYYY")}
            </TextStyles.GreyFont14px>
          </div>

          <TextStyles.FontSize18px className="mt-3" style={{ fontWeight: 500 }}>
            From
          </TextStyles.FontSize18px>
          <div>
            {businessInfoData && businessInfoData.business_name !== null ? (
              <TextStyles.GreyFont16px style={{ fontWeight: 500 }}>
                {businessInfoData && businessInfoData.business_name}
              </TextStyles.GreyFont16px>
            ) : (
              <TextStyles.GreyFont16px
                className="d-flex align-items-center"
                style={{ fontWeight: 500 }}
              >
                {data &&
                  `${data.firstName} ${data.lastName} (${data.country.code})`}
              </TextStyles.GreyFont16px>
            )}

            {data && data.email && (
              <TextStyles.GreyFont14px className="mt-1">
                <span className="fw-medium">Email: </span>
                {data.email}
              </TextStyles.GreyFont14px>
            )}

            {businessInfoData !== null && (
              <div>
                {businessInfoData &&
                  businessInfoData.phone_number &&
                  businessInfoData.phone_code && (
                    <TextStyles.GreyFont14px className="mt-1">
                      <span className="fw-medium">Phone: </span>
                      <span>
                        +{businessInfoData.phone_code.country_code}
                        {businessInfoData.phone_number}
                      </span>
                    </TextStyles.GreyFont14px>
                  )}
                <TextStyles.GreyFont14px className="mt-1">
                  {businessInfoData &&
                    (businessInfoData.street ||
                      businessInfoData.suite ||
                      businessInfoData.city ||
                      businessInfoData.state ||
                      businessInfoData.country ||
                      businessInfoData.zip_code) && (
                      <span className="fw-medium">Address: </span>
                    )}
                  {businessInfoData && businessInfoData.street}
                  {businessInfoData && businessInfoData.suite ? ", " : ""}
                  {businessInfoData && businessInfoData.suite}
                  {businessInfoData && businessInfoData.city ? ", " : ""}
                  {businessInfoData && businessInfoData.city}
                  {businessInfoData && businessInfoData.state ? ", " : ""}
                  {businessInfoData && businessInfoData.state}
                  {businessInfoData && businessInfoData.country ? ", " : ""}
                  {businessInfoData && businessInfoData.country}
                  {businessInfoData && businessInfoData.zip_code ? ", " : ""}
                  {businessInfoData && businessInfoData.zip_code}
                </TextStyles.GreyFont14px>

                {businessInfoData && businessInfoData.tax_id_label && (
                  <TextStyles.GreyFont14px className="mt-1">
                    <span className="fw-medium">Tax ID Label: </span>
                    {businessInfoData.tax_id_label}
                  </TextStyles.GreyFont14px>
                )}

                {businessInfoData && businessInfoData.tax_id_number && (
                  <TextStyles.GreyFont14px className="mt-1">
                    <span className="fw-medium">Tax ID Number: </span>
                    {businessInfoData.tax_id_number}
                  </TextStyles.GreyFont14px>
                )}
              </div>
            )}
          </div>
          <div className="mt-4">
            <TextStyles.Button
              style={{
                cursor: "unset",
                fontWeight: 500,
                fontSize: 18,
                background:
                  brandAndLogoData && brandAndLogoData.brand_color
                    ? brandAndLogoData.brand_color
                    : Colors.primary,
              }}
            >
              View invoice
            </TextStyles.Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EmailPreview;
