import React from "react";
import { makeStyles } from "@mui/styles";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Colors from "../../../Constants/Colors";
import sizes from "../../../Constants/sizes";

const useStyles = makeStyles({
  root: {
    border: `1px solid ${Colors.borderInput} !important`,
    backgroundColor: Colors.white,
    borderRadius: `${sizes.formInputBorderRadius} !important`,
    fontFamily: "Poppins !important",
    fontSize: "0.875rem !important",
    height: sizes.formInputHeight,
    "& .MuiOutlinedInput-input": {
      padding: "0.947rem 1rem",
    },

    "&:hover": {
      border: `1px solid ${Colors.primary} !important`,
    },

    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },

    "&.Mui-focused": {
      border: `1px solid ${Colors.primary} !important`,
    },
  },

  select: {
    display: "flex !important",
    justifyContent: "start",
    alignItems: "center !important",
  },

  listMenu: {
    color: Colors.font2,
    marginBottom: "2px !important",
    fontSize: "0.875rem !important",
    fontFamily: "Poppins !important",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "break-spaces !important",
    "&:hover": {
      backgroundColor: `${Colors.backgroundPink} !important`,
      border: "none",
    },
    '&[aria-selected="true"]': {
      backgroundColor: `${Colors.backgroundPink} !important`,
    },
  },
});

const options = [
  {
    value: "Select Profession",
    label: "Select profession",
  },
  { value: "Designer", label: "Designer" },
  { value: "Developer", label: "Developer" },
  { value: "Consultant", label: "Consultant" },
  { value: "Marketer", label: "Marketer" },
  { value: "Manager", label: "Manager" },
  { value: "Writer", label: "Writer" },
  { value: "Other", label: "Other" }
];
export default function SelectProfession(props) {
  const classes = useStyles();

  return (
    <Select
      classes={{
        select: classes.select,
      }}
      className={classes.root}
      value={props.defaultValue ? props.defaultValue : options[0].value}
      id="grouped-select"
      sx={{
        width: props.width || "100%",
      }}
      onChange={props.onChange}
      MenuProps={{
        PaperProps: { sx: { maxHeight: 250 } },
        disableScrollLock: true,
      }}
    >
      {options &&
        options.map((data, index) => (
          <MenuItem key={index} value={data.value} className={classes.listMenu}>
            {data.label}
          </MenuItem>
        ))}
    </Select>
  );
}
