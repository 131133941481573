import styled from "styled-components";
import Colors from "../../../../Constants/Colors";

const TableHead = styled.div`
  border-radius: 0.75rem 0.75rem 0 0;
  padding: 1rem;
  background-color: ${Colors.tableHeadColor};
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
`;

const TableCell = styled.div`
  width: 100%;
  padding: 1rem;
  cursor: pointer;
  margin: 0 0 2px 0;
  background-color: ${Colors.white};
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
  &:hover {
    background: ${Colors.hoverTableRow};
  }
`;

const Sorting = styled.div`
  display: flex;
  min-width: 13.75rem;
  justify-content: space-between;
  align-items: center;
  padding: 3px 8px;
  border-radius: 6px;

  label {
    display: flex;
    width: 100%;
    cursor: pointer;
  }
  .form-check-input {
    width: 1rem;
    height: 1rem;
    box-shadow: none !important;
    border: 2px solid ${Colors.font2};
    cursor: pointer;
  }
  .form-check-input:checked {
    background-color: ${Colors.primary};
    border-color: ${Colors.primary};
  }

  &:hover {
    background: ${Colors.backgroundPink};
  }
  &:hover svg {
    color: ${Colors.primary};
  }
`;

export default {
  TableHead,
  TableCell,
  Sorting,
};
