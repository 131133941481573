import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { message } from "antd";
import LoaderSpin from "../../../Commons/LoaderSpin";
import ClearIcon from "@mui/icons-material/Clear";
import TextStyles from "../../../../../Constants/TextStyles";
import { updateAddClausePC } from "../../../../../store/service/proposalAndContractService";
import Colors from "../../../../../Constants/Colors";

const EditAddClauseModal = ({ show, handleClose, data, pc_id }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.proposalAndContract.isLoading);
  const {
    register,
    reset,
    formState: { isDirty, isValid },
    handleSubmit,
  } = useForm({
    defaultValues: {
      heading: data.heading,
      paragraph: data.paragraph,
    },
  });

  const formsubmit = (item) => {
    const Obj = {
      pc_id: pc_id,
      _id: data && data._id,
      add_clause: {
        required: true,
        heading: item.heading,
        paragraph: item.paragraph,
      },
    };
    dispatch(updateAddClausePC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleClose();
        reset();
        message.success("Clause updated successfully!");
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      contentClassName="ToMakeModalBorderRudius12px"
      scrollable={true}
      centered={true}
    >
      <form
        onSubmit={handleSubmit(formsubmit)}
        style={{ height: "100%" }}
        className="modal-content"
      >
        <Modal.Header className="d-flex justify-content-between align-items-center">
          <TextStyles.FontSize20px>Edit contract</TextStyles.FontSize20px>
          <ClearIcon
            sx={{
              color: Colors.font2,
              cursor: "pointer",
              opacity: 0.7,
              "&:hover": {
                opacity: 1,
              },
            }}
            onClick={handleClose}
          />
        </Modal.Header>

        <Modal.Body>
          <TextStyles.FontSize14px className="mb-3">
            <label htmlFor="headline" className="mb-2">
              Headline
            </label>
            <TextStyles.InputRectangle
              placeholder="heading"
              id="headline"
              name="heading"
              {...register("heading", {
                required: "Heading can not be empty.",
                validate: (value) => {
                  let trimedLength = value.trim().length;
                  if (trimedLength === 0) {
                    return "Heading can not be empty.";
                  }
                  return true;
                },
              })}
              // readOnly
            />
          </TextStyles.FontSize14px>
          <TextStyles.FontSize14px className="mb-3">
            <label htmlFor="headline" className="mb-2">
              Paragraph
            </label>
            <TextStyles.Textarea
              rows={8}
              placeholder="Add text here"
              id="paragraph"
              name="paragraph"
              {...register("paragraph", {
                required: "Paragraph can not be empty.",
                validate: (value) => {
                  let trimedLength = value.trim().length;
                  if (trimedLength === 0) {
                    return "Paragraph can not be empty.";
                  }
                  return true;
                },
              })}
              // readOnly
            />
          </TextStyles.FontSize14px>
        </Modal.Body>
        <Modal.Footer style={{ display: "inline-block" }}>
          <div className="d-flex gap-3 justify-content-between">
            <TextStyles.CancelButton onClick={handleClose}>
              Cancel
            </TextStyles.CancelButton>
            <TextStyles.Button
              disabled={!isDirty || !isValid || isLoading ? true : false}
            >
              {isLoading ? <LoaderSpin /> : "Save"}
            </TextStyles.Button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default EditAddClauseModal;
