import styled from "styled-components";
import Colors from "../../../../../Constants/Colors";

const TodoRectangle = styled.div`
  width: 100%;
  height: 4.5rem;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  cursor: pointer;
  background-color: ${Colors.white};
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
  contain: inline-size;

  & .radioCheckedButton {
    display: none;
  }

  & .radioButtonWrapper:hover > .radioButton {
    display: none;
  }

  & .radioButtonWrapper:hover > .radioCheckedButton {
    display: inline;
  }

  &:hover {
    background: ${Colors.backgroundPink};
  }

  .dropdown-menu {
    border: 2px solid #f9f9f9;
    padding: 6px 6px;
    border-radius: 6px;
    box-shadow: 1px 2px 3px 1px rgba(0, 0, 0, 0.2);
  }

  .dropdown-item {
    padding: 7px 12px;
    border-radius: 4px;
  }
  .dropdown-item:hover {
    background: ${Colors.backgroundPink};
  }
  .task-moreButton {
    max-width: 150px;
  }
  @media (max-width: 576px) {
    .task-moreButton {
      max-width: 50px;
    }
  }
`;

const Input = styled.input`
  width: 100%;
  max-width: 500px;
  margin: 0 0.7rem;
  font-size: 14px;
  background-color: ${(props) => (props.invalid ? Colors.red : Colors.white)};
  caret-color: ${Colors.font2};
`;

const ListBox = styled.div`
  width: 100%;
  height: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.white};
  border-radius: 0.75rem;
  padding: 1rem;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
`;

export default {
  TodoRectangle,
  Input,
  ListBox,
};
