import React, { useState, useEffect } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import Styled from "../../createProject/SetupStyles";
import TextStyles from "../../../../../Constants/TextStyles";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { AutoCompleteSelectClient } from "../../../Commons/AutoComplete";
import EditServices from "./EditServices";
import EditBillingSchedule from "./EditBillingSchedule";
import {
  fetchProjectForOptions,
  updateProject,
} from "../../../../../store/service/projectService";
import LoaderSpin from "../../../Commons/LoaderSpin";
import SwitchButton from "../../../Commons/SwitchButton";
import Colors from "../../../../../Constants/Colors";
import SelectCurrency from "../../../Commons/SelectCurrency";
import moment from "moment";
import CustomDatePickerOne from "../../../Commons/DatePickers/CustomDatePickerOne";

export default function EditProjectModal({
  show,
  handleClose,
  selectedProject,
  updateRefresh,
  setUpdateRefresh,
}) {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.project.isLoading);

  const clientOptionData = useSelector(
    (state) => state.clients.clientOptionData
  );
  const [selectEndDate, setSelectEndDate] = useState(
    selectedProject && selectedProject.project_end_date ? false : true
  );

  let billing_scheduled = selectedProject && selectedProject.billing_scheduled;

  const {
    register,
    reset,
    watch,
    formState: { errors },
    control,
    setValue,
    handleSubmit,
  } = useForm({
    defaultValues: {
      project_name: selectedProject && selectedProject.project_name,
      client: selectedProject &&
        selectedProject.client_id && {
          _id: selectedProject.client_id._id,
          label: selectedProject.client_id.company_name,
        },
      currency: selectedProject && selectedProject.currency,
      project_start_date:
        selectedProject &&
        selectedProject.project_start_date &&
        new Date(selectedProject.project_start_date),
      project_end_date:
        selectedProject &&
        selectedProject.project_end_date &&
        new Date(selectedProject.project_end_date),
      servicesList: selectedProject && selectedProject.service_list,
      billingScheduled: {
        deposit: {
          require_deposit:
            billing_scheduled &&
            billing_scheduled.deposit &&
            billing_scheduled.deposit.require_deposit,
          value:
            billing_scheduled &&
            billing_scheduled.deposit &&
            billing_scheduled.deposit.value,
        },
        issue_invoice:
          billing_scheduled &&
          billing_scheduled.issue_invoice &&
          new Date(billing_scheduled.issue_invoice),
        weekly_invoice: {
          start_date:
            billing_scheduled &&
            billing_scheduled.weekly_invoice &&
            billing_scheduled.weekly_invoice.start_date &&
            new Date(billing_scheduled.weekly_invoice.start_date),
          invoices_type:
            billing_scheduled &&
            billing_scheduled.weekly_invoice &&
            billing_scheduled.weekly_invoice.invoices_type,
          num_invoices:
            billing_scheduled &&
            billing_scheduled.weekly_invoice &&
            billing_scheduled.weekly_invoice.num_invoices,
          end_date:
            billing_scheduled &&
            billing_scheduled.weekly_invoice &&
            billing_scheduled.weekly_invoice.end_date &&
            new Date(billing_scheduled.weekly_invoice.end_date),
        },
        biWeekly_invoice: {
          start_date:
            billing_scheduled &&
            billing_scheduled.biWeekly_invoice &&
            billing_scheduled.biWeekly_invoice.start_date &&
            new Date(billing_scheduled.biWeekly_invoice.start_date),
          invoices_type:
            billing_scheduled &&
            billing_scheduled.biWeekly_invoice &&
            billing_scheduled.biWeekly_invoice.invoices_type,
          num_invoices:
            billing_scheduled &&
            billing_scheduled.biWeekly_invoice &&
            billing_scheduled.biWeekly_invoice.num_invoices,
          end_date:
            billing_scheduled &&
            billing_scheduled.biWeekly_invoice &&
            billing_scheduled.biWeekly_invoice.end_date &&
            new Date(billing_scheduled.biWeekly_invoice.end_date),
        },
        monthly_invoice: {
          start_date:
            billing_scheduled &&
            billing_scheduled.monthly_invoice &&
            billing_scheduled.monthly_invoice.start_date &&
            new Date(billing_scheduled.monthly_invoice.start_date),
          invoices_type:
            billing_scheduled &&
            billing_scheduled.monthly_invoice &&
            billing_scheduled.monthly_invoice.invoices_type,
          num_invoices:
            billing_scheduled &&
            billing_scheduled.monthly_invoice &&
            billing_scheduled.monthly_invoice.num_invoices,
          end_date:
            billing_scheduled &&
            billing_scheduled.monthly_invoice &&
            billing_scheduled.monthly_invoice.end_date &&
            new Date(billing_scheduled.monthly_invoice.end_date),
        },
      },
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "servicesList",
  });

  const changedCurrency = watch("currency");
  const currencySymbol = changedCurrency
    ? changedCurrency.symbol
    : selectedProject.currency.symbol;

  const [endDateErrorMessage, setEndDateErrorMessage] = useState(null);
  const startDate = watch("project_start_date");
  const endDate = watch("project_end_date");

  const validateEndDate = () => {
    const eDate = endDate && moment(endDate);
    const sDate = moment(startDate);
    if (eDate && eDate.isBefore(sDate, "day")) {
      setEndDateErrorMessage(
        `End date should be greater than ${moment(new Date(startDate))
          .subtract(1, "d")
          .format("DD MMM YYYY")}.`
      );
      return false;
    } else {
      setEndDateErrorMessage(null);
      return true;
    }
  };

  useEffect(() => {
    validateEndDate();
  }, [startDate, endDate]);

  useEffect(() => {
    setValue(
      "project_end_date",
      selectedProject &&
        selectedProject.project_end_date &&
        new Date(selectedProject.project_end_date)
    );
  }, [selectEndDate]);

  const formsubmit = (data) => {
    let Obj = {
      project_id: selectedProject && selectedProject._id,
      client_id: data.client && data.client._id,
      project_name: data.project_name,
      project_start_date: new Date(data.project_start_date),
      project_end_date:
        selectEndDate || data.project_end_date === undefined
          ? null
          : data.project_end_date && new Date(data.project_end_date),

      currency: data.currency ? data.currency : selectedProject.currency,
      billing_scheduled: data.billingScheduled,
      service_list: data.servicesList,
    };
    dispatch(updateProject(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        dispatch(fetchProjectForOptions());
        if (setUpdateRefresh) {
          setUpdateRefresh(!updateRefresh);
        }
        handleClose();
        reset();
        message.success(`${data.payload.message}`);
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      fullscreen
      dialogClassName="ToMakeFullscreenModal"
    >
      <div className="modal-header d-flex justify-content-center">
        <TextStyles.FontSize14px
          style={{
            position: "absolute",
            left: "1rem",
            top: "1.25rem",
            cursor: "pointer",
          }}
          onClick={handleClose}
        >
          <ClearIcon
            sx={{
              fontSize: 30,
              color: Colors.font2,
              opacity: 0.7,
              "&:hover": {
                opacity: 1,
              },
            }}
          />
        </TextStyles.FontSize14px>
        <TextStyles.FontSize20px
          className="d-flex justify-content-center"
          style={{ fontWeight: 500, fontSize: "1.5rem" }}
        >
          Edit project
        </TextStyles.FontSize20px>
      </div>

      <div className="modal-body d-flex justify-content-center">
        <div style={{ maxWidth: 600, width: "100%" }}>
          <form onSubmit={handleSubmit(formsubmit)}>
            <div className="mt-2 mb-3">
              <Styled.SetupBox className="mb-4">
                <TextStyles.FontSize14px className="mb-2">
                  <label htmlFor="project_name" className="mb-2">
                    Project name
                  </label>

                  <TextStyles.InputRectangle
                    name="project_name"
                    id="project_name"
                    invalid={errors.project_name}
                    type="text"
                    placeholder="Project name"
                    {...register("project_name", {
                      required: "Project name is required.",
                      validate: (value) => {
                        let trimedLength = value.trim().length;
                        if (trimedLength === 0) {
                          return "Project name cannot be just empty spaces";
                        }
                        if (trimedLength < 3) {
                          return "Project name must be at least 3 characters";
                        }
                        if (trimedLength > 40) {
                          return "Project name must be at most 40 characters";
                        }
                        return true;
                      },
                    })}
                  />
                  {errors.project_name && (
                    <TextStyles.InValidFeedback>
                      {errors.project_name.message}
                    </TextStyles.InValidFeedback>
                  )}
                </TextStyles.FontSize14px>
                <TextStyles.FontSize14px className="mb-3">
                  <label htmlFor="client" className="mb-2">
                    Client
                  </label>
                  <Controller
                    control={control}
                    name="client"
                    rules={{
                      required: {
                        value: true,
                        message: "Client is required.",
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <AutoCompleteSelectClient
                        placeholder="Choose a client"
                        options={
                          clientOptionData &&
                          clientOptionData.map((data) => {
                            return {
                              label: data.company_name,
                              _id: data._id,
                            };
                          })
                        }
                        defaultValue={value}
                        onChange={onChange}
                        invalid={errors.client}
                      />
                    )}
                  />
                  {errors.client && (
                    <TextStyles.InValidFeedback className="mt-2">
                      {errors.client.message}
                    </TextStyles.InValidFeedback>
                  )}
                </TextStyles.FontSize14px>
                <TextStyles.FontSize14px className="mb-3">
                  <label htmlFor="client" className="mb-2">
                    Select currency
                  </label>
                  <Controller
                    control={control}
                    name="currency"
                    render={({ field: { onChange, value } }) => (
                      <SelectCurrency
                        placeholder="Select currency"
                        onChange={onChange}
                        defaultValue={value}
                        // defaultValue={
                        //   selectedProject && selectedProject.currency
                        // }
                      />
                    )}
                  />
                </TextStyles.FontSize14px>
                <TextStyles.FontSize14px className="mb-3">
                  <div htmlFor="project_start_date" className="mb-2">
                    Start date
                  </div>
                  <Controller
                    name={"project_start_date"}
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <CustomDatePickerOne
                          onChange={onChange}
                          selected={value}
                          placeholder="Select start date"
                          allowClear={false}
                        />
                      );
                    }}
                  />
                </TextStyles.FontSize14px>
                <TextStyles.FontSize14px className="mb-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <div htmlFor="project_end_date">End date</div>
                    <div className="d-flex align-items-center">
                      <TextStyles.FontSize14px
                        style={{ color: Colors.primary, fontWeight: 500 }}
                      >
                        Ongoing
                      </TextStyles.FontSize14px>
                      <SwitchButton
                        defaultChecked={selectEndDate && selectEndDate}
                        onChange={(event) =>
                          setSelectEndDate(event.target.checked)
                        }
                      />
                    </div>
                  </div>
                  {selectEndDate ? (
                    ""
                  ) : (
                    <Controller
                      name={"project_end_date"}
                      control={control}
                      rules={{ validate: validateEndDate }}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <CustomDatePickerOne
                            onChange={onChange}
                            selected={value}
                            placeholder="Select end date"
                            allowClear={true}
                          />
                        );
                      }}
                    />
                  )}
                  {endDateErrorMessage && (
                    <TextStyles.InValidFeedback className="mt-2">
                      {endDateErrorMessage}
                    </TextStyles.InValidFeedback>
                  )}
                </TextStyles.FontSize14px>
              </Styled.SetupBox>
            </div>

            <EditServices
              currencySymbol={currencySymbol}
              control={control}
              Controller={Controller}
              watch={watch}
              register={register}
              fields={fields}
              remove={remove}
              append={append}
            />
            <EditBillingSchedule
              currencySymbol={currencySymbol}
              billing_scheduled={billing_scheduled}
              register={register}
              watch={watch}
              setValue={setValue}
              Controller={Controller}
              control={control}
              reset={reset}
            />
            <div className="pb-3">
              <TextStyles.Button disabled={isLoading ? true : false}>
                {isLoading ? <LoaderSpin /> : "Update project"}
              </TextStyles.Button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}
