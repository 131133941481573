import React, { useState, useEffect } from "react";
import calendarsvg from "../../../../../Assets/assetsnew/calendar.svg";
import dollorBrand from "../../../../../Assets/assetsnew/close-dollor-brand.svg";
import dollorWhite from "../../../../../Assets/assetsnew/close-dollor-white.svg";
import repeatBtn from "../../../../../Assets/assetsnew/repeat-deposit.svg";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";

import Styled from "../../createProject/SetupStyles";
import TextStyles from "../../../../../Constants/TextStyles";
import Colors from "../../../../../Constants/Colors";
import CustomDatePickerOne from "../../../Commons/DatePickers/CustomDatePickerOne";
import SelectEndInvoiceType from "../../../Commons/SelectInvoiceType";
const BillingSchedule = ({
  billing_scheduled,
  register,
  watch,
  setValue,
  Controller,
  control,
  currencySymbol,
}) => {
  const [selector, setSelector] = useState(
    billing_scheduled && billing_scheduled.invoice_frequency
  );

  useEffect(() => {
    setValue("billingScheduled.invoice_frequency", selector);
  }, [selector]);

  const selectDeposit = watch("billingScheduled.deposit.require_deposit");

  useEffect(() => {
    if (selectDeposit) {
      setValue(
        "billingScheduled.deposit.value",
        billing_scheduled &&
          billing_scheduled.deposit &&
          billing_scheduled.deposit.value
      );
    } else {
      setValue("billingScheduled.deposit.value", undefined);
    }
  }, [selectDeposit]);

  useEffect(() => {
    if (selector === "Once") {
      setValue(
        "billingScheduled.issue_invoice",
        billing_scheduled &&
          billing_scheduled.issue_invoice &&
          new Date(billing_scheduled.issue_invoice)
      );
    } else {
      setValue("billingScheduled.issue_invoice", undefined);
    }
  }, [selector]);

  const invoices_type = watch("billingScheduled.weekly_invoice.invoices_type");

  useEffect(() => {
    if (invoices_type === "On") {
      setValue("billingScheduled.weekly_invoice.num_invoices", undefined);
    } else if (invoices_type === "After") {
      setValue("billingScheduled.weekly_invoice.end_date", undefined);
    }
  }, [invoices_type]);

  useEffect(() => {
    if (selector === "Weekly") {
      setValue(
        "billingScheduled.weekly_invoice.start_date",
        billing_scheduled &&
          billing_scheduled.weekly_invoice &&
          billing_scheduled.weekly_invoice.start_date &&
          new Date(billing_scheduled.weekly_invoice.start_date)
      );
      setValue(
        "billingScheduled.weekly_invoice.invoices_type",
        billing_scheduled &&
          billing_scheduled.weekly_invoice &&
          billing_scheduled.weekly_invoice.invoices_type
      );
      setValue(
        "billingScheduled.weekly_invoice.num_invoices",
        billing_scheduled &&
          billing_scheduled.weekly_invoice &&
          billing_scheduled.weekly_invoice.num_invoices
      );
      setValue(
        "billingScheduled.weekly_invoice.end_date",
        billing_scheduled &&
          billing_scheduled.weekly_invoice &&
          billing_scheduled.weekly_invoice.end_date &&
          new Date(billing_scheduled.weekly_invoice.end_date)
      );
    } else {
      setValue("billingScheduled.weekly_invoice.start_date", undefined);
      setValue("billingScheduled.weekly_invoice.end_date", undefined);
      setValue("billingScheduled.weekly_invoice.invoices_type", "Never");
      setValue("billingScheduled.weekly_invoice.num_invoices", undefined);
    }
  }, [selector]);

  const invoices_type1 = watch(
    "billingScheduled.biWeekly_invoice.invoices_type"
  );

  useEffect(() => {
    if (invoices_type1 === "On") {
      setValue("billingScheduled.biWeekly_invoice.num_invoices", undefined);
    } else if (invoices_type === "After") {
      setValue("billingScheduled.biWeekly_invoice.end_date", undefined);
    }
  }, [invoices_type1]);

  useEffect(() => {
    if (selector === "Bi-weekly") {
      setValue(
        "billingScheduled.biWeekly_invoice.start_date",
        billing_scheduled &&
          billing_scheduled.biWeekly_invoice &&
          billing_scheduled.biWeekly_invoice.start_date &&
          new Date(billing_scheduled.biWeekly_invoice.start_date)
      );
      setValue(
        "billingScheduled.biWeekly_invoice.invoices_type",
        billing_scheduled &&
          billing_scheduled.biWeekly_invoice &&
          billing_scheduled.biWeekly_invoice.invoices_type
      );
      setValue(
        "billingScheduled.biWeekly_invoice.num_invoices",
        billing_scheduled &&
          billing_scheduled.biWeekly_invoice &&
          billing_scheduled.biWeekly_invoice.num_invoices
      );
      setValue(
        "billingScheduled.biWeekly_invoice.end_date",
        billing_scheduled &&
          billing_scheduled.biWeekly_invoice &&
          billing_scheduled.biWeekly_invoice.end_date &&
          new Date(billing_scheduled.biWeekly_invoice.end_date)
      );
    } else {
      setValue("billingScheduled.biWeekly_invoice.start_date", undefined);
      setValue("billingScheduled.biWeekly_invoice.end_date", undefined);
      setValue("billingScheduled.biWeekly_invoice.invoices_type", "Never");
      setValue("billingScheduled.biWeekly_invoice.num_invoices", undefined);
    }
  }, [selector]);

  const invoices_type2 = watch(
    "billingScheduled.monthly_invoice.invoices_type"
  );

  useEffect(() => {
    if (invoices_type2 === "On") {
      setValue("billingScheduled.monthly_invoice.num_invoices", undefined);
    } else if (invoices_type2 === "After") {
      setValue("billingScheduled.monthly_invoice.end_date", undefined);
    }
  }, [invoices_type2]);

  useEffect(() => {
    if (selector === "Monthly") {
      setValue(
        "billingScheduled.monthly_invoice.start_date",
        billing_scheduled &&
          billing_scheduled.monthly_invoice &&
          billing_scheduled.monthly_invoice.start_date &&
          new Date(billing_scheduled.monthly_invoice.start_date)
      );
      setValue(
        "billingScheduled.monthly_invoice.invoices_type",
        billing_scheduled &&
          billing_scheduled.monthly_invoice &&
          billing_scheduled.monthly_invoice.invoices_type
      );
      setValue(
        "billingScheduled.monthly_invoice.num_invoices",
        billing_scheduled &&
          billing_scheduled.monthly_invoice &&
          billing_scheduled.monthly_invoice.num_invoices
      );
      setValue(
        "billingScheduled.monthly_invoice.end_date",
        billing_scheduled &&
          billing_scheduled.monthly_invoice &&
          billing_scheduled.monthly_invoice.end_date &&
          new Date(billing_scheduled.monthly_invoice.end_date)
      );
    } else {
      setValue("billingScheduled.monthly_invoice.start_date", undefined);
      setValue("billingScheduled.monthly_invoice.end_date", undefined);
      setValue("billingScheduled.monthly_invoice.invoices_type", "Never");
      setValue("billingScheduled.monthly_invoice.num_invoices", undefined);
    }
  }, [selector]);

  return (
    <div>
      <Styled.SetupBox className="accordion mb-4" id="accordionExample">
        <div className="accordion-item" style={{ border: "none" }}>
          <button
            style={{
              boxShadow: "none",
              background: Colors.white,
              padding: "0px 0px",
            }}
            className="accordion-button collapsed mb-3"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#panelsStayOpen-collapseThree"
            aria-expanded="false"
            aria-controls="panelsStayOpen-collapseThree"
          >
            <div className="d-flex gap-2 align-items-center">
              <img
                src={calendarsvg}
                alt="calenderBtn"
                className="me-1 me-sm-2 me-md-4"
              />
              <div>
                <TextStyles.FontSize20px>
                  {" "}
                  Billing schedule
                </TextStyles.FontSize20px>
                <TextStyles.GreyFont14px>
                  Set deposit amount, invoice dates, and reminders.
                </TextStyles.GreyFont14px>
              </div>
            </div>
          </button>
          <div
            id="panelsStayOpen-collapseThree"
            className="accordion-collapse collapse"
            aria-labelledby="panelsStayOpen-headingThree"
          >
            <div
              className="my-4"
              style={{
                width: "100%",
                height: "1px",
                border: "0.5px solid #edeef2",
              }}
            />

            <div className="d-flex">
              <div className="d-flex flex-column justify-content-center align-items-center pe-3">
                {selectDeposit ? (
                  <>
                    <img src={dollorBrand} alt="dollarBtn" />
                    <div
                      style={{
                        height: "100%",
                        border: `1px solid ${Colors.primary}`,
                      }}
                    />
                    {selector === "Weekly" ||
                    selector === "Bi-weekly" ||
                    selector === "Monthly" ? (
                      <img src={repeatBtn} alt="dollarBtn" />
                    ) : (
                      <img src={dollorBrand} alt="dollarBtn" />
                    )}
                  </>
                ) : (
                  <>
                    <img src={dollorWhite} alt="dollarBtn" />
                    <div
                      style={{
                        height: "100%",
                        border: "0.5px solid rgb(205 205 206)",
                      }}
                    />
                    {selector === "Weekly" ||
                    selector === "Bi-weekly" ||
                    selector === "Monthly" ? (
                      <img src={repeatBtn} alt="dollarBtn" />
                    ) : (
                      <img src={dollorBrand} alt="dollarBtn" />
                    )}
                  </>
                )}
              </div>
              <TextStyles.FontSize14px className="d-flex flex-column">
                <TextStyles.FontSize16px className="d-flex justify-content-start mb-4 mt-1">
                  I will invoice
                </TextStyles.FontSize16px>
                <div className="d-flex flex-row flex-wrap gap-2">
                  <div onClick={() => setSelector("Once")} className="me-2">
                    {selector === "Once" ? (
                      <Styled.Selected>Once</Styled.Selected>
                    ) : (
                      <Styled.NotSelected>Once</Styled.NotSelected>
                    )}
                  </div>
                  <div onClick={() => setSelector("Weekly")} className="me-2">
                    {selector === "Weekly" ? (
                      <Styled.Selected>Weekly</Styled.Selected>
                    ) : (
                      <Styled.NotSelected>Weekly</Styled.NotSelected>
                    )}
                  </div>
                  <div
                    onClick={() => setSelector("Bi-weekly")}
                    className="me-2"
                  >
                    {selector === "Bi-weekly" ? (
                      <Styled.Selected>Bi-weekly</Styled.Selected>
                    ) : (
                      <Styled.NotSelected>Bi-weekly</Styled.NotSelected>
                    )}
                  </div>
                  <div onClick={() => setSelector("Monthly")} className="me-2">
                    {selector === "Monthly" ? (
                      <Styled.Selected>Monthly</Styled.Selected>
                    ) : (
                      <Styled.NotSelected>Monthly</Styled.NotSelected>
                    )}
                  </div>
                </div>
                <TextStyles.FontSize16px className="d-flex justify-content-start pt-4">
                  Deposit
                </TextStyles.FontSize16px>
                <TextStyles.CheckBox
                  className="form-check mt-3"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    style={{ cursor: "pointer" }}
                    className="form-check-input"
                    type="checkbox"
                    id="require_deposit"
                    name="require_deposit"
                    {...register("billingScheduled.deposit.require_deposit")}
                  />
                  <TextStyles.GreyFont14px
                    className="form-check-label"
                    htmlFor="flexCheckChecked"
                    style={{ marginTop: "2px" }}
                  >
                    I require a deposit
                  </TextStyles.GreyFont14px>
                </TextStyles.CheckBox>
                {selectDeposit && (
                  <div>
                    <TextStyles.FontSize14px className="mt-3">
                      <Styled.inputBox style={{ maxWidth: 120 }}>
                        <span>{currencySymbol}</span>
                        <input
                          style={{ width: 60 }}
                          disabled={selectDeposit ? false : true}
                          type="number"
                          min={"0"}
                          id="value"
                          placeholder="00.00"
                          name="value"
                          {...register("billingScheduled.deposit.value")}
                        />
                      </Styled.inputBox>
                    </TextStyles.FontSize14px>

                    <TextStyles.GreyFont14px className="mt-3">
                      <span>
                        <AccessAlarmIcon fontSize="small" />
                      </span>
                      <span className="ps-2">
                        You'll be reminded to invoice the deposit after you
                        create this project.
                      </span>
                    </TextStyles.GreyFont14px>
                  </div>
                )}
                <TextStyles.FontSize16px className="mt-4 pb-1">
                  {selector === "Weekly"
                    ? "Weekly invoices"
                    : selector === "Bi-weekly"
                    ? "Bi-weekly invoices"
                    : selector === "Monthly"
                    ? "Monthly invoices"
                    : "Final invoice"}
                </TextStyles.FontSize16px>
              </TextStyles.FontSize14px>
            </div>
            <div style={{ paddingLeft: 50 }}>
              {selector === "Once" && (
                <div className="d-flex flex-column mt-3">
                  <TextStyles.FontSize14px>
                    <label htmlFor="start_date" className="mb-2">
                      Issue invoice
                    </label>
                    <div style={{ maxWidth: 200 }}>
                      <Controller
                        name={"billingScheduled.issue_invoice"}
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <CustomDatePickerOne
                              onChange={onChange}
                              selected={value}
                              placeholder="Select date"
                              allowClear={true}
                            />
                          );
                        }}
                      />
                    </div>
                  </TextStyles.FontSize14px>
                </div>
              )}
              {selector === "Weekly" && (
                <div>
                  <div className="d-flex flex-column mt-3">
                    <TextStyles.FontSize14px>
                      <label htmlFor="start_date" className="mb-2">
                        Start invoices
                      </label>
                      <div style={{ maxWidth: 200 }}>
                        <Controller
                          name={"billingScheduled.weekly_invoice.start_date"}
                          control={control}
                          render={({ field: { onChange, value } }) => {
                            return (
                              <CustomDatePickerOne
                                onChange={onChange}
                                selected={value}
                                placeholder="Select date"
                                allowClear={true}
                              />
                            );
                          }}
                        />
                      </div>
                    </TextStyles.FontSize14px>
                  </div>

                  <TextStyles.FontSize14px className="mt-3">
                    <label htmlFor="start_date" className="mb-2">
                      End invoices
                    </label>
                    <div className="d-flex flex-wrap align-items-center gap-3">
                      <Controller
                        control={control}
                        name="invoices_type"
                        {...register(
                          "billingScheduled.weekly_invoice.invoices_type"
                        )}
                        render={({ field: { onChange, value } }) => (
                          <SelectEndInvoiceType
                            placeholder="Select"
                            onChange={onChange}
                            defaultValue={value}
                          />
                        )}
                      />

                      {invoices_type === "After" && (
                        <>
                          <Styled.inputBox style={{ width: 60 }}>
                            <input
                              type="number"
                              style={{ width: 30, textAlign: "center" }}
                              id="weekly_num_invoices"
                              placeholder="0"
                              min={"0"}
                              name="num_invoices"
                              {...register(
                                "billingScheduled.weekly_invoice.num_invoices"
                              )}
                            />
                          </Styled.inputBox>
                          <TextStyles.FontSize14px>
                            invoices
                          </TextStyles.FontSize14px>
                        </>
                      )}

                      {invoices_type === "On" && (
                        <div style={{ maxWidth: 200 }}>
                          <Controller
                            name={"billingScheduled.weekly_invoice.end_date"}
                            control={control}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <CustomDatePickerOne
                                  onChange={onChange}
                                  selected={value}
                                  placeholder="Select date"
                                  allowClear={true}
                                />
                              );
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </TextStyles.FontSize14px>
                </div>
              )}

              {selector === "Bi-weekly" && (
                <div>
                  <div className="d-flex flex-column mt-3">
                    <TextStyles.FontSize14px>
                      <label htmlFor="start_date" className="mb-2">
                        Start invoices
                      </label>
                      <div style={{ maxWidth: 200 }}>
                        <Controller
                          name={"billingScheduled.biWeekly_invoice.start_date"}
                          control={control}
                          render={({ field: { onChange, value } }) => {
                            return (
                              <CustomDatePickerOne
                                onChange={onChange}
                                selected={value}
                                placeholder="Select date"
                                allowClear={true}
                              />
                            );
                          }}
                        />
                      </div>
                    </TextStyles.FontSize14px>
                  </div>

                  <TextStyles.FontSize14px className="mt-3">
                    <label htmlFor="start_date" className="mb-2">
                      End invoices
                    </label>
                    <div className="d-flex flex-wrap align-items-center gap-3">
                      <Controller
                        control={control}
                        className="form-select"
                        name="invoices_type"
                        {...register(
                          "billingScheduled.biWeekly_invoice.invoices_type"
                        )}
                        render={({ field: { onChange, value } }) => (
                          <SelectEndInvoiceType
                            placeholder="Select"
                            onChange={onChange}
                            defaultValue={value}
                          />
                        )}
                      />

                      {invoices_type1 === "After" && (
                        <>
                          <Styled.inputBox style={{ width: 60 }}>
                            <input
                              type="number"
                              style={{ width: 30, textAlign: "center" }}
                              id="biWeekly_num_invoices"
                              placeholder="0"
                              min={"0"}
                              name="num_invoices"
                              {...register(
                                "billingScheduled.biWeekly_invoice.num_invoices"
                              )}
                            />
                          </Styled.inputBox>
                          <TextStyles.FontSize14px>
                            invoices
                          </TextStyles.FontSize14px>
                        </>
                      )}

                      {invoices_type1 === "On" && (
                        <div style={{ maxWidth: 200 }}>
                          <Controller
                            name={"billingScheduled.biWeekly_invoice.end_date"}
                            control={control}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <CustomDatePickerOne
                                  onChange={onChange}
                                  selected={value}
                                  placeholder="Select date"
                                  allowClear={true}
                                />
                              );
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </TextStyles.FontSize14px>
                </div>
              )}

              {selector === "Monthly" && (
                <div>
                  <div className="d-flex flex-column mt-3">
                    <TextStyles.FontSize14px>
                      <label htmlFor="start_date" className="mb-2">
                        Start invoices
                      </label>
                      <div style={{ maxWidth: 200 }}>
                        <Controller
                          name={"billingScheduled.monthly_invoice.start_date"}
                          control={control}
                          render={({ field: { onChange, value } }) => {
                            return (
                              <CustomDatePickerOne
                                onChange={onChange}
                                selected={value}
                                placeholder="Select date"
                                allowClear={true}
                              />
                            );
                          }}
                        />
                      </div>
                    </TextStyles.FontSize14px>
                  </div>

                  <TextStyles.FontSize14px className="mt-3">
                    <label htmlFor="start_date" className="mb-2">
                      End invoices
                    </label>
                    <div className="d-flex flex-wrap align-items-center gap-3">
                      <Controller
                        control={control}
                        className="form-select"
                        name="invoices_type"
                        {...register(
                          "billingScheduled.monthly_invoice.invoices_type"
                        )}
                        render={({ field: { onChange, value } }) => (
                          <SelectEndInvoiceType
                            placeholder="Select"
                            onChange={onChange}
                            defaultValue={value}
                          />
                        )}
                      />

                      {invoices_type2 === "After" && (
                        <>
                          <Styled.inputBox style={{ width: 60 }}>
                            <input
                              type="number"
                              style={{ width: 30, textAlign: "center" }}
                              id="monthly_num_invoices"
                              placeholder="0"
                              min={"0"}
                              name="num_invoices"
                              {...register(
                                "billingScheduled.monthly_invoice.num_invoices"
                              )}
                            />
                          </Styled.inputBox>
                          <TextStyles.FontSize14px>
                            invoices
                          </TextStyles.FontSize14px>
                        </>
                      )}

                      {invoices_type2 === "On" && (
                        <div style={{ maxWidth: 200 }}>
                          <Controller
                            name={"billingScheduled.monthly_invoice.end_date"}
                            control={control}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <CustomDatePickerOne
                                  onChange={onChange}
                                  selected={value}
                                  placeholder="Select date"
                                  allowClear={true}
                                />
                              );
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </TextStyles.FontSize14px>
                </div>
              )}
            </div>
          </div>
        </div>
      </Styled.SetupBox>
    </div>
  );
};

export default BillingSchedule;
