import React from "react";
import { Modal } from "react-bootstrap";
import Colors from "../../../../Constants/Colors";
import TextStyles from "../../../../Constants/TextStyles";

const DiscardChangesModal = (props) => {
  const { confirmDialog, show, handleClose } = props;

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      contentClassName="ToMakeModalBorderRudius12px"
    >
      <div
        className="modal-content"
        style={{ border: "none", borderRadius: 12, padding: "0 15px" }}
      >
        <div className="container-fluid">
          <TextStyles.FontSize20px
            style={{ fontWeight: 500 }}
            className="d-flex justify-content-start my-3"
          >
            Leaving invoice
          </TextStyles.FontSize20px>
          <TextStyles.FontSize16px className="d-flex justify-content-start mb-3">
            All your invoice progress will be lost.
          </TextStyles.FontSize16px>

          <div className="row my-4">
            <div className="col">
              <TextStyles.CancelButton onClick={handleClose}>
                No, Keep Editing
              </TextStyles.CancelButton>
            </div>
            <div className="col">
              <TextStyles.Button
                color={Colors.white}
                borderColor={Colors.red}
                backgroundColor={Colors.red}
                onClick={() => {
                  handleClose();
                  confirmDialog.onConfirm();
                }}
              >
                Discard Changes
              </TextStyles.Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DiscardChangesModal;
