import styled from "styled-components";
import Colors from "../../../../Constants/Colors";

const SetupBox = styled.div`
  width: 100%;
  height: auto;
  padding: 1rem;
  border-radius: 6px;
  position: relative;
  border-top: 4px solid ${Colors.primary};
  box-shadow: 1px 1px 9px 2px lightgrey;
  background-color: ${Colors.white};
  .review_box_padding {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .edit_invoice_service .edit_invoice_serviceBtn {
    visibility: hidden;
  }
  .edit_invoice_service:hover .edit_invoice_serviceBtn {
    visibility: visible;
  }

  .edit_invoice_serviceBtn:hover {
    background: #e8e8e8 !important;
  }
  .edit_line_item_service .edit_line_item_serviceBtn {
    visibility: hidden;
  }

  .edit_line_item_service:hover .edit_line_item_serviceBtn {
    visibility: visible;
  }
  .edit_line_item_serviceBtn:hover {
    background: #e8e8e8 !important;
  }

  .edit_line_item_service .delete_line_item_serviceBtn {
    visibility: hidden;
  }
  .edit_line_item_service:hover .delete_line_item_serviceBtn {
    visibility: visible;
  }
  .delete_line_item_serviceBtn:hover {
    background: #e8e8e8 !important;
  }

  .invoice_taxes .edit_invoice_taxBtn {
    visibility: hidden;
  }
  .invoice_taxes:hover .edit_invoice_taxBtn {
    visibility: visible;
  }
  .edit_invoice_taxBtn:hover {
    background: #e8e8e8 !important;
  }

  .invoice_taxes .delete_invoice_taxBtn {
    visibility: hidden;
  }
  .invoice_taxes:hover .delete_invoice_taxBtn {
    visibility: visible;
  }
  .delete_invoice_taxBtn:hover {
    background: #e8e8e8 !important;
  }

  .invoice_discount .edit_invoice_discountBtn {
    visibility: hidden;
  }
  .invoice_discount:hover .edit_invoice_discountBtn {
    visibility: visible;
  }
  .edit_invoice_discountBtn:hover {
    background: #e8e8e8 !important;
  }

  .invoice_discount .delete_invoice_discountBtn {
    visibility: hidden;
  }
  .invoice_discount:hover .delete_invoice_discountBtn {
    visibility: visible;
  }
  .delete_invoice_discountBtn:hover {
    background: #e8e8e8 !important;
  }

  .invoice_notes .edit_invoice_NoteBtn {
    visibility: hidden;
  }

  .invoice_notes:hover .edit_invoice_NoteBtn {
    visibility: visible;
  }
  .edit_invoice_NoteBtn:hover {
    background: #e8e8e8 !important;
  }

  .invoice_notes .delete_invoice_NoteBtn {
    visibility: hidden;
  }

  .invoice_notes:hover .delete_invoice_NoteBtn {
    visibility: visible;
  }

  .delete_invoice_NoteBtn:hover {
    background: #e8e8e8 !important;
  }

  .invoice_business .edit_invoice_businessBtn {
    visibility: hidden;
  }

  .invoice_business:hover .edit_invoice_businessBtn {
    visibility: visible;
  }

  .edit_invoice_businessBtn:hover {
    background: #e8e8e8 !important;
  }

  @media only screen and (max-width: 992px) and (min-width: 768px) {
    .review_box_padding {
      padding-left: 3rem;
      padding-right: 3rem;
    }
  }

  @media only screen and (max-width: 768px) and (min-width: 576px) {
    .review_box_padding {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  @media (max-width: 576px) {
    .review_box_padding {
      padding-left: 0rem;
      padding-right: 0rem;
    }
  }
`;

const inputBox = styled.div`
  border-radius: 0.75rem;
  padding: 1rem;
  margin-right: 3px;
  background: ${Colors.white};
  height: 3.437rem;
  border: 1px solid ${(props) => (props.invalid ? "red" : Colors.font2)};
`;

const Selected = styled.div`
  padding: 1rem 0.75rem;
  border-radius: 0.75rem;
  display: flex;
  justify-content: center;
  cursor: pointer;
  background-color: ${Colors.backgroundPink};
  color: ${Colors.primary};
`;

const NotSelected = styled(Selected)`
  color: ${Colors.font2} !important;
  background-color: ${Colors.tableHeadColor};
`;

const SelectorRectangle = styled.div`
  width: 6.625rem;
  height: 3.5rem;
  display: flex;
  border-radius: 1rem;
  border: solid 1px #f0eefa;
  background-color: ${Colors.white};
`;

const InputBox = styled.div`
  border-radius: 0.75rem;
  padding: 1rem;
  border: 1px solid ${(props) => (props.invalid ? "red" : Colors.font2)};
  background-color: ${(props) => (props.invalid ? "#e7d5d5" : Colors.white)};
`;

const NextButton = styled.button`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 100;
  min-width: fit-content;
  min-width: 6rem;
  padding: 0 1.5rem;
  height: 3.15rem;
  font-family: Poppins, serif;
  font-size: 0.875rem;
  color: ${(props) => props.color || Colors.white};
  border-radius: 0.75rem;
  background-color: ${(props) => props.backgroundColor || Colors.primary};
  transition: background-color 0.4s ease;
  &:hover {
    background-color: ${(props) =>
      props.backgroundColor
        ? `${props.backgroundColor}`
        : `${Colors.hoverButtonColor}`};
  }

  &:active {
    background-color: ${(props) =>
      props.backgroundColor ? props.backgroundColor : `${Colors.primary}`};
  }

  &:disabled {
    opacity: 0.5 !important;
  }
`;

export default {
  SetupBox,
  inputBox,
  Selected,
  NotSelected,
  SelectorRectangle,
  InputBox,
  NextButton,
};
