import React, { useState } from "react";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { message } from "antd";
import TextStyles from "../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";
import { useDispatch, useSelector } from "react-redux";
import { updateWarrantyPC } from "../../../../../../store/service/proposalAndContractService";
import WarrantyModal from "../../PageModals/WarrantyModal";
import RemoveModal from "../../../../Commons/RemoveModal";
import Colors from "../../../../../../Constants/Colors";

const Warranty = () => {
  const selectedData = useSelector(
    (state) => state.proposalAndContract.selectedData
  );
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const handleCloseRemoveModal = () => setShowRemoveModal(false);
  const heading =
    selectedData && selectedData.warranty && selectedData.warranty.heading
      ? selectedData && selectedData.warranty && selectedData.warranty.heading
      : "Warranty";
  const paragraph =
    selectedData && selectedData.warranty && selectedData.warranty.paragraph
      ? selectedData && selectedData.warranty && selectedData.warranty.paragraph
      : `Contractor hereby warrants that:(i) The Services will be conducted in a professional and workmanlike manner, free from any inconsistency with any other contractual obligations held by the Contractor;(ii) The Services, Inventions, and their development, use, production, distribution, or exploitation will not infringe, misappropriate, or violate any intellectual property or rights of any entity, including the Contractor;(iii) The Contractor possesses full rights to provide the Client with the assignments and rights as stipulated in this Agreement;(iv) The Contractor will comply with all applicable laws while performing the Services and adhering to the Terms and Conditions;(v) If the Contractor's work requires a license, the Contractor has obtained the necessary license, which remains in full force and effect.\n\nEXCEPT AS SPECIFIED IN THIS ATTACHMENT B, THE CONTRACTOR DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, SUITABILITY, COMPLETENESS, OR RESULTS DERIVED FROM THE WORK. EXCEPT AS EXPRESSLY STATED HEREIN, ALL DELIVERABLES ARE PROVIDED ON AN "AS-IS" BASIS.
`;

  const hideClauseHandler = () => {
    const Obj = {
      _id: selectedData._id,
      warranty: {
        required: false,
        heading: null,
        paragraph: null,
      },
    };
    dispatch(updateWarrantyPC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleCloseRemoveModal();
        message.success("Cluase hidden successfully!");
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  return (
    <div
      style={{
        display:
          selectedData &&
          selectedData.warranty &&
          selectedData.warranty.required === true
            ? "inline"
            : "none",
      }}
    >
      <Styled.ReviewContentBox className="mt-4">
        <Styled.EditButtonToolTip
          onClick={() => setShowModal(true)}
          className="contract_review_edit_tp"
          style={{ marginLeft: -60 }}
        >
          <BorderColorOutlinedIcon sx={{ color: Colors.white, fontSize: 20 }} />
          <span className="ms-1">Edit</span>
        </Styled.EditButtonToolTip>
        <Styled.EditButtonToolTip
          backgroundColor="#383838"
          className="contract_review_edit_tp"
          style={{ marginLeft: 60 }}
          onClick={() => {
            setShowRemoveModal(true);
            setConfirmDialog({
              onConfirm: hideClauseHandler,
            });
          }}
        >
          <VisibilityOffIcon sx={{ color: Colors.white, fontSize: 20 }} />
          <span className="ms-1">Hide</span>
        </Styled.EditButtonToolTip>

        <TextStyles.FontSize20px className="mb-2">
          {heading}
        </TextStyles.FontSize20px>
        <TextStyles.FontSize14px style={{ whiteSpace: "break-spaces" }}>
          {paragraph}
        </TextStyles.FontSize14px>
      </Styled.ReviewContentBox>

      {showModal && (
        <WarrantyModal
          selectedData={selectedData}
          paragraph={paragraph}
          heading={heading}
          show={showModal}
          handleClose={() => setShowModal(false)}
        />
      )}
      {confirmDialog && (
        <RemoveModal
          title={heading}
          confirmDialog={confirmDialog}
          showRemoveModal={showRemoveModal}
          handleCloseRemoveModal={handleCloseRemoveModal}
        />
      )}
    </div>
  );
};

export default Warranty;
