import React from "react";
import { makeStyles } from "@mui/styles";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Colors from "../../../Constants/Colors";
import sizes from "../../../Constants/sizes";

const useStyles = makeStyles({
  root: {
    border: `1px solid ${Colors.borderInput} !important`,
    backgroundColor: Colors.white,
    borderRadius: "0.75rem !important",
    fontSize: "0.875rem !important",
    height: sizes.formInputHeight,
    fontFamily: "Poppins !important",

    "&:hover": {
      border: `1px solid ${Colors.primary} !important`,
    },

    "&.Mui-focused": {
      border: `1px solid ${Colors.primary} !important`,
    },

    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },

  select: {
    display: "flex !important",
    justifyContent: "start",
    alignItems: "center !important",
  },

  listMenu: {
    color: "grey",
    fontWeight: "400 !important",
    marginBottom: "2px !important",
    fontSize: "0.875rem !important",
    fontFamily: "Poppins !important",
    "&:hover": {
      backgroundColor: `${Colors.backgroundPink} !important`,
      border: "none",
    },
    '&[aria-selected="true"]': {
      backgroundColor: `${Colors.backgroundPink} !important`,
    },
  },
});

export default function SelectFrequencyTimes(props) {
  const classes = useStyles();
  const options = props.options;

  return (
    <div>
      <Select
        classes={{
          select: classes.select,
        }}
        className={classes.root}
        value={props.defaultValue ? props.defaultValue : options[0]._id}
        id="grouped-select"
        sx={{ width: "100%" }}
        onChange={props.onChange}
        MenuProps={{
          PaperProps: { sx: { maxHeight: 250 } },
          disableScrollLock: true,
        }}
      >
        {options &&
          options.map((data, index) => (
            <MenuItem
              key={data._id}
              value={data._id}
              className={classes.listMenu}
            >
              {data.times}
            </MenuItem>
          ))}
      </Select>
    </div>
  );
}
