import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AutoCompleteSelectClient } from "../../../Commons/AutoComplete";
import TextStyles from "../../../../../Constants/TextStyles";
import Styled from "./SetupClientStyles";
import Colors from "../../../../../Constants/Colors";
import { invoiceActions } from "../../../../../store/storage/invoiceSlice";
import { useHistory } from "react-router-dom";

const SetupClient = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const clientOptionData = useSelector(
    (state) => state.clients.clientOptionData
  );
  const createInvoiceMode = useSelector(
    (state) => state.invoices.createInvoiceMode
  );
  const createInvoiceClientMode = useSelector(
    (state) => state.invoices.createInvoiceClientMode
  );

  const selectedInvoiceData = useSelector(
    (state) => state.invoices.selectedInvoiceData
  );

  const client_id =
    selectedInvoiceData &&
    selectedInvoiceData.client_id &&
    selectedInvoiceData.client_id;

  const selectedExistingClientData =
    clientOptionData &&
    client_id &&
    clientOptionData
      .filter((client) => client._id === (client_id && client_id._id))
      .map((data) => {
        return { label: data.company_name, _id: data._id };
      })[0];

  const [selected, setSelected] = useState(null);
  const [selectExistingClient, setSelectExistingClient] = useState(
    selectedExistingClientData ? selectedExistingClientData : null
  );

  useEffect(() => {
    if (createInvoiceClientMode === "create_new_client") {
      setSelectExistingClient(null);
    }
    if (createInvoiceClientMode) {
      setSelected(createInvoiceClientMode);
    }
  }, []);

  const selectClientHandler = (value) => {
    const clientId = value && value._id;
    const client_id =
      clientOptionData &&
      clientOptionData.filter((client) => client._id === clientId)[0];
    const Obj = {
      client_id,
    };

    dispatch(invoiceActions.selectedInvoiceAction(Obj));
    if (value) {
      setSelectExistingClient(value);
    } else {
      setSelectExistingClient(null);
    }
  };

  const nextButtonHandler = () => {
    if (
      selected === "select_existing_client" &&
      selectExistingClient &&
      createInvoiceMode === "quickInvoice"
    ) {
      dispatch(invoiceActions.createInvoiceClientModeAction(selected));
      return history.push("/db/invoices/create/quickInvoice");
    }
    if (
      selected === "select_existing_client" &&
      selectExistingClient &&
      createInvoiceMode === "cProjectInvoice"
    ) {
      dispatch(invoiceActions.createInvoiceClientModeAction(selected));
      return history.push("/db/invoices/create/setupProject");
    }
    if (selected === "create_new_client") {
      dispatch(invoiceActions.selectedInvoiceAction({ client_id: null }));
      dispatch(invoiceActions.createInvoiceClientModeAction(selected));
      return history.push("/db/invoices/create/createClient");
    }
  };

  return (
    <div className="d-flex justify-content-center px-2">
      <div style={{ overflow: "hidden" }}>
        <TextStyles.FontSize27px className=" text-center mb-4">
          Who's your client ?
        </TextStyles.FontSize27px>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 d-flex justify-content-center">
            <Styled.DashBoardContainer
              className="d-flex flex-column justify-content-center align-items-center"
              onClick={() => setSelected("select_existing_client")}
              style={{
                border:
                  selected === "select_existing_client"
                    ? `3px solid ${Colors.primary}`
                    : "",
              }}
            >
              <TextStyles.FontSize16px className="mb-3">
                Select from existing clients
              </TextStyles.FontSize16px>

              <AutoCompleteSelectClient
                placeholder="Choose a client"
                options={
                  clientOptionData &&
                  clientOptionData.map((data) => {
                    return { label: data.company_name, _id: data._id };
                  })
                }
                onChange={selectClientHandler}
                defaultValue={selectExistingClient && selectExistingClient}
              />
            </Styled.DashBoardContainer>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 d-flex justify-content-center">
            <Styled.DashBoardContainer
              className="d-flex flex-column justify-content-center align-items-center"
              onClick={() => setSelected("create_new_client")}
              style={{
                border:
                  selected === "create_new_client"
                    ? `3px solid ${Colors.primary}`
                    : "",
              }}
            >
              <TextStyles.FontSize16px>
                Create new client
              </TextStyles.FontSize16px>
            </Styled.DashBoardContainer>
          </div>
        </div>

        <Styled.SubmitButton
          disabled={
            selected === "select_existing_client" && selectExistingClient
              ? false
              : selected === "create_new_client"
              ? false
              : true
          }
          style={{
            minWidth: 120,
          }}
          onClick={nextButtonHandler}
        >
          Next
        </Styled.SubmitButton>
      </div>
    </div>
  );
};

export default SetupClient;
