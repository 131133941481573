import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import ClearIcon from "@mui/icons-material/Clear";

import ProposalReview from "../ProposalReviews/Review";
import ContractReview from "../ContractReviews/Review";
import Send from "../Send/Send";

import Styled from "../CreateAgreements/CreateAgreementsStyles";
import TextStyles from "../../../../Constants/TextStyles";
import Colors from "../../../../Constants/Colors";
import { BackButton } from "../../Commons/BackAndForwardButton";
import { useLocation } from "react-router-dom";
import ProtectedRoute from "../../../auth/ProtectedRoute.js";
import { pcActions } from "../../../../store/storage/proposalAndContractSlice.js";

export default function EditProposal() {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const pathname = location && location.pathname.split("/")[4];

  const selectedData = useSelector(
    (state) => state.proposalAndContract.selectedData
  );

  const backBtn = () => {
    dispatch(pcActions.resetPcDataAction());
    history.push("/db/agreements");
  };

  const BackButtonUI = () => {
    return (
      <div
        style={{
          position: "fixed",
          left: 20,
          top: "50vh",
          zIndex: 200,
        }}
        onClick={() => history.goBack()}
      >
        <BackButton />
      </div>
    );
  };

  return (
    <div>
      <div
        style={{
          background: Colors.backgroundGrey,
          minHeight: "100vh",
        }}
      >
        <div
          style={{
            background: Colors.white,
            borderBottom: "2px solid #dfe1e9",
            zIndex: 200,
          }}
          className="fixed-top"
        >
          <div className="p-2">
            <TextStyles.FontSize14px
              style={{
                position: "absolute",
                left: "0.75rem",
                top: "0.75rem",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={backBtn}
              >
                <ClearIcon
                  sx={{
                    fontSize: 27,
                    color: Colors.font2,
                    cursor: "pointer",
                    opacity: 0.7,
                    "&:hover": {
                      opacity: 1,
                    },
                  }}
                />
              </div>
            </TextStyles.FontSize14px>
            <TextStyles.FontSize20px
              className="d-flex justify-content-center w-100 ps-4 ps-sm-none"
              style={{
                textAlign: "center",
                fontWeight: 500,
                fontSize: 24,
                minWidth: 200,
              }}
            >
              <span className="three-dots mx-2">
                {selectedData.section === "Proposal and contract" &&
                  "Edit proposal and contract"}
                {selectedData.section === "Contract" && "Edit contract"}{" "}
                {selectedData.section === "Proposal" && "Edit proposal"}
              </span>
            </TextStyles.FontSize20px>
          </div>

          <Styled.ProgressBar>
            {selectedData && selectedData.section !== "Contract" && (
              <div
                className="w-100"
                style={
                  pathname === "reviewProposal" ||
                  pathname === "reviewContract" ||
                  pathname === "send"
                    ? {
                        height: "100%",
                        paddingTop: "3px",
                        backgroundColor: Colors.primary,
                        color: Colors.white,
                        justifyContent: "center",
                        display: "flex",
                        minWidth: "5rem",
                      }
                    : {
                        justifyContent: "center",
                        display: "flex",
                        minWidth: "5rem",
                      }
                }
              >
                <span className="three-dots mx-2">Review proposal</span>{" "}
              </div>
            )}
            {selectedData && selectedData.section !== "Proposal" && (
              <div
                className="w-100"
                style={
                  pathname === "reviewContract" || pathname === "send"
                    ? {
                        height: "100%",
                        paddingTop: "3px",
                        backgroundColor: Colors.primary,
                        color: Colors.white,
                        justifyContent: "center",
                        display: "flex",
                        minWidth: "5rem",
                      }
                    : {
                        justifyContent: "center",
                        display: "flex",
                        minWidth: "5rem",
                      }
                }
              >
                <span className="three-dots mx-2">Review contract</span>
              </div>
            )}

            <div
              className="w-100"
              style={
                pathname === "send"
                  ? {
                      height: "100%",
                      paddingTop: "3px",
                      backgroundColor: Colors.primary,
                      color: Colors.white,
                      justifyContent: "center",
                      display: "flex",
                      minWidth: "5rem",
                    }
                  : {
                      justifyContent: "center",
                      display: "flex",
                      minWidth: "5rem",
                    }
              }
            >
              <span className="three-dots mx-2">Send</span>
            </div>
          </Styled.ProgressBar>
        </div>

        <div style={{ paddingTop: 100 }}>
          {selectedData &&
            selectedData.section === "Proposal and contract" &&
            pathname !== "reviewProposal" && <BackButtonUI />}

          {selectedData &&
            selectedData.section === "Proposal" &&
            pathname !== "reviewProposal" && <BackButtonUI />}
          {selectedData &&
            selectedData.section === "Contract" &&
            pathname !== "reviewContract" && <BackButtonUI />}

          {pathname === "reviewProposal" && (
            <ProtectedRoute
              path="/db/agreements/:id/reviewProposal"
              exact
              component={ProposalReview}
            />
          )}
          {pathname === "reviewContract" && (
            <ProtectedRoute
              path="/db/agreements/:id/reviewContract"
              exact
              component={ContractReview}
            />
          )}
          {pathname === "send" && (
            <ProtectedRoute
              path="/db/agreements/:id/send"
              exact
              component={Send}
            />
          )}
        </div>
      </div>
    </div>
  );
}
