import React from "react";

import TextStyles from "../../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";
import Colors from "../../../../../../../Constants/Colors";

const CancellationFee = (props) => {
  const selectedData = props && props.selectedData;

  const heading = "Cancellation Fee";

  return (
    <div
      style={{
        display:
          selectedData &&
          selectedData.cancellation_fee &&
          selectedData.cancellation_fee.required === true
            ? "inline"
            : "none",
      }}
    >
      {selectedData &&
        selectedData.cancellation_fee &&
        selectedData.cancellation_fee.cancellation_fee_deposit_require ===
          true && (
          <Styled.ReviewContentBox className="mt-4">
            <TextStyles.FontSize20px className="mb-2">
              {heading}
            </TextStyles.FontSize20px>
            <TextStyles.FontSize14px>
              {selectedData &&
              selectedData.cancellation_fee &&
              selectedData.cancellation_fee.cancellation_fee_deposit_require ===
                true ? (
                <>
                  If the Client terminates this Agreement earlier without cause,
                  Contractor will charge a cancellation fee of
                  <span
                    className="ms-2"
                    style={{ color: Colors.primary, fontWeight: 500 }}
                  >
                    {selectedData.currency.symbol}
                    {parseFloat(
                      selectedData.cancellation_fee.cancellation_fee_amount || 0
                    ).toFixed(2)}
                  </span>
                  .
                </>
              ) : (
                <>Note: This section shows if only applicable.</>
              )}
            </TextStyles.FontSize14px>
          </Styled.ReviewContentBox>
        )}
    </div>
  );
};

export default CancellationFee;
