import styled from "styled-components";
import Colors from "../../../../Constants/Colors";

const WeeklyViewBox = styled.div`
  width: 100%;
  padding: 0 1rem;
  box-shadow: 0 8px 12px 0 rgba(238, 241, 245, 0.89);
  background-color: ${Colors.white};
`;

const WeeklyViewDays = styled.div`
  width: 100%;
  height: 3.15rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DaysInputRectangle = styled.input`
  width: 100%;
  text-align: center;
  padding: 2px 3px;
  border-radius: 6px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border: 1px solid
    ${(props) => (props.invalid ? Colors.red : Colors.borderInput)};
  background-color: ${(props) =>
    props.invalid ? Colors.inputErrorBg : Colors.white};
  transition: border 0.4s ease;
  :focus {
    border: 1px solid ${Colors.primary};
  }

  &:hover {
    border: 1px solid ${Colors.primary};
  }
`;

const ListBox = styled.div`
  width: 100%;
  height: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.white};
  border-radius: 0 0 0.75rem 0.75rem;
  padding: 1rem;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
`;

export default {
  WeeklyViewBox,
  WeeklyViewDays,
  DaysInputRectangle,
  ListBox,
};
