import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";

import RadioButtonCheckedRoundedIcon from "@mui/icons-material/RadioButtonCheckedRounded";
import RadioButtonUncheckedRoundedIcon from "@mui/icons-material/RadioButtonUncheckedRounded";

import LoaderSpin from "../../../Commons/LoaderSpin";

import TextStyles from "../../../../../Constants/TextStyles";
import { updateBusinessInfo } from "../../../../../store/service/AuthService";
import SelectSTDCode from "../../../Commons/SelectSTDCode";
import Colors from "../../../../../Constants/Colors";
import SelectCountry from "../../../Commons/SelectCountry";
import Constant from "../../../Commons/Constant";

const BusinessInfo = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.auth.isLoading);
  const data = useSelector((state) => state.auth.data);
  const businessInfoData = useSelector((state) => state.auth.businessInfoData);
  const [radio_btn, set_radio_btn] = useState();
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    defaultValues: {
      business_name: businessInfoData && businessInfoData.business_name,
      phone_code: businessInfoData && businessInfoData.phone_code,
      phone_number: businessInfoData && businessInfoData.phone_number,
      street: businessInfoData && businessInfoData.street,
      suite: businessInfoData && businessInfoData.suite,
      city: businessInfoData && businessInfoData.city,
      zip_code: businessInfoData && businessInfoData.zip_code,
      country:
        businessInfoData &&
        businessInfoData.country &&
        Constant.countryOptions.filter(
          (data) => data.name === businessInfoData.country
        )[0],
      state: businessInfoData && businessInfoData.state,
      tax_id_label: businessInfoData && businessInfoData.tax_id_label,
      tax_id_number: businessInfoData && businessInfoData.tax_id_number,
    },
  });

  useEffect(() => {
    if (businessInfoData) {
      if (
        businessInfoData.business_name !== `${data.firstName} ${data.lastName}`
      ) {
        set_radio_btn(true);
      }
    }
  }, []);

  const formsubmit = async (res) => {
    const Obj = {
      business_name: radio_btn
        ? res.business_name
        : `${data.firstName} ${data.lastName}`,
      phone_code: res.phone_code,
      phone_number: res.phone_number,
      street: res.street,
      suite: res.suite,
      city: res.city,
      zip_code: res.zip_code,
      country: res.country ? res.country.name : null,
      state: res.state,
      tax_id_label: res.tax_id_label,
      tax_id_number: res.tax_id_number,
    };

    dispatch(updateBusinessInfo(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        message.success(`${data.payload.message}`);
      } else {
        message.error(`$  {data.error.message}!`);
      }
    });
  };

  return (
    <div className="mt-4">
      <TextStyles.FontSize20px className="d-flex justify-content-start mb-3">
        Business Info
      </TextStyles.FontSize20px>
      <form onSubmit={handleSubmit(formsubmit)}>
        <div className="d-flex flex-column flex-md-row gap-2 gap-md-5 mb-3">
          <div className="d-flex justify-content-start">
            <RadioButtonCheckedRoundedIcon
              onClick={() => set_radio_btn(false)}
              sx={{
                color: radio_btn ? Colors.font2 : Colors.primary,
                display: radio_btn ? "none" : "inline",
                cursor: "pointer",
              }}
            />
            <RadioButtonUncheckedRoundedIcon
              onClick={() => set_radio_btn(false)}
              sx={{
                color: radio_btn ? Colors.font2 : Colors.primary,
                display: radio_btn ? "inline" : "none",
                cursor: "pointer",
              }}
            />
            <TextStyles.FontSize14px
              className="ps-2"
              style={{ textAlign: "start" }}
            >
              Use my name as my business name
            </TextStyles.FontSize14px>
          </div>
          <div className="d-flex justify-content-start">
            <RadioButtonCheckedRoundedIcon
              onClick={() => set_radio_btn(true)}
              sx={{
                color: radio_btn ? Colors.primary : Colors.font2,
                display: radio_btn ? "inline" : "none",
                cursor: "pointer",
              }}
            />
            <RadioButtonUncheckedRoundedIcon
              onClick={() => set_radio_btn(true)}
              sx={{
                color: radio_btn ? Colors.primary : Colors.font2,
                display: radio_btn ? "none" : "inline",
                cursor: "pointer",
              }}
            />

            <TextStyles.FontSize14px
              className="ps-2"
              style={{ textAlign: "start" }}
            >
              Use a registered business name
            </TextStyles.FontSize14px>
          </div>
        </div>

        {radio_btn && (
          <TextStyles.FontSize14px className="mb-3" style={{ maxWidth: 600 }}>
            <label htmlFor="business_name" className="mb-2">
              Business name
            </label>
            <TextStyles.InputRectangle
              invalid={errors.business_name}
              id="business_name"
              type="text"
              name="business_name"
              placeholder="Business name"
              {...register("business_name", {
                required: "Business name is required.",
                validate: (value) => {
                  let trimedLength = value.trim().length;
                  if (trimedLength === 0) {
                    return "Business name is required.";
                  }
                  return true;
                },
              })}
            />
            {errors.business_name && (
              <TextStyles.InValidFeedback>
                {errors.business_name.message}
              </TextStyles.InValidFeedback>
            )}
          </TextStyles.FontSize14px>
        )}

        <TextStyles.FontSize14px className="mb-4" style={{ maxWidth: 600 }}>
          <label htmlFor="phone_number" className="mb-2">
            Phone number
          </label>

          <div className="row">
            <div className="col-12 col-sm-6 mb-3 mb-sm-0">
              <Controller
                control={control}
                name="phone_code"
                render={({ field: { onChange, value } }) => (
                  <SelectSTDCode
                    placeholder="Select code"
                    onChange={onChange}
                    defaultValue={value}
                  />
                )}
              />
            </div>
            <div className="col-12 col-sm-6">
              <TextStyles.InputRectangle
                style={{ width: "100%", margin: "0px" }}
                invalid={errors.phone_number}
                id="phone_number"
                type="tel"
                name="phone_number"
                placeholder=" Phone number"
                {...register("phone_number", {
                  pattern: {
                    value: /^[\d\s-]{7,16}$/,
                    message: "Please enter valid phone number.",
                  },
                })}
              />
              {errors.phone_number && (
                <TextStyles.InValidFeedback className="mt-2">
                  {errors.phone_number.message}
                </TextStyles.InValidFeedback>
              )}
            </div>
          </div>
        </TextStyles.FontSize14px>

        <TextStyles.FontSize20px className="mb-3">
          Business address
        </TextStyles.FontSize20px>

        <TextStyles.FontSize14px className="mb-3" style={{ maxWidth: 600 }}>
          <div className="row">
            <div className="col-12 col-sm-6 mb-3 mb-sm-0">
              <label htmlFor="street_address" className="mb-2">
                Street address
              </label>
              <TextStyles.InputRectangle
                id="street_address"
                type="text"
                placeholder="Street address"
                name="street"
                {...register("street")}
              />
            </div>
            <div className="col-12 col-sm-6">
              <label htmlFor="suite_address" className="mb-2">
                Suite address
              </label>
              <TextStyles.InputRectangle
                id="suite_address"
                type="text"
                placeholder="Suite address"
                name="suite"
                {...register("suite")}
              />
            </div>
          </div>
        </TextStyles.FontSize14px>
        <TextStyles.FontSize14px className="mb-3" style={{ maxWidth: 600 }}>
          <div className="row">
            <div className="col-12 col-sm-6 mb-3 mb-sm-0">
              <label htmlFor="city" className="mb-2">
                City
              </label>
              <TextStyles.InputRectangle
                id="city"
                type="text"
                placeholder="City"
                name="city"
                {...register("city")}
              />
            </div>
            <div className="col-12 col-sm-6">
              <label htmlFor="zip_code" className="mb-2">
                Zip code
              </label>
              <TextStyles.InputRectangle
                id="zip_code"
                type="text"
                placeholder="Zip code"
                name="zip_code"
                {...register("zip_code")}
              />
            </div>
          </div>
        </TextStyles.FontSize14px>
        <TextStyles.FontSize14px className="mb-3" style={{ maxWidth: 600 }}>
          <div className="row">
            <TextStyles.FontSize14px className="col-12 col-sm-6 mb-3 mb-sm-0">
              {" "}
              <label htmlFor="state" className="mb-2">
                State
              </label>
              <TextStyles.InputRectangle
                id="state"
                type="text"
                placeholder="State"
                name="state"
                {...register("state")}
              />
            </TextStyles.FontSize14px>
            <TextStyles.FontSize14px className="col-12 col-sm-6">
              <label htmlFor="country" className="mb-2">
                Country
              </label>
              <div>
                <Controller
                  control={control}
                  id="country"
                  name="country"
                  render={({ field: { onChange, value } }) => (
                    <SelectCountry
                      placeholder="Select country"
                      onChange={onChange}
                      defaultValue={value}
                    />
                  )}
                />
              </div>
            </TextStyles.FontSize14px>
          </div>
        </TextStyles.FontSize14px>
        <TextStyles.FontSize14px className="mb-3" style={{ maxWidth: 600 }}>
          <div className="row">
            <div className="col-12 col-sm-6 mb-3 mb-sm-0">
              <label htmlFor="tax_id_label" className="mb-2">
                Tax ID label
              </label>
              <TextStyles.InputRectangle
                id="tax_id_label"
                type="text"
                placeholder="Tax ID label"
                name="tax_id_label"
                {...register("tax_id_label")}
              />
            </div>
            <div className="col-12 col-sm-6">
              <label htmlFor="tax_id_number" className="mb-2">
                Tax ID number
              </label>
              <TextStyles.InputRectangle
                id="tax_id_number"
                type="text"
                placeholder="Tax ID number"
                name="tax_id_number"
                {...register("tax_id_number")}
              />
            </div>
          </div>
        </TextStyles.FontSize14px>
        <div className="mb-3" style={{ maxWidth: 600 }}>
          <TextStyles.Button disabled={isLoading}>
            {isLoading ? <LoaderSpin /> : "Save settings"}
          </TextStyles.Button>
        </div>
      </form>
    </div>
  );
};

export default BusinessInfo;
