import React from "react";
import Footer from "../Footer/Footer";
import LandingPage from "./LandingPage/LandingPage";
import ContactForm from "./ContactForm/ContactForm";
import { Helmet } from "react-helmet";

const ContactUs = () => {
  return (
    <div>
      <Helmet>
        <title>How to Contact Us - Zodot</title>
        <meta
          content="Want to learn how Zodot can support your business? Contact us! Our support team is here to help with any questions you have."
          name="description"
        />
        <link rel="canonical" href="https://zodot.co/contact" />
      </Helmet>
      <LandingPage />
      <ContactForm />
      <Footer />
    </div>
  );
};

export default ContactUs;
