import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";

import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import PauseCircleFilledIcon from "@mui/icons-material/PauseCircleFilled";

import Colors from "../../../../Constants/Colors";
import Styled from "./TimerStyles";
import TextStyles from "../../../../Constants/TextStyles";
import { useDispatch, useSelector } from "react-redux";

import { message } from "antd";
import {
  start_timer_activity,
  stop_timer_activity,
  start_timer_change_activity_name,
  start_timer_change_project_name,
  cancel_start_activity,
  createActivityList,
  fetchAllActivityLists,
} from "../../../../store/service/timeTrackingService";
import { timeTrackingActions } from "../../../../store/storage/timetrackingSlice";

import { convertSecondsToHHMMSS } from "../../Commons/utils";

import {
  AutoCompleteSelectProject,
  AutoCompleteCreateActivity,
} from "../../Commons/TimeTrackingAutoComplete/Autocomplete";

const TimerBox = () => {
  const dispatch = useDispatch();

  const activityListData = useSelector(
    (state) => state.timeTracking.activityListData
  );

  const startActivityData = useSelector(
    (state) => state.timeTracking.startActivityData
  );

  const elapsedTime = useSelector((state) => state.timeTracking.elapsedTime);

  const projectOptionsData = useSelector(
    (state) => state.project.projectOptionsData
  );

  const { reset, handleSubmit, control } = useForm({
    defaultValues: {
      add_activity:
        startActivityData && startActivityData.activity_name && activityListData
          ? activityListData
              .filter(
                (d) => d.activity_name === startActivityData.activity_name
              )
              .map((data) => {
                return { _id: data._id, title: data.activity_name };
              })[0]
          : null,
      select_project:
        startActivityData && startActivityData.project_id && projectOptionsData
          ? projectOptionsData
              .filter((d) => d._id === startActivityData.project_id)
              .map((d) => {
                return {
                  _id: d._id ? d._id : null,
                  title: d && d.project_name ? d.project_name : null,
                  client_id:
                    d && d.client_id && d.client_id._id
                      ? d.client_id._id
                      : null,
                  client_name:
                    d && d.client_id && d.client_id.company_name
                      ? d.client_id.company_name
                      : null,
                };
              })[0]
          : null,
    },
  });

  useEffect(() => {
    reset({
      add_activity:
        startActivityData && startActivityData.activity_name && activityListData
          ? activityListData
              .filter(
                (d) => d.activity_name === startActivityData.activity_name
              )
              .map((data) => {
                return { _id: data._id, title: data.activity_name };
              })[0]
          : null,
      select_project:
        startActivityData && startActivityData.project_id && projectOptionsData
          ? projectOptionsData
              .filter((d) => d._id === startActivityData.project_id)
              .map((d) => {
                return {
                  _id: d._id ? d._id : null,
                  title: d && d.project_name ? d.project_name : null,
                  client_id:
                    d && d.client_id && d.client_id._id
                      ? d.client_id._id
                      : null,
                  client_name:
                    d && d.client_id && d.client_id.company_name
                      ? d.client_id.company_name
                      : null,
                };
              })[0]
          : null,
    });
  }, [startActivityData]);

  const startTimerHandler = (data) => {
    const Obj = {
      project_id: data.select_project ? data.select_project._id : null,
      client_id: data.select_project ? data.select_project.client_id : null,
      activity_name:
        data.add_activity && data.add_activity.inputValue
          ? data.add_activity.inputValue
          : data.add_activity && data.add_activity._id
          ? data.add_activity.title
          : data.add_activity
          ? data.add_activity
          : null,
    };
    if (Obj.activity_name) {
      dispatch(
        timeTrackingActions.addNewActivityList({
          activity_name: Obj.activity_name,
        })
      );
    }
    dispatch(timeTrackingActions.startTimer(Obj));
    if (Obj.activity_name) {
      dispatch(createActivityList({ activity_name: Obj.activity_name })).then(
        () => {
          dispatch(fetchAllActivityLists());
        }
      );
    }
    dispatch(start_timer_activity(Obj));
  };

  const stopTimerHandler = (data) => {
    const Obj = {
      project_id: data.select_project ? data.select_project._id : null,
      client_id: data.select_project ? data.select_project.client_id : null,
      activity_name:
        data.add_activity && data.add_activity.inputValue
          ? data.add_activity.inputValue
          : data.add_activity && data.add_activity._id
          ? data.add_activity.title
          : null,
    };
    dispatch(timeTrackingActions.stopTimer());
    dispatch(stop_timer_activity(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        dispatch(timeTrackingActions.updateRefresh());
        message.success(`${data.payload.message}`);
      } else {
        message.error(`${data.error.message}`);
      }
    });
  };

  const startTimerChangeActivityNameHandler = (data) => {
    const Obj = {
      activity_name:
        data && data._id
          ? data.title
          : data && data.inputValue
          ? data.inputValue
          : data
          ? data
          : null,
    };
    if (startActivityData && startActivityData.start_time) {
      dispatch(timeTrackingActions.addNewActivityList(Obj));
      if (Obj.activity_name && !data._id) {
        dispatch(createActivityList(Obj)).then((data) => {
          if (data.meta.requestStatus === "fulfilled") {
            dispatch(fetchAllActivityLists());
          }
        });
      }
      dispatch(start_timer_change_activity_name(Obj));
    }
  };

  const startTimerChangeProjectNameHandler = (data) => {
    const Obj = {
      project_id: data && data._id ? data._id : null,
    };
    if (startActivityData && startActivityData.start_time) {
      dispatch(start_timer_change_project_name(Obj));
    }
  };

  const cancelActivityHandler = () => {
    dispatch(timeTrackingActions.stopTimer());
    dispatch(cancel_start_activity()).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        dispatch(fetchAllActivityLists());
      }
    });
  };

  const resetHandle = () => {
    reset({
      add_activity: null,
      select_project: null,
    });
    dispatch(timeTrackingActions.resetActivityAction());
  };

  return (
    <Styled.TimerBox>
      <div className="d-flex gap-3 flex-wrap flex-md-nowrap align-items-center w-100">
        <div className="d-flex gap-3 flex-wrap flex-sm-nowrap align-items-center w-100">
          <div style={{ width: "100%", maxWidth: 65 }}>
            <button
              type="submit"
              // disabled={isLoading ? true : false}
              className="tract_your_time_tour"
            >
              <PlayCircleFilledWhiteIcon
                onClick={handleSubmit(startTimerHandler)}
                sx={{
                  fontSize: 75,
                  color: Colors.primary,
                  cursor: "pointer",
                  display:
                    startActivityData && startActivityData.start_time
                      ? "none"
                      : "block",
                }}
              />
              <PauseCircleFilledIcon
                onClick={handleSubmit(stopTimerHandler)}
                sx={{
                  fontSize: 75,
                  color: Colors.primary,
                  cursor: "pointer",
                  display:
                    startActivityData && startActivityData.start_time
                      ? "block"
                      : "none",
                }}
              />
            </button>
          </div>
          <div
            className="d-flex flex-column justify-content-center align-items-center"
            style={{ width: "100%", maxWidth: 115 }}
          >
            <TextStyles.FontSize18px>
              {convertSecondsToHHMMSS(elapsedTime).formattedTime}
            </TextStyles.FontSize18px>
            {startActivityData && startActivityData.start_time ? (
              <TextStyles.GreyFont14px
                className="mt-2"
                style={{ cursor: "pointer" }}
                onClick={cancelActivityHandler}
              >
                Cancel
              </TextStyles.GreyFont14px>
            ) : (
              <TextStyles.GreyFont14px
                className="mt-2"
                onClick={resetHandle}
                style={{ cursor: "pointer" }}
              >
                Reset
              </TextStyles.GreyFont14px>
            )}
          </div>
          <div style={{ width: "100%" }}>
            <Controller
              control={control}
              name="add_activity"
              render={({ field: { onChange, value } }) => (
                <AutoCompleteCreateActivity
                  placeholder="Write activity..."
                  options={
                    activityListData &&
                    activityListData.map((d) => {
                      return { _id: d._id, title: d.activity_name };
                    })
                  }
                  onChange={(value) => {
                    onChange(value);
                    startTimerChangeActivityNameHandler(value);
                  }}
                  defaultValue={value}
                />
              )}
            />
          </div>
        </div>
        <div className="d-flex gap-3 flex-wrap flex-sm-nowrap align-items-center w-100">
          <Controller
            control={control}
            name="select_project"
            render={({ field: { onChange, value } }) => (
              <AutoCompleteSelectProject
                placeholder="Assign to project"
                options={
                  projectOptionsData &&
                  projectOptionsData.map((d) => {
                    return {
                      _id: d._id ? d._id : null,
                      title: d && d.project_name ? d.project_name : null,
                      client_id:
                        d && d.client_id && d.client_id._id
                          ? d.client_id._id
                          : null,
                      client_name:
                        d && d.client_id && d.client_id.company_name
                          ? d.client_id.company_name
                          : null,
                    };
                  })
                }
                onChange={(value) => {
                  onChange(value);
                  startTimerChangeProjectNameHandler(value);
                }}
                defaultValue={value}
              />
            )}
          />
        </div>
      </div>
    </Styled.TimerBox>
  );
};

export default React.memo(TimerBox);
