const unsupportedCurrencySymbols = [
  "AFN",
  "AMD",
  "AZN",
  "BHD",
  "GEL",
  "JOD",
  "MAD",
  "RUB",
  "SDG",
  "AED",
  "DZD",
  "IRR",
  "IQD",
  "KWD",
  "LYD",
  "OMR",
  "QAR",
  "SAR",
  "TND",
  "YER",
];

const pdfSupportedCurrency = (currencyCode) => {
  return !unsupportedCurrencySymbols.includes(currencyCode);
};

export default pdfSupportedCurrency;
