import React from "react";
import LandingPage from "./LandingPage/LandingPage";
import Features from "./Features/Features";

import Footer from "../../Footer/Footer";
import AdditionalFeatures from "./AdditionalFeatures/AdditionalFeatures";
import SignupForm from "../../UIs/SignupForm";
import { Helmet } from "react-helmet";

const Agreements = () => {
  return (
    <div>
      <Helmet>
        <title>
          The Best Free Agreement Software to Attract Clients - Zodot
        </title>
        <meta
          content="Build client trust with Zodot's online agreement software. Share detailed, customized agreements and supporting attachments effortlessly."
          name="description"
        />
        <link rel="canonical" href="https://zodot.co/features/agreements" />
      </Helmet>
      <LandingPage />
      <Features />
      <AdditionalFeatures />
      <SignupForm />
      <Footer />
    </div>
  );
};

export default Agreements;
