import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";

import EditInvoiceDates from "../InvoiceModals/EditInvoiceDates";
import EditInvoiceTitle from "../InvoiceModals/EditInvoiceTitle";
import EditClientInfo from "../InvoiceModals/EditClientInfo";
import EditInvoiceService from "../InvoiceModals/EditInvoiceService";
import InvoiceNotes from "../InvoiceModals/InvoiceNotes";
import EditBusinessInfo from "../InvoiceModals/EditBusinessInfo";
import EditLineItemService from "../InvoiceModals/EditLineItemService";

import { ReactTooltip, ReactTooltipWithIcon } from "../../Commons/ReactTooltip";

import Styled from "./ReviewInvoiceStyle";
import Colors from "../../../../Constants/Colors";
import TextStyles from "../../../../Constants/TextStyles";
import AddLineItemService from "../InvoiceModals/AddLineItemService";
import InvoiceTaxes from "../InvoiceModals/InvoiceTaxes";
import DiscountInvoices from "../InvoiceModals/DiscountInvoices";
import ChangeCurrency from "../InvoiceModals/changeCurrency";
import { invoiceActions } from "../../../../store/storage/invoiceSlice";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";

const ReviewInvoice = () => {
  const location = useLocation();
  const pathname =
    location && location.pathname.split("/").slice(0, -1).join("/");
  const history = useHistory();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.auth.data);
  const businessInfoData = useSelector((state) => state.auth.businessInfoData);
  const brandAndLogoData = useSelector((state) => state.auth.brandAndLogoData);

  const selectedInvoiceData = useSelector(
    (state) => state.invoices.selectedInvoiceData
  );
  const invoice_services_array =
    selectedInvoiceData && selectedInvoiceData.invoice_services;
  const line_item_services_array =
    selectedInvoiceData && selectedInvoiceData.line_item_services;
  const taxes_array = selectedInvoiceData && selectedInvoiceData.taxes;

  const [changeCurrency, setChangeCurrency] = useState(false);
  const [editInvoice, setEditInvoice] = useState(false);
  const [editInvoiceDates, setEditInvoiceDates] = useState(false);
  const [editClientInfo, setEditClientInfo] = useState(false);
  const [editInvoiceService, setEditInvoiceService] = useState(false);
  const [editLineItemService, setEditLineItemService] = useState(false);
  const [addLineItemService, setAddLineItemService] = useState(false);
  const [taxes, setTaxes] = useState(false);
  const [discount, setDiscount] = useState(false);

  const [selectedData, setSelectedData] = useState(null);
  const [editNote, setEditNote] = useState(false);
  const [editBusinessInfo, setEditBusinessInfo] = useState(false);

  const totalInvoiceServiceAmount = useMemo(() => {
    let sum = 0;
    invoice_services_array &&
      invoice_services_array.forEach((d) => {
        if (d.service_rate !== "" && d.checked) {
          sum += parseFloat(d.service_rate) * parseFloat(d.service_rate_count);
        }
      });
    return sum;
  }, [invoice_services_array]);

  const totalLineItemServiceAmount = useMemo(() => {
    let sum = 0;
    line_item_services_array &&
      line_item_services_array.forEach((d) => {
        if (d.service_rate !== "") {
          sum += parseFloat(d.service_rate) * parseFloat(d.service_rate_count);
        }
      });
    return sum;
  }, [line_item_services_array]);

  const totalServiceAmount = useMemo(() => {
    return totalInvoiceServiceAmount + totalLineItemServiceAmount;
  }, [totalInvoiceServiceAmount, totalLineItemServiceAmount]);

  const invoiceDiscount = useMemo(() => {
    let sum = 0;
    if (selectedInvoiceData && selectedInvoiceData.discount) {
      if (selectedInvoiceData.discount.percentage) {
        sum = parseFloat(
          totalServiceAmount *
            parseFloat(selectedInvoiceData.discount.percentage) *
            0.01
        );
      } else {
        sum = selectedInvoiceData.discount.value;
      }
    }
    return sum;
  }, [totalServiceAmount, selectedInvoiceData]);

  const totalServiceAmountAfterDiscount = useMemo(() => {
    return parseFloat(totalServiceAmount) - parseFloat(invoiceDiscount);
  }, [totalServiceAmount, invoiceDiscount]);

  const totalTaxesAmount = useMemo(() => {
    let sum = 0;
    taxes_array &&
      taxes_array.forEach((d) => {
        if (d.percentage !== "") {
          sum +=
            (parseFloat(d.percentage) *
              parseFloat(totalServiceAmountAfterDiscount)) /
            100;
        }
      });
    return sum;
  }, [taxes_array, totalServiceAmountAfterDiscount]);

  // Use `useEffect` to dispatch the action after taxes amount is calculated
  useEffect(() => {
    if (totalTaxesAmount !== undefined) {
      dispatch(
        invoiceActions.selectedInvoiceAction({
          total_tax: totalTaxesAmount,
        })
      );
    }
  }, [totalTaxesAmount]);

  const totalDueAmount = useMemo(() => {
    const totalDueAmount = totalServiceAmountAfterDiscount + totalTaxesAmount;
    return totalDueAmount;
  }, [totalServiceAmountAfterDiscount, totalTaxesAmount]);

  // Use another `useEffect` for updating the total due amount
  useEffect(() => {
    if (totalDueAmount !== undefined) {
      dispatch(
        invoiceActions.selectedInvoiceAction({
          invoiced_amount: totalDueAmount,
        })
      );
    }
  }, [totalDueAmount]);

  const deleteLineItemServiceHandler = (index) => {
    const lineItemServicesArray = [...line_item_services_array];
    lineItemServicesArray.splice(index, 1);
    dispatch(
      invoiceActions.selectedInvoiceAction({
        line_item_services: lineItemServicesArray,
      })
    );
  };

  const deleteNotesHandler = () => {
    const Obj = {
      invoice_notes: null,
    };
    dispatch(invoiceActions.selectedInvoiceAction(Obj));
  };

  const deleteTaxHandler = (index) => {
    const taxesData = [...taxes_array];
    taxesData.splice(index, 1);
    dispatch(invoiceActions.selectedInvoiceAction({ taxes: taxesData }));
  };

  const deleteDiscountHandler = (_id) => {
    const Obj = {
      discount: undefined,
    };
    dispatch(invoiceActions.selectedInvoiceAction(Obj));
  };

  return (
    <div className="px-lg-4 px-md-4 px-sm-3 px-2">
      <div
        className="d-flex justify-content-center w-100"
        style={{ contain: "content" }}
      >
        <div style={{ width: "100%", maxWidth: 830 }}>
          <Styled.SetupBox
            className="my-3"
            style={{
              borderTop: `4px solid ${
                brandAndLogoData && brandAndLogoData.brand_color
                  ? brandAndLogoData.brand_color
                  : Colors.primary
              }`,
            }}
          >
            <div className="d-flex justify-content-end mb-3">
              <div
                className="d-flex align-items-center"
                onClick={() => setChangeCurrency(true)}
                style={{ cursor: "pointer" }}
              >
                <CurrencyExchangeIcon
                  sx={{
                    color: Colors.font2,
                    backgroundColor: "white",
                    fontSize: 18,
                    fontWeight: 500,
                  }}
                />
                <TextStyles.GreyFont16px className="ms-2">
                  Change currency
                </TextStyles.GreyFont16px>
              </div>
            </div>
            {brandAndLogoData &&
              brandAndLogoData.brand_logo &&
              brandAndLogoData.brand_logo.file_url && (
                <div
                  className={
                    brandAndLogoData && brandAndLogoData.logo_alignment
                      ? `text-${brandAndLogoData.logo_alignment} review_box_padding mb-3`
                      : "text-center review_box_padding mb-3"
                  }
                >
                  <img
                    src={brandAndLogoData.brand_logo.file_url}
                    alt="logoImg"
                    style={{ maxWidth: 512, maxHeight: 512 }}
                  />
                </div>
              )}
            <div className="d-flex flex-wrap flex-sm-nowrap gap-3 review_box_padding mt-4 w-100">
              <div className="w-100">
                <div
                  onClick={() => setEditInvoice(true)}
                  style={{ cursor: "pointer" }}
                  className="d-flex align-items-center"
                >
                  <TextStyles.FontSize27px
                    className="d-flex"
                    id="edit_title"
                    style={{
                      color:
                        brandAndLogoData && brandAndLogoData.brand_color
                          ? brandAndLogoData.brand_color
                          : Colors.primary,
                      fontWeight: 600,
                    }}
                  >
                    {selectedInvoiceData && selectedInvoiceData.invoice_title}
                  </TextStyles.FontSize27px>
                  <ReactTooltipWithIcon
                    anchorId={`edit_title`}
                    content="Edit title"
                    place="right"
                    variant="grey"
                  />
                </div>
                {selectedInvoiceData && selectedInvoiceData.invoice_no && (
                  <TextStyles.FontSize14px>
                    Invoice number:
                    <span style={{ color: Colors.font2, marginLeft: 5 }}>
                      {selectedInvoiceData && selectedInvoiceData.invoice_no}
                    </span>
                  </TextStyles.FontSize14px>
                )}
                <div
                  className="d-flex"
                  onClick={() => setEditClientInfo(true)}
                  style={{ cursor: "pointer" }}
                >
                  <ReactTooltipWithIcon
                    anchorId={`edit_client_info`}
                    content="Edit client's information"
                    place="right"
                    variant="grey"
                  />
                  <div id="edit_client_info">
                    <TextStyles.FontSize18px className="mt-3">
                      {selectedInvoiceData &&
                        selectedInvoiceData.client_id &&
                        selectedInvoiceData.client_id.company_name}
                    </TextStyles.FontSize18px>
                    <TextStyles.GreyFont14px className="mt-2 ">
                      {selectedInvoiceData &&
                        selectedInvoiceData.client_id &&
                        selectedInvoiceData.client_id.street}
                      {selectedInvoiceData &&
                      selectedInvoiceData.client_id &&
                      selectedInvoiceData.client_id.suite
                        ? ", "
                        : ""}
                      {selectedInvoiceData &&
                        selectedInvoiceData.client_id &&
                        selectedInvoiceData.client_id.suite}
                      {selectedInvoiceData &&
                      selectedInvoiceData.client_id &&
                      selectedInvoiceData.client_id.city
                        ? ", "
                        : ""}
                      {selectedInvoiceData &&
                        selectedInvoiceData.client_id &&
                        selectedInvoiceData.client_id.city}
                      {selectedInvoiceData &&
                      selectedInvoiceData.client_id &&
                      selectedInvoiceData.client_id.state
                        ? ", "
                        : ""}
                      {selectedInvoiceData &&
                        selectedInvoiceData.client_id &&
                        selectedInvoiceData.client_id.state}
                      {selectedInvoiceData &&
                      selectedInvoiceData.client_id &&
                      selectedInvoiceData.client_id.country
                        ? ", "
                        : ""}
                      {selectedInvoiceData &&
                        selectedInvoiceData.client_id &&
                        selectedInvoiceData.client_id.country}
                      {selectedInvoiceData &&
                      selectedInvoiceData.client_id &&
                      selectedInvoiceData.client_id.zip_code
                        ? ", "
                        : ""}
                      {selectedInvoiceData &&
                        selectedInvoiceData.client_id &&
                        selectedInvoiceData.client_id.zip_code}
                      {selectedInvoiceData &&
                      selectedInvoiceData.client_id &&
                      selectedInvoiceData.client_id.tax_id_label
                        ? ", "
                        : ""}
                      {selectedInvoiceData &&
                        selectedInvoiceData.client_id &&
                        selectedInvoiceData.client_id.tax_id_label}
                      {selectedInvoiceData &&
                      selectedInvoiceData.client_id &&
                      selectedInvoiceData.client_id.tax_id_number
                        ? ", "
                        : ""}
                      {selectedInvoiceData &&
                        selectedInvoiceData.client_id &&
                        selectedInvoiceData.client_id.tax_id_number}
                    </TextStyles.GreyFont14px>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end w-100">
                <ReactTooltipWithIcon
                  anchorId={`edit_dates`}
                  content="Edit dates"
                  place="right"
                  variant="grey"
                />
                <div
                  className="d-flex flex-column align-items-end"
                  id={`edit_dates`}
                  style={{ cursor: "pointer" }}
                  onClick={() => setEditInvoiceDates(true)}
                >
                  <TextStyles.FontSize16px
                    style={{
                      color:
                        brandAndLogoData && brandAndLogoData.brand_color
                          ? brandAndLogoData.brand_color
                          : Colors.primary,
                      fontWeight: 600,
                    }}
                  >
                    {selectedInvoiceData &&
                      selectedInvoiceData.currency &&
                      selectedInvoiceData.currency.symbol}
                    {totalDueAmount > 0
                      ? parseFloat(totalDueAmount).toFixed(2)
                      : "00.00"}
                  </TextStyles.FontSize16px>
                  <TextStyles.GreyFont14px className="mt-2">
                    <span style={{ color: Colors.font1 }}>Due: </span>
                    <span>
                      {selectedInvoiceData &&
                      selectedInvoiceData.issued &&
                      selectedInvoiceData.due_date &&
                      moment(selectedInvoiceData.issued).format(
                        "DD MMM YYYY"
                      ) !==
                        moment(selectedInvoiceData.due_date).format(
                          "DD MMM YYYY"
                        )
                        ? moment(selectedInvoiceData.due_date).format(
                            "DD MMM YYYY"
                          )
                        : "Upon receipt"}
                    </span>
                  </TextStyles.GreyFont14px>
                  <TextStyles.GreyFont14px className="mt-2">
                    <span style={{ color: Colors.font1 }}>Issued: </span>
                    <span>
                      {selectedInvoiceData && selectedInvoiceData.issued
                        ? moment(
                            selectedInvoiceData && selectedInvoiceData.issued
                          ).format("DD MMM YYYY")
                        : moment(new Date()).format("DD MMM YYYY")}
                    </span>
                  </TextStyles.GreyFont14px>
                </div>
              </div>
            </div>

            <div className="review_box_padding">
              <div
                className="my-3"
                style={{
                  width: "100%",
                  height: "1px",
                  border: "0.5px solid #f6f6f6",
                }}
              />
            </div>

            {selectedInvoiceData.invoice_services.length > 0 && (
              <div className="edit_invoice_service review_box_padding">
                <div className="d-flex align-items-center mb-3">
                  <TextStyles.FontSize20px
                    style={{ fontWeight: 500, fontSize: 24 }}
                  >
                    {selectedInvoiceData &&
                      selectedInvoiceData.project_id &&
                      selectedInvoiceData.project_id.project_name}
                  </TextStyles.FontSize20px>

                  <ReactTooltip
                    anchorId={`edit_invoice_services`}
                    content="Edit services"
                    variant="grey"
                  />
                  <BorderColorOutlinedIcon
                    id={`edit_invoice_services`}
                    onClick={() => setEditInvoiceService(true)}
                    className="edit_invoice_serviceBtn"
                    sx={{
                      width: 32,
                      height: 32,
                      color: Colors.font2,
                      border: "2px solid #ececec",
                      background: "#f6f6f6",
                      borderRadius: 2,
                      padding: "3px",
                      right: 10,
                      cursor: "pointer",
                      position: "absolute",
                    }}
                  />
                </div>
                <div className="d-flex gap-2">
                  <TextStyles.FontSize18px
                    className="d-flex w-100"
                    style={{
                      minWidth: 50,
                    }}
                  >
                    <span className="three-dots">Description</span>
                  </TextStyles.FontSize18px>
                  <TextStyles.FontSize18px
                    className="d-flex justify-content-end w-100"
                    style={{ maxWidth: 60, minWidth: 50 }}
                  >
                    <span className="three-dots">Qty</span>
                  </TextStyles.FontSize18px>
                  <TextStyles.FontSize18px
                    className="d-flex justify-content-end w-100"
                    style={{ maxWidth: 110, minWidth: 50 }}
                  >
                    <span className="three-dots">Price</span>
                  </TextStyles.FontSize18px>
                  <TextStyles.FontSize18px
                    className="d-flex justify-content-end w-100"
                    style={{ maxWidth: 120, minWidth: 50 }}
                  >
                    <span className="three-dots">Amount</span>
                  </TextStyles.FontSize18px>
                </div>
                <div
                  className="my-3"
                  style={{
                    width: "100%",
                    height: "1px",
                    border: "0.5px solid #f6f6f6",
                  }}
                />
                {selectedInvoiceData &&
                  selectedInvoiceData.invoice_services &&
                  selectedInvoiceData.invoice_services
                    .filter((item) => item.checked)
                    .map((data, index) => {
                      return (
                        <div className="d-flex gap-2 mb-2" key={index}>
                          <div
                            className="d-flex flex-column w-100"
                            style={{
                              minWidth: 50,
                            }}
                          >
                            <TextStyles.FontSize14px className="three-dots">
                              <span className="three-dots">
                                {data.service_name}
                              </span>
                            </TextStyles.FontSize14px>
                            <TextStyles.GreyFont14px className="d-none d-md-block">
                              {data.desc}
                            </TextStyles.GreyFont14px>
                          </div>
                          <TextStyles.FontSize14px
                            className="d-flex justify-content-end w-100"
                            style={{ maxWidth: 60, minWidth: 50 }}
                          >
                            <span className="three-dots">
                              {data.service_rate_count}
                            </span>
                          </TextStyles.FontSize14px>

                          <TextStyles.FontSize14px
                            className="d-flex justify-content-end w-100"
                            style={{ maxWidth: 110, minWidth: 50 }}
                          >
                            <span className="three-dots">
                              {" "}
                              {selectedInvoiceData &&
                                selectedInvoiceData.currency &&
                                selectedInvoiceData.currency.symbol}
                              {data.service_rate
                                ? parseFloat(data.service_rate).toFixed(2)
                                : "00.00"}
                            </span>{" "}
                          </TextStyles.FontSize14px>

                          <TextStyles.FontSize14px
                            className="d-flex justify-content-end w-100"
                            style={{ maxWidth: 120, minWidth: 50 }}
                          >
                            <span className="three-dots">
                              {selectedInvoiceData &&
                                selectedInvoiceData.currency &&
                                selectedInvoiceData.currency.symbol}

                              {data.service_rate && data.service_rate_count
                                ? parseFloat(
                                    data.service_rate * data.service_rate_count
                                  ).toFixed(2)
                                : "00.00"}
                            </span>
                          </TextStyles.FontSize14px>
                        </div>
                      );
                    })}
                <div
                  className="my-3"
                  style={{
                    width: "100%",
                    height: "1px",
                    border: "0.5px solid #f6f6f6",
                  }}
                />
              </div>
            )}

            {selectedInvoiceData.line_item_services.length > 0 && (
              <div>
                <div className="d-flex review_box_padding mb-2">
                  <TextStyles.FontSize20px
                    style={{ fontWeight: 500, fontSize: 24 }}
                  >
                    {selectedInvoiceData &&
                    selectedInvoiceData.project_id &&
                    selectedInvoiceData.project_id.project_name
                      ? "Other services"
                      : "Services"}
                  </TextStyles.FontSize20px>
                </div>
                <div className="d-flex gap-2 review_box_padding">
                  <TextStyles.FontSize18px
                    className="d-flex w-100"
                    style={{
                      minWidth: 50,
                    }}
                  >
                    <span className="three-dots">Description</span>
                  </TextStyles.FontSize18px>
                  <TextStyles.FontSize18px
                    className="d-flex justify-content-end w-100"
                    style={{ maxWidth: 60, minWidth: 50 }}
                  >
                    <span className="three-dots">Qty</span>
                  </TextStyles.FontSize18px>
                  <TextStyles.FontSize18px
                    className="d-flex justify-content-end w-100"
                    style={{ maxWidth: 110, minWidth: 50 }}
                  >
                    <span className="three-dots">Price</span>
                  </TextStyles.FontSize18px>
                  <TextStyles.FontSize18px
                    className="d-flex justify-content-end w-100"
                    style={{ maxWidth: 120, minWidth: 50 }}
                  >
                    <span className="three-dots">Amount</span>
                  </TextStyles.FontSize18px>
                </div>

                <div className="review_box_padding">
                  <div
                    className="my-3"
                    style={{
                      width: "100%",
                      height: "1px",
                      border: "0.5px solid #f6f6f6",
                    }}
                  />
                </div>

                {selectedInvoiceData &&
                  selectedInvoiceData.line_item_services &&
                  selectedInvoiceData.line_item_services.map((data, index) => {
                    return (
                      <div
                        className="d-flex gap-2 edit_line_item_service review_box_padding mb-2"
                        key={index}
                      >
                        <div
                          className="d-flex flex-column w-100"
                          style={{
                            minWidth: 50,
                          }}
                        >
                          <TextStyles.FontSize14px className="three-dots">
                            <span className="three-dots">
                              {data.service_name}
                            </span>
                          </TextStyles.FontSize14px>
                          <TextStyles.GreyFont14px className="d-none d-md-block">
                            {data.desc}
                          </TextStyles.GreyFont14px>
                        </div>
                        <TextStyles.FontSize14px
                          className="d-flex justify-content-end w-100"
                          style={{ maxWidth: 60, minWidth: 50 }}
                        >
                          <span className="three-dots">
                            {data.service_rate_count}
                          </span>
                        </TextStyles.FontSize14px>

                        <TextStyles.FontSize14px
                          className="d-flex justify-content-end w-100"
                          style={{ maxWidth: 110, minWidth: 50 }}
                        >
                          <span className="three-dots">
                            {" "}
                            {selectedInvoiceData &&
                              selectedInvoiceData.currency &&
                              selectedInvoiceData.currency.symbol}
                            {data.service_rate
                              ? parseFloat(data.service_rate).toFixed(2)
                              : "00.00"}
                          </span>{" "}
                        </TextStyles.FontSize14px>

                        <TextStyles.FontSize14px
                          className="d-flex justify-content-end w-100"
                          style={{ maxWidth: 120, minWidth: 50 }}
                        >
                          <span className="three-dots">
                            {selectedInvoiceData &&
                              selectedInvoiceData.currency &&
                              selectedInvoiceData.currency.symbol}

                            {data.service_rate && data.service_rate_count
                              ? parseFloat(
                                  data.service_rate * data.service_rate_count
                                ).toFixed(2)
                              : "00.00"}
                          </span>
                        </TextStyles.FontSize14px>
                        <ReactTooltip
                          anchorId={`edit_line_item_services.${index}`}
                          content="Edit service"
                          variant="grey"
                        />

                        <BorderColorOutlinedIcon
                          id={`edit_line_item_services.${index}`}
                          onClick={() => {
                            setSelectedData({ line_item: data, index: index });
                            setEditLineItemService(true);
                          }}
                          className="edit_line_item_serviceBtn"
                          sx={{
                            width: 32,
                            height: 32,
                            color: Colors.font2,
                            border: "2px solid #ececec",
                            background: "#f6f6f6",
                            borderRadius: 2,
                            padding: "3px",
                            right: 10,
                            cursor: "pointer",
                            position: "absolute",
                          }}
                        />
                        <ReactTooltip
                          anchorId={`delete_line_item_services.${index}`}
                          content="Delete service"
                          variant="grey"
                        />
                        <DeleteForeverRoundedIcon
                          id={`delete_line_item_services.${index}`}
                          onClick={() => deleteLineItemServiceHandler(index)}
                          className="delete_line_item_serviceBtn"
                          sx={{
                            width: 32,
                            height: 32,
                            color: Colors.font2,
                            border: "2px solid #ececec",
                            background: "#f6f6f6",
                            borderRadius: 2,
                            padding: "3px",
                            right: 50,
                            cursor: "pointer",
                            position: "absolute",
                          }}
                        />
                      </div>
                    );
                  })}

                <div className="review_box_padding">
                  <div
                    className=" my-3"
                    style={{
                      width: "100%",
                      height: "1px",
                      border: "0.5px solid #f6f6f6",
                    }}
                  />
                </div>
              </div>
            )}

            <div className="review_box_padding mt-3">
              <div className="d-flex gap-2">
                <div
                  className="d-flex w-100"
                  style={{
                    minWidth: 50,
                  }}
                />
                <TextStyles.FontSize14px
                  className="d-flex justify-content-end w-100"
                  style={{ maxWidth: 110, minWidth: 50 }}
                >
                  <span className="three-dots">Subtotal</span>
                </TextStyles.FontSize14px>
                <TextStyles.FontSize14px
                  className="d-flex justify-content-end w-100"
                  style={{ maxWidth: 120, minWidth: 50 }}
                >
                  <span className="three-dots">
                    {selectedInvoiceData &&
                      selectedInvoiceData.currency &&
                      selectedInvoiceData.currency.symbol}
                    {totalServiceAmount > 0
                      ? parseFloat(totalServiceAmount).toFixed(2)
                      : "00.00"}
                  </span>
                </TextStyles.FontSize14px>
              </div>
            </div>

            <div className="d-flex review_box_padding">
              <div className="d-flex gap-2 w-100">
                <div
                  className="d-flex w-100"
                  style={{
                    minWidth: 50,
                  }}
                />

                <TextStyles.FontSize14px
                  className="d-flex justify-content-end w-100"
                  style={{ maxWidth: 230, minWidth: 50 }}
                >
                  <div
                    className="my-2"
                    style={{
                      width: "100%",
                      height: "1px",
                      border: "0.5px solid #f6f6f6",
                    }}
                  />
                </TextStyles.FontSize14px>
              </div>
            </div>

            {selectedInvoiceData && selectedInvoiceData.taxes.length > 0 ? (
              selectedInvoiceData.taxes.map((data, index) => {
                return (
                  <div key={index} className="invoice_taxes review_box_padding">
                    <div className="d-flex gap-2 align-items-center">
                      <div
                        className="d-flex w-100"
                        style={{
                          minWidth: 50,
                        }}
                      />

                      <TextStyles.FontSize14px
                        className="d-flex justify-content-end w-100"
                        style={{
                          minWidth: 50,
                          maxWidth: "fit-content",
                        }}
                      >
                        <span className="three-dots">
                          {data.tax_label}
                          {`(${data.percentage}%)`}
                        </span>
                      </TextStyles.FontSize14px>
                      <TextStyles.FontSize14px
                        className="d-flex justify-content-end w-100"
                        style={{ maxWidth: 120, minWidth: 50 }}
                      >
                        <span className="three-dots">
                          {" "}
                          {selectedInvoiceData &&
                            selectedInvoiceData.currency &&
                            selectedInvoiceData.currency.symbol}
                          {totalServiceAmountAfterDiscount > 0
                            ? (
                                parseFloat(data.percentage) *
                                parseFloat(totalServiceAmountAfterDiscount) *
                                0.01
                              ).toFixed(2)
                            : "00.00"}
                        </span>
                      </TextStyles.FontSize14px>
                      <ReactTooltip
                        anchorId={`edit_taxes.${index}`}
                        content="Edit Taxes"
                        variant="grey"
                      />
                      <BorderColorOutlinedIcon
                        id={`edit_taxes.${index}`}
                        className="edit_invoice_taxBtn"
                        onClick={() => setTaxes(true)}
                        sx={{
                          width: 32,
                          height: 32,
                          color: Colors.font2,
                          border: "2px solid #ececec",
                          background: "#f6f6f6",
                          borderRadius: 2,
                          padding: "3px",
                          right: 10,
                          cursor: "pointer",
                          position: "absolute",
                        }}
                      />
                      <ReactTooltip
                        anchorId={`delete_taxes.${index}`}
                        content="Delete tax"
                        variant="grey"
                      />

                      <DeleteForeverRoundedIcon
                        id={`delete_taxes.${index}`}
                        className="delete_invoice_taxBtn"
                        onClick={() => deleteTaxHandler(index)}
                        sx={{
                          width: 32,
                          height: 32,
                          color: Colors.font2,
                          border: "2px solid #ececec",
                          background: "#f6f6f6",
                          borderRadius: 2,
                          padding: "3px",
                          right: 50,
                          cursor: "pointer",
                          position: "absolute",
                        }}
                      />
                    </div>
                    <div className="d-flex review_box_padding">
                      <div className="d-flex gap-2 w-100">
                        <div
                          className="d-flex w-100"
                          style={{
                            minWidth: 100,
                          }}
                        />

                        <TextStyles.FontSize14px
                          className="d-flex justify-content-end w-100"
                          style={{ maxWidth: 230, minWidth: 50 }}
                        >
                          <div
                            className="my-2"
                            style={{
                              width: "100%",
                              height: "1px",
                              border: "0.5px solid #f6f6f6",
                            }}
                          />
                        </TextStyles.FontSize14px>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <>
                <div className="d-flex review_box_padding">
                  <div className="d-flex gap-2 w-100">
                    <div
                      className="d-flex w-100"
                      style={{
                        minWidth: 100,
                      }}
                    />

                    <TextStyles.GreyFont14px
                      className="d-flex justify-content-end w-100"
                      style={{ maxWidth: 230, minWidth: 50, cursor: "pointer" }}
                      onClick={() => setTaxes(true)}
                    >
                      <ReactTooltip
                        anchorId={`add_taxes`}
                        content="Add taxes"
                        variant="grey"
                      />
                      <span id={`add_taxes`}> + Taxes</span>
                    </TextStyles.GreyFont14px>
                  </div>
                </div>
                <div className="d-flex review_box_padding">
                  <div className="d-flex gap-2 w-100">
                    <div
                      className="d-flex w-100"
                      style={{
                        minWidth: 100,
                      }}
                    />

                    <TextStyles.FontSize14px
                      className="d-flex justify-content-end w-100"
                      style={{ maxWidth: 230, minWidth: 50 }}
                    >
                      <div
                        className="my-2"
                        style={{
                          width: "100%",
                          height: "1px",
                          border: "0.5px solid #f6f6f6",
                        }}
                      />
                    </TextStyles.FontSize14px>
                  </div>
                </div>
              </>
            )}

            {selectedInvoiceData && selectedInvoiceData.discount ? (
              <div className="invoice_discount review_box_padding">
                <div className="d-flex gap-2 align-items-center">
                  <div
                    className="d-flex w-100"
                    style={{
                      minWidth: 50,
                    }}
                  />
                  <TextStyles.FontSize14px
                    className="d-flex justify-content-end w-100"
                    style={{
                      minWidth: 50,
                      maxWidth: "fit-content",
                    }}
                  >
                    <span className="three-dots">
                      Discount
                      {selectedInvoiceData.discount.percentage
                        ? `(${selectedInvoiceData.discount.percentage}%)`
                        : ""}
                    </span>
                  </TextStyles.FontSize14px>
                  <TextStyles.FontSize14px
                    className="d-flex justify-content-end w-100"
                    style={{
                      minWidth: 50,
                      maxWidth: 120,
                    }}
                  >
                    <span className="three-dots">
                      {selectedInvoiceData &&
                        selectedInvoiceData.currency &&
                        selectedInvoiceData.currency.symbol}
                      {selectedInvoiceData.discount &&
                      selectedInvoiceData.discount.percentage
                        ? parseFloat(
                            totalServiceAmount *
                              parseFloat(
                                selectedInvoiceData.discount.percentage
                              ) *
                              0.01
                          ).toFixed(2)
                        : parseFloat(
                            selectedInvoiceData.discount.value
                          ).toFixed(2)}
                    </span>
                  </TextStyles.FontSize14px>

                  <ReactTooltip
                    anchorId={`edit_discount`}
                    content="Edit Discount"
                    variant="grey"
                  />

                  <BorderColorOutlinedIcon
                    id={`edit_discount`}
                    className="edit_invoice_discountBtn"
                    onClick={() => setDiscount(true)}
                    sx={{
                      width: 32,
                      height: 32,
                      color: Colors.font2,
                      border: "2px solid #ececec",
                      background: "#f6f6f6",
                      borderRadius: 2,
                      padding: "3px",
                      right: 10,
                      cursor: "pointer",
                      position: "absolute",
                    }}
                  />

                  <ReactTooltip
                    anchorId={`delete_discount`}
                    content="Delete discount"
                    variant="grey"
                  />

                  <DeleteForeverRoundedIcon
                    id={`delete_discount`}
                    className="delete_invoice_discountBtn"
                    onClick={deleteDiscountHandler}
                    sx={{
                      width: 32,
                      height: 32,
                      color: Colors.font2,
                      border: "2px solid #ececec",
                      background: "#f6f6f6",
                      borderRadius: 2,
                      padding: "3px",
                      right: 50,
                      cursor: "pointer",
                      position: "absolute",
                    }}
                  />
                </div>

                <div className="d-flex review_box_padding">
                  <div className="d-flex gap-2 w-100">
                    <div
                      className="d-flex w-100"
                      style={{
                        minWidth: 100,
                      }}
                    />

                    <TextStyles.FontSize14px
                      className="d-flex justify-content-end w-100"
                      style={{ maxWidth: 230, minWidth: 50 }}
                    >
                      <div
                        className="my-2"
                        style={{
                          width: "100%",
                          height: "1px",
                          border: "0.5px solid #f6f6f6",
                        }}
                      />
                    </TextStyles.FontSize14px>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="d-flex review_box_padding">
                  <div className="d-flex gap-2 w-100">
                    <div
                      className="d-flex w-100"
                      style={{
                        minWidth: 100,
                      }}
                    />

                    <TextStyles.GreyFont14px
                      className="d-flex justify-content-end w-100"
                      style={{ maxWidth: 230, minWidth: 50, cursor: "pointer" }}
                      onClick={() => setDiscount(true)}
                    >
                      <ReactTooltip
                        anchorId={`add_discount`}
                        content="Add discount"
                        variant="grey"
                      />
                      <span id={`add_discount`}> + Discount</span>
                    </TextStyles.GreyFont14px>
                  </div>
                </div>
                <div className="d-flex review_box_padding">
                  <div className="d-flex gap-2 w-100">
                    <div
                      className="d-flex w-100"
                      style={{
                        minWidth: 100,
                      }}
                    />

                    <TextStyles.FontSize14px
                      className="d-flex justify-content-end w-100"
                      style={{ maxWidth: 230, minWidth: 50 }}
                    >
                      <div
                        className="my-2"
                        style={{
                          width: "100%",
                          height: "1px",
                          border: "0.5px solid #f6f6f6",
                        }}
                      />
                    </TextStyles.FontSize14px>
                  </div>
                </div>
              </>
            )}

            <div className="review_box_padding">
              <div className="d-flex gap-2 align-items-center">
                <TextStyles.GreyFont14px
                  className="d-flex w-100"
                  style={{ minWidth: 50, cursor: "pointer" }}
                  onClick={() => setAddLineItemService(true)}
                >
                  <ReactTooltip
                    anchorId={`add_line_item`}
                    content="Add line item"
                    variant="grey"
                  />
                  <span id={`add_line_item`} className="three-dots">
                    + Add line item
                  </span>
                </TextStyles.GreyFont14px>

                <TextStyles.FontSize14px
                  className="d-flex justify-content-end w-100"
                  style={{
                    minWidth: 50,
                    fontWeight: 600,
                    maxWidth: "fit-content",
                  }}
                >
                  <span className="three-dots">Amount due</span>
                </TextStyles.FontSize14px>
                <TextStyles.FontSize14px
                  className="d-flex justify-content-end w-100"
                  style={{
                    minWidth: 50,
                    maxWidth: 120,
                    fontWeight: 600,
                  }}
                >
                  <span className="three-dots">
                    {selectedInvoiceData &&
                      selectedInvoiceData.currency &&
                      selectedInvoiceData.currency.symbol}
                    {totalDueAmount > 0
                      ? parseFloat(totalDueAmount).toFixed(2)
                      : "00.00"}
                  </span>
                </TextStyles.FontSize14px>
              </div>
            </div>
            <div className="d-flex review_box_padding">
              <div
                className="my-3"
                style={{
                  width: "100%",
                  height: "1px",
                  border: "0.5px solid #f6f6f6",
                }}
              />
            </div>

            {selectedInvoiceData &&
              (selectedInvoiceData.invoice_notes === null ||
                selectedInvoiceData.invoice_notes === undefined) && (
                <div className="review_box_padding">
                  <TextStyles.GreyFont14px
                    style={{ cursor: "pointer" }}
                    onClick={() => setEditNote(true)}
                  >
                    <ReactTooltip
                      anchorId={`add_invoice_note`}
                      content="Add a note"
                      variant="grey"
                    />
                    <span id={`add_invoice_note`}> + Notes</span>
                  </TextStyles.GreyFont14px>
                </div>
              )}

            {selectedInvoiceData && selectedInvoiceData.invoice_notes && (
              <div className="d-flex flex-column invoice_notes review_box_padding">
                <TextStyles.FontSize16px>Notes</TextStyles.FontSize16px>
                <TextStyles.GreyFont14px className="mt-2">
                  {selectedInvoiceData && selectedInvoiceData.invoice_notes}
                </TextStyles.GreyFont14px>

                <ReactTooltip
                  anchorId={`edit_invoice_note`}
                  content="Edit note"
                  variant="grey"
                />
                <BorderColorOutlinedIcon
                  id={`edit_invoice_note`}
                  className="edit_invoice_NoteBtn"
                  onClick={() => setEditNote(true)}
                  sx={{
                    width: 32,
                    height: 32,
                    color: Colors.font2,
                    border: "2px solid #ececec",
                    background: "#f6f6f6",
                    borderRadius: 2,
                    padding: "3px",
                    right: 10,
                    cursor: "pointer",
                    position: "absolute",
                  }}
                />

                <ReactTooltip
                  anchorId={`delete_invoice_note`}
                  content="Delete note"
                  variant="grey"
                />

                <DeleteForeverRoundedIcon
                  id={`delete_invoice_note`}
                  className="delete_invoice_NoteBtn"
                  onClick={deleteNotesHandler}
                  sx={{
                    width: 32,
                    height: 32,
                    color: Colors.font2,
                    border: "2px solid #ececec",
                    background: "#f6f6f6",
                    borderRadius: 2,
                    padding: "3px",
                    right: 50,
                    cursor: "pointer",
                    position: "absolute",
                  }}
                />
              </div>
            )}

            <div className="d-flex review_box_padding">
              <div
                className="my-3"
                style={{
                  width: "100%",
                  height: "1px",
                  border: "0.5px solid #f6f6f6",
                }}
              />
            </div>

            <div className="d-flex mb-4 invoice_business review_box_padding">
              <div>
                {businessInfoData && businessInfoData.business_name !== null ? (
                  <TextStyles.FontSize16px>
                    {businessInfoData && businessInfoData.business_name}
                  </TextStyles.FontSize16px>
                ) : (
                  <TextStyles.FontSize16px className="d-flex align-items-center">
                    {data &&
                      `${data.firstName} ${data.lastName} (${data.country.code})`}
                  </TextStyles.FontSize16px>
                )}

                {data && data.email && (
                  <TextStyles.GreyFont14px className="mt-1">
                    <span className="fw-medium">Email: </span>
                    <span>{data.email}</span>
                  </TextStyles.GreyFont14px>
                )}

                {businessInfoData !== null && (
                  <div>
                    {businessInfoData &&
                      businessInfoData.phone_number &&
                      businessInfoData.phone_code && (
                        <TextStyles.GreyFont14px className="mt-1">
                          <span className="fw-medium">Phone: </span>
                          <span>
                            +{businessInfoData.phone_code.country_code}
                            {businessInfoData.phone_number}
                          </span>
                        </TextStyles.GreyFont14px>
                      )}
                    <TextStyles.GreyFont14px className="mt-1">
                      {businessInfoData &&
                        (businessInfoData.street ||
                          businessInfoData.suite ||
                          businessInfoData.city ||
                          businessInfoData.state ||
                          businessInfoData.country ||
                          businessInfoData.zip_code) && (
                          <span className="fw-medium">Address: </span>
                        )}
                      {businessInfoData && businessInfoData.street}
                      {businessInfoData && businessInfoData.suite ? ", " : ""}
                      {businessInfoData && businessInfoData.suite}
                      {businessInfoData && businessInfoData.city ? ", " : ""}
                      {businessInfoData && businessInfoData.city}
                      {businessInfoData && businessInfoData.state ? ", " : ""}
                      {businessInfoData && businessInfoData.state}
                      {businessInfoData && businessInfoData.country ? ", " : ""}
                      {businessInfoData && businessInfoData.country}
                      {businessInfoData && businessInfoData.zip_code
                        ? ", "
                        : ""}
                      {businessInfoData && businessInfoData.zip_code}
                    </TextStyles.GreyFont14px>

                    {businessInfoData && businessInfoData.tax_id_label && (
                      <TextStyles.GreyFont14px className="mt-1">
                        <span className="fw-medium">Tax ID Label: </span>
                        {businessInfoData.tax_id_label}
                      </TextStyles.GreyFont14px>
                    )}

                    {businessInfoData && businessInfoData.tax_id_number && (
                      <TextStyles.GreyFont14px className="mt-1">
                        <span className="fw-medium">Tax ID Number: </span>
                        {businessInfoData.tax_id_number}
                      </TextStyles.GreyFont14px>
                    )}
                  </div>
                )}
              </div>
              <div>
                <ReactTooltip
                  anchorId={`edit_business_info`}
                  content="Edit business information"
                  variant="grey"
                />

                <BorderColorOutlinedIcon
                  id={`edit_business_info`}
                  className="edit_invoice_businessBtn"
                  onClick={() => setEditBusinessInfo(true)}
                  sx={{
                    width: 32,
                    height: 32,
                    color: Colors.font2,
                    border: "2px solid #ececec",
                    background: "#f6f6f6",
                    borderRadius: 2,
                    padding: "3px",
                    right: 10,
                    cursor: "pointer",
                    position: "absolute",
                  }}
                />
              </div>
            </div>
          </Styled.SetupBox>
        </div>
      </div>
      <Styled.NextButton onClick={() => history.push(`${pathname}/send`)}>
        Next
      </Styled.NextButton>
      {changeCurrency && (
        <ChangeCurrency
          show={changeCurrency}
          handleClose={() => setChangeCurrency(false)}
          selectedData={selectedInvoiceData}
        />
      )}
      {editInvoice && (
        <EditInvoiceTitle
          show={editInvoice}
          handleClose={() => setEditInvoice(false)}
          selectedData={selectedInvoiceData}
        />
      )}
      {editInvoiceDates && (
        <EditInvoiceDates
          show={editInvoiceDates}
          handleClose={() => setEditInvoiceDates(false)}
          selectedData={selectedInvoiceData}
        />
      )}
      {editClientInfo && (
        <EditClientInfo
          show={editClientInfo}
          handleClose={() => setEditClientInfo(false)}
          client_id={selectedInvoiceData.client_id}
        />
      )}
      {editInvoiceService && (
        <EditInvoiceService
          show={editInvoiceService}
          handleClose={() => setEditInvoiceService(false)}
          selectedData={selectedInvoiceData}
        />
      )}
      {editLineItemService && (
        <EditLineItemService
          show={editLineItemService}
          handleClose={() => setEditLineItemService(false)}
          selectedData={selectedInvoiceData}
          selectedItem={selectedData.line_item}
          selectedIndex={selectedData.index}
        />
      )}
      {addLineItemService && (
        <AddLineItemService
          show={addLineItemService}
          handleClose={() => setAddLineItemService(false)}
          selectedData={selectedInvoiceData}
        />
      )}
      {taxes && (
        <InvoiceTaxes
          show={taxes}
          handleClose={() => setTaxes(false)}
          selectedData={selectedInvoiceData}
          totalServiceAmount={totalServiceAmountAfterDiscount}
        />
      )}
      {discount && (
        <DiscountInvoices
          show={discount}
          handleClose={() => setDiscount(false)}
          selectedData={selectedInvoiceData}
          totalServiceAmount={totalServiceAmount}
        />
      )}
      {editNote && (
        <InvoiceNotes
          show={editNote}
          handleClose={() => setEditNote(false)}
          selectedData={selectedInvoiceData}
        />
      )}
      {editBusinessInfo && (
        <EditBusinessInfo
          show={editBusinessInfo}
          handleClose={() => setEditBusinessInfo(false)}
          businessInfoData={businessInfoData}
          data={data}
        />
      )}
    </div>
  );
};

export default ReviewInvoice;
