import React, { useState } from "react";
import SideDrawer from "../../Components/Navigation/SideDrawer/SideDrawer";
import Toolbar from "../../Components/Navigation/Navbars/ToolBar";

const Layout = ({ children }) => {
  const [sideDrawerVisible, setSideDrawerVisible] = useState(false);
  const sideDrawerHandler = () => setSideDrawerVisible(false);
  const toggleHandler = () => setSideDrawerVisible(!sideDrawerVisible);
  let urlElements = window.location.href.split("/");

  return (
    <>
      {urlElements[3] !== "db" ? (
        <>
          <Toolbar open={toggleHandler} scroll={300} />
          <SideDrawer close={sideDrawerHandler} open={sideDrawerVisible} />
          <main>{children}</main>
        </>
      ) : (
        <main>{children}</main>
      )}
    </>
  );
};

export default Layout;
