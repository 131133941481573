import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./Footer.module.css";
import logo from "../../Assets/assetsnew/frontend_logo_with_name.webp";
import Colors from "../../Constants/Colors";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "../../Assets/frontendImages/twitter.svg";

import { Rating } from "@mui/material";

import PartnerImg1 from "../../Assets/frontendImages/partner-image-01.svg";
import PartnerImg2 from "../../Assets/frontendImages/partner-image-02.svg";
import PartnerImg3 from "../../Assets/frontendImages/partner-image-03.svg";
import PartnerImg4 from "../../Assets/frontendImages/partner-image-04.svg";
import PartnerImg5 from "../../Assets/frontendImages/partner-image-05.svg";
import StarIcon from "@mui/icons-material/Star";

const Footer = () => {
  return (
    <section className={`${styles.footer_section}`}>
      <div className="container-fluid py-4 py-md-5">
        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="row">
              <div className="col-12 col-sm-6">
                <div className={`${styles.footer_list_title} mb-3`}>
                  Features
                </div>
                <ul className={`${styles.reset_ul}`}>
                  <li>
                    <NavLink
                      to="/features/task-management"
                      style={{ textDecoration: "none" }}
                      className={`${styles.footer_link}`}
                    >
                      Task management
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/features/invoicing"
                      style={{ textDecoration: "none" }}
                      className={`${styles.footer_link}`}
                    >
                      Invoicing
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/features/agreements"
                      style={{ textDecoration: "none" }}
                      className={`${styles.footer_link}`}
                    >
                      Agreements
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/features/transactions"
                      style={{ textDecoration: "none" }}
                      className={`${styles.footer_link}`}
                    >
                      Transactions
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/features/statements"
                      style={{ textDecoration: "none" }}
                      className={`${styles.footer_link}`}
                    >
                      Statements
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/features/time-tracking"
                      style={{ textDecoration: "none" }}
                      className={`${styles.footer_link}`}
                    >
                      Time tracking
                    </NavLink>
                  </li>
                </ul>
              </div>
              <div className="col-12 col-sm-6">
                <div className={`${styles.footer_list_title} mb-3`}>Info</div>
                <ul className={`${styles.reset_ul}`}>
                  <li>
                    <NavLink
                      to="/why-zodot"
                      style={{ textDecoration: "none" }}
                      className={`${styles.footer_link}`}
                    >
                      Why Zodot?
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/reviews"
                      style={{ textDecoration: "none" }}
                      className={`${styles.footer_link}`}
                    >
                      Reviews
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/contact"
                      style={{ textDecoration: "none" }}
                      className={`${styles.footer_link}`}
                    >
                      Contact us
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-6">
            <div className="row">
              <div className="col-12 col-sm-6">
                <div className={`${styles.footer_list_title} mb-3`}>
                  Policies
                </div>
                <ul className={`${styles.reset_ul}`}>
                  <li>
                    <NavLink
                      to="/terms-of-service"
                      style={{ textDecoration: "none" }}
                      className={`${styles.footer_link}`}
                    >
                      Terms of service
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/privacy-policy"
                      style={{ textDecoration: "none" }}
                      className={`${styles.footer_link}`}
                    >
                      Privacy policy
                    </NavLink>
                  </li>
                </ul>
              </div>
              <div className="col-12 col-sm-6">
                <div className="mb-4 text-center text-sm-start mt-3 mt-sm-0">
                  <img
                    src={logo}
                    alt="Logo"
                    style={{
                      width: "100%",
                      maxWidth: 200,
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                </div>
                <div className="d-flex gap-4 justify-content-center justify-content-sm-start">
                  <NavLink
                    aria-label="instagram"
                    to={{
                      pathname: "https://www.instagram.com/zodotcorp",
                    }}
                    target="_blank"
                    style={{ textDecoration: "none" }}
                    className={`${styles.footer_link}`}
                  >
                    <InstagramIcon sx={{ color: Colors.white, fontSize: 35 }} />
                  </NavLink>

                  <NavLink
                    aria-label="twitter"
                    to={{ pathname: "https://twitter.com/Zodot_team" }}
                    target="_blank"
                    style={{ textDecoration: "none" }}
                    className={`${styles.footer_link}`}
                  >
                    <img
                      width="35px"
                      height="100%"
                      src={TwitterIcon}
                      alt="twriterIcons"
                    />
                  </NavLink>

                  <NavLink
                    aria-label="linkedin"
                    to={{ pathname: "https://www.linkedin.com/company/zodot/" }}
                    target="_blank"
                    style={{ textDecoration: "none" }}
                    className={`${styles.footer_link}`}
                  >
                    <LinkedInIcon sx={{ color: Colors.white, fontSize: 35 }} />
                  </NavLink>
                </div>
              </div>
            </div>

            <div
              className={`d-flex flex-wrap gap-3 justify-content-center justify-content-xl-start ${styles.partnerRatingWrapper}`}
            >
              <div
                className={`py-2 px-3 d-flex flex-column align-items-center justify-content-center gap-1 rounded ${styles.partnerRating}`}
              >
                <img
                  className="w-100"
                  src={PartnerImg1}
                  alt="PartnerImg1"
                  style={{ height: 20 }}
                />
                <p className="m-0" style={{ fontSize: 11, fontWeight: 600 }}>
                  4.5/5
                </p>
                <Rating
                  sx={{ fontSize: "12px" }}
                  name="rating"
                  value={4.5}
                  precision={0.5}
                  readOnly
                  emptyIcon={<StarIcon fontSize="inherit" />}
                />
              </div>

              <div
                className={`py-2 px-3 d-flex flex-column align-items-center justify-content-center gap-1 rounded ${styles.partnerRating}`}
              >
                <img
                  className="w-100"
                  src={PartnerImg2}
                  alt="PartnerImg2"
                  style={{ height: 20 }}
                />
                <p className="m-0" style={{ fontSize: 11, fontWeight: 600 }}>
                  4.3/5
                </p>
                <Rating
                  sx={{ fontSize: "12px" }}
                  name="rating"
                  value={4.5}
                  precision={0.5}
                  readOnly
                  emptyIcon={<StarIcon fontSize="inherit" />}
                />
              </div>

              <div
                className={`py-2 px-3 d-flex flex-column align-items-center justify-content-center gap-1 rounded ${styles.partnerRating}`}
              >
                <img
                  className="w-100"
                  src={PartnerImg3}
                  alt="PartnerImg3"
                  style={{ height: 20 }}
                />
                <p className="m-0" style={{ fontSize: 11, fontWeight: 600 }}>
                  4.7/5
                </p>
                <Rating
                  sx={{ fontSize: "12px" }}
                  name="rating"
                  value={4.5}
                  precision={0.5}
                  readOnly
                  emptyIcon={<StarIcon fontSize="inherit" />}
                />
              </div>

              <div
                className={`py-2 px-3 d-flex flex-column align-items-center justify-content-center gap-1 rounded ${styles.partnerRating}`}
              >
                <img
                  className="w-100"
                  src={PartnerImg4}
                  alt="PartnerImg4"
                  style={{ height: 20 }}
                />
                <p className="m-0" style={{ fontSize: 11, fontWeight: 600 }}>
                  4.5/5
                </p>
                <Rating
                  sx={{ fontSize: "12px" }}
                  name="rating"
                  value={4.5}
                  precision={0.5}
                  readOnly
                  emptyIcon={<StarIcon fontSize="inherit" />}
                />
              </div>

              <div
                className={`py-2 px-3 d-flex flex-column align-items-center justify-content-center gap-1 rounded ${styles.partnerRating}`}
              >
                <img
                  className="w-100"
                  src={PartnerImg5}
                  alt="PartnerImg5"
                  style={{ height: 20 }}
                />
                <p className="m-0" style={{ fontSize: 11, fontWeight: 600 }}>
                  4.6/5
                </p>
                <Rating
                  sx={{ fontSize: "12px" }}
                  name="rating"
                  value={4.5}
                  precision={0.5}
                  readOnly
                  emptyIcon={<StarIcon fontSize="inherit" />}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={`${styles.footer_copy_write} mt-3`}>
          &copy; {new Date().getFullYear()} Zodot LLC. All rights reserved.
        </div>
      </div>
    </section>
  );
};

export default Footer;
