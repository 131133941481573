import React, { useState } from "react";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { message } from "antd";
import TextStyles from "../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";
import { useDispatch, useSelector } from "react-redux";
import { updateMiscellaneousPC } from "../../../../../../store/service/proposalAndContractService";
import RemoveModal from "../../../../Commons/RemoveModal";
import MiscellaneousModal from "../../PageModals/MiscellaneousModal";
import Colors from "../../../../../../Constants/Colors";

const Miscellaneous = () => {
  const selectedData = useSelector(
    (state) => state.proposalAndContract.selectedData
  );
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const handleCloseRemoveModal = () => setShowRemoveModal(false);

  const heading =
    selectedData &&
    selectedData.miscellaneous &&
    selectedData.miscellaneous.heading
      ? selectedData.miscellaneous.heading
      : "Miscellaneous";

  const paragraph =
    selectedData &&
    selectedData.miscellaneous &&
    selectedData.miscellaneous.paragraph
      ? selectedData.miscellaneous.paragraph
      : `1. Section and subsection headings in this Agreement are for convenience only and will not be used in interpreting the Agreement. Neither party will be considered the drafter of this Agreement for interpreting any ambiguity.\n\n2.This Agreement may be signed with manual or facsimile signatures and in counterparts, each of which will be considered an original, and together they will constitute one and the same instrument. If any provision is found illegal or unenforceable, it will be revised to give maximum effect to its original intent or eliminated if not permissible, while the rest of the Agreement remains enforceable.\n\nSeverability: If any provision of this Agreement is held invalid or unenforceable by a court of competent jurisdiction, such invalidity shall not affect the validity or operation of any other provision, and the invalid provision shall be severed from the Agreement.\n\nAssignability: This Agreement and the rights and obligations with respect to Contractor are personal and may not be assigned by Contractor without prior written consent from Client. Clients may assign this Agreement to a successor or purchaser of a portion of its assets.`;

  const hideClauseHandler = () => {
    const Obj = {
      _id: selectedData._id,
      miscellaneous: {
        required: false,
        heading: null,
        paragraph: null,
      },
    };
    dispatch(updateMiscellaneousPC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleCloseRemoveModal();
        message.success("Clause hidden successfully!");
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  return (
    <div
      style={{
        display:
          selectedData &&
          selectedData.miscellaneous &&
          selectedData.miscellaneous.required === true
            ? "inline"
            : "none",
      }}
    >
      <Styled.ReviewContentBox className="mt-4">
        <Styled.EditButtonToolTip
          onClick={() => setShowModal(true)}
          className="contract_review_edit_tp"
          style={{ marginLeft: -60 }}
        >
          <BorderColorOutlinedIcon sx={{ color: Colors.white, fontSize: 20 }} />
          <span className="ms-1">Edit</span>
        </Styled.EditButtonToolTip>
        <Styled.EditButtonToolTip
          backgroundColor="#383838"
          className="contract_review_edit_tp"
          style={{ marginLeft: 60 }}
          onClick={() => {
            setShowRemoveModal(true);
            setConfirmDialog({
              onConfirm: hideClauseHandler,
            });
          }}
        >
          <VisibilityOffIcon sx={{ color: Colors.white, fontSize: 20 }} />
          <span className="ms-1">Hide</span>
        </Styled.EditButtonToolTip>

        <TextStyles.FontSize20px className="mb-2">
          {heading}
        </TextStyles.FontSize20px>
        <TextStyles.FontSize14px style={{ whiteSpace: "break-spaces" }}>
          {paragraph}
        </TextStyles.FontSize14px>
      </Styled.ReviewContentBox>
      {showModal && (
        <MiscellaneousModal
          selectedData={selectedData}
          heading={heading}
          paragraph={paragraph}
          show={showModal}
          handleClose={() => setShowModal(false)}
        />
      )}
      {confirmDialog && (
        <RemoveModal
          title={heading}
          confirmDialog={confirmDialog}
          showRemoveModal={showRemoveModal}
          handleCloseRemoveModal={handleCloseRemoveModal}
        />
      )}
    </div>
  );
};

export default Miscellaneous;
